import {
  UPDATE_AGENT_PROFILE_DETAIL,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_PROFILE_FAILURE,
  FETCH_UPDATE_AGENT_DETAIL,
  FETCH_UPDATE_AGENT_SUCCESS,
  FETCH_UPDATE_AGENT_FAILED,
  UPDATE_AGENT_PASSWORD_SUCCESS,
  UPDATE_AGENT_PASSWORD,
  UPDATE_AGENT_PASSWORD_FAILED
} from "./ProfileActionType";

const initialState = {
  activeAgent: {
    fetchUpdateAgent: {},
    isFetchUpdate: false,
    isUpdateProfile: false,
  },
};

const profilePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATE_AGENT_DETAIL: {
      return {
        ...state,
        isFetchUpdate: action?.isFetchUpdate,
      };
    }

    case FETCH_UPDATE_AGENT_SUCCESS: {
      return {
        ...state,
        activeAgent: {
          ...state.fetchUpdateAgent,
          fetchUpdateAgent: action?.activeAgent,
          isFetchUpdate: action?.isFetchUpdate,
        },
      };
    }

    case FETCH_UPDATE_AGENT_FAILED: {
      return {
        ...state,
        isFetchUpdate: action?.isFetchUpdate,
      };
    }

    case UPDATE_AGENT_PROFILE_DETAIL: {
      return {
        ...state,
        activeAgent: {
          ...state.isUpdateProfile,
          isUpdateProfile: action?.isUpdateProfile,
        },
      };
    }

    case UPDATE_AGENT_PROFILE_SUCCESS: {
      return {
        ...state,
        activeAgent: {
          ...state.activeAgent,
          isUpdateProfile: action?.isUpdateProfile,
          fetchUpdateAgent: {
            ...state.activeAgent.fetchUpdateAgent,
            agent: action?.agent,
          },
        },
      };
    }

    case UPDATE_AGENT_PROFILE_FAILURE: {
      return {
        ...state,
        activeAgent: {
          ...state.isUpdateProfile,
          isUpdateProfile: action?.isUpdateProfile,
        },
      };
    }


    case UPDATE_AGENT_PASSWORD_FAILED: {
      return {
        ...state,
        isFetchUpdate: action?.isFetchUpdate,
      };
    }

    default:
      return state;
  }
};

export default profilePageReducer;
