
import React from 'react'
import styled from "styled-components";
import Box from "konnekt/Box";
import TextInput from "konnekt/TextInput";
import Button from 'konnekt/Buttons';
import Icon from 'konnekt/Icon';
import Label from 'konnekt/Label';

import DropDownControlled from "konnekt/Dropdown";
import { getMediaTypeOption, getLanguageOption, getStateOption , getCountryOption} from "util/utility";


const UpdateShipping = ({shippingDetails, shippingUpdateHandler, updateShippingAddressHandler, shippingAddressList}) => {
    return (
        <Box>
        <Box mt="08" display="flex" justifyContent="space-between">
          <Box width="100%">
            <Label variant="text4" mb="5px">
              First Name
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("first_name", e)}
              value={shippingDetails?.first_name}
              width="98%"
              bodyTextLimit={24}
              placeholder="First Name"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Last Name
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("last_name", e)}
              value={shippingDetails?.last_name}
              width="98%"
              bodyTextLimit={24}
              placeholder="Last Name"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
        </Box>
        <Box mt="08" display="flex" justifyContent="space-between">
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Email
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("contact_email", e)}
              value={shippingDetails?.contact_email}
              width="98%"
              bodyTextLimit={24}
              placeholder="Email(Optional)"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Phone Number
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("contact_phone", e)}
              value={shippingDetails?.contact_phone}
              width="98%"
              bodyTextLimit={24}
              placeholder="Phone Number(Optional)"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
        </Box>
        <Box mt="08" display="flex" justifyContent="space-between">
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Company
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("company_name", e)}
              value={shippingDetails?.company_name}
              width="98%"
              bodyTextLimit={24}
              placeholder="Company(Optional)"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Address Line 1
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("address_1", e)}
              value={shippingDetails?.address_1}
              width="98%"
              bodyTextLimit={24}
              placeholder="Address Line 1 (Optional)"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
        </Box>
        <Box mt="08" width="100%">
          <Label variant="text4" mb="5px">
            Address Line 2
          </Label>
          <TextInput
            type="text"
            onChange={e => shippingUpdateHandler("address_2", e)}
            value={shippingDetails?.address_2}
            width="98%"
            bodyTextLimit={24}
            placeholder="Address Line 2 (Optional)"
            border="1px solid rgba(0,0,0,.08)"
            height="42px"
          />
        </Box>
        <Box mt="08" display="flex" justifyContent="space-between">
          <Box width="100%">
            <Label variant="text4" mb="5px">
              City
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("city", e)}
              value={shippingDetails?.city}
              width="98%"
              bodyTextLimit={24}
              placeholder="City"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Zip
            </Label>
            <TextInput
              type="text"
              onChange={e => shippingUpdateHandler("zip_code", e)}
              value={shippingDetails?.zip_code}
              width="98%"
              bodyTextLimit={24}
              placeholder="Zip"
              border="1px solid rgba(0,0,0,.08)"
              height="42px"
            />
          </Box>
        </Box>
        <Box mt="08" display="flex" justifyContent="space-between">
          <Box width="100%">
            <Label variant="text4" mb="5px">
              Country
            </Label>
            <DropDownControlled
              selectedValue={shippingDetails?.country}
              width="98%"
              height="44px"
              mt="10px"
              variant="lighterGrey"
              options={getCountryOption()}
              onOptionClick={item =>
                shippingUpdateHandler("country", item?.value)
              }
            />
          </Box>
          <Box width="100%">
            <Label variant="text4" mb="5px">
              State
            </Label>
            <DropDownControlled
              selectedValue={shippingDetails?.state}
              width="98%"
              height="44px"
              mt="10px"
              variant="lighterGrey"
              options={getStateOption()}
              onOptionClick={item =>
                shippingUpdateHandler("state", item?.value)
              }
            />
          </Box>
        </Box>
        <Box mt="08" width="100%">
          <Label variant="text4" mb="5px">
            GSTIN
          </Label>
          <TextInput
            type="text"
            onChange={e => shippingUpdateHandler("gstin", e)}
            value={shippingDetails?.gstin}
            width="98%"
            bodyTextLimit={24}
            placeholder="GSTIN"
            border="1px solid rgba(0,0,0,.08)"
            height="42px"
          />
        </Box>
        <Button type="primary" margin="auto" mt="10"
        onClick={() => updateShippingAddressHandler()}
          >
          {shippingAddressList?.length ? "Update" : "Add"}
        </Button>
        <Box height="50"></Box>
      </Box>

    )
}

export default UpdateShipping;
