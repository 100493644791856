import _ from "lodash";
import moment from "moment";
import { messageErrorCodes } from "constants/enums/channelMessageErrors";

import { CLOSE_SEARCH_SECTION } from "modules/Inbox/inboxStateTypes";
import { sortArrayWithTimeStamp, sanitizeFromNumber } from "util/chatUtil";
import {
  UPDATE_ACTIVE_CHAT_ID,
  UPDATE_AGENT_CHANGE_MESSAGE,
  TOGGLE_CHAT_WINDOW,
  UPDATE_CHAT_ACTIVATED_MESSAGE,
  UPDATE_CHAT_CLOSED_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  UPDATE_DELIVERY_EVENT_MESSAGE,
  UPDATE_MESSAGE_THREAD,
  UPDATE_MESSAGE_THREAD_STATUS,
  UPDATE_NOTE_MESSAGE,
  UPDATE_SEEN_EVENT_MESSAGE,
  UPDATE_SEND_EVENT_FAILED_MESSAGE,
  UPDATE_SEND_EVENT_MESSAGE,
  TOGGLE_USER_DETAIL_VIEW,
  UPDATE_ACTIVE_CHAT,
  UPDATE_CONVERSATION_CLOSED,
  ASSIGNING_AGENT_TO_CHAT,
  ASSIGNED_AGENT_TO_CHAT,
  SELECT_CHAT_TYPE,
  SELECT_FILTER_TYPE,
  SELECT_SORT_OPTION,
  UPDATE_DOCUMENT_MESSAGE,
  REMOVE_DOCUMENT_MESSAGE,
  UPDATE_AGENT_ASSIGNMENT_MESSAGE,
  UPDATE_CHAT_OPENED_MESSAGE,
  CLOSING_CHAT,
  UPDATE_CUSTOMER_DETAILS_CHAT,
  UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID,
  UPDATE_INBOX_SYNC_MOBILE,
  SET_NOTIFICATION_RIBBON,
  UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET,
  MARK_MESSAGE_AS_BEING_RETRIED,
  UPDATE_NEW_CONVERSATIONS_WITH_THREAD,
  EMPTY_MESSAGE_THREAD,
  UPDATE_FILTER_OPTIONS,
  UPDATE_LIMIT_EXCEEDED_POPUP_STATE,
  ASSIGNING_CONVERSATION_LABEL,
  UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL,
  CLEAR_CONVERSATION_LABEL,
  CLEAR_CONVERSATION_LABEL_START,
  UPDATE_EVENT_COUNT,
  UPDATE_SPAM_CHAT_STATUS,
  TOOGLE_SPAM_CHAT_START,
  TOOGLE_SPAM_CHAT_SUCCESS,
  TOOGLE_SPAM_CHAT_FAILURE,
  UPDATE_NEW_CUSTOMER_MESSAGES_DATA,
  UPDATE_IS_NEW_CUSTOMER_MESSAGES,
  UPDATE_CHAT_INTERVENED_MESSAGE,
  FETCH_CONVERSATION_EVENTS,
  UPDATE_CONVERSATION_EVENTS,
  SELECT_CHAT_STATUS,
  UPDATE_CHAT_REQUESTING_INTERVENTION_MESSAGE,
  UPDATE_SEARCH_VALUE,
  FETCH_CAMPAIGN_DETAILS,
} from "./ThreadActions";
import clone from "lodash/clone";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const initialState = {
  currentStatus: "",
  currentFilters: {},
  isFetchingActiveChats: false,
  isFetchingClosedChats: false,
  threads: {},
  isClosingChat: false,
  isIntervenedChat: false,
  toggleForChange: false,
  inactivatedThreads: {},
  orgActiveChatCount: 0,
  orgClosedChatCount: 0,
  orgActiveAgentChatCount: 0,
  orgClosedAgentChatCount: 0,
  orgUnassignedChatCount: 0,
  chatThreads: {},
  activeConversation: {},
  activeFromNumber: "",
  isChatWindowVisible: false,
  isUserDetailsViewVisible: false,
  selectedFilter: "All",
  selectedChatType: "Open",
  selectedChatStatus: "initiated",
  selectedSort: "Newest",
  filterOptions: ["All", "You", "Unassigned", "Spam"],
  chatOptions: ["Open", "Closed"],
  sortOptions: ["Newest", "Oldest"],
  selectedConversationUserProfile: {
    userDetails: {},
    tags: [],
    orderHistory: [],
    notes: [],
    conversationHistory: [],
  },
  agentProfileOrder: [],
  quickReplyTemplates: [],
  approvedTemplateMessages: [],
  storedDocumentTemplates: [],
  isFetchingActiveChatMessages: false,
  isAssigningAgent: false,
  sentMessages: {},
  statusMessages: {},
  selectedCustomerChatHistory: {},
  historyThread: {
    chatPageNum: 1,
  },
  selectedCustomerFromNumber: "",
  toggleForDocumentChange: false,
  showNotificationRibbon: false,
  isFetchingConversations: false,
  chats: [],
  chatPageNum: 1,
  chatPageSize: 10,
  chatTotalCount: 0,
  isLimitExceeded: false,
  limitExceedErrorCode: "",
  isAssigningConvLabel: false,
  chatCurrentCount: 0,
  spam: {
    toogleSpamLoading: false,
  },
  isNewCustomerMessage: false,
  newCustomerMessagesCount: 0,
  conversationEvents: {},
  campaignsData: [],
  searchValue: "",
};

const inboxReducer = (state = initialState, action) => {
  let threads = {};
  let inactivatedThreads = {};
  let messageType = "CustomerMessage";
  let chatId = "";
  let fromNumber = "";
  let activeConversation = {};
  let chatThreads = {};
  let messageLength;
  let sentMessages = {};
  const statusMessages = {};

  switch (action.type) {
    case UPDATE_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };

    case UPDATE_CHAT_MESSAGE: {
      let isNewMessage = false;
      let newMessagesCount = 0;
      activeConversation = state.activeConversation;
      threads = state.threads;
      inactivatedThreads = state.inactivatedThreads;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.message.from)
      ) {
        fromNumber = sanitizeFromNumber(action.message.to);
      } else {
        fromNumber = sanitizeFromNumber(action.message.from);
      }
      messageType = action.message.source;
      if (
        activeConversation &&
        activeConversation.phone_number === fromNumber &&
        action.message.wa_message_id &&
        action.message.wa_message_id !== ""
      ) {
        window.trackerQueue.push({
          trackerData: {
            conversation_id: activeConversation?._id,
            last_message_read_id: action.message._id,
            agent_user_id: activeConversation?.active_agent_id,
          },
        });
      }
      if (action.message && fromNumber) {
        const activeConversationPhoneNumber =
          (activeConversation && activeConversation.phone_number) ||
          activeConversation?.customer_id?.wa_phone_number ||
          activeConversation?.customer_id?.wa_phone_number;
        if (activeConversationPhoneNumber === fromNumber) {
          activeConversation.last_message_id = action.message;
          if (messageType === "outbox") {
            activeConversation.last_customer_message_at_utc =
              new moment().toISOString();
            isNewMessage = true;
            newMessagesCount = state.newCustomerMessagesCount + 1;
          } else {
            isNewMessage = false;
            newMessagesCount = 0;
          }
          activeConversation.recieved_at_utc = new moment().toISOString();
          activeConversation.timestamp = new Date().getTime();
          activeConversation.last_message_timestamp =
            action.message?.created_at;
        }
        if (threads[fromNumber]) {
          threads[fromNumber].last_message_id = action.message;
          // Update chatTotalCount
          threads[fromNumber].chatTotalCount =
            threads[fromNumber].chatTotalCount + 1;
          if (threads[fromNumber].messages) {
            if (
              !threads[fromNumber].sentMessages[action.message.wa_message_id] &&
              !threads[fromNumber].sentMessages[action.message.az_id] &&
              !threads[fromNumber].sentMessages[action.message._id]
            ) {
              activeConversation.session_message_count =
                activeConversation?.session_message_count + 1;
              threads[fromNumber].messages.push({
                messageType,
                messageId: action.message.wa_message_id,
                message: action.message,
                timestamp: new Date(action.message.created_at).getTime(),
              });
              if (action.message.wa_message_id) {
                threads[fromNumber].sentMessages[
                  action.message.wa_message_id
                ] = 1;
              }
              if (action.message._id) {
                threads[fromNumber].sentMessages[action.message._id] = 1;
              }
              if (action.message.az_id) {
                threads[fromNumber].sentMessages[action.message.az_id] = 1;
              }
            }
          }
        } else if (
          inactivatedThreads[fromNumber] &&
          inactivatedThreads[fromNumber].messages
        ) {
          inactivatedThreads[fromNumber].messages.push({
            messageType,
            messageId: action.message.wa_message_id,
            message: action.message,
            timestamp: new Date(action.message.created_at_utc).getTime(),
          });
        } else {
          inactivatedThreads[fromNumber] = {};
          inactivatedThreads[fromNumber].messages = [];
          inactivatedThreads[fromNumber].messages.push({
            messageType,
            messageId: action.message.wa_message_id,
            message: action.message,
            timestamp: new Date(action.message.created_at_utc).getTime(),
          });
        }
      }
      return {
        ...state,
        threads,
        inactivatedThreads,
        sentMessages,
        isNewCustomerMessage: isNewMessage,
        newCustomerMessagesCount: newMessagesCount,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_NOTE_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          // update notes count
          threads[fromNumber].noteTotalCount =
            threads[fromNumber].noteTotalCount + 1;
          if (
            !threads[fromNumber].sentMessages[action.event.wa_message_id] &&
            !threads[fromNumber].sentMessages[action.event.az_id] &&
            !threads[fromNumber].sentMessages[action.event._id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "note",
              messageId: action.event.messageId,
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
          }
          if (action.event.wa_message_id) {
            threads[fromNumber].sentMessages[action.event.wa_message_id] = 1;
          }
          if (action.event.az_id) {
            threads[fromNumber].sentMessages[action.event.az_id] = 1;
          }
          if (action.event._id) {
            threads[fromNumber].sentMessages[action.event._id] = 1;
          }
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_SEND_EVENT_MESSAGE:
      // debugger;
      activeConversation = state.activeConversation;
      threads = state.threads;
      inactivatedThreads = state.inactivatedThreads;
      sentMessages = state.sentMessages;
      messageType = action.event.source;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.event.to)
      ) {
        fromNumber = sanitizeFromNumber(action.event.from);
      } else {
        fromNumber = sanitizeFromNumber(action.event.to);
      }
      // if (
      //   !action.isNewCustomerMessage &&
      //   activeConversation &&
      //   activeConversation?.phone_number === fromNumber &&
      //   action.event.wa_message_id &&
      //   action.event.wa_message_id !== "" &&
      //   action.currentUserId &&
      //   window.trackerQueue
      // ) {
      //   window.trackerQueue.push({
      //     trackerData: {
      //       chat_id: activeConversation?.id,
      //       last_message_read_at_utc: new Date(),
      //       agent_user_id: action.currentUserId,
      //       channel_message_id: action.event.wa_message_id,
      //     },
      //   });
      // }

      if (action.event && fromNumber) {
        if (
          threads[fromNumber] &&
          !threads[fromNumber].sentMessages[action.event.wa_message_id] &&
          threads[fromNumber].sentMessages[action.event._id]
        ) {
          if (threads[fromNumber] && threads[fromNumber].messages) {
            threads[fromNumber].last_message_id = action.event;
            threads[fromNumber].last_message_id.created_at = moment(
              new Date()
            ).toISOString();
            threads[fromNumber].messages = _.map(
              threads[fromNumber].messages,
              (item) => {
                if (
                  _.toString(item.message._id) === _.toString(action.event._id)
                ) {
                  item.message = { ...action.event };
                }
                return item;
              }
            );
            if (action.event.wa_message_id) {
              threads[fromNumber].sentMessages[action.event.wa_message_id] = 1;
            }
            if (action.event.az_id) {
              threads[fromNumber].sentMessages[action.event.az_id] = 1;
            }
            if (action.event._id) {
              threads[fromNumber].sentMessages[action.event._id] = 1;
            }
          }
        }
      }
      return {
        ...state,
        threads,
        inactivatedThreads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_SEND_EVENT_FAILED_MESSAGE:
      let { isLimitExceeded } = state;
      let { limitExceedErrorCode } = state;
      threads = state.threads;
      if (action.event && action.event.from) {
        fromNumber = sanitizeFromNumber(action.event.from);
        if (threads[fromNumber]) {
          threads[fromNumber].messages = threads[fromNumber].messages.map(
            (item) => {
              if (item.message.wa_message_id === action.event.wa_message_id) {
                item.message.isNotDelivered = true;

                if (action.event.channel_error_code) {
                  item.message.channel_error_code =
                    action.event.channel_error_code;
                  item.message.channel_failure_reason =
                    action.event.channel_failure_reason;
                }

                if (
                  action.event.channel_error_code &&
                  messageErrorCodes.includes(action.event.channel_error_code)
                ) {
                  isLimitExceeded = true;
                  limitExceedErrorCode = action.event.channel_error_code;
                }
                return item;
              }
              if (item.message.az_id === action.event.az_id) {
                item.message.isNotDelivered = true;

                if (action.event.channel_error_code) {
                  item.message.channel_error_code =
                    action.event.channel_error_code;
                  item.message.channel_failure_reason =
                    action.event.channel_failure_reason;
                }

                if (
                  action.event.channel_error_code &&
                  messageErrorCodes.includes(action.event.channel_error_code)
                ) {
                  isLimitExceeded = true;
                  limitExceedErrorCode = action.event.channel_error_code;
                }
                return item;
              }
              return item;
            }
          );
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
        isLimitExceeded,
        limitExceedErrorCode,
      };

    case UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET:
      threads = state.threads;
      if (action.event && action.event.fromNumber) {
        fromNumber = sanitizeFromNumber(action.event.fromNumber);
        if (threads[fromNumber]) {
          threads[fromNumber].messages = threads[fromNumber].messages.map(
            (item) => {
              if (item.message.az_id === action.event.az_id) {
                item.message.isNotDelivered = true;
                if (action.event.channel_error_code) {
                  item.message.channel_error_code =
                    action.event.channel_error_code;
                  item.message.channel_failure_reason =
                    action.event.channel_failure_reason;
                }
                item.message.isBeingRetried = false;
                item.message.rawSocketInput = action.event.rawSocketInput;
                return item;
              } else {
                return item;
              }
            }
          );
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_DELIVERY_EVENT_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.to);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          if (
            !_.isEmpty(threads[fromNumber].last_message_id) &&
            threads[fromNumber].last_message_id.wa_message_id ===
              action.event.wa_message_id
          ) {
            threads[fromNumber].last_message_id.message_delivered_at =
              action.event.message_delivered_at;
            threads[fromNumber].last_message_id.message_status = "Delivered";
          }
          threads[fromNumber].messages = _.map(
            threads[fromNumber].messages,
            (item) => {
              if (
                _.toString(item.message._id) === _.toString(action.event._id)
              ) {
                item.message.message_delivered_at =
                  action.event.message_delivered_at;
                item.message.isNotDelivered = false;
                if (action.event.message_error_code) {
                  item.message.message_error_code =
                    action.event.message_error_code;
                  item.message.message_failure_reason =
                    action.event.message_failure_reason;
                }
              }
              return item;
            }
          );
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_SEEN_EVENT_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.to);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          if (
            !_.isEmpty(threads[fromNumber].last_message_id) &&
            threads[fromNumber].last_message_id.wa_message_id ===
              action.event.wa_message_id
          ) {
            threads[fromNumber].last_message_id.message_seen_at =
              action.event.message_seen_at;
            threads[fromNumber].last_message_id.message_status = "Read";
          }
          threads[fromNumber].messages = threads[fromNumber].messages.map(
            (item) => {
              if (
                item._id &&
                action.event._id &&
                _.toString(item._id) === _.toString(action.event._id)
              ) {
                item.message.message_seen_at = action.event.message_seen_at;
                item.message.message_status = "Read";
                item.message.isNotDelivered = false;
              }
              if (
                item.message.wa_message_id &&
                action.event.wa_message_id &&
                _.toString(item.message.wa_message_id) ===
                  _.toString(action.event.wa_message_id)
              ) {
                item.message.message_seen_at = action.event.message_seen_at;
                item.message.message_status = "Read";
                item.message.isNotDelivered = false;
              }
              if (action.event.message_error_code) {
                item.message.message_error_code =
                  action.event.message_error_code;
                item.message.message_failure_reason =
                  action.event.message_failure_reason;
              }
              return item;
            }
          );
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_CLOSED_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      chatId = action.event.chat_id;
      activeConversation = state.activeConversation;
      if (action.event && fromNumber) {
        if (threads?.[fromNumber]) {
          if (
            !threads?.[fromNumber]?.statusMessages[
              action.event.wa_message_id
            ] &&
            !threads?.[fromNumber]?.statusMessages[action?.event?.az_id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "event",
              messageId: action.event.wa_message_id
                ? action.event.wa_message_id
                : "",
              message: action.event,
              timestamp: action.event.created_at
                ? new Date(action.event.created_at).getTime()
                : new Date().getTime(),
            });
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (
              activeConversation?.phone_number === fromNumber &&
              activeConversation?.conversation_status !== "resolved"
            ) {
              activeConversation.conversation_status = "resolved";
              threads[fromNumber].conversation_status = "resolved";
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_INTERVENED_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      chatId = action.event.chat_id;
      activeConversation = state.activeConversation;
      if (action.event && fromNumber) {
        if (threads?.[fromNumber]) {
          if (
            !threads?.[fromNumber]?.statusMessages[
              action.event.wa_message_id
            ] &&
            !threads?.[fromNumber]?.statusMessages[action?.event?.az_id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "event",
              messageId: action.event.wa_message_id
                ? action.event.wa_message_id
                : "",
              message: action.event,
              timestamp: action.event.created_at
                ? new Date(action.event.created_at).getTime()
                : new Date().getTime(),
            });
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (
              activeConversation?.phone_number === fromNumber &&
              activeConversation?.conversation_status !== "intervened"
            ) {
              activeConversation.conversation_status = "intervened";
              threads[fromNumber].conversation_status = "intervened";
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_REQUESTING_INTERVENTION_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      chatId = action.event.chat_id;
      activeConversation = state.activeConversation;
      if (action.event && fromNumber) {
        if (threads?.[fromNumber]) {
          if (
            !threads?.[fromNumber]?.statusMessages[
              action.event.wa_message_id
            ] &&
            !threads?.[fromNumber]?.statusMessages[action?.event?.az_id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "event",
              messageId: action.event.wa_message_id
                ? action.event.wa_message_id
                : "",
              message: action.event,
              timestamp: action.event.created_at
                ? new Date(action.event.created_at).getTime()
                : new Date().getTime(),
            });
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (action.event.wa_message_id) {
              threads[fromNumber].statusMessages[
                action.event.wa_message_id
              ] = 1;
            }
            if (
              activeConversation?.phone_number === fromNumber &&
              activeConversation?.conversation_status !== "initiated"
            ) {
              activeConversation.conversation_status = "initiated";
              threads[fromNumber].conversation_status = "initiated";
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_DOCUMENT_MESSAGE:
      threads = state.threads;
      activeConversation = state.activeConversation;
      fromNumber = sanitizeFromNumber(action.fromNumber);
      if (fromNumber && threads[fromNumber] && action.update) {
        if (
          action.isFromSocket &&
          threads[fromNumber].last_message_id &&
          threads[fromNumber].last_message_id.media_id &&
          _.toString(threads[fromNumber].last_message_id.media_id) ===
            _.toString(action.media_id)
        ) {
          threads[fromNumber].last_message_id = {
            ...threads[fromNumber].last_message_id,
            ...action.update,
          };
        }
        threads[fromNumber].messages = threads[fromNumber].messages.map(
          (item) => {
            if (
              _.toString(item.message.media_id) === _.toString(action.media_id)
            ) {
              item.message.isFetchingItem = action.update.isFetchingItem;
              item.message.media_url = action.update.media_url;
              item.message.is_retry_failed = action.update.isRetryFailed;
            }
            return item;
          }
        );
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
        toggleForDocumentChange: !state.toggleForDocumentChange,
      };

    case REMOVE_DOCUMENT_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.fromNumber);
      const previousMessage =
        threads[fromNumber].messages[threads[fromNumber].messages.length - 2];
      if (fromNumber && threads[fromNumber]) {
        if (
          threads[fromNumber].last_message_id &&
          threads[fromNumber].last_message_id.media_id &&
          _.toString(threads[fromNumber].last_message_id.media_id) ===
            _.toString(action.media_id)
        ) {
          threads[fromNumber].last_message_id = previousMessage.message;
        }
        threads[fromNumber].messages.pop();
      }
      return {
        ...state,
        threads,
        toggleForDocumentChange: !state.toggleForDocumentChange,
      };

    case UPDATE_CHAT_ACTIVATED_MESSAGE:
      chatThreads = state.chatThreads;
      activeConversation = state.activeConversation;
      threads = state.threads;
      inactivatedThreads = state.inactivatedThreads;
      messageType = "event";
      fromNumber = sanitizeFromNumber(action.chat.from);
      if (action.chat && fromNumber) {
        if (threads[fromNumber]) {
          const oldThread = threads[fromNumber];
          if (
            !_.isEmpty(activeConversation) &&
            sanitizeFromNumber(
              activeConversation?.customer_id.channel_phone_number
            ) === fromNumber
          ) {
            activeConversation = { ...action.chat };
            activeConversation.phone_number = fromNumber;
          }
          threads[fromNumber] = {
            ...oldThread,
            ...action.chat,
            chatPageNum: 1,
            chatPageSize: 100,
            chatTotalCount: 0,
            customerDetails: {},
          };
        } else {
          threads[fromNumber] = {
            ...action.chat,
            chatPageNum: 1,
            chatPageSize: 100,
            chatTotalCount: 0,
            last_message_timestamp: new Date(
              action.event.created_at_utc
            ).getTime(), // change date later on
            messages: [],
            sentMessages: {},
            statusMessages: {},
          };
        }
        // if (
        //   inactivatedThreads[fromNumber] &&
        //   inactivatedThreads[fromNumber].messages &&
        //   inactivatedThreads[fromNumber].messages.length > 0
        // ) {
        //   inactivatedThreads[fromNumber].messages.forEach((item) => {
        //     threads[fromNumber].messages.push(item);
        //     if (item.message.wa_message_id) {
        //       threads[fromNumber].sentMessages[
        //         item.message.wa_message_id
        //       ] = 1;
        //     }
        //     if (item.message.az_id) {
        //       threads[fromNumber].sentMessages[item.message.az_id] = 1;
        //     }
        //   });
        // }
        // todo
        // threads[fromNumber].messages.push({
        //   messageType,
        //   messageId: action.event.wa_message_id,
        //   message: action.event,
        //   timestamp: new Date(action.event.created_at_utc).getTime(),
        // });
        // if (action.event.id) {
        //   threads[fromNumber].sentMessages[action.event.id] = 1;
        // }
        // if (action.event.wa_message_id) {
        //   threads[fromNumber].sentMessages[action.event.wa_message_id] = 1;
        // }
        // if (action.event.az_id) {
        //   threads[fromNumber].sentMessages[action.event.az_id] = 1;
        // }
        // threads[fromNumber].messages = sortArrayWithTimeStamp(
        //   threads[fromNumber].messages,
        // );
        // messageLength = threads[fromNumber].messages.length;
        // if (messageLength > 1) {
        //   const chatMessages = threads[fromNumber].messages.filter(
        //     (item) => item.chat_message_type === "CustomerMessage",
        //   );
        //   if (chatMessages && chatMessages.length > 0) {
        //     action.chat.last_message =
        //       chatMessages[chatMessages.length - 1].message;
        //     threads[fromNumber].last_message =
        //       chatMessages[chatMessages.length - 1].message;
        //   }
        // }
        // if (action.chat) {
        //   chatThreads[action.chat.id] = 1;
        // }
      }
      return {
        ...state,
        threads,
        activeConversation,
        chatThreads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_OPENED_MESSAGE:
      activeConversation = state.activeConversation;
      threads = state.threads;
      messageType = "event";
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber] && threads[fromNumber].messages) {
          if (
            !threads[fromNumber].sentMessages[action.event.wa_message_id] &&
            !threads[fromNumber].sentMessages[action.event.az_id] &&
            !threads[fromNumber].sentMessages[action.event._id]
          ) {
            threads[fromNumber].messages.push({
              messageType,
              messageId: action.event.wa_message_id,
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action.event.id) {
              threads[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action.event.wa_message_id) {
              threads[fromNumber].sentMessages[action.event.wa_message_id] = 1;
            }
            if (action.event.az_id) {
              threads[fromNumber].sentMessages[action.event.az_id] = 1;
            }

            if (
              activeConversation?.phone_number === fromNumber &&
              activeConversation?.is_closed
            ) {
              activeConversation.is_closed = false;
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_AGENT_CHANGE_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id?.channel_phone_number
        ) === sanitizeFromNumber(fromNumber)
      ) {
        activeConversation.assigned_to_user_id =
          action.event.agent_assigned_user_id;
      }
      if (action?.event && fromNumber) {
        if (threads && threads[fromNumber]) {
          if (
            !threads[fromNumber]?.sentMessages[action?.event.id] &&
            !threads[fromNumber]?.sentMessages[action?.event.az_id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "event",
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action?.event.id) {
              threads[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action?.event.az_id) {
              threads[fromNumber].sentMessages[action.event.az_id] = 1;
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.event.from);
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id.channel_phone_number
        ) === sanitizeFromNumber(fromNumber)
      ) {
        activeConversation.conversation_label_id =
          action.event.new_conversation_label_id;
      }
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          if (
            !threads[fromNumber].sentMessages[action.event.id] &&
            !threads[fromNumber].sentMessages[action.event.az_id]
          ) {
            threads[fromNumber].messages.push({
              messageType: "event",
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action.event.id) {
              threads[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action.event.az_id) {
              threads[fromNumber].sentMessages[action.event.az_id] = 1;
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_MESSAGE_THREAD:
      threads = { ...state.threads };
      fromNumber = action.fromNumber;
      if (!threads[fromNumber]) threads[fromNumber] = {};
      if (fromNumber && threads[fromNumber]) {
        if (!isNaN(action.chatCurrentCount)) {
          threads[fromNumber].chatCurrentCount = action.chatCurrentCount;
        } else {
          threads[fromNumber].chatCurrentCount = action.chatTotalCount;
        }
        threads[fromNumber].chatPageNum = action.chatPageNum;
        if (action?.loadedChatCount !== undefined) {
          threads[fromNumber].loadedChatCount = action.loadedChatCount;
        }
        if (
          threads[fromNumber].messages &&
          threads[fromNumber].messages.length > 0
        ) {
          if (action.chats.length && action.chats.length > 0) {
            if (!threads[fromNumber].sentMessages) {
              threads[fromNumber].sentMessages = {};
            }
            if (!threads[fromNumber].statusMessages) {
              threads[fromNumber].statusMessages = {};
            }
            action.chats.forEach((item) => {
              if (
                !threads[fromNumber].sentMessages[item._id] &&
                !threads[fromNumber].sentMessages[item.wa_message_id] &&
                !threads[fromNumber].sentMessages[item.az_id]
              ) {
                threads[fromNumber].messages.unshift({
                  messageType: item.source,
                  timestamp: new Date(item.message_received_at).getTime(),
                  message: item,
                });
                if (item._id) {
                  threads[fromNumber].sentMessages[item._id] = 1;
                }
                if (item.wa_message_id) {
                  threads[fromNumber].sentMessages[item.wa_message_id] = 1;
                }
                if (item.az_id) {
                  threads[fromNumber].sentMessages[item.az_id] = 1;
                }
              }
            });
          }
        } else {
          threads[fromNumber].messages = [];
          if (!threads[fromNumber].sentMessages) {
            threads[fromNumber].sentMessages = {};
          }
          if (!threads[fromNumber].statusMessages) {
            threads[fromNumber].statusMessages = {};
          }
          if (action.chats && action.chats.length > 0) {
            action.chats.forEach((item) => {
              threads[fromNumber].messages.unshift({
                messageType: item.source,
                timestamp: new Date(item.received_at_utc).getTime(),
                message: item,
              });
              if (threads[fromNumber].sentMessages) {
                if (item._id) {
                  threads[fromNumber].sentMessages[item._id] = 1;
                }
                if (item.wa_message_id) {
                  threads[fromNumber].sentMessages[item.wa_message_id] = 1;
                }
                if (item.az_id) {
                  threads[fromNumber].sentMessages[item.az_id] = 1;
                }
              } else {
                threads[fromNumber].sentMessages = {};
                if (item._id) {
                  threads[fromNumber].sentMessages[item._id] = 1;
                }
                if (item.wa_message_id) {
                  threads[fromNumber].sentMessages[item.wa_message_id] = 1;
                }
                if (item.az_id) {
                  threads[fromNumber].sentMessages[item.az_id] = 1;
                }
              }
            });
          }
        }
        threads[fromNumber].messages = sortArrayWithTimeStamp(
          threads[fromNumber].messages
        );
        if (action.chatTotalCount) {
          threads[fromNumber].chatTotalCount = action.chatTotalCount;
        }
        if (action.chatId) {
          threads[fromNumber].id = action.chatId;
        }
      }
      return {
        ...state,
        isFetchingActiveChatMessages: action.isFetchingActiveChatMessages,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_MESSAGE_THREAD_STATUS:
      return {
        ...state,
        isFetchingActiveChatMessages: action.isFetchingActiveChatMessages,
      };

    case SELECT_CHAT_TYPE:
      return {
        ...state,
        selectedChatType: action.selectedChatType,
      };

    case SELECT_CHAT_STATUS:
      return {
        ...state,
        selectedChatStatus: action.selectedChatStatus,
      };

    case SELECT_FILTER_TYPE:
      const { selectedFilter } = action;
      return {
        ...state,
        selectedFilter,
      };

    case SELECT_SORT_OPTION:
      return {
        ...state,
        selectedSort: action.selectedSort,
      };

    case UPDATE_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeFromNumber: action.activeFromNumber,
      };

    case UPDATE_ACTIVE_CHAT:
      return {
        ...state,
        activeConversation: action.activeConversation,
      };

    case UPDATE_LIMIT_EXCEEDED_POPUP_STATE:
      return {
        ...state,
        isLimitExceeded: action.isLimitExceeded,
      };

    case UPDATE_NEW_CONVERSATIONS_WITH_THREAD:
      threads = state.threads;
      activeConversation = state.activeConversation;
      chatThreads = state.chatThreads;
      if (action.chats && action.chats.length > 0) {
        action.chats.forEach((item) => {
          let phone_number =
            item && item.customer_id && item.customer_id.wa_phone_number
              ? item.customer_id.wa_phone_number.toString()
              : "";
          if (phone_number) {
            if (threads[phone_number]) {
              let oldThread = threads[phone_number];
              if (
                !_.isEmpty(activeConversation) &&
                sanitizeFromNumber(
                  activeConversation?.customer_id.wa_phone_number
                ) === phone_number
              ) {
                activeConversation = { ...item };
                activeConversation.phone_number = phone_number;
              }
              threads[phone_number] = {
                ...oldThread,
                ...item,
                chatPageNum: 1,
                chatPageSize: 100,
                chatTotalCount: 0,
                customerDetails: {},
                last_message_timestamp: moment(
                  new Date(item.last_activity_at_utc)
                ),
              };
            } else {
              threads[phone_number] = {
                ...item,
                chatPageNum: 1,
                chatPageSize: 100,
                chatTotalCount: 0,
                sentMessages: {},
                statusMessages: {},
                isResyncNeeded: false,
                customerDetails: {},
                last_message_timestamp: moment(
                  new Date(item.last_activity_at_utc)
                ),
                messages: [],
              };
              item.phone_number =
                item && item.customer_id && item.customer_id.wa_phone_number
                  ? item.customer_id.wa_phone_number.toString()
                  : "";
              item.timestamp = new Date(item.last_activity_at_utc).getTime();
              chatThreads[item.id] = 1;
            }
          }
        });
      }
      return {
        ...state,
        toggleForChange: !state.toggleForChange,
        activeConversation,
        threads,
        chatThreads,
      };
      break;

    case TOGGLE_CHAT_WINDOW:
      return {
        ...state,
        isChatWindowVisible: action.isChatWindowVisible,
      };

    case TOGGLE_USER_DETAIL_VIEW:
      return {
        ...state,
        isUserDetailsViewVisible: action.isUserDetailsViewVisible,
      };

    case UPDATE_CONVERSATION_CLOSED:
      return {
        ...state,
        chatId: action.chatId,
        isChatWindowVisible: action.isChatWindowVisible,
      };

    case ASSIGNING_AGENT_TO_CHAT:
      return {
        ...state,
        isAssigningAgent: action.isAssigningAgent,
        toggleForChange: !state.toggleForChange,
      };

    case CLOSING_CHAT:
      return {
        ...state,
        isClosingChat: action.isClosingChat,
      };

    case UPDATE_AGENT_ASSIGNMENT_MESSAGE:
      threads = state.threads;
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id.channel_phone_number
        ) === sanitizeFromNumber(action.fromNumber)
      ) {
        activeConversation.assigned_to_user_id =
          action.update.agent_assigned_user_id;
      }
      fromNumber = action.fromNumber;
      if (threads[action.fromNumber]?.messages) {
        if (
          !threads[fromNumber].sentMessages[action.update.wa_message_id] &&
          !threads[fromNumber].sentMessages[action.update.az_id]
        ) {
          threads[action.fromNumber].messages.push({
            messageType: "event",
            message: action.update,
            timestamp: new Date(action.update.created_at_utc).getTime(),
          });
          if (action.update.wa_message_id) {
            threads[fromNumber].sentMessages[action.update.wa_message_id] = 1;
          }
          if (action.update.az_id) {
            threads[fromNumber].sentMessages[action.update.az_id] = 1;
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case ASSIGNED_AGENT_TO_CHAT:
      return {
        ...state,
        isAssigningAgent: action.isAssigningAgent,
      };

    case UPDATE_CUSTOMER_DETAILS_CHAT:
      return {
        ...state,
        sentMessages: {},
        statusMessages: {},
      };

    case MARK_MESSAGE_AS_BEING_RETRIED:
      threads = state.threads;
      threads[action.fromNumber].messages = threads[
        action.fromNumber
      ].messages.map((item) => {
        if (item.message.az_id === action.az_id) {
          item.message.isBeingRetried = true;
        }
        return item;
      });
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID:
      return {
        ...state,
        selectedCustomerFromNumber: action.selectedCustomerFromNumber,
      };

    case UPDATE_INBOX_SYNC_MOBILE:
      const resetData = action.data;
      return {
        ...state,
        selectedFilter: resetData.selectedFilter,
        selectedChatType: resetData.selectedChatType,
        selectedSort: resetData.selectedSort,
        isChatWindowVisible: resetData.isChatWindowVisible,
      };

    case SET_NOTIFICATION_RIBBON:
      return {
        ...state,
        showNotificationRibbon: action.showNotificationRibbon,
      };

    case EMPTY_MESSAGE_THREAD:
      threads = {};
      chatThreads = {};
      return {
        ...state,
        threads,
        chatThreads,
      };

    case ASSIGNING_CONVERSATION_LABEL:
    case CLEAR_CONVERSATION_LABEL_START:
      return {
        ...state,
        isAssigningConvLabel: action.isAssigningConvLabel,
      };
    case CLEAR_CONVERSATION_LABEL:
      return {
        ...state,
        isAssigningConvLabel: action.isAssigningConvLabel,
        activeConversation: {
          ...state.activeConversation,
          conversation_label_id: null,
        },
      };
    case UPDATE_EVENT_COUNT:
      const threadsCopy = { ...state.threads };
      fromNumber = sanitizeFromNumber(action.payload?.from);
      if (fromNumber) {
        if (threadsCopy[fromNumber]) {
          // update events count
          threadsCopy[fromNumber].eventTotalCount =
            threadsCopy[fromNumber].eventTotalCount + 1;
        }
      }

      return { ...state, threads: threadsCopy };

    case CLOSE_SEARCH_SECTION:
      // reset chat selection
      return {
        ...state,
        activeConversation:
          state?.threads && Object.values(state?.threads)?.[0],
      };

    case UPDATE_SPAM_CHAT_STATUS: {
      const updatedMessages =
        action?.payload.chat_id &&
        action.payload?.az_id &&
        state.threads[action.payload?.fromNumber]?.messages
          ? [
              ...state.threads[action.payload.fromNumber].messages,
              {
                messageType: "event",
                message: action.payload,
                timestamp: new Date(action.payload.created_at_utc).getTime(),
              },
            ]
          : state.threads[action.payload.fromNumber]?.messages || [];

      const updateActiveConversation = () => {
        if (action?.payload.chat_id === state?.activeConversation?.id) {
          return {
            ...state.activeConversation,
            is_spam: action.payload.is_spam,
            messages: updatedMessages,
          };
        }

        return state.activeConversation;
      };

      return {
        ...state,
        threads: {
          ...state.threads,
          [action.payload.fromNumber]: {
            ...state.threads[action.payload.fromNumber],
            messages: updatedMessages,
            is_spam: action.payload.is_spam,
            MultiImage: false,
          },
        },
        activeConversation: updateActiveConversation(),
      };
    }

    case TOOGLE_SPAM_CHAT_START:
      return { ...state, spam: { toogleSpamLoading: true } };

    case TOOGLE_SPAM_CHAT_SUCCESS:
    case TOOGLE_SPAM_CHAT_FAILURE:
      return { ...state, spam: { toogleSpamLoading: false } };

    case UPDATE_NEW_CUSTOMER_MESSAGES_DATA:
      return {
        ...state,
        isNewCustomerMessage: false,
        newCustomerMessagesCount: 0,
      };

    case UPDATE_IS_NEW_CUSTOMER_MESSAGES:
      return {
        ...state,
        isNewCustomerMessage: false,
      };

    case FETCH_CONVERSATION_EVENTS:
      return {
        ...state,
        conversationEvents: {
          events: action.chat,
          sentMessages: {},
        },
      };

    case FETCH_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignsData: action.campaigns,
      };

    case UPDATE_CONVERSATION_EVENTS:
      let updatedData = clone(state.conversationEvents);
      if (!updatedData?.sentMessages[action?.payload?.wa_message_id]) {
        updatedData.events.unshift(action.payload);
        if (action.payload.wa_message_id) {
          updatedData.sentMessages[action.payload.wa_message_id] = 1;
        }
      }
      return {
        ...state,
        conversationEvents: updatedData,
      };

    case UPDATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };

    default:
      return { ...state };
  }
};

export default inboxReducer;

export const getConversationEvents = (state) => state.thread.conversationEvents;
export const getSearchValue = (state) => state.thread.searchValue;
export const getAssigningConvLabelStatus = (state) =>
  state.thread.isAssigningConvLabel;
export const getAllThreads = (state) => state.thread.threads;
export const getActiveConversation = (state) => state.thread.activeConversation;
export const getActiveConversationChatId = (state) =>
  state.thread.activeConversation ? state.thread.activeConversation?.id : "";
export const getActiveCustomerId = (state) =>
  state.thread.activeConversation &&
  state.thread.activeConversation?.customer_id &&
  state.thread.activeConversation?.customer_id.id
    ? state.thread.activeConversation?.customer_id.id
    : "";
export const getActiveFromNumber = (state) => state.thread.activeFromNumber;
export const getCustomerPageActiveFromNumber = (state) =>
  state.thread.selectedCustomerFromNumber;
export const getActiveThreads = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber]
    : {};
export const getCurrentChatPageNum = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber]?.chatPageNum
    : 1;
export const getCurrentLoadedChatCount = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber]?.loadedChatCount
    : 0;

export const getCurrentChatTotalCount = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber]?.chatTotalCount
    : 0;

export const getIfAllChatLoaded = (state) =>
  getCurrentLoadedChatCount(state) === getCurrentChatTotalCount(state);

export const getActiveCustomerDetailsFromThreads = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber].customerDetails
    : {};
export const getCustomerActiveThreads = (state) =>
  state.thread.selectedCustomerFromNumber
    ? state.thread.threads[state.thread.selectedCustomerFromNumber]
    : [];
export const getInActiveThreads = (state) => state.thread.inactivatedThreads;
export const getIfAgentIsAssigned = (state) =>
  state.thread.activeConversation &&
  state.thread.activeConversation?.assigned_to_user_id &&
  state.thread.activeConversation?.assigned_to_user_id !== "";
// export const getActiveThreads = (state) => state.thread.threads['9818130272'];
export const getActiveChatMessagesFetchStatus = (state) =>
  state.thread.isFetchingActiveChatMessages;
export const getActiveThreadsCount = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].messages
    ? state.thread.threads[state.thread.activeFromNumber].messages.length
    : 0;
export const getCustomerActiveThreadsCount = (state) =>
  state.thread.selectedCustomerFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.selectedCustomerFromNumber] &&
  state.thread.threads[state.thread.selectedCustomerFromNumber].messages
    ? state.thread.threads[state.thread.selectedCustomerFromNumber].messages
        .length
    : 0;

export const getCustomerActiveThreadsMessages = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].messages
    ? state.thread.threads[state.thread.activeFromNumber].messages
    : [];

export const getIfReachedAllChats = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.activeFromNumber]
    ? state.thread.threads[state.thread.activeFromNumber].chatCurrentCount >=
      state.thread.threads[state.thread.activeFromNumber].chatTotalCount
    : false;
// export const getActiveThreadsCount = (state) => state.thread.activeFromNumber && state.thread.threads &&
// state.thread.threads[state.thread.activeFromNumber] ? state.thread.threads[state.thread.activeFromNumber].length :
// [];
export const getWidgetFetchingState = (state) =>
  state.thread.isFetchingWidgetInfo;
export const getChatWindowVisibleState = (state) =>
  state.thread.isChatWindowVisible;
export const getAllChatLoadedState = (state) =>
  state.thread.isActiveChatLoaded && state.thread.isClosedChatLoaded;
export const getUserDetailsViewVisibleState = (state) =>
  state.thread.isUserDetailsViewVisible;

// chats
export const getChatToggleEvent = (state) => state.thread.toggleForChange;
export const getChatToggleDocumentEvent = (state) =>
  state.thread.toggleForDocumentChange;

// filters
export const getFilterOptions = (state) => state.thread.filterOptions;
export const getSelectedFilter = (state) => state.thread.selectedFilter;
export const getChatOptions = (state) => state.thread.chatOptions;
export const getSelectedChatOption = (state) => state.thread.selectedChatType;
export const getSelectedSortOption = (state) => state.thread.selectedSort;
export const getSortOptions = (state) => state.thread.sortOptions;
export const getClosingChatState = (state) => state.thread.isClosingChat;

export const getChatAssingmentState = (state) => state.thread.isAssigningAgent;
export const getSentMessageStore = (state) => state.thread.sentMessages;
export const getFirstLoadStatus = (state) => state.thread.isActiveChatLoaded;

export const getSelectedChatStatus = (state) => state.thread.selectedChatStatus;

export const getOnlyCustomerChats = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].messages; // ?
// state.thread.threads[state.thread.activeFromNumber].messages.filter(item
// => item.messageType === 'CustomerMessage') : [];
export const getCustomerPageOnlyCustomerChats = (state) =>
  state.thread.selectedCustomerFromNumber &&
  state.thread.threads[state.thread.selectedCustomerFromNumber] &&
  state.thread.threads[state.thread.selectedCustomerFromNumber].messages; // ?
// state.thread.threads[state.thread.activeFromNumber].messages.filter(item
// => item.messageType === 'CustomerMessage')
// : [];
export const getResyncingState = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].isResyncNeeded; // ?
// state.thread.threads[state.thread.activeFromNumber].messages.filter(item
// => item.messageType === 'CustomerMessage') :
// [];

// Todo refactor
//export const getShowNotificationRibbon = state => state.inbox.showNotificationRibbon && (state.app.organizations &&
// state.app.organizations[0] && state.app.organizations[0].organization_id &&
// state.app.organizations[0].organization_id.onboarding_status ?
// state.app.organizations[0].organization_id.onboarding_status === onboardingStatuses.ONBOARDED : false) &&
// state.app.isAuthenticated || (!!state.payment.activeSubscriptionList ? state.payment.activeSubscriptionList.length
// === 0 : false);

export const checkIfUserHasNotificationPermission = () => {
  if (!("Notification" in window)) {
    return false;
  }

  if (Notification.permission === "granted") return false;
  return true;
};

export const getShowNotificationRibbon = (state) => {
  return (
    state.thread.showNotificationRibbon &&
    (state.app.organizations &&
    state.app.organizations[0] &&
    state.app.organizations[0].organization_id &&
    state.app.organizations[0].organization_id.onboarding_status
      ? state.app.organizations[0].organization_id.onboarding_status ===
        onboardingStatuses.ONBOARDED
      : false) &&
    state.app.isAuthenticated
  );
};

export const getIsLimitExceeded = (state) => state.thread.isLimitExceeded;
export const getLimitExceededErrorCode = (state) =>
  state.thread.limitExceedErrorCode;
