import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { layout, space } from "styled-system";
import PropTypes from "prop-types";

import Box from "konnekt/Box";

import useEventListner from "customHooks/useEventListener";

import { isIosSafari } from "util/deviceUtility";

const OuterContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000000000;
`;

const Container = styled.div`
  position: absolute;

  bottom: ${({ bottom }) => bottom || 0}px;

  background-color: #fff;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 24px 8px 0 8px;
  max-height: 75vh;
  min-height: 30vh;
  z-index: 2;

  ${layout}
  ${space}
`;

const BottomDropdown = ({ handleOutsideAreaClick, children, ...rest }) => {
  const [bottomDropdownPos, setBottomDropdownPos] = useState(0);

  const [dropdownHeight, setdropdownHeight] = useState(null);

  const dropdownRef = useRef();

  const outerRef = useRef();

  const setBottomDropdownPosition = () => {
    if (isIosSafari()) {
      setBottomDropdownPos(window.outerHeight - outerRef.current?.offsetHeight);
    } else {
      setBottomDropdownPos(outerRef.current?.offsetHeight - window.innerHeight);
    }
  };

  useEffect(() => {
    setBottomDropdownPosition();
  }, [setBottomDropdownPosition]);

  useEffect(() => {
    if (dropdownRef.current?.offsetHeight > dropdownHeight) {
      setdropdownHeight(dropdownRef.current?.offsetHeight);
    }
  }, [dropdownRef.current?.offsetHeight]);

  useEventListner("resize", setBottomDropdownPosition, window.visualViewport);

  return (
    <OuterContainer
      ref={outerRef}
      onClick={handleOutsideAreaClick}
      display={{ _: "block", desktop: "none" }}
    >
      {isIosSafari() && (
        <Box
          bg="white"
          position="absolute"
          bottom="0"
          left="0"
          zIndex="1"
          borderRadius="20px 20px 0px 0px"
          height="200px"
          width="100%"
        />
      )}
      <Container
        height={dropdownHeight}
        bottom={bottomDropdownPos}
        ref={dropdownRef}
        onClick={(e) => e.stopPropagation()}
        {...rest}
      >
        {children}
      </Container>
    </OuterContainer>
  );
};

BottomDropdown.propTypes = {
  headerText: PropTypes.string,
  list: PropTypes.shape({
    type: PropTypes.oneOf(["add", "select"]),
    data: PropTypes.array.isRequired,
  }),
};

export default BottomDropdown;
