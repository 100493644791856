import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import PropTypes from "prop-types";
import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Loader from "konnekt/Loader";

const StyledTableHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.heading == "Favourite" ? "center" : "flex-start"}; ;
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;
const MobileDesign = styled(Box)`
  // margin-right:30px;
  line-height: 22px;
  /* &:nth-child(even) {
    color: var(--blue);
  } */
  @media only screen and (max-width: 767px) {
    margin-right: 0px;
    padding: 0px;
    font-size: 13px;
  }
`;
const MobileScroll = styled(Box)`
  overflow: scroll;
  overflow-x: hidden;
  max-height: 60rem;
`;
const StyledBox = styled(Box)`
  word-break: break-word;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    padding: 1rem;
    font-size: 1.2rem;
  }
  /* 
  &:nth-child(even) {
    color: var(--blue);
  } */
`;

const TableHeaderRows = styled(Box)`
  @media only screen and (max-width: 426px) {
    font-size: 1.2rem;
  }
`;

const NoResultFoundText = styled.div`
  align-items: center;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 14px;
  width: 250px;
  color: rgba(152, 152, 152, 0.8);
  @media (max-width: ${(props) => props.theme.breakpoints.mobileL}) {
    font-size: 14px;
    width: 210px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const Table = ({
  columns,
  data,
  noResultIcon = "noResult",
  tooltipProps = {},
  noResultText = "No result found",
  noResultProps = {
    height: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  tableProps,
  gridTemplateColumnsHeader,
  gridTemplateColumnsBody,
  zeroState,
  fetching,
}) => {
  const renderTableBody = () => {
    if (fetching) {
      return (
        <Box {...noResultProps}>
          <Loader color="primary" />
        </Box>
      );
    }
    if (zeroState) {
      return zeroState;
    }
    if (!data?.length) {
      return (
        <Box {...noResultProps}>
          <Icon iconName={noResultIcon} />
          <NoResultFoundText>{noResultText}</NoResultFoundText>
        </Box>
      );
    }
    return (
      <MobileScroll>
        <Box display="block" {...tableProps?.tbodyProps}>
          {data.map((row) => (
            <MobileDesign>
              <Box
                display="grid"
                gridTemplateColumns={gridTemplateColumnsBody}
                borderBottom="1px solid #F3F3F3"
                {...tableProps?.trProps}
                className="table-row-hover"
              >
                {row.map((cell, colIndex) => (
                  <StyledBox
                    padding="1.2rem 1.2rem"
                    height="100%"
                    fontSize="sm"
                    fontStyle="normal"
                    fontWeight="400"
                    // lineHeight="30px"
                    {...tableProps?.tdProps}
                    className="td-hover"
                    style={
                      {
                        ...columns[colIndex]?.styleColumn,
                        ...columns[colIndex]?.styleCells,
                      } ?? {}
                    }
                  >
                    {cell}
                  </StyledBox>
                ))}
              </Box>
            </MobileDesign>
          ))}
        </Box>
      </MobileScroll>
    );
  };
  const renderTableHeader = () => {
    return (
      <MobileDesign>
        <Box
          position="sticky"
          top={0}
          zIndex={10}
          background="#F5F6FA"
          {...tableProps?.theadProps}
        >
          <Box
            display="grid"
            //  marginRight="30px"
            background="#F5F6FA"
            gridTemplateColumns={gridTemplateColumnsHeader}
            // fontSize={{ _: "sm", mobileL: "sm" }}
            fontWeight="bold"
            textAlign="center"
            {...tableProps?.thRowProps}
          >
            {renderTableHeaderRows()}
          </Box>
        </Box>
      </MobileDesign>
    );
  };

  const renderTableHeaderRows = () => {
    const tableHeader = columns?.map(
      ({ id, heading, subHeading, tooltip, styleColumn, styleHeader }) => (
        <TableHeaderRows
          key={id ?? uuidv4()}
          // variant="text4"
          padding="1.2rem .8rem"
          fontSize="1.4rem"
          borderColor="lightGrey.10"
          bg="#F5F6FA"
          background="var(--blue)"
          color="var(--white)"
          {...tableProps?.thProps}
          style={{ ...styleColumn, ...styleHeader } ?? {}}
        >
          <StyledTableHeader heading={heading}>
            <div>
              {heading}
              {subHeading}
            </div>
            {tooltip && (
              <StyledIcon
                iconName="info"
                color="darkgray"
                data-tip={tooltip}
                {...tooltipProps}
              />
            )}
          </StyledTableHeader>
        </TableHeaderRows>
      )
    );
    return tableHeader;
  };

  return (
    <>
      <ReactTooltip
        className="tooltip special-tooltip"
        multiline
        place="top"
        effect="solid"
      />
      <Box width="100%" mb="1rem" {...tableProps?.tableProps}>
        {renderTableHeader()}
        {renderTableBody()}
      </Box>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf([
    PropTypes.shape({
      header: PropTypes.element,
      tooltip: PropTypes.string,
    }),
  ]).isRequired,
  data: PropTypes.arrayOf([
    PropTypes.arrayOf([PropTypes.string, PropTypes.number, PropTypes.element]),
  ]),
  noResultIcon: PropTypes.string,
};

export default Table;
