const colors = [
  "#FBD1C4",
  "#F7C7FF",
  "#FBE59A",
  "#F9A8A8",
  "#A4DAD3",
  "#85D5F8",
  "#C5EA8D",
  "#64D7B6",
  "#ABC1F9",
  "#AE88CF",
];

const colorSet = {
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
  a: 9,
  b: 4,
  c: 0,
  d: 1,
  e: 2,
  f: 3,
};

export const getBackgroundColor = (id) => {
  let lastId = !!id ? id.toString() : "a";
  lastId = lastId[0];
  if (lastId) {
    return colors[colorSet[lastId]];
  } else {
    return colors[0];
  }
};

export function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
