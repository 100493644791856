import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "konnekt/Icon/Icon";
import Label from "konnekt/Label";
import { InputGroup } from "react-bootstrap";
import Button from "konnekt/Buttons";
import {
  MessageContainer,
  CommonBox,
  FormContainer,
  BoxInput,
  FormGridContainer,
  TextInputStyle,
  DropDownControlledStyle,
  FormCard,
} from "../BusinessStyles";
import {
  businessDetailsUpdate,
  newBusinessV2,
  editBusinessV2,
  updateAdminDetails,
} from "modules/Manage/ManagePageAction";

import { isValidMobileNo } from "util/utility";
import ErrorMessage from "../../../../../konnekt/ErrorMessage";
import isNumeric from "validator/lib/isNumeric";
import isEmail from "validator/lib/isEmail";
import isAlpha from "validator/lib/isAlpha";
import { getCountryCodeList } from "modules/App/AppSelectors";
import { FETCH_NEW_BUSINESS } from "modules/Manage/ManagePageActionTypes";
import CountryCodeSelection from "modules/Templates/pages/CreateTemplatePage/components/CountryCodeSelection";
const CreateNewBusinessPage = ({ handleBack }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const getOnboarding = () => {
    return [
      {
        label: "Active",
        value: true,
      },
      {
        label: "InActive",
        value: false,
      },
    ];
  };
  const getGender = () => {
    return [
      {
        label: "Male",
        value: "male",
      },
      {
        label: "Female",
        value: "female",
      },
    ];
  };
  const getPrefixName = () => {
    return [
      {
        label: "Mrs",
        value: "Mrs",
      },
      {
        label: "Mr",
        value: "Mr",
      },
    ];
  };
  const getWhatsappApiStatus = () => {
    return [
      {
        label: "Live",
        value: "live",
      },
      {
        label: "InActive",
        value: "inactive",
      },
      {
        label: "Not connected",
        value: "not_connected",
      },
    ];
  };
  const getProviderList = () => {
    return [
      {
        label: "Aisensy",
        value: "Aisensy",
      },
      {
        label: "Meta",
        value: "Meta",
      },
    ];
  };

  const updateDetails = (keyName, keyValue, adminObj = false) => {
    if (adminObj) {
      dispatch(updateAdminDetails(keyName, keyValue));
    } else {
      dispatch(businessDetailsUpdate(keyName, keyValue));
    }
  };

  const business = useSelector((state) => state.manage.business);
  const countryCodeList = useSelector((state) => getCountryCodeList(state));
  const businessData = useSelector((state) => {
    return state.manage.manageNewBusiness.businessData;
  });
  useEffect(() => {
    if (Object.keys(businessData).length) {
      dispatch({
        type: FETCH_NEW_BUSINESS,
        businessData: {},
        isFetchingNewBusiness: false,
      });
      handleBack();
    }
  }, [businessData]);

  const handleValidation = (business) => {
    let isValid = true;
    let errors = {};

    if (!business?.name || !isAlpha(business?.name)) {
      isValid = false;
      errors["name"] = "Please enter name only in alphabet";
    }
    if (business && business.is_onboarded === undefined) {
      isValid = false;
      errors["is_onboarded"] = "Please select onbording status";
    }
    if (
      !business?.contact_number ||
      !isNumeric(business?.contact_number) ||
      business?.contact_number.toString().length > 12
    ) {
      isValid = false;
      errors["contact_number"] = "Please enter valid phone number";
    }
    if (!business?.contact_email || !isEmail(business?.contact_email)) {
      isValid = false;
      errors["contact_email"] = "Please enter email";
    }
    if (!business?.tax_number) {
      isValid = false;
      errors["tax_number"] = "Please enter GSTN number";
    }
    if (!business?.country) {
      isValid = false;
      errors["country"] = "Please enter country";
    }
    if (!business?.business_address) {
      isValid = false;
      errors["business_address"] = "Please enter business address";
    }
    if (!business?.billing_address) {
      isValid = false;
      errors["billing_address"] = "Please enter billing address";
    }
    if (business !== null && Object.keys(business).length && business?._id) {
      if (!business?.admin?.plain_password) {
        isValid = false;
        errors["plain_password"] = "Please enter password";
      }
    } else {
      if (!business?.admin?.local_password) {
        isValid = false;
        errors["local_password"] = "Please enter password";
      }
    }

    if (!business?.admin?.gender) {
      isValid = false;
      errors["gender"] = "Please select gender";
    }
    if (!business?.admin?.prefix_name) {
      isValid = false;
      errors["prefix_name"] = "Please select prefix name";
    }
    if (!business?.admin?.username) {
      isValid = false;
      errors["username"] = "Please enter username";
    }
    if (
      !business?.admin?.contact_email ||
      !isEmail(business?.admin?.contact_email)
    ) {
      isValid = false;
      errors["email"] = "Please enter email";
    }
    if (
      !business?.admin?.phone_number ||
      !isNumeric(business?.admin?.phone_number)
    ) {
      isValid = false;
      errors["phone_number_admin"] = "Please enter phone number";
    }
    if (!business?.admin?.country_code) {
      isValid = false;
      errors["country_code_admin"] = "Please select country code";
    }
    if (!business?.admin?.first_name || !isAlpha(business?.admin?.first_name)) {
      isValid = false;
      errors["first_name"] = "Please enter first name only in alphabet";
    }
    if (!business?.admin?.last_name || !isAlpha(business?.admin?.last_name)) {
      isValid = false;
      errors["last_name"] = "Please enter last name only in alphabet";
    }
    if (!business?.whatsapp_api_status) {
      isValid = false;
      errors["whatsapp_api_status"] = "Please select whatsapp api status";
    }
    if (!business?.provider) {
      isValid = false;
      errors["provider"] = "Please select Provider";
    }
    if (business?.zipcode && !isNumeric(business?.zipcode)) {
      isValid = false;
      errors["zipcode"] = "Please enter only number";
    }

    return {
      isValid,
      errors,
    };
  };

  const saveHandler = (e) => {
    e.preventDefault();
    let validate = handleValidation(business);
    true;
    if (validate.isValid) {
      if (business !== null && Object.keys(business).length && business?._id) {
        dispatch(editBusinessV2(business));
      } else {
        dispatch(newBusinessV2(business));
      }
    } else {
      setErrors(validate.errors);
    }
  };

  return (
    <MessageContainer>
      {/* title */}
      <CommonBox>
        <Icon
          cursor="pointer"
          iconName="back"
          width="2rem"
          style={{ marginRight: "1rem" }}
          onClick={() => handleBack()}
        />
        <h3>
          {business !== null && Object.keys(business).length && business?._id
            ? "Update "
            : "Create "}{" "}
          Business
        </h3>
      </CommonBox>
      {/* form-start */}
      <FormContainer>
        <FormCard>
          <h4>Business Details</h4>
          <FormGridContainer>
            <BoxInput>
              <Label variant="text4">
                Name <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("name", value)}
                value={business?.name}
                placeholder="Enter business name"
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Business Address<sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("business_address", value)}
                value={business?.business_address}
                placeholder="Enter business address"
              />
              {errors.business_address && (
                <ErrorMessage>{errors.business_address}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Billing Address<sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("billing_address", value)}
                value={business?.billing_address}
                placeholder="Enter billing address"
              />
              {errors.billing_address && (
                <ErrorMessage>{errors.billing_address}</ErrorMessage>
              )}
            </BoxInput>

            <BoxInput>
              <Label variant="text4">State</Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("state", value)}
                value={business?.state}
                placeholder="Enter state name"
              />
              {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
            </BoxInput>

            <BoxInput>
              <Label variant="text4">City</Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("city", value)}
                value={business?.city}
                placeholder="Enter city name"
              />
              {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
            </BoxInput>

            <BoxInput>
              <Label variant="text4">Pin Code</Label>
              <TextInputStyle
                type="number"
                onChange={(value) => updateDetails("zipcode", value)}
                value={business?.zipcode}
                placeholder="Enter zipcode"
                maxLength="10"
              />
              {errors.zipcode && <ErrorMessage>{errors.zipcode}</ErrorMessage>}
            </BoxInput>

            <BoxInput>
              <Label variant="text4">
                Contact Number <sup>*</sup>
              </Label>
              <TextInputStyle
                type="number"
                onChange={(value) => updateDetails("contact_number", value)}
                value={business?.contact_number}
                placeholder="Enter contact number"
                data-cy="phn-num"
                maxLength="10"
              />
              {errors.contact_number && (
                <ErrorMessage>{errors.contact_number}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Contact Email <sup>*</sup>
              </Label>
              <TextInputStyle
                type="email"
                onChange={(value) => updateDetails("contact_email", value)}
                value={business?.contact_email}
                placeholder="Enter Email"
              />
              {errors.contact_email && (
                <ErrorMessage>{errors.contact_email}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Country <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("country", value)}
                value={business?.country}
                placeholder="Enter Country name"
                data-cy="phn-num"
              />
              {errors.country && <ErrorMessage>{errors.country}</ErrorMessage>}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Select Onboarding Status <sup>*</sup>
              </Label>
              {/* {onBoarding values
                active, inactive , pending, deleted, rejected } */}
              <DropDownControlledStyle
                selectedValue={
                  getOnboarding()?.filter(
                    (item) => item.value === business?.is_onboarded
                  )?.[0]?.label
                }
                variant="lighterGrey"
                options={getOnboarding()}
                onOptionClick={(onboard) =>
                  updateDetails("is_onboarded", onboard?.value)
                }
              />
              {errors.is_onboarded && (
                <ErrorMessage>{errors.is_onboarded}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Select whatsapp api status <sup>*</sup>
              </Label>
              {/* {onBoarding values
                active, inactive , pending, deleted, rejected } */}
              <DropDownControlledStyle
                selectedValue={
                  getWhatsappApiStatus()?.filter(
                    (item) => item.value === business?.whatsapp_api_status
                  )?.[0]?.label
                }
                variant="lighterGrey"
                options={getWhatsappApiStatus()}
                onOptionClick={(onboard) =>
                  updateDetails("whatsapp_api_status", onboard?.value)
                }
              />
              {errors.whatsapp_api_status && (
                <ErrorMessage>{errors.whatsapp_api_status}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Select Provider <sup>*</sup>
              </Label>
              <DropDownControlledStyle
                selectedValue={
                  getProviderList()?.filter(
                    (item) => item.value === business?.provider
                  )?.[0]?.label
                }
                variant="lighterGrey"
                options={getProviderList()}
                onOptionClick={(provider) =>
                  updateDetails("provider", provider?.value)
                }
              />
              {errors.provider && (
                <ErrorMessage>{errors.provider}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                GSTN <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("tax_number", value)}
                value={business?.tax_number}
                placeholder="Enter enter gstn"
              />
              {errors.tax_number && (
                <ErrorMessage>{errors.tax_number}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">Api Key</Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("business_api_key", value)}
                value={business?.business_api_key}
                placeholder="Enter text Business Api Key"
              />
              {errors.business_api_key && (
                <ErrorMessage>{errors.business_api_key}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">Business Logo Url</Label>
              <TextInputStyle
                type="text"
                onChange={(value) =>
                  updateDetails("wa_business_logo_url", value)
                }
                value={business?.wa_business_logo_url}
                placeholder="Enter Business Logo Url"
              />
              {errors.wa_business_logo_url && (
                <ErrorMessage>{errors.wa_business_logo_url}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">Business description</Label>
              <TextInputStyle
                type="text"
                onChange={(value) =>
                  updateDetails("wa_business_description", value)
                }
                value={business?.wa_business_description}
                placeholder="Enter text Business Description"
              />
              {errors.wa_business_description && (
                <ErrorMessage>{errors.wa_business_description}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">Business website</Label>
              <TextInputStyle
                type="text"
                onChange={(value) =>
                  updateDetails("wa_business_website", value)
                }
                value={business?.wa_business_website}
                placeholder="Enter text Business Website"
              />
              {errors.wa_business_website && (
                <ErrorMessage>{errors.wa_business_website}</ErrorMessage>
              )}
            </BoxInput>
          </FormGridContainer>
        </FormCard>
        {/* admin details */}
        <FormCard>
          <h4>Admin Details</h4>
          <FormGridContainer>
            <BoxInput>
              <Label variant="text4">
                Prefix Name <sup>*</sup>
              </Label>

              <DropDownControlledStyle
                selectedValue={
                  getPrefixName()?.filter(
                    (item) => item.value === business?.admin?.prefix_name
                  )?.[0]?.label
                }
                variant="lighterGrey"
                options={getPrefixName()}
                onOptionClick={(onboard) =>
                  updateDetails("prefix_name", onboard?.value, true)
                }
              />
              {errors.prefix_name && (
                <ErrorMessage>{errors.prefix_name}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                First Name <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("first_name", value, true)}
                value={business?.admin?.first_name}
                placeholder="Enter First name"
              />
              {errors && errors.first_name && (
                <ErrorMessage>{errors.first_name}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Last Name <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("last_name", value, true)}
                value={business?.admin?.last_name}
                placeholder="Enter last name"
              />
              {errors.last_name && (
                <ErrorMessage>{errors.last_name}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                User Name <sup>*</sup>
              </Label>
              <TextInputStyle
                type="text"
                onChange={(value) => updateDetails("username", value, true)}
                value={business?.admin?.username}
                placeholder="Enter user name"
              />
              {errors.username && (
                <ErrorMessage>{errors.username}</ErrorMessage>
              )}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Contact Number <sup>*</sup>
              </Label>
              <CommonBox width="100%">
                <InputGroup style={{ width: "auto" }}>
                  <InputGroup.Prepend>
                    <CountryCodeSelection
                      style={{
                        height: "44px",
                        borderColor: "#00000014",
                        color: "#222222",
                        borderRadius: "4px",
                      }}
                      isAppend
                      countryCode={
                        business?.admin?.country_code
                          ? business?.admin?.country_code
                          : ""
                      }
                      handleChange={(value) =>
                        updateDetails("country_code", value, true)
                      }
                      countryCodeList={countryCodeList}
                    />
                  </InputGroup.Prepend>
                </InputGroup>
                {errors.country_code_admin && (
                  <ErrorMessage>{errors.country_code_admin}</ErrorMessage>
                )}
                <TextInputStyle
                  type="text"
                  onChange={(value) =>
                    updateDetails("phone_number", value, true)
                  }
                  value={business?.admin?.phone_number}
                  placeholder="Enter contact phone "
                  width="100%"
                  maxLength="10"
                />
                {errors.phone_number_admin && (
                  <ErrorMessage>{errors.phone_number_admin}</ErrorMessage>
                )}
              </CommonBox>
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Contact Email <sup>*</sup>
              </Label>
              <TextInputStyle
                type="email"
                onChange={(value) =>
                  updateDetails("contact_email", value, true)
                }
                value={business?.admin?.contact_email}
                placeholder="Enter contact email"
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">Profile Image URLs</Label>
              <TextInputStyle
                type="url"
                onChange={(value) =>
                  updateDetails("profile_image_url", value, true)
                }
                value={business?.admin?.profile_image_url}
                placeholder="Enter profile image url"
              />
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Gender <sup>*</sup>
              </Label>
              <DropDownControlledStyle
                selectedValue={
                  getGender()?.filter(
                    (item) => item.value === business?.admin?.gender
                  )?.[0]?.label
                }
                variant="lighterGrey"
                options={getGender()}
                onOptionClick={(onboard) =>
                  updateDetails("gender", onboard?.value, true)
                }
              />
              {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
            </BoxInput>
            <BoxInput>
              <Label variant="text4">
                Enter Password <sup>*</sup>
              </Label>
              <TextInputStyle
                type="password"
                onChange={(value) =>
                  business !== null &&
                  Object.keys(business).length &&
                  business?._id
                    ? updateDetails("plain_password", value, true)
                    : updateDetails("local_password", value, true)
                }
                value={
                  business !== null &&
                  Object.keys(business).length &&
                  business?._id
                    ? business?.admin?.plain_password
                    : business?.admin?.local_password
                }
                placeholder="Enter enter password"
              />
              {business !== null &&
              Object.keys(business).length &&
              business?._id
                ? errors.plain_password && (
                    <ErrorMessage>{errors.plain_password}</ErrorMessage>
                  )
                : errors.local_password && (
                    <ErrorMessage>{errors.local_password}</ErrorMessage>
                  )}
            </BoxInput>
          </FormGridContainer>
        </FormCard>
        <BoxInput>
          <Button
            type="primary"
            text={
              business !== null && Object.keys(business).length && business?._id
                ? "Update"
                : "Add"
            }
            onClick={(e) => saveHandler(e)}
          />
        </BoxInput>
      </FormContainer>
    </MessageContainer>
  );
};

export default CreateNewBusinessPage;
