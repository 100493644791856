import { useSelector } from "react-redux";
import useActiveCustomerId from "./useActiveCustomerId";

const useActiveChat = () => {
  const activeCustomerId = useActiveCustomerId();

  return useSelector(
    (state) =>
      state.customer?.customerData[activeCustomerId]?.activeChats || {},
  );
};

export default useActiveChat;
