import propTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import { layout, flexbox, typography, space, system } from "styled-system";

const typeToColorMapping = {
  green: "#ffffff",
  white: "#222222",
};
const typeToBackgroundMapping = {
  green: "#278351",
  white: "#ffffff",
};
const StyledContainer = styled.div`
  background: ${({ variant }) => typeToBackgroundMapping[variant]};
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  border-bottom: 0.6px solid #4444446e;
  font-size: 18px;
  height: 60px;
  justify-content: space-between;
  //padding: 10px 25px;

  ${layout}
  ${flexbox}
  ${space}

  ${system({
    cursor: {
      property: "cursor",
    },
  })};
`;

const StyledLeftHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledHeader = styled.div`
  display: flex;
  font-weight: 600;
  color: ${({ variant }) => typeToColorMapping[variant]};
  margin-bottom: 0px;

  ${typography}
  @media only screen and (max-width: 769px) {
    font-size: ${(props) => (props.font == "small-m" ? "1.2rem" : "")};
  }
`;

const ModalHeader = ({
  children,
  iconName,
  iconColor,
  font,
  iconSize = "small",
  onIconClick = () => {},
  onHide,
  variant = "green",
  isHideCloseIcon = false,
  closeIconSize = "xs",
  ...props
}) => {
  if (variant === "whiteV2") {
    return (
      <Box
        display="flex"
        height="56px"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="lightGrey.05"
        px="24px"
        {...props}
      >
        {!isHideCloseIcon && (
          <Icon iconName="close" iconSize="tiny" onClick={onHide} />
        )}
        <Label flex="1" textAlign="center" variant="text2" color="darkGrey.100">
          {children}
        </Label>
        {iconName && (
          <Icon
            iconName={iconName}
            color={iconColor || typeToColorMapping[variant]}
            iconSize={iconSize}
            onClick={onIconClick}
          />
        )}
      </Box>
    );
  }
  return (
    <StyledContainer variant={variant} {...props}>
      <StyledLeftHeader {...props}>
        {iconName && (
          <Icon
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            mr="12px"
            iconName={iconName}
            color={iconColor || typeToColorMapping[variant]}
            iconSize={iconSize}
          />
        )}
        <StyledHeader variant={variant} font={font} {...props}>
          {children}
        </StyledHeader>
      </StyledLeftHeader>
      {!isHideCloseIcon && (
        <Icon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          iconName="close"
          iconSize={closeIconSize}
          color={typeToColorMapping[variant]}
          onClick={onHide}
        />
      )}
    </StyledContainer>
  );
};

ModalHeader.propTypes = {
  children: propTypes.string,
  iconName: propTypes.string,
  onHide: propTypes.func,
  variant: propTypes.oneOf(["green", "white", "whiteV2"]),
};

ModalHeader.defaultProps = {
  children: null,
  iconName: null,
  onHide: null,
  variant: "green",
};

export default ModalHeader;
