import React from "react";
import styled from "styled-components";
import { space, color, layout, position, flexbox } from "styled-system";

const Container = styled.div`
  padding: 16px 8px 12px;
  border: 1px solid #5E3EC5;
  border-radius: 8px;
  background-color: #fff;
  max-height: 100%;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  border-bottom-left-radius: ${(props) => props.activeSmartCard && 0};
  border-bottom-right-radius: ${(props) => props.activeSmartCard && 0};
  height: ${(props) => props.activeSmartCard && "100%"};

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    padding: 12px 8px 12px;
    padding-top: ${(props) => props.activeSmartCard && 0};
  }

  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};

  ${space}
  ${color}
  ${layout}
  ${position}
  ${flexbox}
`;

const SmartCard = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default SmartCard;
