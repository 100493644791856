import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import isArray from "lodash/isArray";

import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import useOrganizationId from "customHooks/useOrganizationId";
import { showNotification } from "util/utility";
import PersonalDetailsCard from "components/Smartcards/PersonalDetailsCard";

import { toggleUserDetailsView } from "modules/Inbox/ThreadActions";
import {
  createNewNote,
  deleteNoteById,
  updateNoteDetails,
  updateUserTraits,
  updateCustomerTags,
} from "modules/Customer/CustomerActions";
import { saveWidgetPreferences } from "modules/App/AppActions";
import AddTagForm from "components/CustomerComponents/AddTagForm";
import useMediaQuery from "customHooks/useMediaQuery";
import LiveChatCustomer from "modules/Inbox/pages/InboxPage/components/UserDetailsSidebar/components/LiveChatCustomer";
import AttributesCard from "./components/AttributesCard";
import CampaignsCard from "./components/CampaignsCard";
import useActiveCustomerId from "customHooks/useActiveCustomerId";
import useActiveCustomer from "customHooks/useActiveCustomer";
import TagsCard from "./components/TagsCard";
import { addTagDetails } from "../../../../../Manage/ManagePageAction";
import BlockIncomingMessage from "./components/BlockIncomingMessage";
import NotesCard from "./components/NotesCard";
import { addNotes } from "modules/Inbox/ConversationActions";

const UserDetailsSidebar = ({
  handleToggleCustomizeModal,
  detailSegmentDndInfoList,
  isShowCustomizeModal,
  handleCloseSidebar,
  customerList,
  rest,
}) => {
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery("desktop", "up");

  const [tagFormVisiblity, setTagFormVisibility] = useState(false);
  const activeProjectId = useSelector((state) => state?.app?.activeProjectId);

  const organizationId = useOrganizationId();
  const activeCustomerId = useActiveCustomerId();
  const activeCustomer = useActiveCustomer();
  const {
    isSavingWidgetPreferences,
    isCreatingOrganizationTag,
    allAgents: agents,
    user,
    allTags,
    tagList,
  } = useSelector((state) => state.app);

  const {
    customerData,
    isUpdatingCustomerDetail,
    customer_attributes: customerAttributes,
    isUpdatingCustomerTags,
    isFetchingChatList,
    isFetchingCustomerEvents,
    isFetchingOrderList,
  } = useSelector((state) => state.customer);

  const { isUserDetailsViewVisible, threads } = useSelector(
    (state) => state.thread
  );

  const { activeSmartCard, personalDetailUpdatedData, personalDetailsErrors } =
    useSelector((state) => state.inboxState.smartCard);

  const handleBackFromDetailsview = () => {
    dispatch(toggleUserDetailsView(false));
  };

  const toggleAndSave = () => {
    dispatch(saveWidgetPreferences(organizationId, detailSegmentDndInfoList));
    handleToggleCustomizeModal(!isShowCustomizeModal, true);
  };

  const updateProfile = (traits) => {
    const id = activeCustomerId;
    if (id && activeProjectId) {
      dispatch(updateUserTraits(traits));
    } else if (!id) {
      showNotification("warning", "Customer is not available");
    }
  };

  const updateNote = (noteId, note) => {
    if (!!note && note?.notes?.trim() !== "" && organizationId) {
      dispatch(
        updateNoteDetails(organizationId, activeCustomerId, noteId, note)
      );
    } else {
      showNotification("warning", "Can't save empty note");
    }
  };

  const handleCreateNewNote = (note) => {
    if (!!note && note?.notes?.trim() !== "") {
      dispatch(createNewNote(organizationId, activeCustomerId, note));
    } else {
      showNotification("warning", "Can't save empty note");
    }
  };

  const deleteNote = (id) => {
    if (!!id && organizationId) {
      dispatch(deleteNoteById(organizationId, activeCustomerId, id));
    }
  };

  const createNewTag = (tag) => {
    if (tag && tag.name) {
      dispatch(addTagDetails(tag));
    }
  };

  const updateCustomer = (data) => {
    if (activeProjectId) {
      dispatch(
        updateCustomerTags(activeProjectId, activeCustomerId, {
          ...activeCustomer,
          ...data,
        })
      );
    }
  };

  const handleAddNewtagsUser = (newTagList) => {
    const newCustomer = {
      ...activeCustomer,
      tags: [...newTagList],
      traits: activeCustomer.traits || {},
    };

    updateCustomer(newCustomer);
  };

  const disableSaveBtn = () =>
    personalDetailsErrors &&
    !Object.values(personalDetailsErrors).every((item) => item === false);

  const handleSaveBtnClick = () => {
    if (!disableSaveBtn()) {
      updateProfile(personalDetailUpdatedData);
    }
  };
  const getDetailsegmentComponent = (id) => {
    const noteList =
      activeCustomerId &&
      customerData[activeCustomerId] &&
      customerData[activeCustomerId].noteList
        ? customerData[activeCustomerId].noteList
        : [];
    const activeCustomerChatHistory =
      activeCustomerId &&
      customerData[activeCustomerId] &&
      customerData[activeCustomerId].activeChats
        ? customerData[activeCustomerId].activeChats
        : [];
    const customerEvents =
      customerData &&
      customerData[activeCustomerId] &&
      customerData[activeCustomerId].customerEvents
        ? customerData[activeCustomerId].customerEvents
        : [];
    const activeCustomerThread =
      activeCustomer && threads && threads[activeCustomer.channel_phone_number]
        ? threads[activeCustomer.channel_phone_number]
        : null;
    const customerOrders =
      customerData &&
      customerData[activeCustomerId] &&
      customerData[activeCustomerId].orderList
        ? customerData[activeCustomerId].orderList
        : [];
    switch (id) {
      case "Personal Detail":
        return (
          <PersonalDetailsCard
            customerAttributes={customerAttributes}
            activeCustomer={activeCustomer}
            maxHeight="100%"
            handleSaveBtnClick={handleSaveBtnClick}
            isUpdatingCustomerDetail={isUpdatingCustomerDetail}
            customerList={customerList}
          />
        );

      case "Attributes":
        return (
          <AttributesCard
            heading="Attributes"
            traits={activeCustomer?.traits}
            updateProfile={updateProfile}
          />
        );

      case "Campaigns":
        return <CampaignsCard heading="Campaigns" />;
      case "Notes":
        return <NotesCard heading="Notes" activeCustomer={activeCustomer} />;
      case "Tags":
        return (
          <TagsCard
            heading="Tags"
            updateTag={updateCustomer}
            createNewTag={createNewTag}
            activeCustomer={activeCustomer}
          />
        );
      case "Customer Journey":
        return <LiveChatCustomer heading="Customer Journey" />;
      case "Block incoming message":
        return <BlockIncomingMessage activeCustomer={activeCustomer} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Box noScrollBar bg="#fff" {...rest} height="100%" borderRadius="8px">
        <div
          className="hide-desktop"
          style={{ position: "sticky", top: 0, zIndex: "1" }}
        >
          {isUserDetailsViewVisible && (
            <Box
              p="21px"
              bg="#DDDEFF"
              display="flex"
              className="align-items-center justify-content-between"
            >
              <Icon
                iconName="back"
                width="13px"
                height="13px"
                className="hide-in-desktop"
                onClick={() => handleBackFromDetailsview()}
              ></Icon>
              <span
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Chat profile
              </span>
              <span></span>
            </Box>
          )}
        </div>
        {customerList && (
          <Box display="flex" width="100%" alignItems="center">
            {handleCloseSidebar && (
              <Icon
                iconName="close"
                onClick={() => handleCloseSidebar()}
                width="14px"
                mr="13px"
              />
            )}
            <Label
              className="details"
              data-cy="details-text"
              text="User details"
              fontSize="18px"
              fontWeight="400"
            />
          </Box>
        )}
        <div>
          {!activeSmartCard?.name && (
            <Box
              mb={activeSmartCard?.name ? "20px" : "24px"}
              display="flex"
              alignItems="center"
              className=" hide-in-mobile"
            ></Box>
          )}
          <Box
            height={activeSmartCard?.name ? "100%" : "calc(100% - 45px)"}
            className={
              activeSmartCard?.name
                ? "mobile-side-scroll-m"
                : "full-view mobile-side-scroll-m mt-0-mobile"
            }
          >
            <Box
              mt={{ _: `${activeSmartCard?.name ? 0 : "10px"}`, tablet: 0 }}
              pb="40px"
            >
              {detailSegmentDndInfoList
                ?.filter((item) => item.isVisible)
                .map((card, index) => (
                  <React.Fragment key={index}>
                    {getDetailsegmentComponent(card.id)}
                  </React.Fragment>
                ))}
            </Box>
            {/*<UserSpamCard/>*/}
          </Box>
        </div>
      </Box>

      {tagFormVisiblity && (
        <AddTagForm
          show={tagFormVisiblity}
          onHide={() => setTagFormVisibility(false)}
          allTags={allTags}
          tagList={tagList}
          isUpdatingCustomerDetail={isUpdatingCustomerDetail}
          isUpdatingCustomerTags={isUpdatingCustomerTags}
          updateCustomer={updateCustomer}
          isCreatingOrganizationTag={isCreatingOrganizationTag}
          createNewTag={createNewTag}
          handleAddNewtagsUser={handleAddNewtagsUser}
          customerId={activeCustomerId}
          oldTags={(activeCustomer &&
          activeCustomer.tags &&
          isArray(activeCustomer.tags)
            ? activeCustomer.tags
            : []
          ).filter((id) => !!allTags[id])}
        />
      )}
    </>
  );
};

UserDetailsSidebar.defaultProps = {
  detailSegmentDndInfoList: [
    {
      id: "Personal Detail",
      isVisible: true,
      displayOrder: 1,
    },
    {
      id: "Attributes",
      isVisible: true,
      displayOrder: 2,
    },
    {
      id: "Campaigns",
      isVisible: true,
      displayOrder: 3,
    },
    {
      id: "Tags",
      isVisible: true,
      displayOrder: 4,
    },
    {
      id: "Customer Journey",
      isVisible: true,
      displayOrder: 5,
    },
    {
      id: "Orders",
      isVisible: true,
      displayOrder: 6,
    },
    {
      id: "Notes",
      isVisible: true,
      displayOrder: 7,
    },
    {
      id: "Block incoming message",
      isVisible: true,
      displayOrder: 8,
    },
  ],
};

export default UserDetailsSidebar;
