import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import Icon from "konnekt/Icon";
import { getFormattedTime } from "util/timeUtility";
import Box from "konnekt/Box";
import { layout, space } from "styled-system";
import "css/ReactDatepicker.css";

const StyledInput = styled("input")`
  background: none;
  &:focus {
    outline: none;
  }
  padding-left: 6px;
  height: auto;
  &::-webkit-input-placeholder {
    /* Edge */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  &::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  ${layout}
  ${space}
`;

const CalendarIcon = () => (
  <Icon
    iconName="calendar"
    color="#222222"
    height="23px"
    width="23px"
    noWrapper
  />
);

const mapInputWrapperStyles = {
  light: {
    mt: "0.2rem",
    p: "1px 2px",
    width: "100%",
    height: "30px",
    border: "0.5px solid #c4c4c4",
    borderRadius: "3px",
  },
  dark: {
    border: "2px solid #222",
    borderRadius: "6px",
    paddingBottom: "3px",
    paddingLeft: "2px",
    width: "184px",
    height: "41px",
  },
};

const CustomDatePicker = ({
  selectedDate,
  setDate,
  placeholder,
  dateTimeFormat = "DD/MM/YYYY",
  iconDir = "left",
  inputWrapperProps,
  inputProps,
  variant = "light",
  ...props
}) => (
  <DatePicker
    selected={selectedDate}
    onChange={(date) => setDate?.(date)}
    showTimeSelect={true}
    timeIntervals={5}
    style={{
      padding: "5 0 6 0",
    }}
    popperPlacement="top-end"
    {...props}
    customInput={
      <Box
        className="campaign-input"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxSizing="border-box"
        {...(mapInputWrapperStyles[variant] ?? {})}
        {...inputWrapperProps}
      >
        {iconDir === "left" && <CalendarIcon />}
        <StyledInput
          type="text"
          placeholder={placeholder}
          value={
            selectedDate ? getFormattedTime(selectedDate, dateTimeFormat) : null
          }
          {...inputProps}
        />
        {iconDir === "right" && <CalendarIcon />}
      </Box>
    }
  />
);

export default CustomDatePicker;
