import React, { useEffect, useState } from "react";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import {
  LiveChatProfilesBody,
  LiveChatProfilesHading,
  LiveChatProfileItem,
  NotesList,
} from "./TagCard.styles";
import Button from "konnekt/Buttons";
import { useSelector, useDispatch } from "react-redux";
import { addNotes } from "modules/Inbox/ConversationActions";
import { getFormattedTime } from "util/timeUtility";
const NotesCard = ({ heading, activeCustomer }) => {
  const [iconManage, setIconManage] = useState();
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();

  const iconChangeHandle = () => {
    setIconManage(!iconManage);
  };

  const activeProjectId = useSelector((state) => state?.app?.activeProjectId);
  const activeConversationId = useSelector(
    (state) => state.thread?.activeConversation?._id
  );
  const handleNotes = () => {
    const data = {};
    data.text = notes;
    data._id = activeCustomer?._id;
    if (activeProjectId) {
      dispatch(addNotes(activeProjectId, data));
    }
    setNotes("");
  };
  const renderNoteList = () => {
    return (
      activeCustomer?.notes &&
      activeCustomer?.notes?.length > 0 &&
      activeCustomer?.notes?.map((notes) => (
        <NotesList key={notes._id}>
          <h6>{notes?.text}</h6>
          <Box display="flex" justifyContent="space-between" color="#727272">
            <p>{notes?.created_by}</p>
            <p>{getFormattedTime(notes?.created_at, "MMMM DD, YYYY")}</p>
          </Box>
        </NotesList>
      ))
    );
  };
  return (
    <LiveChatProfilesBody>
      <Box display="flex" justifyContent="space-between">
        <LiveChatProfilesHading>{heading}</LiveChatProfilesHading>
        {!iconManage ? (
          <Icon iconName="plus" color="#5E3EC5" onClick={iconChangeHandle} />
        ) : (
          <Icon iconName="minus" color="#5E3EC5" onClick={iconChangeHandle} />
        )}
      </Box>
      <LiveChatProfileItem iconManage={iconManage}>
        <Box mt="7px">
          <textarea
            className="form-control"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            cols="30"
            rows="2"
            placeholder="Enter your notes here"
          ></textarea>
          {notes.length > 0 && (
            <Button
              fontSize="13px"
              fontWeight="500"
              height="28px"
              mt="10px"
              onClick={handleNotes}
            >
              Create
            </Button>
          )}
        </Box>
        <Box fontSize="12px" mt="20px" p="0 12px">
          {renderNoteList()}
        </Box>
      </LiveChatProfileItem>
    </LiveChatProfilesBody>
  );
};

export default NotesCard;
