import callApi, { callApiV2 } from "util/apiCaller";
import publicApiCaller from "util/publicApiCaller";
import { toast } from "react-toastify";
import React from "react";
import _ from "lodash";
import { trackEvent } from "util/tracking";
import JSON5 from "json5";
import { clearLocalStorage } from "util/utility";
import {
  makeNameVariableFirstInList,
  showNotification,
} from "../../util/utility";
import { assignConversationLabelToCustomer } from "../Inbox/ThreadActions";

export const SELECT_PROJECT = "SELECT_PROJECT";
export const ATTEMPT_TO_LOGIN = "ATTEMPT_TO_LOGIN";
export const UPDATE_WEB_CLIENT_ID = "UPDATE_WEB_CLIENT_ID";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ATTEMPT_TO_LOGOUT = "ATTEMPT_TO_LOGOUT";

export const ATTEMPT_TO_VERIFY = "ATTEMPT_TO_VERIFY";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILED = "VERIFY_FAILED";

export const TOGGLE_BOTTOM_NAVBAR = "TOGGLE_BOTTOM_NAVBAR";

export const FETCHING_ORGANIZATION_DETAILS = "FETCHING_ORGANIZATION_DETAILS";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_ORG = "FETCH_USER_ORG";
export const FETCHING_USER_CHANNELS = "FETCHING_USER_CHANNELS";
export const FETCH_USER_CHANNELS = "FETCH_USER_CHANNELS";
export const ADDING_USER_CHANNEL = "ADDING_USER_CHANNEL";
export const ADD_USER_CHANNEL = "ADD_USER_CHANNEL";

export const ORGANIZATION_TAG_LIST_FETCH_STATUS =
  "ORGANIZATION_TAG_LIST_FETCH_STATUS";
export const ORGANIZATION_TAG_LIST = "ORGANIZATION_TAG_LIST";
export const CREATE_ORGANIZATION_TAG_STATUS = "CREATE_ORGANIZATION_TAG_STATUS";
export const CREATE_ORGANIZATION_TAG = "CREATE_ORGANIZATION_TAG";
export const CHECK_ORGANIZATION_ONBOARD_STATUS =
  "CHECK_ORGANIZATION_ONBOARD_STATUS";
export const UPDATE_ORGANIZATION_ONBOARD_STATUS =
  "UPDATE_ORGANIZATION_ONBOARD_STATUS";

export const FETCHING_ALL_ORG_AGENTS = "FETCHING_ALL_ORG_AGENTS";
export const FETCH_ALL_ORG_AGENTS = "FETCH_ALL_ORG_AGENTS";

export const FETCHING_OTP = "FETCHING_OTP";
export const FETCH_OTP = "FETCH_OTP";

export const VERIFYING_OTP = "VERIFYING_OTP";
export const VERIFY_OTP = "VERIFY_OTP";

export const FETCHING_ORG_EVENT_SOURCES = "FETCHING_ORG_EVENT_SOURCES";
export const FETCH_ORG_EVENT_SOURCES = "FETCH_ORG_EVENT_SOURCES";

export const FETCHING_ORG_QUICK_REPLIES = "FETCHING_ORG_QUICK_REPLIES";
export const FETCH_ORG_QUICK_REPLIES = "FETCH_ORG_QUICK_REPLIES";
export const ADDING_ORG_QUICK_REPLIES = "ADDING_ORG_QUICK_REPLIES";
export const UPDATING_ORG_QUICK_REPLIES = "UPDATING_ORG_QUICK_REPLIES";
export const REMOVING_ORG_QUICK_REPLIES = "REMOVING_ORG_QUICK_REPLIES";
export const ADDED_ORG_QUICK_REPLIES = "ADDED_ORG_QUICK_REPLIES";
export const REMOVED_ORG_QUICK_REPLIES = "REMOVED_ORG_QUICK_REPLIES";
export const UPDATED_ORG_QUICK_REPLIES = "UPDATED_ORG_QUICK_REPLIES";
export const FETCH_USER_DEVICE_TYPE = "FETCH_USER_DEVICE_TYPE";

export const FETCHING_ORG_MEMBERS = "FETCHING_ORG_MEMBERS";
export const FETCH_ORG_MEMBERS = "FETCH_ORG_MEMBERS";
export const ADDING_ORG_MEMBERS = "ADDING_ORG_MEMBERS";
export const UPDATING_ORG_MEMBERS = "UPDATING_ORG_MEMBERS";
export const REMOVING_ORG_MEMBERS = "REMOVING_ORG_MEMBERS";
export const ADDED_ORG_MEMBERS = "ADDED_ORG_MEMBERS";
export const REMOVED_ORG_MEMBERS = "REMOVED_ORG_MEMBERS";
export const UPDATED_ORG_MEMBERS = "UPDATED_ORG_MEMBERS";
export const SET_NETWORK_STATUS = "SET_NETWORK_STATUS";
export const SET_SOCKET_STATUS = "SET_SOCKET_STATUS";
export const TOGGLE_CREATE_ACCOUNT_SEGMENT = "TOGGLE_CREATE_ACCOUNT_SEGMENT";

// register
export const REQUEST_REGISTER = "REQUEST_REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GENERATE_VERIFICATION_EMAIL = "GENERATE_VERIFICATION_EMAIL";
export const EMAIL_VERIFYING = "EMAIL_VERIFYING";
export const EMAIL_VERIFY = "EMAIL_VERIFY";

export const VERIFYING_BUSINESS_MANAGER = "VERIFYING_BUSINESS_MANAGER";
export const VERIFIED_BUSINESS_MANAGER = "VERIFIED_BUSINESS_MANAGER";

export const UPDATING_BUSINESS_MANAGER = "UPDATING_BUSINESS_MANAGER";
export const UPDATED_BUSINESS_MANAGER = "UPDATED_BUSINESS_MANAGER";

export const CREATING_ORGANIZATION_CHANNEL = "CREATING_ORGANIZATION_CHANNEL";
export const CREATED_ORGANIZATION_CHANNEL = "CREATED_ORGANIZATION_CHANNEL";
export const SET_QUALITY_RATING = "SET_QUALITY_RATING";

export const TOGGLE_RECOVERY_PASSWORD_SEGMENT =
  "TOGGLE_RECOVERY_PASSWORD_SEGMENT";
export const SEND_EMAIL_RESET_PASSWORD = "SEND_EMAIL_RESET_PASSWORD";
export const RECOVERY_PASSWORD_STATUS = "RECOVERY_PASSWORD_PASSWORD";

export const TOGGLE_RESET_PASSWORD_SEGMENT = "TOGGLE_RESET_PASSWORD_SEGMENT";
export const UPDATING_RESET_PASSWORD = "UPDATING_RESET_PASSWORD";

export const RESEND_INVITATION_MEMBER = "RESEND_INVITATION_MEMBER";
export const SET_BOX_VALUE = "SET_BOX_VALUE";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

export const UPDATE_RECONNECTING_STATUS = "UPDATE_RECONNECTING_STATUS";
export const SAVE_WIDGET_PREFERENCES = "SAVE_WIDGET_PREFERENCES";
export const SAVE_WIDGET_PREFERENCES_STATUS = "SAVE_WIDGET_PREFERENCES_STATUS";

export const ADD_NEW_BULK_UPLOAD_SELECTED_TAGS =
  "ADD_NEW_BULK_UPLOAD_SELECTED_TAGS";
export const REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS =
  "REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS";
export const TOGGLE_BULK_UPLOAD_SEGMENT = "TOGGLE_BULK_UPLOAD_SEGMENT";
export const UPDATE_BULK_UPLOAD_SELECTED_TAGS =
  "UPDATE_BULK_UPLOAD_SELECTED_TAGS";
export const CLEAR_BULK_UPLOAD_NON_CREATED_TAGS =
  "CLEAR_BULK_UPLOAD_NON_CREATED_TAGS";
export const UPDATE_ORGANIZATION_STATUS = "UPDATE_ORGANIZATION_STATUS";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const FETCH_ORGANIZATION_STATUS = "FETCH_ORGANIZATION_STATUS";
export const TOGGLE_SHOPIFY_SEGMENT = "TOGGLE_SHOPIFY_SEGMENT";

export const FETCHING_BUNDLED_DATA = "FETCHING_BUNDLED_DATA";
export const FETCH_BUNDLED_DATA = "FETCH_BUNDLED_DATA";

export const TOGGLE_ADD_QUICKREPLY_MODAL = "TOGGLE_ADD_QUICKREPLY_MODAL";
export const TOGGLE_EDIT_QUICKREPLY_MODAL = "TOGGLE_EDIT_QUICKREPLY_MODAL";
export const TOGGLE_DELETE_QUICKREPLY_MODAL = "TOGGLE_DELETE_QUICKREPLY_MODAL";
export const TOGGLE_DELETE_TEAMSETTING_MODAL =
  "TOGGLE_DELETE_TEAMSETTING_MODAL";
export const TOGGLE_EDIT_TEAMSETTING_MODAL = "TOGGLE_EDIT_TEAMSETTING_MODAL";
export const SET_QUICK_REPLY_FIELDS = "SET_QUICK_REPLY_FIELDS";
export const SET_QUICK_REPLY_VARIABLE_COUNT = "SET_QUICK_REPLY_VARIABLE_COUNT";
export const SET_QUICK_REPLY_INPUT_FIELDS = "SET_QUICK_REPLY_INPUT_FIELDS";

export const TOGGLE_BUG_REPORT_MODAL = "TOGGLE_BUG_REPORT_MODAL";
export const TOGGLE_PAYMENT_MODAL = "TOGGLE_PAYMENT_MODAL";
export const SET_BUG_REPORT_ISSUE_WITH = "SET_BUG_REPORT_ISSUE_WITH";
export const SET_BUG_REPORT_SUBJECT = "SET_BUG_REPORT_SUBJECT";
export const SET_BUG_REPORT_DETAIL = "SET_BUG_REPORT_DETAIL";
export const UPDATE_BUG_REPORT = "UPDATE_BUG_REPORT";
export const TOGGLE_EMOJI_CARD_MODAL = "TOGGLE_EMOJI_CARD_MODAL";

export const SET_TEAM_MEMBER_FIELDS = "SET_TEAM_MEMBER_FIELDS";
export const TOGGLE_ADD_TEAM_MEMBER_MODAL = "TOGGLE_ADD_TEAM_MEMBER_MODAL";

export const SET_ORGANIZATION_DETAIL_INPUT_VALUES =
  "SET_ORGANIZATION_DETAIL_INPUT_VALUES";
export const SET_USER_TO_CAMP_REDIRECT_DATA = "SET_USER_TO_CAMP_REDIRECT_DATA";

export const FETCHING_FB_EMBEDDED_DATA = "FETCHING_FB_EMBEDDED_DATA";
export const SAVE_USER_JOURNEY = "SAVE_USER_JOURNEY";
export const TOGGLE_SIDEBAR_STATUS = "TOGGLE_SIDEBAR_STATUS";
export const CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS =
  "CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS";
export const FETCHING_ORG_CONVERSATION_LABELS =
  "FETCHING_ORG_CONVERSATION_LABELS";
export const FETCH_ORG_CONVERSATION_LABELS = "FETCH_ORG_CONVERSATION_LABELS";
export const UPDATE_ORG_CONVERSATION_LABELS = "UPDATE_ORG_CONVERSATION_LABELS";

export const FETCH_LIST_MESSAGE_DETAILS = "FETCH_LIST_MESSAGE_DETAILS";
export const FETCH_LIST_MESSAGE_DETAILS_SUCCESS =
  "FETCH_LIST_MESSAGE_DETAILS_SUCCESS";
export const FETCH_LIST_MESSAGE_DETAILS_FAILURE =
  "FETCH_LIST_MESSAGE_DETAILS_FAILURE";
export const UPDATE_LIST_MESSAGE_DATA = "UPDATE_LIST_MESSAGE_DATA";
export const UPDATE_LIST_MESSAGE_DETAILS = "UPDATE_LIST_MESSAGE_DETAILS";

export const UPDATE_ORG_FOR_FAQ_BOT = "UPDATE_ORG_FOR_FAQ_BOT";

export const UPDATE_ORG_FOR_E_MANDATE = "UPDATE_ORG_FOR_E_MANDATE";

export const SET_CATALOG_ID = "SET_CATALOG_ID";

export const FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST";
export const FETCH_PROJECT_LIST_SUCCESS = "FETCH_PROJECT_LIST_SUCCESS";
export const FETCH_PROJECT_LIST_FAILURE = "FETCH_PROJECT_LIST_FAILURE";

export const FETCH_BUSINESS_LIST = "FETCH_BUSINESS_LIST";
export const FETCH_BUSINESS_LIST_SUCCESS = "FETCH_BUSINESS_LIST_SUCCESS";
export const FETCH_BUSINESS_LIST_FAILURE = "FETCH_BUSINESS_LIST_FAILURE";

export const ADD_PROJECT_DETAILS = "ADD_PROJECT_DETAILS";
export const ADD_PROJECT_DETAILS_SUCCESS = "ADD_PROJECT_DETAILS_SUCCESS";
export const ADD_PROJECT_DETAILS_FAILURE = "ADD_PROJECT_DETAILS_FAILURE";
export const UPDATE_USER_PROJECT_DETAILS = "UPDATE_USER_PROJECT_DETAILS";
export const UPDATE_USER_PROJECT_DETAILS_UPDATE_SUCCESS =
  "UPDATE_USER_PROJECT_DETAILS_UPDATE_SUCCESS";
export const USER_PROJECT_DETAILS_UPDATE_FAILURE =
  "USER_PROJECT_DETAILS_UPDATE_FAILURE";
export const FETCH_USER_PROJECT_DATA = "FETCH_USER_PROJECT_DATA";
export const FETCH_CAMPAIGN_LIST_SUCCESS = "FETCH_CAMPAIGN_LIST_SUCCESS";
export const FETCH_CAMPAIGN_DETAIL = "FETCH_CAMPAIGN_DETAIL";
export const FETCH_CAMPAIGN_FAILURE = "FETCH_CAMPAIGN_FAILURE";
export const ADD_NEW_CAMPAIGN_DETAILS_STATUS =
  "ADD_NEW_CAMPAIGN_DETAILS_STATUS";
export const ADD_NEW_CAMPAIGN_DETAILS = "ADD_NEW_CAMPAIGN_DETAILS";
export const FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS =
  "FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS";
export const FETCH_ALL_CUSTOMER_LIST_DETAILS =
  "FETCH_ALL_CUSTOMER_LIST_DETAILS";
export const HANDLE_USER_PROJECT_REMOVAL = "HANDLE_USER_PROJECT_REMOVAL";
export const FETCH_BUSINESS_ADMIN = "FETCH_BUSINESS_ADMIN";
export const FETCH_BUSINESS_ADMIN_SUCCESS = "FETCH_BUSINESS_ADMIN_SUCCESS";
export const FETCH_BUSINESS_ADMIN_FAILURE = "FETCH_BUSINESS_ADMIN_FAILURE";
const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const errorList = [
  {
    code: 400,
    title: "Media download error",
    message: "Failed to download the media from the sender.",
  },
  {
    code: 401,
    title: "Group does not exist",
    message:
      "Message failed to send because this group doesn't exist or you're not a participant.",
  },
  {
    code: 402,
    title: "Business eligibility — Payment issue",
    message:
      "Message failed to send because there were one or more errors related to your payment method.\nPayment account is not attached to a WhatsApp Account\nCredit line is over the limit\nCredit line (Payment account) not set or active\nWhatsApp Business Account is deleted\nAccount has been suspended by us\nTimezone not set\nCurrency not set\nMessagingFor request (On Behalf Of (OBO)) is pending or declined\nCheck your payment setup in WhatsApp Manager and try again.",
  },
  {
    code: 408,
    title: "Message is not valid",
    message:
      "Message failed to send because it was pending for too long.\nResend the message.",
  },
  {
    code: 410,
    title: "Message expired",
    message: "Message failed to send during its Time To Live (TTL) duration.",
  },
  {
    code: 429,
    title: "Rate limit hit",
    message:
      "Message failed to send because there were too many messages sent from this phone number in a short period of time.\nResend the failed messages.",
  },
  {
    code: 430,
    title: "Unsigned certificate",
    message:
      "Message failed to send because there was an error related to your certificate.\nDownload a new certificate from the WhatsApp Manager and re-register.",
  },
  {
    code: 432,
    title: "Certificate ID mismatch",
    message:
      "Message failed to send because there was an error related to your certificate.\nDownload a new certificate from the WhatsApp Manager and re-register.",
  },
  {
    code: 433,
    title: "Certificate signature invalid",
    message:
      "Occurs when a message is sent but the business client's certificate is not properly signed using the client's identity keys. This likely indicates that the client re-registered with new identity keys but did not go through the full certificate creation flow.",
  },
  {
    code: 470,
    title: "Re-engagement message",
    message:
      "Message failed to send because more than 24 hours have passed since the customer last replied to this number.\nUse a message template to respond.",
  },
  {
    code: 471,
    title: "Spam Rate limit hit",
    message:
      "Message failed to send because there are restrictions on how many messages can be sent from this phone number. This may be because too many previous messages were blocked or flagged as spam.\nCheck your quality status in the WhatsApp Manager. See the Quality-Based Rate Limits documentation for more information.",
  },
  {
    code: 472,
    title: "User's number is part of an experiment",
    message:
      "Failed to send message because this user's phone number is part of an experiment.\nSkip sending messages to this user.",
  },
  {
    code: 500,
    title: "Generic error",
    message:
      "Message failed to send because of an unknown error.\nTry again later.",
  },
  {
    code: 1000,
    title: "Generic error",
    message: "",
  },
  {
    code: 1001,
    title: "Message too long",
    message: "Message length exceeds 4096 characters.",
  },
  {
    code: 1002,
    title: "Invalid recipient type",
    message: "Valid recipient types are:\n\nindividual\ngroup",
  },
  {
    code: 1003,
    title: "Not a group participant",
    message: "User is not a participant of the group.",
  },
  {
    code: 1004,
    title: "Resource already exists",
    message:
      "Possible UUID conflict for media upload request or media with that UUID already exists.",
  },
  {
    code: 1005,
    title: "Access denied",
    message:
      "Media directory is not writable (upload request), or\nInvalid credentials, or\nCertificate Error, or\nApp Expired: a version upgrade is required, or\nNumber is already registered on WhatsApp\nSee Migrating a Phone Number for information on moving a phone number from WhatsApp to the WhatsApp Business API.",
  },
  {
    code: 1006,
    title: "Resource not found",
    message: "File or resource not found",
  },
  {
    code: "1007 (Deprecated)",
    title: "Recipient blocked to receive message",
    message: "Recipient is not whitelisted",
  },
  {
    code: 1008,
    title: "Required parameter is missing",
    message: "Missing a required parameter",
  },
  {
    code: 1009,
    title: "Parameter value is not valid",
    message:
      "Value entered for a parameter is of the wrong type or other problem.",
  },
  {
    code: 1010,
    title: "Parameter is not required",
    message: "Contains a parameter that is not required.",
  },
  {
    code: 1011,
    title: "Service not ready",
    message: "",
  },
  {
    code: 1012,
    title: "Group is unknown",
    message:
      "You will receive this error when you send messages to a group in which you are the only member. Add some members to the group before sending messages.",
  },
  {
    code: 1013,
    title: "User is not valid",
    message: "",
  },
  {
    code: 1014,
    title: "Internal error",
    message:
      "Upload failed on bad image (image not uploaded correctly) or endpoint not found",
  },
  {
    code: 1015,
    title: "Too many requests",
    message: "Client-side rate limit has been hit",
  },
  {
    code: 1016,
    title: "System overloaded",
    message:
      "If the system is under heavy load, this error is returned to allow the system to recover from the load.",
  },
  {
    code: 1017,
    title: "Not Primary Master",
    message:
      "You receive this error when a master-only request, such as set settings, get settings, import, export, code request, register, etc. is sent to a node that is not a primary master. This could happen when the WhatsApp Business API Client is not setup properly or internal errors.\nRetrying the request should resolve this error most of time. If not, please contact us for support.",
  },
  {
    code: 1018,
    title: "Not Primary Coreapp",
    message:
      "You receive this error when requests are sent to a Coreapp node that is not the shard owner or in the process to become the shard owner. You might see this error when we are doing shard failover in the multiconnect setup.\n\nRetrying the request should resolve this error most of time. If not, please contact Support.",
  },
  {
    code: 1019,
    title: "Not a group admin",
    message: "",
  },
  {
    code: 1020,
    title: "Bad group",
    message: "",
  },
  {
    code: 1021,
    title: "Bad User",
    message:
      "You will receive this message when you send a message to yourself.\nTo resolve, send the message to number that is not your own.",
  },
  {
    code: 1022,
    title: "Webhooks URL is not configured",
    message:
      "You will receive this error if you have not configured the REST API Webhooks format.",
  },
  {
    code: 1023,
    title: "Database error occurred",
    message: "",
  },
  {
    code: 1024,
    title: "Password Change Required",
    message: "You are required to change your password.",
  },
  {
    code: 1025,
    title: "Invalid Request",
    message: "The request is not valid.",
  },
  {
    code: 1026,
    title: "Receiver Incapable",
    message: "The receiver is incapable of receiving this message.",
  },
  {
    code: 2000,
    title: "Template Param Count Mismatch",
    message:
      "Number of parameters provided does not match the expected number of parameters.",
  },
  {
    code: 2001,
    title: "Template Missing",
    message: "Template does not exist for a language and locale.",
  },
  {
    code: 2002,
    title: "Template Fetch Failed",
    message: "The receiver failed to download the template.",
  },
  {
    code: 2003,
    title: "Template Pack Missing",
    message: "No templates exist for a specific language and locale.",
  },
  {
    code: 2004,
    title: "Template Param Length Too Long",
    message: "Parameter length too long",
  },
  {
    code: 2005,
    title: "Template Hydrated Text Too Long",
    message: "Translated text too long",
  },
  {
    code: 2006,
    title: "Template White Space Policy Violated",
    message: "Whitespace policy violated",
  },
  {
    code: 2007,
    title: "Template Format Character Policy Violated",
    message: "Format character policy violated",
  },
  {
    code: 2008,
    title: "Template Media Format Unsupported",
    message: "Media format used is unsupported",
  },
  {
    code: 2009,
    title: "Template Required Component Missing",
    message: "Required component in the Template is missing",
  },
  {
    code: 2010,
    title: "Template Invalid Hydrated URL",
    message: "URL in button component is invalid",
  },
  {
    code: 2011,
    title: "Template Invalid Phone Number",
    message: "Phone Number in button component is invalid",
  },
  {
    code: 2012,
    title: "Template Parameter Format Mismatch",
    message: "Parameter format does not match format in the created Template",
  },
  {
    code: 2013,
    title: "Template Buttons Unsupported",
    message: "Buttons are unsupported by the receiver",
  },
];

export function toggleSideBarStatus(val) {
  return {
    type: TOGGLE_SIDEBAR_STATUS,
    isShowSidebar: val,
  };
}

export function setUserToCampaignRedirectData(
  isRedirectedFromUser,
  redirectAppliedFilters
) {
  return {
    type: SET_USER_TO_CAMP_REDIRECT_DATA,
    isRedirectedFromUser,
    redirectAppliedFilters,
  };
}

export function setOrganizationDetailInputValues(data) {
  return {
    type: SET_ORGANIZATION_DETAIL_INPUT_VALUES,
    organizationDetailInputValues: data,
  };
}

export function handleTogglePaymentModal(val) {
  return {
    type: TOGGLE_PAYMENT_MODAL,
    isOpenSubscriptionStartPaymentModal: val,
  };
}

export function toggleQuickReplyAddModal(val) {
  return {
    type: TOGGLE_ADD_QUICKREPLY_MODAL,
    isOpenAddReplyForm: !!val,
  };
}

export function toggleQuickReplyEditModal(val) {
  return {
    type: TOGGLE_EDIT_QUICKREPLY_MODAL,
    isOpenEditReplyForm: !!val,
  };
}

export function toggleQuickReplyDeleteModal(val) {
  return {
    type: TOGGLE_DELETE_QUICKREPLY_MODAL,
    isOpenDeleteReplyForm: !!val,
  };
}

export function toggleDeleteTeamSettingModal(val) {
  return {
    type: TOGGLE_DELETE_TEAMSETTING_MODAL,
    isOpenDeleteMember: !!val,
  };
}

export function toggleEditTeamSettingModal(val) {
  return {
    type: TOGGLE_EDIT_TEAMSETTING_MODAL,
    isOpenEditMember: !!val,
  };
}

export function toggleReportBugModal(val) {
  return {
    type: TOGGLE_BUG_REPORT_MODAL,
    isOpenBugReportModal: !!val,
  };
}

export function changeOrganizationDetails(org) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION,
      organization: org,
      isUpdatingOrganization: false,
    });
  };
}

export function setBugReportSubject(message) {
  return (dispatch) => {
    dispatch({
      type: SET_BUG_REPORT_SUBJECT,
      text: message,
    });
  };
}

export function setBugReportDetail(message) {
  return (dispatch) => {
    dispatch({
      type: SET_BUG_REPORT_DETAIL,
      text: message,
    });
  };
}

export function setBugReportIssueWith(message) {
  return (dispatch) => {
    dispatch({
      type: SET_BUG_REPORT_ISSUE_WITH,
      text: message,
    });
  };
}

export function updateBugReport() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BUG_REPORT,
      bugReportSubject: "",
      bugReportDetail: "",
      bugReportIssueWith: "",
    });
  };
}

export function setQuickReplyFields(data) {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_REPLY_FIELDS,
      data,
    });
  };
}

export function setQuickReplyVariableCount(count) {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_REPLY_VARIABLE_COUNT,
      count,
    });
  };
}

export function setQuickReplyInputFields(data) {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_REPLY_INPUT_FIELDS,
      data,
    });
  };
}

export function toggleAddTeamMemberModal(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_TEAM_MEMBER_MODAL,
      isOpenAddTeamMemberModal: !!val,
    });
  };
}

export function setTeamMemberInputFields(data) {
  return (dispatch) => {
    dispatch({
      type: SET_TEAM_MEMBER_FIELDS,
      data,
    });
  };
}

export function updateOrganization(
  organizationId,
  organization,
  shouldGotoWelcomeScreen = false
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION_STATUS,
      isUpdatingOrganization: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/details/`,
      "put",
      organization
    ).then((res) => {
      if (res.result) {
        if (shouldGotoWelcomeScreen) {
          dispatch(toggleCreateAccountSegment("thankYouScreen"));
        } else {
          showNotification("success", "Details updated");
        }
        dispatch({
          type: UPDATE_ORGANIZATION,
          organization,
          isUpdatingOrganization: false,
        });
      } else {
        dispatch({
          type: UPDATE_ORGANIZATION_STATUS,
          isUpdatingOrganization: false,
        });
      }
    });
  };
}

export function checkToken(sToken) {
  return (dispatch) => {
    const token =
      typeof window === "undefined" ? sToken : localStorage.getItem("token");
    if (!token) {
      dispatch({
        type: VERIFY_FAILED,
        isVerifyingToken: false,
        isAuthenticated: false,
        isTokenFound: false,
      });
    } else {
      dispatch({
        type: ATTEMPT_TO_VERIFY,
        isVerifyingToken: true,
        isTokenFound: true,
      });
      return callApi(`v1/organizations/profile/`, "get").then((res) => {
        if (res.result) {
          dispatch({
            type: VERIFY_SUCCESS,
            isAuthenticated: true,
            isVerifyingToken: false,
            user: res.data,
            widget_preferences: res.data ? res.data.widget_preferences : null,
            token,
          });
          dispatch(fetchOrganizationDetailsForUser());
        } else {
          dispatch({
            type: VERIFY_FAILED,
            isVerifyingToken: false,
            isAuthenticated: false,
          });
        }
      });
    }
  };
}

// key --> isOnboarded - false, true
export function fetchOrganizationDetailsForUser() {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORGANIZATION_DETAILS,
      isFetchingOrganizationDetails: true,
    });
    return callApi(`v1/organizations/`, "get").then((res) => {
      if (res.result) {
        if (
          process.env.REACT_APP_ENVIRONMENT === "development" &&
          res.data[0].organization_id &&
          !res.data[0].organization_id.subscription_plan_selected_on_sign_up
        ) {
          dispatch(toggleCreateAccountSegment("paymentPlanSelection"));
        }

        dispatch({
          type: FETCH_USER_ORG,
          organizations: res.data,
          isOrganizationOnboarded:
            res.data[0] &&
            res.data[0].organization_id &&
            res.data[0].organization_id.onboarding_status
              ? res.data[0].organization_id.onboarding_status ===
                onboardingStatuses.ONBOARDED
              : false,
          isFetchingOrganizationDetails: false,
        });
      } else {
        dispatch({
          type: FETCHING_ORGANIZATION_DETAILS,
          isFetchingOrganizationDetails: false,
        });
      }
    });
  };
}

export function updateIsFAQBotStatus(status) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORG_FOR_FAQ_BOT,
      payload: status,
    });
  };
}

export function fetchAllChannelsForOrganization(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_USER_CHANNELS,
      isFetchingUserChannels: true,
    });
    return callApi(`v1/organizations/${organizationId}/channels/`, "get").then(
      (res) => {
        if (res.result) {
          dispatch({
            type: FETCH_USER_CHANNELS,
            channels: res.data,
            isFetchingUserChannels: false,
            channelId: res.data && res.data.length > 0 ? res.data[0].id : "",
          });
        } else {
          dispatch({
            type: FETCHING_USER_CHANNELS,
            isFetchingUserChannels: false,
          });
        }
      }
    );
  };
}

export function addUserChannel(organizationId, body) {
  return (dispatch) => {
    dispatch({
      type: ADDING_USER_CHANNEL,
      isAddingUserChannel: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/channels/`,
      "post",
      body
    ).then((res) => {
      if (res.result) {
        // dispatch(fetchAllChannelsForOrganization(organizationId))
        dispatch({
          type: ADD_USER_CHANNEL,
          channel: res.data,
          isAddingUserChannel: false,
        });
      } else {
        dispatch({
          type: ADDING_USER_CHANNEL,
          isAddingUserChannel: false,
        });
      }
    });
  };
}

export function logOutUser() {
  const login_via =
    typeof window === "undefined" ? "" : localStorage.getItem("login_via");
  const is_super_admin =
    typeof window === "undefined" ? "" : localStorage.getItem("is_super_admin");
  if (login_via === "admin") {
    if (is_super_admin === "true") {
      window.location = "/super-admin/login";
    } else {
      window.location = "/admin/login";
    }
  } else {
    window.location = "/login";
  }
  clearLocalStorage();
  return (dispatch) => {
    dispatch({
      type: ATTEMPT_TO_LOGOUT,
      isVerifyingToken: false,
      isAuthenticated: false,
      user: {},
      token: "",
    });
  };
}

export function logoutAndSwitchApp(nextApp) {
  clearLocalStorage();
  window.location =
    nextApp === "agent"
      ? "/login"
      : nextApp === "super-admin"
      ? "/super-admin/login"
      : "/admin/login";
  return (dispatch) => {
    dispatch({
      type: ATTEMPT_TO_LOGOUT,
      isVerifyingToken: false,
      isAuthenticated: false,
      user: {},
      token: "",
    });
  };
}

export function loginUser(creds, url) {
  return (dispatch) => {
    //if (window) {
    //  clearLocalStorage();
    //}
    dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    return publicApiCaller(`v1/login/`, "post", creds, true).then((res) => {
      if (res && res.id) {
        if (res.token) {
          window.localStorage.setItem("token", res.token);
        }
        dispatch(checkToken());
        dispatch({
          type: LOGIN_SUCCESS,
          isAuthenticated: true,
          user: res,
          widget_preferences: res.widget_preferences
            ? res.widget_preferences
            : null,
          token: res.token,
          isLoggingIn: false,
        });
      } else {
        showNotification("error", "Failed to Login! Invalid credentials.");
        dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
      }
    });
  };
}

export function setNetworkStatus(status) {
  return {
    type: SET_NETWORK_STATUS,
    isOnline: status,
  };
}

export function setSocketStatus(status) {
  return {
    type: SET_SOCKET_STATUS,
    isSocketConnected: status,
  };
}

export function setReconnectingStatus(val) {
  return {
    type: UPDATE_RECONNECTING_STATUS,
    isReconnectingToSocket: val,
  };
}

export function toggleBottomNavbar(val) {
  return {
    type: TOGGLE_BOTTOM_NAVBAR,
    isBottomNavVisible: val,
  };
}

export function fetchOrganizationTagById(organizationId) {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATION_TAG_LIST_FETCH_STATUS,
      isFetchingOrganizationTags: true,
    });
    return callApi(`v1/organizations/${organizationId}/tags/`, "GET").then(
      (res) => {
        if (res && res.result) {
          dispatch({
            type: ORGANIZATION_TAG_LIST,
            tagList: res.data,
            isOrganizationtagsLoadedAlready: true,
            isFetchingOrganizationTags: false,
          });
        } else {
          dispatch({
            type: ORGANIZATION_TAG_LIST_FETCH_STATUS,
            isFetchingOrganizationTags: false,
          });
        }
      }
    );
  };
}

export function createOrganizationNewTag(organizationId, tag) {
  return (dispatch) => {
    dispatch({
      type: CREATE_ORGANIZATION_TAG_STATUS,
      isCreatingOrganizationTag: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/tags/`,
      "POST",
      tag
    ).then((res) => {
      if (res && res.result) {
        const newTag = { ...tag, id: res.id };
        dispatch({
          type: CREATE_ORGANIZATION_TAG,
          tag: newTag,
          isCreatingOrganizationTag: false,
        });

        showNotification("success", "Tags added successfully");
      } else {
        dispatch({
          type: CREATE_ORGANIZATION_TAG_STATUS,
          isCreatingOrganizationTag: false,
        });

        showNotification("error", res?.message);
      }
    });
  };
}

export function processOrganizationIsOnboarded(val, onBoardingStatus) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_ORGANIZATION_ONBOARD_STATUS,
      isOrganizationOnboarded: val,
      onBoardingStatus,
      isOrganizationFirstOnboarded: true,
    });
}

export function toggleCreateAccountSegment(val) {
  return (dispatch) =>
    dispatch({
      type: TOGGLE_CREATE_ACCOUNT_SEGMENT,
      selectedAccountSegment: val,
    });
}

export function updateAppWebClientId(webClientId) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_WEB_CLIENT_ID,
      webClientId,
    });
  };
}

export function fetchCustomerOTP(organizationId, channelId, otpMethod = "sms") {
  return (dispatch) => {
    dispatch({
      type: FETCHING_OTP,
      isSendingOTP: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/channels/${channelId}/OTP/?otpMethod=${otpMethod}`,
      "get"
    ).then((res) => {
      if (res.result && res.status_code && res.status_code === 202) {
        dispatch({
          type: FETCH_OTP,
          isSendingOTP: false,
          isOTPSent: true,
        });
      } else if (res.result && res.status_code && res.status_code === 201) {
        dispatch({
          type: VERIFY_OTP,
          isVerifyingOTP: false,
          isSendingOTP: false,
          isVerified: true,
        });
      } else if (res.result && res.status_code && res.status_code === 404) {
        toast.error(
          ({ closeToast }) => (
            <div style={{ position: "relative" }}>
              <i
                style={{
                  position: "absolute",
                  width: "40px",
                  height: "100%",
                  top: "-5px",
                }}
              >
                <img
                  style={{ marginRight: "8px" }}
                  src="/assets/svg/toast_error.svg"
                  alt=""
                />
              </i>
              <div className="toast-font">
                Organization Channel doesn't exist
              </div>
            </div>
          ),
          {
            className: "toast-width-m",
          }
        );
        dispatch({
          type: FETCHING_OTP,
          isSendingOTP: false,
        });
      } else {
        if (res && res.data && res.data.errors) {
          const { errors } = res.data;
          if (errors && errors[0]) {
            const message = errorList.filter(
              (item) => _.toString(item.code) === _.toString(errors[0].code)
            );
            if (message && message[0]) {
              toast.error(
                ({ closeToast }) => (
                  <div style={{ position: "relative" }}>
                    <i
                      style={{
                        position: "absolute",
                        width: "40px",
                        height: "100%",
                        top: "-5px",
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src="/assets/svg/toast_error.svg"
                        alt=""
                      />
                    </i>
                    <div className="toast-font">{message[0].message}</div>
                  </div>
                ),
                {
                  className: "toast-width-m",
                }
              );
            }
          }
        }
        dispatch({
          type: FETCHING_OTP,
          isSendingOTP: false,
        });
      }
    });
  };
}

export function verifyCustomerOTP(organizationId, channelId, code) {
  return (dispatch) => {
    dispatch({
      type: VERIFYING_OTP,
      isVerifyingOTP: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/channels/${channelId}/OTP/verify/`,
      "post",
      {
        code,
      }
    ).then((res) => {
      // fetchorganizations();
      if (res.result) {
        dispatch({
          type: VERIFY_OTP,
          isVerifyingOTP: false,
          isVerified: true,
        });
      } else {
        dispatch({
          type: VERIFYING_OTP,
          isVerifyingOTP: false,
        });
      }
    });
  };
}

export function fetchEventSources(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORG_EVENT_SOURCES,
      isFetchingEventSources: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/event_sources`,
      "get"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_ORG_EVENT_SOURCES,
          isFetchingEventSources: false,
          isEventSourcesLoadedAlready: true,
          orgEventSources: res.data,
        });
      } else {
        dispatch({
          type: FETCHING_ORG_EVENT_SOURCES,
          isFetchingEventSources: false,
        });
      }
    });
  };
}

export function fetchQuickReplies(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORG_QUICK_REPLIES,
      isFetchingQuickReplies: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/quick-replies/`,
      "get"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_ORG_QUICK_REPLIES,
          isFetchingQuickReplies: false,
          isQuickRepliesLoadedAlready: true,
          orgQuickReplies: res.data,
        });
      } else {
        dispatch({
          type: FETCHING_ORG_QUICK_REPLIES,
          isFetchingQuickReplies: false,
        });
      }
    });
  };
}

// quick replies get, put, delete, post
export function addQuickReplies(organizationId, body) {
  return (dispatch) => {
    dispatch({
      type: ADDING_ORG_QUICK_REPLIES,
      isAddingQuickReplies: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/quick-replies/`,
      "post",
      body
    ).then((res) => {
      if (res.result) {
        if (res.message) {
          showNotification("success", res.message);
        }
        dispatch({
          type: ADDED_ORG_QUICK_REPLIES,
          quickReply: res.data ? res.data : { ...body },
          isAddingQuickReplies: false,
        });
      } else {
        dispatch({
          type: ADDING_ORG_QUICK_REPLIES,
          isAddingQuickReplies: false,
        });
      }
    });
  };
}

export function updateQuickReplies(organizationId, quickReplyId, body) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_ORG_QUICK_REPLIES,
      isUpdatingQuickReplies: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/quick-replies/${quickReplyId}/`,
      "put",
      body
    ).then((res) => {
      if (res.result) {
        if (res.message) {
          showNotification("success", res.message);
        }
        dispatch({
          type: UPDATED_ORG_QUICK_REPLIES,
          quickReply: {
            ...body,
            id: quickReplyId,
          },
          isUpdatingQuickReplies: false,
        });
      } else {
        dispatch({
          type: UPDATING_ORG_QUICK_REPLIES,
          isUpdatingQuickReplies: false,
        });
      }
    });
  };
}

export function removeQuickReplies(organizationId, quickReplyId) {
  return (dispatch) => {
    dispatch({
      type: REMOVING_ORG_QUICK_REPLIES,
      isRemovingQuickReplies: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/quick-replies/${quickReplyId}/`,
      "delete"
    ).then((res) => {
      if (res.result) {
        if (res.message) {
          showNotification("success", res.message);
        }
        dispatch({
          type: REMOVED_ORG_QUICK_REPLIES,
          quickReply: {
            id: quickReplyId,
          },
          isRemovingQuickReplies: false,
        });
      } else {
        dispatch({
          type: REMOVING_ORG_QUICK_REPLIES,
          isRemovingQuickReplies: false,
        });
      }
    });
  };
}

export function fetchUserDeviceType() {
  return (dispatch) => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    dispatch({
      type: FETCH_USER_DEVICE_TYPE,
      IsUserDeviceMobile: check,
    });
  };
}

export function fetchMembers(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORG_MEMBERS,
      isFetchingMembers: true,
    });
    return callApi(`v1/organizations/${organizationId}/members/`, "get").then(
      (res) => {
        if (res.result) {
          dispatch({
            type: FETCH_ORG_MEMBERS,
            isFetchingMembers: false,
            orgMembers: res.data,
          });
        } else {
          dispatch({
            type: FETCHING_ORG_MEMBERS,
            isFetchingMembers: false,
          });
        }
      }
    );
  };
}

export function addMembers(organizationId, body) {
  return (dispatch) => {
    dispatch({
      type: ADDING_ORG_MEMBERS,
      isAddingMembers: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/members/`,
      "post",
      body
    ).then((res) => {
      if (res.result) {
        if (res.message) {
          showNotification("success", res.message);
        }
        dispatch({
          type: ADDED_ORG_MEMBERS,
          member: {
            ...body,
            id: res.id,
          },
          isAddingMembers: false,
        });
      } else {
        dispatch({
          type: ADDING_ORG_MEMBERS,
          isAddingMembers: false,
        });
      }
    });
  };
}

// todo change to hidden method
export function updateMembers(organizationId, memberId, body, userId) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_ORG_MEMBERS,
      isUpdatingMembers: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/${memberId}/`,
      "put",
      body
    ).then((res) => {
      if (res.result) {
        if (userId && memberId && userId === memberId) {
          window.location.reload();
        } else {
          if (res.message) {
            showNotification("success", "Updated User Information");
          }
          dispatch({
            type: UPDATED_ORG_MEMBERS,
            member: {
              ...body,
              id: memberId,
            },
            isUpdatingMembers: false,
          });
        }
      } else {
        dispatch({
          type: UPDATING_ORG_MEMBERS,
          isUpdatingMembers: false,
        });
      }
    });
  };
}

export function removeMembers(organizationId, memberId) {
  return (dispatch) => {
    dispatch({
      type: REMOVING_ORG_MEMBERS,
      isRemovingMembers: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/members/${memberId}/`,
      "delete"
    ).then((res) => {
      if (res.result) {
        if (res.message) {
          showNotification("success", res.message);
        }
        dispatch({
          type: REMOVED_ORG_MEMBERS,
          member: {
            id: memberId,
          },
          isRemovingMembers: false,
          isOpenEditMember: false,
          isOpenDeleteMember: false,
        });
      } else {
        dispatch({
          type: REMOVING_ORG_MEMBERS,
          isRemovingMembers: false,
        });
      }
    });
  };
}

export function registerUser(creds) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_REGISTER,
      isRegistering: true,
    });
    return publicApiCaller(`v1/register`, "post", creds).then((res) => {
      if (res) {
        const user = {};
        user.email = creds.email;
        user.username = creds.email;
        user.password = creds.password;
        if (res.result) {
          trackEvent({
            category: "Create Password",
            label: "Confirm and Proceed",
            value: "Confirm and Proceed",
            action: "Button Click",
          });
          dispatch(loginUser(user));
          if (process.env.REACT_APP_ENVIRONMENT === "development") {
            dispatch(toggleCreateAccountSegment("paymentPlanSelection"));
          }
          dispatch({
            type: REGISTER_SUCCESS,
            isRegistering: false,
            isRegistered: true,
          });
        } else {
          dispatch(toggleCreateAccountSegment("firstScreen"));
          dispatch({
            type: REGISTER_FAILURE,
            isRegistering: false,
          });
        }
      } else {
        dispatch({
          type: REGISTER_FAILURE,
          isRegistering: false,
        });
      }
    });
  };
}

export function validateBusinessManagerId(id) {
  return (dispatch) => {
    dispatch({
      type: VERIFYING_BUSINESS_MANAGER,
      isVerifyingBusinessManagerId: true,
    });
    return callApi(`v1/validate/business-manager-id/${id}`, "GET").then(
      (res) => {
        if (res) {
          dispatch({
            type: VERIFIED_BUSINESS_MANAGER,
            isVerifyingBusinessManagerId: false,
            isVerifiedBusinessManagerId: res.data,
          });
        } else {
          dispatch({
            type: VERIFYING_BUSINESS_MANAGER,
            isVerifyingBusinessManagerId: false,
          });
        }
      }
    );
  };
}

export function updateBusinessManagerId(organizationId, fbBusinessManagerId) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_BUSINESS_MANAGER,
      isUpdatingBusinessManagerId: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/updateFBBusinessManagerId`,
      "PATCH",
      {
        FB_business_manager_id: fbBusinessManagerId,
      }
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: UPDATED_BUSINESS_MANAGER,
          isUpdatingBusinessManagerId: false,
          isUpdatedBusinessManagerId: true,
          organization: res.data,
        });
      } else {
        dispatch({
          type: UPDATING_BUSINESS_MANAGER,
          isUpdatingBusinessManagerId: false,
        });
      }
    });
  };
}

export function updateBusinessManagerStatus(organizationId, status) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_BUSINESS_MANAGER,
      isUpdatingBusinessManagerId: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/updateFBBusinessManagerId`,
      "PATCH",
      {
        FB_business_manager_status: status,
      }
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: UPDATED_BUSINESS_MANAGER,
          isUpdatingBusinessManagerId: false,
          isUpdatedBusinessManagerId: true,
          organization: res.data,
        });
      } else {
        dispatch({
          type: UPDATING_BUSINESS_MANAGER,
          isUpdatingBusinessManagerId: false,
        });
      }
    });
  };
}

export function toggleRecoveryPasswordSegment(val) {
  return (dispatch) =>
    dispatch({
      type: TOGGLE_RECOVERY_PASSWORD_SEGMENT,
      selectedRecoveryPasswordSegment: val,
    });
}

export function sendEmailForResetPassword(username) {
  return (dispatch) => {
    dispatch({
      type: SEND_EMAIL_RESET_PASSWORD,
      isSendingMailForResetPassword: true,
    });
    const body = {
      username,
    };
    return publicApiCaller(
      `v1/user/password/reset-generate-email`,
      "post",
      body
    ).then((res) => {
      if (res && res.result) {
        dispatch(toggleRecoveryPasswordSegment("confirmationScreen"));
        dispatch({
          type: SEND_EMAIL_RESET_PASSWORD,
          isSendingMailForResetPassword: false,
        });
        dispatch({
          type: RECOVERY_PASSWORD_STATUS,
          isRecoveryPassword: true,
        });
        dispatch({
          type: SET_BOX_VALUE,
          isShowBox: false,
        });
      } else {
        dispatch({
          type: SEND_EMAIL_RESET_PASSWORD,
          isSendingMailForResetPassword: false,
        });
      }
    });
  };
}

export function toggleResetPasswordSegment(val) {
  return (dispatch) =>
    dispatch({
      type: TOGGLE_RESET_PASSWORD_SEGMENT,
      selectedResetPasswordSegment: val,
    });
}

export function updatingResetPassword(cred) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_RESET_PASSWORD,
      isUpdatingResetPassword: true,
    });
    const body = {
      reset_key: cred.reset_key,
      password: cred.new_password,
      email: cred.email,
    };
    return publicApiCaller(`v1/user/password/reset`, "post", body).then(
      (res) => {
        if (res && res.result) {
          dispatch(toggleResetPasswordSegment("confirmationScreen"));
          dispatch({
            type: UPDATING_RESET_PASSWORD,
            isUpdatingResetPassword: false,
          });
        } else {
          dispatch({
            type: UPDATING_RESET_PASSWORD,
            isUpdatingResetPassword: false,
          });
        }
      }
    );
  };
}

export function sendResentInvitationForMember(organizationId, email) {
  return (dispatch) => {
    dispatch({
      type: RESEND_INVITATION_MEMBER,
      email,
      isResendingInvitationForMember: true,
    });
    const body = {
      email,
    };
    return callApi(
      `v1/organizations/${organizationId}/members-reinvite/`,
      "post",
      body
    ).then((res) => {
      if (res && res.result) {
        showNotification("success", res.message);
        dispatch({
          type: RESEND_INVITATION_MEMBER,
          email,
          isResendingInvitationForMember: false,
        });
      } else {
        dispatch({
          type: RESEND_INVITATION_MEMBER,
          email,
          isResendingInvitationForMember: false,
        });
      }
    });
  };
}

export function toggleBox(val) {
  return (dispatch) =>
    dispatch({
      type: SET_BOX_VALUE,
      isShowBox: val,
    });
}

export function saveWidgetPreferences(organizationId, widget_preferences) {
  return (dispatch) => {
    dispatch({
      type: SAVE_WIDGET_PREFERENCES_STATUS,
      isSavingWidgetPreferences: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "PUT",
      {
        widget_preferences: JSON5.stringify(widget_preferences),
      }
    ).then((res) => {
      if (res.result) {
        showNotification("success", "Preferences updated successfully");
        dispatch({
          type: SAVE_WIDGET_PREFERENCES,
          isSavingWidgetPreferences: false,
          widgetPreferences: widget_preferences,
        });
      } else {
        showNotification("error", "Failed to update");
        dispatch({
          type: SAVE_WIDGET_PREFERENCES_STATUS,
          isSavingWidgetPreferences: false,
        });
      }
    });
  };
}

export function saveUserJourneyPreferences(organizationId, userJourney) {
  return (dispatch) => {
    dispatch({
      type: SAVE_USER_JOURNEY,
      user_journey: userJourney,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "PUT",
      {
        user_journey: JSON5.stringify(userJourney),
      }
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: SAVE_USER_JOURNEY,
          user_journey: userJourney,
        });
      }
    });
  };
}

export function saveAllUserPreferences(
  organizationId,
  all_users_columns_preferences
) {
  if (
    all_users_columns_preferences &&
    all_users_columns_preferences.length > 0
  ) {
    all_users_columns_preferences = makeNameVariableFirstInList(
      all_users_columns_preferences
    );
  }
  return (dispatch) => {
    dispatch({
      type: SAVE_WIDGET_PREFERENCES_STATUS,
      isSavingWidgetPreferences: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "PUT",
      {
        all_users_columns_preferences: JSON5.stringify(
          all_users_columns_preferences
        ),
      }
    ).then((res) => {
      if (res.result) {
        showNotification("success", "Preferences updated successfully");
        dispatch({
          type: SAVE_WIDGET_PREFERENCES,
          isSavingWidgetPreferences: false,
          allUserPreferences: all_users_columns_preferences,
        });
      } else {
        showNotification("error", "Failed to update");
        dispatch({
          type: SAVE_WIDGET_PREFERENCES_STATUS,
          isSavingWidgetPreferences: false,
        });
      }
    });
  };
}

export function addNewTagToBeCreated(tag) {
  return (dispatch) => {
    dispatch({
      type: ADD_NEW_BULK_UPLOAD_SELECTED_TAGS,
      tag,
    });
  };
}

export function removeNewTagToBeCreated(tagId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS,
      tagId,
    });
  };
}

export function toggleBulkUploadSelectedSegment(segment) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_BULK_UPLOAD_SEGMENT,
      selectedBulkUploadSegment: segment,
    });
  };
}

export function updateBulkUploadSelectedTags(selectedBulkUploadTags) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BULK_UPLOAD_SELECTED_TAGS,
      selectedBulkUploadTags,
    });
  };
}

export function clearNonCreatedTags() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BULK_UPLOAD_NON_CREATED_TAGS,
    });
  };
}

export function toggleShopifySegment(val) {
  return (dispatch) =>
    dispatch({
      type: TOGGLE_SHOPIFY_SEGMENT,
      selectedShopifySegment: val,
    });
}

export function fetchNewBundledData() {
  return (dispatch) => {
    dispatch({
      type: FETCHING_BUNDLED_DATA,
      isFetchingUserChannels: true,
      isFetchingAgents: true,
    });
    return callApi(`v1/organizations/org-channel-agents/`, "get").then(
      (res) => {
        if (res && res.result) {
          dispatch({
            type: FETCH_BUNDLED_DATA,
            channels: res.data.channels,
            activeAgents: res.data.agents,
            allAgents: res.data.agents,
            isFetchingUserChannels: false,
            isFetchingAgents: false,
            channelId:
              res.data && res.data.channels && res.data.channels.length > 0
                ? res.data.channels[0].id
                : "",
          });
        } else {
          dispatch({
            type: FETCHING_BUNDLED_DATA,
            isFetchingUserChannels: false,
            isFetchingAgents: false,
          });
        }
      }
    );
  };
}

export function sendUserPilotUserTraits(userId, userTraits) {
  if (window && window.userpilot) {
    window.userpilot.identify(`${userId}-A14`, userTraits);
  }
}

export function createConversationLabel(organizationId, data, chatId, wcId) {
  return (dispatch) => {
    dispatch({
      type: CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS,
      isCreatingOrganizationConvLabel: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/conversation-labels/`,
      "POST",
      data
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS,
          isCreatingOrganizationConvLabel: false,
        });
        dispatch(
          assignConversationLabelToCustomer(
            organizationId,
            chatId,
            res.data?.id,
            wcId
          )
        );
      } else {
        dispatch({
          type: CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS,
          isCreatingOrganizationConvLabel: false,
        });
      }
    });
  };
}

export function fetchConversationLabels(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORG_CONVERSATION_LABELS,
      isFetchingConvLabels: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/conversation-labels/`,
      "get"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_ORG_CONVERSATION_LABELS,
          isFetchingConvLabels: false,
          isConvLabelsLoadedAlready: true,
          orgConvLabels: res.data,
        });
      } else {
        dispatch({
          type: FETCHING_ORG_CONVERSATION_LABELS,
          isFetchingConvLabels: false,
        });
      }
    });
  };
}

export function updateConversationLabel(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORG_CONVERSATION_LABELS,
      event,
    });
  };
}

export const fetchListMessageDetails = (organizationId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_LIST_MESSAGE_DETAILS,
    });
    const res = await callApiV2({
      endpoint: `v1/organizations/${organizationId}/list-messages/`,
    });
    dispatch({
      type: FETCH_LIST_MESSAGE_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_LIST_MESSAGE_DETAILS_FAILURE,
    });
  }
};

export const updateListMessageDetails = (payload) => ({
  type: UPDATE_LIST_MESSAGE_DETAILS,
  payload,
});

export const updateListMessageData = (payload) => ({
  type: UPDATE_LIST_MESSAGE_DATA,
  payload,
});

export function updateIsEMandateStatusForOrg(status) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORG_FOR_E_MANDATE,
      payload: status,
    });
  };
}

export const setCatalogId = (catalogId) => ({
  type: SET_CATALOG_ID,
  payload: catalogId,
});

export const fetchProjectList =
  (activeBusinessId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_PROJECT_LIST,
      });
      const login_via =
        typeof window === "undefined" ? "" : localStorage.getItem("login_via");
      const is_super_admin =
        typeof window === "undefined"
          ? ""
          : localStorage.getItem("is_super_admin");
      const appName =
        login_via === "admin"
          ? is_super_admin == "true"
            ? "super-admin"
            : "admin"
          : "agent";
      const business_id = getState().app?.user?.business_id;
      let businessId = null;
      if (appName === "super-admin") {
        businessId = activeBusinessId;
      } else {
        businessId = business_id;
      }

      const res =
        businessId &&
        (await callApiV2({
          method: "post",
          endpoint: `elevate/project/list`,
          body: {
            Project: {
              pageNum: 1000,
              business_id: businessId,
            },
          },
        }));
      if (res?.status === "Success") {
        let projectId = window.localStorage.getItem("projectId");
        if (projectId) {
          let activeProject = res.data?.list?.filter(
            (item) => item._id === projectId
          );
          dispatch(setProjectId(activeProject[0]));
        }
        dispatch({
          type: FETCH_PROJECT_LIST_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PROJECT_LIST_FAILURE,
      });
    }
  };

export const fetchBusinessList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_BUSINESS_LIST,
    });

    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/business/list`,
      body: {
        business: {
          pageNum: 1,
          query: {
            is_deleted: false,
          },
        },
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCH_BUSINESS_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_BUSINESS_LIST_FAILURE,
    });
  }
};

export const fetchBusinessAdmin =
  (business_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_BUSINESS_ADMIN,
      });

      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/business/business/${business_id}/admin`,
        body: {
          business: {
            pageNum: 1,
          },
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_BUSINESS_ADMIN_SUCCESS,
          payload: res.data.adminList ? res.data.adminList : null,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BUSINESS_ADMIN_FAILURE,
      });
    }
  };

export const addProjectDetails =
  (projectName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PROJECT_DETAILS,
      });

      const businessId = getState().app?.user?.business_id;

      const body = {
        project: {
          business_id: businessId,
          name: projectName,
        },
      };
      if (businessId) {
        const res = await callApiV2({
          method: "post",
          endpoint: `elevate/project/new`,
          body: body,
        });
        if (res?.status === "Success") {
          dispatch({
            type: ADD_PROJECT_DETAILS_SUCCESS,
            payload: res.data,
          });
        }
        return res;
      }
    } catch (err) {
      dispatch({
        type: ADD_PROJECT_DETAILS_FAILURE,
      });
    }
  };

export const setProjectId = (project) => async (dispatch, getState) => {
  dispatch({
    type: SELECT_PROJECT,
    projectId: project?._id,
    activeProject: project,
  });
};

export const updateProjectUserDetails = (id, project) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_PROJECT_DETAILS,
      isUpdatingProject: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/project/${id}/update`,
      body: {
        project: project,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "User details updated successfully");
      dispatch({
        type: UPDATE_USER_PROJECT_DETAILS_UPDATE_SUCCESS,
        activeProject: res?.data?.project,
        isUpdatingProject: false,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_PROJECT_DETAILS_UPDATE_FAILURE,
      isUpdatingProject: false,
    });
  }
};

export const removeProjectFromList = (id) => async (dispatch) => {
  try {
    dispatch({
      type: HANDLE_USER_PROJECT_REMOVAL,
      isRemovingProject: true,
      isProjectRemoved: false,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/project/${id}/remove`,
      body: {},
    });
    if (res?.status === "Success") {
      showNotification("success", "Deleted Project");
      dispatch({
        type: HANDLE_USER_PROJECT_REMOVAL,
        isRemovingProject: false,
        isProjectRemoved: true,
      });
    } else {
      showNotification("Error", "Could not delete project");
      dispatch({
        type: HANDLE_USER_PROJECT_REMOVAL,
        isProjectRemoved: false,
        isRemovingProject: false,
      });
    }
  } catch (err) {
    console.log(err);
    showNotification("Error", "Some Error Occurred");
    dispatch({
      type: HANDLE_USER_PROJECT_REMOVAL,
      isRemovingProject: false,
      isProjectRemoved: false,
    });
  }
};

export const fetchAllCustomerListDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS,
      isFetchingCustomersList: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/customers/list`,
      body: {
        customers: {
          pageSize: 100,
        },
      },
    });
    dispatch({
      type: FETCH_ALL_CUSTOMER_LIST_DETAILS,
      payload: res.data,
      isFetchingCustomersList: false,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS,
      isFetchingCustomersList: false,
    });
  }
};
