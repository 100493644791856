import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import { showNotification } from "util/utility";
import publicApiCaller from "util/publicApiCaller";
import queryString from "util/queryString";
import Button from "konnekt/Buttons";
import Box from "konnekt/Box";
import Checkbox from "konnekt/Checkbox";
import Label from "konnekt/Label";
import Icon from "konnekt/Icon";
import { LOGIN_SUCCESS } from "modules/App/AppActions";
import jwt_decode from "jwt-decode";
import { getAuthenticatedState } from "modules/App/AppSelectors";

const ImageBackground = styled(Box)`
  background-image: url("https://automateeazy.s3.us-east-1.amazonaws.com/images/LgBg.svg");
  height: 100vh;
  background-size: 100%;
  width: 100%;
  text-align: center;
  padding-top: 15rem;

  img {
    width: 300px;
    margin-bottom: 25px;
  }

  h4 {
    line-height: 38px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 30px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
    img {
      width: 100%;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 20px;
    }
  }
`;

const SignupSuccess = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

const RegisterForm = styled(Box)`
  padding: 30px 35px 0px 35px;

  a {
    text-align: center;
    display: block;
    font-weight: 600;
  }

  img {
    position: absolute;
    right: 12px;
    top: 14px;
    width: 21px;
  }

  input {
    box-sizing: border-box;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 50px 0px 15px;
  }

  button {
    background: #5e3ec5;
    border-radius: 8px;
    color: #fff;
    padding: 13px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  a {
    text-align: center;
    display: block;
    font-weight: 600;
    color: #5E3EC5;
    margin-left: 10px;
  }

  label {
    color: red;
    font-size: 12px;
    display: flex;
    margin-top: 7px
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 40px 20px;
    h2 {
      font-size: 25px;
    }
`;

const GenderType = styled(Box)`
  .gender-type-mr {
    margin-bottom: 20px;
  }

  .mr-left {
    margin-left: 25px;
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #212121;
  }
`;
const IconImage = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 15px;

  label {
    display: flex;
  }
`;
const ErrorImage = styled(Icon)`
  margin-right: 7px;
`;

const RegisterPage = ({ checkToken, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    gender: "male",
    username: "",
    local_password: "",
  });
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const isAuthenticated = useSelector(state => getAuthenticatedState(state));

  useEffect(() => {
    if (isAuthenticated) {
      checkToken();
      history.push("/manage/user");
    }
  }, [isAuthenticated]);

  const handleChangeUser = (keyName, keyValue) => {
    const update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
    setErrors({});
  };

  const handleValidation = () => {
    let isValid = true;
    let newErrors = {};
    if (!user.contact_name) {
      isValid = false;
      newErrors["contact_name"] = "Please enter your name";
    }
    if (!user.contact_email || !isEmail(user.contact_email)) {
      isValid = false;
      newErrors["contact_email"] = "Please enter valid email address";
    }
    if (!user.username) {
      isValid = false;
      newErrors["username"] = "Please enter username";
    }
    if (!user.local_password) {
      isValid = false;
      newErrors["local_password"] = "Please enter password";
    }
    return {
      isValid,
      errors: newErrors,
    };
  };

  const handleLoginAPI = (creds) => {
    setIsRegistering(true);
    const queryObj = queryString.parse(window.location.search);
    if (queryObj) {
      creds.business_id = queryObj?.business_id || "";
    }
    const fullName = creds?.contact_name.split(" ");
    creds.first_name = fullName[0];
    creds.last_name = fullName.length > 1 ? fullName[fullName.length - 1] : "";
    creds.created_by = "default";
    const agent = {
      "agent": creds
    };
    publicApiCaller("auth/agent/sign-up", "post", agent).then((res) => {
      if (res) {
        if (res.status === "Success") {
          const { token } = res?.data;
          window.localStorage.setItem("token", token);
          const tempUser = jwt_decode(token);
          dispatch({
            type: LOGIN_SUCCESS,
            isLoggingIn: false,
            isAuthenticated: true,
            token: token,
            user: tempUser,
          });
          showNotification("success", "Registration is done");
          setIsRegistered(true);
          setIsRegistering(false);
        } else {
          setIsRegistered(false);
          setIsRegistering(false);
        }
      } else {
        showNotification("error", res?.data?.message || "Something went wrong...");
        setIsRegistered(false);
        setIsRegistering(false);
      }
    });
  };
  const handleSubmit = () => {
    const check = handleValidation();
    if (check.isValid) {
      handleLoginAPI(user);
    } else {
      setErrors(check.errors);
    }
  };

  const renderBodySection = () => {
    if (isRegistered) {
      return (
        <Box className="col-lg-6 col-md-6">
          <SignupSuccess>
            <Box textAlign="center">
              <Box variant="text2">
                Thank you! You have Successfully Registered.
              </Box>
              <Box fontSize="20px" fontWeight="500" mt="15px">
                We will contact you very soon!
              </Box>
            </Box>
          </SignupSuccess>
        </Box>
      );
    }
    return (
      <Box className="col-lg-6 col-md-6">
        <RegisterForm>
          <Box className="content-box">
            <Box className="form-box">
              <h6>Welcome Back</h6>
              <h2>Register to AutomatEazy</h2>

              <Box className="input-box position-relative">
                <input
                  value={user?.contact_name}
                  onChange={(e) =>
                    handleChangeUser("contact_name", e.target.value)
                  }
                  type="text"
                  placeholder="Name"
                />
                {errors && errors.contact_name ? (
                  <label>
                    <ErrorImage
                      iconName="info"
                      width="15px"
                      height="15px"
                      color="red"
                    ></ErrorImage>
                    {errors.contact_name}</label>
                ) : null}
                <IconImage
                  iconName="puser"
                  width="22px"
                  height="22px">
                </IconImage>
              </Box>
              <br/>
              <Box className="input-box position-relative">
                <input
                  value={user?.contact_email}
                  onChange={(e) =>
                    handleChangeUser("contact_email", e.target.value)
                  }
                  type="contact_email"
                  placeholder="Email ID"
                />
                {errors && errors.contact_email ? (
                  <label>
                    <ErrorImage
                      iconName="info"
                      width="15px"
                      height="15px"
                      color="red"
                    ></ErrorImage>
                    {errors.contact_email}</label>
                ) : null}
                <IconImage
                  iconName="message"
                  width="22px"
                  height="22px">
                </IconImage>
              </Box>
              <br/>
              <GenderType>
                <h6>Select Your Gender :</h6>
                <Box display="flex" mb="20px">
                  <Checkbox type="radio" checked={user?.gender === "male"}
                            onChange={(e) => handleChangeUser("gender", "male")}/>
                  <Label ml="12px" color="darkGrey.60" text="Male"/>
                  <Checkbox type="radio" className="mr-left" checked={user?.gender === "female"}
                            onChange={(e) => handleChangeUser("gender", "female")}/>
                  <Label ml="12px" color="darkGrey.60" text="Female"/>
                </Box>
              </GenderType>
              <Box className="input-box position-relative">
                <input
                  value={user?.username}
                  autocomplete="new-user"
                  list="autocompleteOff"
                  onChange={(e) =>
                    handleChangeUser("username", e.target.value)
                  }
                  type="text"
                  placeholder="Username"
                />
                {errors && errors.username ? (
                  <label>
                    <ErrorImage
                      iconName="info"
                      width="15px"
                      height="15px"
                      color="red"
                    ></ErrorImage>
                    {errors.username}</label>
                ) : null}
                <IconImage
                  iconName="puser"
                  width="22px"
                  height="22px">
                </IconImage>
              </Box>
              <br/>
              <Box className="input-box position-relative">

                <input
                  value={user?.local_password}
                  list="autocompleteOff"
                  autoComplete="new-password"
                  onChange={(e) =>
                    handleChangeUser("local_password", e.target.value)
                  }
                  type="password"
                  placeholder="Password"
                />
                {errors && errors.local_password ? (
                  <label>
                    <ErrorImage
                      iconName="info"
                      width="15px"
                      height="15px"
                      color="red"
                    ></ErrorImage>
                    {errors.local_password}</label>
                ) : null}
                <IconImage
                  iconName="lock"
                  width="22px"
                  height="22px">
                </IconImage>
              </Box>
              <Box className="input-box">
                <Button onClick={() => handleSubmit()}>{isRegistering ? "Registering..." : "Register"}</Button>
                <Box display="flex" justifyContent="center">
                  Already have an account?
                  <Link to="/login">Login</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </RegisterForm>
      </Box>
    );
  };

  return (
    <>
      <section className="container-fluid">
        <Box className="row">
          <Box className="col-lg-6 col-md-6 p-0">
            <ImageBackground>
              <img src="/images/automate-logo.svg" alt=""/>
              <h4>
                Send Personalized Campaigns <br/>
                on WhatsApp
              </h4>
            </ImageBackground>
          </Box>
          {renderBodySection()}
        </Box>
      </section>
    </>
  );
};

export default RegisterPage;