/**
 * Root Reducer
 */
import { combineReducers } from "redux";
// Import Reducers
import app from "./modules/App/AppReducer";
import inboxState from "./modules/Inbox/inboxStateReducer";
import customer from "./modules/Customer/CustomerReducer";
import thread from "./modules/Inbox/ThreadReducer";
import conversation from "./modules/Inbox/ConversationReducer";
import replyMessage from "./modules/Inbox/ReplyMessageReducer";
import tracker from "./modules/Inbox/TrackerReducer";
import toasts from "./modules/App/ToastReducer";
import signup from "./modules/Signup/SignupReducer";
import template from "./modules/Templates/TemplateReducer";
import payment from "./modules/Payments/PaymentReducer";
import permissions from "./modules/Settings/PermissionReducer";
import imageViewer from "./modules/ImageViewerModule/redux/ImageViewerReducer";
import mediaPreview from "./modules/MediaPreviewModule/redux/MediaPreviewReducer";
import manage from "modules/Manage/ManagePageReducer";
import campaignDetails from "modules/Campaigns/campaignReducer";
import profile from "modules/Profile/ProfileReducer";
import plans from 'modules/Plans/PlansReducer';
import billing from 'modules/Manage/pages/Billing/BillingReducer';
 
// Combine all reducers into one root reducer
export default combineReducers({
  app,
  inboxState,
  customer,
  thread,
  tracker,
  replyMessage,
  toasts,
  signup,
  template,
  payment,
  permissions,
  conversation,
  imageViewer,
  mediaPreview,
  manage,
  campaignDetails,
  profile,
  plans,
  billing,
});
