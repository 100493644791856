import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "konnekt/Icon";
import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import Modal, { ModalHeader, ModalFooter } from "konnekt/Modal";
import { updateCanCustomerCanSendMessage } from "../../../../../../Customer/CustomerActions";
import { v4 as uuidv4 } from "uuid";
import { Container, FlexCenterGap } from "CommonStyle/CommonStyles";

const BlockIncomingMessage = ({ activeCustomer }) => {
  const {
    _id,
    wa_phone_number,
    is_user_message_blocked,
    project_id,
    contact_name,
  } = activeCustomer;
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.app.user._id);
  const activeConversationId = useSelector(
    (state) => state.thread?.activeConversation?._id
  );
  const handleBlockActiveCustomer = () => {
    dispatch(
      updateCanCustomerCanSendMessage(_id, {
        customer: {
          is_user_message_blocked: !is_user_message_blocked,
          user_message_blocked_by: loggedInUser,
        },
        conversation_id: activeConversationId,
        project_id,
        wa_message_id: uuidv4(),
      })
    );
    setDialogOpen(false);
  };
  return (
    <Container>
      <Box
        display="flex"
        width="100%"
        position="relative"
        left="2%"
        onClick={() => setDialogOpen(!isDialogOpen)}
      >
        <FlexCenterGap
          mt="1rem"
          style={{
            color: is_user_message_blocked ? "#4caf50" : "#ff5a00",
            fontWeight: 600,
            fontSize: "1.4rem",
            gap: "1rem",
            cursor: "pointer",
          }}
        >
          <Icon iconName="block" width="2rem" height="2rem" />
          <span>
            {is_user_message_blocked
              ? "Unblock incoming Messages"
              : "Block incoming Messages"}
          </span>
        </FlexCenterGap>
      </Box>
      {isDialogOpen && (
        <Modal
          padding="0 1.4rem"
          onHide={() => setDialogOpen(false)}
          isOpen
          position="relative"
        >
          <ModalHeader onHide={() => setDialogOpen(false)} variant="white">
            {`${
              is_user_message_blocked ? "Unblock" : "Block"
            } ${contact_name} `}
          </ModalHeader>
          <Box py="1rem">
            <ul className="list">
              <li>
                This action will block the user from sending messages to your
                Business.
              </li>
              <li>
                This feature helps you tackle spam and filter out incoming
                messages you don't want.
              </li>
              <li>You can undo this action.</li>
            </ul>
            <p style={{ fontSize: "1.4rem", margin: "1rem 0 0" }}>
              Are you sure?
            </p>
          </Box>

          <ModalFooter>
            <FlexCenterGap justifyContent="end">
              <Button
                text="Cancel"
                onClick={() => setDialogOpen(false)}
                type="outlineColor"
              />
              <Button
                onClick={handleBlockActiveCustomer}
                type="primary"
                text="Confirm"
              />
            </FlexCenterGap>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

export default BlockIncomingMessage;
