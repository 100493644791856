import React, { useState, useEffect } from "react";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import { getAttributeList } from "../../../../../../Manage/ManageSelectors";
import { useSelector, useDispatch } from "react-redux";
import Buttons from "../../../../../../../konnekt/Buttons";
import TextInput from "../../../../../../../konnekt/TextInput";
import { cancelCustomerDetails } from "../../../../../../Customer/CustomerActions";
import {
  getConditionOption,
  getBooleanOption,
} from "../../../../../../../util/utility";
import DropDownControlled from "../../../../../../../konnekt/Dropdown";
import Checkbox from "../../../../../../../konnekt/Checkbox";
import Label from "../../../../../../../konnekt/Label";
import {
  LiveChatProfilesBody,
  LiveChatProfilesHading,
  LiveChatProfilesText,
  DatePickerInput,
  LiveChatProfileItem,
} from "./AttributesCard.styles";
const AttributesCard = ({ heading, traits, updateProfile }) => {
  const dispatch = useDispatch();
  const [iconManage, setIconManage] = useState();
  const [editActive, setEditActive] = useState(false);
  const [traitDetails, updateTraitDetails] = useState(null);

  const attributeList = useSelector((state) => getAttributeList(state));

  const isUpdatingCustomerDetails = useSelector(
    (state) => state?.customer?.isUpdatingCustomerDetail
  );

  useEffect(() => {
    let filterData = {};
    attributeList?.map((item) => {
      const newPair = {};
      newPair[`${item?._id}`] = traits?.[item?._id] ?? "";
      return Object.assign(filterData, newPair);
    });
    updateTraitDetails(filterData);
  }, [attributeList, traits, isUpdatingCustomerDetails]);

  const iconChangeHandle = () => {
    setIconManage(!iconManage);
  };

  const handleClick = (flag) => {
    setEditActive(flag);
  };

  const handleCancel = (flag) => {
    dispatch(cancelCustomerDetails());
    setEditActive(flag);
  };

  const handleSave = (flag) => {
    setEditActive(flag);
    updateProfile(traitDetails);
  };

  const updateTraits = (keyName, keyValue) => {
    const updatedValue = { ...traitDetails };
    updatedValue[keyName] = keyValue;
    updateTraitDetails(updatedValue);
  };

  const renderInputData = (item) => {
    if (item?.data_type === "string") {
      return (
        <TextInput
          type="text"
          onChange={(e) => updateTraits(item?._id, e)}
          value={traitDetails?.[item?._id]}
          placeholder="Enter value"
          width="140px"
          border="1px solid rgba(0,0,0,.08)"
          height="36x"
          placeholderTextSize="1.2rem"
        />
      );
    }
    if (item?.data_type === "number") {
      return (
        <Box>
          <TextInput
            type="text"
            onChange={(e) => updateTraits(item?._id, e)}
            value={traitDetails?.[item?._id]}
            regexValidation="^[0-9]*$"
            placeholder="Enter value"
            width="140px"
            border="1px solid rgba(0,0,0,.08)"
            height="36x"
            placeholderTextSize="1.2rem"
          />
        </Box>
      );
    }
    if (item?.data_type === "date") {
      return (
        <DatePickerInput
          type="date"
          value={traitDetails?.[item?._id]}
          onChange={(e) => updateTraits(item?._id, e.target.value)}
          style={{
            border: "1px solid rgba(0,0,0,.08)",
            padding: "0 1rem",
            borderRadius: ".6rem",
          }}
        />
      );
    }
    if (item?.data_type === "boolean") {
      return (
        <Box display="flex" mt="5px" mb="5px" mr="20px">
          <Checkbox
            type="radio"
            iconSize="2xs"
            checked={traitDetails?.[item?._id] === true}
            onChange={(e) => updateTraits(item?._id, true)}
          />
          <Label
            mr="14px"
            ml="6px"
            color="darkGrey.60"
            fontSize="12px"
            text="Yes"
          />
          <Checkbox
            type="radio"
            iconSize="2xs"
            className="mr-left"
            checked={traitDetails?.[item?._id] === false}
            onChange={(e) => updateTraits(item?._id, false)}
          />
          <Label ml="12px" color="darkGrey.60" fontSize="12px" text="No" />
        </Box>
      );
    }
  };

  const renderDataDetails = (item) => {
    if (item?.data_type === "boolean") {
      if (traitDetails?.[item?._id] === true) {
        return "true";
      }
      if (traitDetails?.[item?._id] === false) {
        return "false";
      }
      return "-";
    }
    return (
      <Box>{traitDetails?.[item?._id] ? traitDetails?.[item?._id] : "-"}</Box>
    );
  };

  return (
    <LiveChatProfilesBody>
      <Box display="flex" justifyContent="space-between">
        <LiveChatProfilesHading>{heading}</LiveChatProfilesHading>
        {!iconManage ? (
          <Icon iconName="plus" color="#5E3EC5" onClick={iconChangeHandle} />
        ) : (
          <Icon iconName="minus" color="#5E3EC5" onClick={iconChangeHandle} />
        )}
      </Box>
      <LiveChatProfileItem iconManage={iconManage} mt="8px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          my="20px"
        >
          {editActive ? (
            <Buttons
              minHeight="25px"
              minWidth="40px"
              text="Save"
              fontSize="10px"
              onClick={() => handleSave(!editActive)}
            />
          ) : (
            <Buttons
              minHeight="25px"
              minWidth="40px"
              text="Edit"
              fontSize="10px"
              onClick={() => handleClick(!editActive)}
            />
          )}
          {editActive && (
            <Buttons
              minHeight="25px"
              minWidth="40px"
              ml="25px"
              text="Cancel"
              fontSize="10px"
              onClick={() => handleCancel(!editActive)}
            >
              Cancel
            </Buttons>
          )}
        </Box>
        {attributeList?.map((item) => {
          return (
            <LiveChatProfilesText color="#757575" key={item._id}>
              {item.name}
              {editActive ? (
                <LiveChatProfilesText>
                  {renderInputData(item)}
                </LiveChatProfilesText>
              ) : (
                <LiveChatProfilesText color="#212121">
                  {renderDataDetails(item)}
                </LiveChatProfilesText>
              )}
            </LiveChatProfilesText>
          );
        })}
      </LiveChatProfileItem>
    </LiveChatProfilesBody>
  );
};

export default AttributesCard;
