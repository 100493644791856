import { v4 as uuidv4 } from "uuid";
export const sendBrowserNotification = (name, messsage) => {
  if ("Notification" in window && Notification.permission === "granted") {
    //new Notification(name,{body:messsage,icon:'/images/192_icon.png'})
    navigator.serviceWorker.getRegistration().then((registration) => {
      registration.showNotification(name, {
        body: messsage,
        renotify: true,
        tag: uuidv4(),
        icon: "/images/favicon_whitebg.png",
      });
    });
  }
};
