import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ViewList from "./ViewList/ViewList";
import EditList from "./EditList/EditList";

import useActiveCustomerNumber from "customHooks/useActiveCustomerNumber";
import useActiveCustomer from "customHooks/useActiveCustomer";

import { EDIT } from "constants/smartCardsContants";

import { getFormattedTime } from "util/timeUtility";

const DATATYPES_TO_RENDER = ["bool", "datetime", "str", "number"];

const PersonalDetailsList = ({
  customerAttributes,
  handleSaveBtnClick,
  type = "normal",
  edit,
  setEdit
}) => {
  const activeCustomer = useActiveCustomer();

  //const activeCustomerNumber = useActiveCustomerNumber();
  const activeCustomerNumber = `${activeCustomer?.country_code || ""}${activeCustomer?.phone_number || ""}`;

  const [userDetailList, setUserDetailList] = useState([]);

  const { activeSmartCard } = useSelector(
    (state) => state.inboxState.smartCard,
  );

  useEffect(() => {
    const { traits } = activeCustomer;

    const filterCutomerAttributes = (item) => {
      if (item.dataType === "datetime") {
        return {
          ...item,
          value: traits[item.keyName]
            ? getFormattedTime(traits[item.keyName], "DD-MMM-YYYY")
            : null,
        };
      }
      if (
        item.dataType === "bool" &&
        (traits[item.keyName] === true || traits[item.keyName] === false)
      ) {
        return {
          ...item,
          value: traits[item.keyName] === true ? "Yes" : "No",
        };
      }

      return {
        ...item,
        value: traits[item.keyName] || null,
      };
    };

    if (traits) {
      setUserDetailList(
        customerAttributes
        .filter((item) => DATATYPES_TO_RENDER.includes(item.dataType))
        .map((item) => filterCutomerAttributes(item)),
      );
    }
  }, [customerAttributes, activeCustomer]);

  //if (activeSmartCard.mode === EDIT) {
  //  return (
  //    <EditList
  //      handleSaveBtnClick={handleSaveBtnClick}
  //      type={type}
  //      customerAttributes={customerAttributes}
  //    />
  //  );
  //}
  return (
    <ViewList
      userDetailList={userDetailList}
      activeCustomer={activeCustomer}
      activeCustomerNumber={activeCustomerNumber}
      edit={edit}
      setEdit={setEdit}
    />
  );
};

PersonalDetailsList.propTypes = {
  customerAttributes: PropTypes.array,
  listType: PropTypes.oneOf(["normal", "bottomDropdown"]),
};

export default PersonalDetailsList;
