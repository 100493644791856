import { useEffect, useRef } from "react";

function useInputValidation(
  allowedKeys = /[A-Za-z0-9-_.\s]/,
  spaceAllowed = false,
) {
  const ref = useRef();
  useEffect(() => {
    const handleKeyValidation = (e) => {
      if (e.code === "Space" && spaceAllowed) {
        return;
      }
      if (!e.key.match(allowedKeys)) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    };
    ref.current.addEventListener("keypress", handleKeyValidation);
    return () => {
      ref.current.removeEventListener("keypress", handleKeyValidation);
    };
  }, []);

  return ref;
}

export default useInputValidation;
