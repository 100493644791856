import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { border, layout, space, typography, color } from "styled-system";

import ErrorMessage from "konnekt/ErrorMessage";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 1px;

  &:focus {
    outline: none;
    border: 1px solid #278351;
  }

  ${border}
  ${layout}
  ${space}
`;

const StyledTextInput = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.widthValue ? props.widthValue : "100%")};
`;
const StyledInput = styled("input")`
  ${typography}
  ${border}
  ${space}
  ${layout}
  ${color}
  &:focus {
    outline: none;
    border: none;
  }
  &::placeholder {
    font-size: ${(props) =>
      props.placeholderTextSize ? props.placeholderTextSize : "1.4rem"};
  }
  cursor: ${(props) => (props.pointer ? "not-allowed" : "")};
  background-color: ${(props) => (props.inputBg ? props.inputBg : "")};
`;
const TextInput = ({
  pointer,
  value,
  onChange,
  placeholder,
  disabled,
  isCharCountVisible,
  changeLimitExceed,
  bodyTextLimit,
  errorMessage,
  errorVisibility,
  regexValidation = "(.*?)",
  isEditableButton,
  isSaveButton,
  onSave,
  showLimitExceedError = true,
  prepend,
  widthValue,
  inputBg,
  placeholderTextSize,
  ...rest
}) => {
  const [isLimitExceed, setIsLimitExceed] = useState(false);
  const [isEditable, setIsEditable] = useState(isEditableButton || false);
  const [inputValue, setInputValue] = useState(value || "");

  const renderError = () => {
    if (errorVisibility && !disabled) {
      return <ErrorMessage mt="5px">{errorMessage}</ErrorMessage>;
    }
    if (isCharCountVisible && isLimitExceed && showLimitExceedError) {
      return (
        <ErrorMessage mt="5px">
          You have exceeded your character limit
        </ErrorMessage>
      );
    }
  };

  // const renderCharCount = () => {
  //   if (isCharCountVisible && bodyTextLimit) {
  //     const charCount = inputValue ? inputValue.length : 0;
  //     return (
  //       <Box variant="text7" color="lightGrey.20">
  //         {`${charCount}/${bodyTextLimit}`}
  //       </Box>
  //     );
  //   }
  //   return null;
  // };

  // Check the SearchBar component and make validation method similar

  const _onChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.validity.valid) {
      handleSetLimit(e.target.value);
      onChange(e.target.value);
    }
  };

  const handleSetLimit = (text) => {
    if (bodyTextLimit && text?.length > bodyTextLimit) {
      if (!isLimitExceed) {
        if (isCharCountVisible && changeLimitExceed) {
          changeLimitExceed(true);
        }
        setIsLimitExceed(true);
      }
    } else {
      if (isLimitExceed) {
        if (isCharCountVisible && changeLimitExceed) {
          changeLimitExceed(false);
        }
        setIsLimitExceed(false);
      }
    }
  };

  const handleEdit = () => {
    setIsEditable(false);
  };

  const onHandleSave = () => {
    setIsEditable(true);
    onSave();
  };

  return (
    <StyledTextInput widthValue={widthValue}>
      <StyledContainer {...rest}>
        {prepend}
        <StyledInput
          {...rest}
          width="100%"
          height="auto"
          border="none"
          pr="10px"
          disabled={isEditable || disabled}
          type="text"
          placeholder={placeholder}
          onChange={(e) => {
            _onChange(e);
          }}
          value={value}
          bg={(isEditable || disabled) && "#fafafa"}
          pattern={regexValidation}
          maxLength={bodyTextLimit}
          showLimitExceedError={true}
          pointer={pointer}
          inputBg={inputBg}
          placeholderTextSize={placeholderTextSize}
        />

        {isEditable && (
          <Box
            onClick={handleEdit}
            pr="10px"
            bg={(isEditable || disabled) && "lightGrey.01"}
          >
            <Icon iconName="edit" p="4px 6px" />
          </Box>
        )}
        {!isEditable && (
          <Box display="flex" bg={(isEditable || disabled) && "lightGrey.01"}>
            {isCharCountVisible && (
              <Box pr="10px" variant="text7" color="lightGrey.20">
                {`${value?.length}/${bodyTextLimit}`}
              </Box>
            )}
            {isSaveButton && (
              <Label
                pr="10px"
                ml="10px"
                variant="text6"
                color="primary"
                onClick={onHandleSave}
              >
                Save
              </Label>
            )}
          </Box>
        )}
      </StyledContainer>
      {renderError()}
      {/* {renderCharCount()} */}
    </StyledTextInput>
  );
};

TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorVisibility: PropTypes.bool,
  isCharCountVisible: PropTypes.bool,
  bodyTextLimit: PropTypes.number,
  prepend: PropTypes.element,
};

TextInput.defaultProps = {
  value: "",
  placeholder: "Enter input here",
  disabled: false,
  errorMessage: "Required",
  errorVisibility: false,
  isCharCountVisible: false,
  bodyTextLimit: 120,
  prepend: null,
};

export default TextInput;
