import React from "react";
import styled from "styled-components";

import Checkbox from "konnekt/Checkbox";
import Box from "konnekt/Box";
import Label from "konnekt/Label";

const Container = styled(Box)`
  & > div:not(:last-child) {
    margin-right: 26px;
  }
`;

const RadioButtonList = ({ data, selectedValue, onChange }) => {
  const renderList = () => {
    return data.map((item) => {
      return (
        <Box
          key={item.name}
          display="flex"
          alignItems="center"
          onClick={() => onChange(item.name)}
        >
          <Checkbox type="radio" checked={item.name === selectedValue} />
          <Label ml="12px" color="darkGrey.60" text={item.name} />
        </Box>
      );
    });
  };

  return (
    <Container display="flex" alignItems="center">
      {renderList()}
    </Container>
  );
};

export default RadioButtonList;
