import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  let URLpath = "";
  const loginVia = localStorage.getItem("login_via");
  const is_super_admin = localStorage.getItem("is_super_admin");
  useEffect(() => {
    const check = () => {
      if (loginVia == "admin" && !is_super_admin) {
        URLpath = "/admin/login";
      } else if (loginVia == "admin" && is_super_admin) {
        URLpath = "/super-admin/login";
      } else {
        URLpath = "/login";
      }
    };
    check();
  }, [loginVia, is_super_admin]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated ||
          props.location.pathname.startsWith("/admin") ||
          props.location.pathname.startsWith("/super-admin")
        ) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: { URLpath },
              state: {
                _refurl: `${props.location.pathname}${
                  !!props.location.search ? props.location.search : ""
                }`,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectRoute;
