import * as actions from './BillingActionTypes';

const INITIAL_STATUS = {
    razorpayOrder: {
        status: 'none',
        data: {}
    },
    pendingInvoices: false,
    invoices: {
        status: 'none',
        data: []
    },
    newSubscription: {
        status: 'none',
        data: null
    },
    paymentMethods: {
        status: 'none',
        data: []
    },
    confirmedSubscription: {
        status: 'none',
        data: null
    },
    addTopupSubscription: {
        status: 'none',
        data: null
    }
};

const BillingReducer = (state= INITIAL_STATUS, action) => {
    switch(action.type){
        case actions.CREATE_RAZORPAY_ORDER_STATUS:
            return {
                ...state,
                razorpayOrder: {
                    status: action.status,
                    data: {}
                }
            }
        case actions.CREATE_RAZORPAY_ORDER:
            return {
                ...state,
                razorpayOrder: {
                    status: action.status,
                    data: action.data
                }
            }
        case actions.FETCH_PENDING_INVOICES:
            return {
                ...state,
                pendingInvoices: action?.payload?.pendingInvoices
            }
        case actions.CREATE_NEW_SUBSCRIPTION_STATUS: 
            return {
                ...state,
                newSubscription: {
                    status: action.status,
                    data: null
                }
            }
        case actions.CREATE_NEW_SUBSCRIPTION: 
            return {
                ...state,
                newSubscription: {
                    status: action.status,
                    data: action.data
                }
            }
        case actions.FETCH_PAYMENT_METHOD_STATUS:
            return {
                ...state,
                paymentMethods: {
                    status: action.status,
                    data: []
                }
            }
        case actions.FETCH_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: {
                    status: action.status,
                    data: action.data,
                }
            }
        case actions.CONFIRM_SUBSCRIPTION_PAYMENT_STATUS:
            return {
                ...state,
                confirmedSubscription: {
                    status: action.status,
                    data: null
                }
            }
        case actions.CONFIRM_SUBSCRIPTION_PAYMENT:
            return {
                ...state,
                confirmedSubscription: {
                    status: action.status,
                    data: action.data
                }
            }
        case actions.FETCH_INVOICES_STATUS:
            return {
                ...state,
                invoices: {
                    status: action.status,
                    data: []
                }
            }
        case actions.FETCH_INVOICES:
            return {
                ...state,
                invoices: {
                    status: action.status,
                    data: action.data
                }
            }
        case actions.CLEAR_RAZORPAY_ORDER:
            return {
                ...state,
                razorpayOrder:{
                    ...INITIAL_STATUS.razorpayOrder
                }
            }
        case actions.BUY_CONVERSATION_CREDIT_STATUS:
            return {
                ...state,
                addTopupSubscription: {
                    data: null,
                    status: action.status
                }
            }
        case actions.BUY_CONVERSATION_CREDIT:
            return {
                ...state,
                addTopupSubscription: {
                    data: action.data,
                    status: action.status
                }
            }
        case actions.CLEAR_CONVERSATION_CREDIT:
            return {
                ...state,
                addTopupSubscription: {
                    ...INITIAL_STATUS.addTopupSubscription
                }
            }
        default: 
            return {
                ...state
            }
    }
};

export default BillingReducer;