import PropTypes from "prop-types";
import useInputValidation from "customHooks/useInputValidation";

export default function InputValidationWrapper({
  allowedKeys = /[A-Za-z0-9-_.\s]/,
  spaceAllowed = false,
  children,
}) {
  const inputValidationRef = useInputValidation(allowedKeys, spaceAllowed);

  return children(inputValidationRef);
}

InputValidationWrapper.propTypes = {
  allowedKeys: PropTypes.instanceOf(RegExp),
  children: PropTypes.func.isRequired,
};

InputValidationWrapper.defaultProps = {
  allowedKeys: /[A-Za-z0-9-_.]/,
};
