
import React from 'react'
import styled from "styled-components";
import Box from "konnekt/Box";
import TextInput from "konnekt/TextInput";
import Button from 'konnekt/Buttons';
import Icon from 'konnekt/Icon';
import DropDownControlled from "konnekt/Dropdown";


const ChangePlanTitle = styled(Box)`
  font-size: ${props => props.fs};
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${props => props.color};
  margin-bottom: ${props => props.mb};
`;

const BillingOrShippingBody = styled(Box)`
  padding: 12px 24px;
  width: 100%;
  /* height: 211px; */
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
`;

const BillShipText = styled(Box)`
  font-size: 12px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.1px;
  color: #212121;
`;



const BillingShipping = ({updateBillingDetails, forwardHandle, shippingDetails, setBackBtnBilling, setBackBtnPayment}) => {
    return (
<Box pt="10" pb="10">
                <ChangePlanTitle mb="10px" color="#000">
                  Billing
                </ChangePlanTitle>
                <BillingOrShippingBody>
                  <Box>
                    <BillShipText>{updateBillingDetails?.first_name} {updateBillingDetails?.last_name}</BillShipText>
                    <BillShipText>{updateBillingDetails?.contact_email}</BillShipText>
                    <BillShipText>{updateBillingDetails?.phone_number}</BillShipText>
                    <BillShipText>{updateBillingDetails?.company}</BillShipText>
                    <BillShipText>{updateBillingDetails?.state}, {updateBillingDetails?.country}</BillShipText>
                    <BillShipText>{updateBillingDetails?.zip_code}</BillShipText>
                    <BillShipText>{updateBillingDetails?.gstin}</BillShipText>
                  </Box>
                  <Box>
                    <Icon
                      iconName="forward"
                      color="#000"
                      onClick={() =>
                        forwardHandle("Update your billing details")
                      }
                    />
                  </Box>
                </BillingOrShippingBody>
                <Box mt="10">
                  <ChangePlanTitle mb="10px" color="#000">
                    Shipping
                  </ChangePlanTitle>
                </Box>
                <BillingOrShippingBody>
                  <Box>
                    <BillShipText>{shippingDetails?.first_name} {shippingDetails?.last_name}</BillShipText>
                    <BillShipText>{shippingDetails?.contact_email}</BillShipText>
                    <BillShipText>{shippingDetails?.phone_number}</BillShipText>
                    <BillShipText>{shippingDetails?.company}</BillShipText>
                    <BillShipText>{shippingDetails?.state}</BillShipText>
                    <BillShipText>{shippingDetails?.country}</BillShipText>
                    <BillShipText>{shippingDetails?.zip_code}</BillShipText>
                    <BillShipText>{shippingDetails?.gstin}</BillShipText>
                  </Box>
                  <Box>
                    <Icon
                      iconName="forward"
                      color="#000"
                      onClick={() =>{
                        setBackBtnBilling(true)
                        setBackBtnPayment(true)
                        forwardHandle("Update your shipping details")
                      }
                      }
                    />
                  </Box>
                </BillingOrShippingBody>
              </Box>
    )
}

export default BillingShipping
