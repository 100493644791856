import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Box from "konnekt/Box";
import logo from "konnekt/Icon/logo.svg"
import Button from "konnekt/Buttons";
import Label from "konnekt/Label";
import DropDownControlled from "konnekt/Dropdown";
import { handleSubscriptionPayment } from "modules/razorpay/apis";
import {createRazorpayOrder, createNewSubscription, confirmSubscriptionPayment, clearRazorpayOrder} from "../BillingActions";
import {
  getRazorpayNewOrder, 
  getNewSubscription, 
  getPendingInvoices,
  getPaymentMethod,
  getInvoices
} from '../BillingSelectors';

const ChangePlanBox = styled(Box)`
  width: 100%;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 7 15 7 15;
  margin-top: ${(props) => props.mt};
`;
const ChangePlanTitle = styled(Box)`
  font-size: ${(props) => props.fs};
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${(props) => props.color};
  margin-bottom: ${(props) => props.mb};
`;
const BasicAmountBox = styled(Box)`
  align-items: center;
  justify-content: center;
`;
const Label1982 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${(props) => props.color};
`;

const ChangePlan = ({
  activeProjectId,
  updateBillingDetails,
  modalEditHandle,
  activeSubscription,
  onClose
}) => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activePlansListOptions, setActivePlansListOptions] = useState(null);
  const activePlans = useSelector(
    (state) => state.manage?.getPlansList?.activePlansList
  );
  const activeUser = useSelector(state => state?.app?.user);
  const razorpayOrder = useSelector(getRazorpayNewOrder);
  const newSubscription = useSelector(getNewSubscription);
  const hasPendingInvoice = useSelector(getPendingInvoices);
  const paymentMethod = useSelector(getPaymentMethod);
  const billingAddress = useSelector(state => state?.manage?.billingAddressList?.addressList?.[0] || {});
  const invoices = useSelector(getInvoices);

  const getPendingInvoice = () => invoices.find(item => item.invoice_status==='pending');
  useEffect(() => {
    if (activeSubscription) {
      setSelectedPlan(activeSubscription?.plan_id);
    } 
    return () => {
      dispatch(clearRazorpayOrder());
    } 
  }, [activeSubscription]);
  useEffect(()=> {
    if(razorpayOrder.status === 'success' || newSubscription.status === 'success'){
      const body = {
        amount: selectedPlan?.amount,
        order_id: razorpayOrder.data?.id || newSubscription.data?.order_num,
        currency: 'INR'
      }; 
      openRazorpayWindow(body);
    }
  }, [razorpayOrder, newSubscription])

  const changeSelectedPlan = (item) => {
    let selected = activePlans.filter((plan) => plan.name === item.value)[0];
    setSelectedPlan(selected);
  };
  useEffect(() => {
    let options = activePlans?.map((plan) => {
      return {
        label: plan.name,
        value: plan.name,
      };
    });
    setActivePlansListOptions(options);
  }, [activePlans]);

  const handlePaymentConfirmation = (status) => {
    const pendingInvoice = getPendingInvoice();
    const body = {
      payment_status: status,
      invoice_id: pendingInvoice?._id || newSubscription?._id,
      plan_id: selectedPlan._id
    };
    const subscriptionId = pendingInvoice?.subscription_id || newSubscription?.subscription_id;
    dispatch(confirmSubscriptionPayment(activeProjectId, subscriptionId, body));
  };

  const openRazorpayWindow = () => {
    const { amount, id: order_id, currency } = razorpayOrder.data;
    const options = {
        key: process.env.RAZORPAY_API_KEY || 'rzp_test_HKcCUmEfsNPCdE', // Enter the Key ID generated from the Dashboard
        amount: amount?.toString(),
        currency: currency,
        name: activeUser?.contact_name,
        description: `Payment for ${selectedPlan?.name}`,
        image: { logo },
        order_id: order_id,
        handler: async function (response) {
            const data = {
                orderCreationId: order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
            handlePaymentConfirmation('paid');
            onClose();
        },
        prefill: {
            name: activeUser?.contact_name,
            email: activeUser?.contact_email,
            contact: activeUser?.phone_number,
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      handlePaymentConfirmation('unpaid');
    });
  };

  const placeOrderHandle = async () => {
    if(!hasPendingInvoice){
      dispatch(createNewSubscription(activeProjectId, {
        subscription: {
          plan_id: selectedPlan._id,
          billing_address_id: billingAddress?._id,
          payment_method_id: paymentMethod._id
        }
      }));
    }else{
      const body = {
        amount: selectedPlan?.amount,
        currency: "INR",
        subscription_id : activeSubscription?._id || '656a1b14e06de3bd18fe8aa4',
        customer_id : activeUser?._id
      };
      dispatch(createRazorpayOrder(activeProjectId, body));
    }
  };

  return (
    <Box pt="10" pb="10">
      <ChangePlanBox mb="20px">
        <ChangePlanTitle mb="10px" fs="16px" color="#000">
          Select plan
        </ChangePlanTitle>
        <Box display="flex" justifyContent="space-between">
          <Label variant="text4">{selectedPlan?.name}</Label>
          <BasicAmountBox>
            <Label1982 color="#28C152">
              ₹
              {selectedPlan?.amount
                ? selectedPlan?.amount
                : selectedPlan?.plan_id?.amount}
              /mon
            </Label1982>
            <ChangePlanTitle mb="0px" fs="14px" color="gray">
              (Tax Excl.)
            </ChangePlanTitle>
          </BasicAmountBox>
        </Box>
        <ChangePlanTitle mb="0px" fs="16px" color="#000">
          Choose your billing
        </ChangePlanTitle>
        {activePlansListOptions?.length > 0 && (
          <DropDownControlled
            selectedValue={
              selectedPlan?.name
                ? selectedPlan?.name
                : activePlansListOptions[0].value
            }
            width="73.5%"
            height="44px"
            mt="10px"
            variant="lighterGrey"
            options={activePlansListOptions}
            onOptionClick={(item) => changeSelectedPlan(item)}
          />
        )}
      </ChangePlanBox>
      <ChangePlanBox mt="18px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ChangePlanTitle mb="0px" color="#000">
            Billing Address
          </ChangePlanTitle>
          <Button type="outlineColor" width="78" onClick={modalEditHandle}>
            Edit
          </Button>
        </Box>
        <ChangePlanTitle mb="0px" fs="10px" color="gray">
          {updateBillingDetails?.address_1
            ? updateBillingDetails?.address_1
            : updateBillingDetails?.address_2}
          <br></br> {updateBillingDetails?.city} {updateBillingDetails?.state},{" "}
          {updateBillingDetails?.country} {updateBillingDetails?.zip_code}
        </ChangePlanTitle>
      </ChangePlanBox>
      <ChangePlanBox mt="18px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ChangePlanTitle mb="0px" fs="16px" color="#000">
            Payment Method
          </ChangePlanTitle>
        </Box>
        <ChangePlanTitle mb="0px" fs="10px" color="gray">
          Razorpay
        </ChangePlanTitle>
      </ChangePlanBox>
      <Box mt="18">
        <ChangePlanTitle mb="0px" fs="16px" color="#000">
          {activeSubscription?.plan_id
            ? "This Subscription is active"
            : "Choose Subscription Plan"}
        </ChangePlanTitle>
        <Button
          type="primary"
          // bg="#e0e0e0"
          bg={selectedPlan?._id !== activeSubscription?.plan_id?._id ? "#a76ed4" : "#e0e0e0"}
          width="100%"
          border="none"
          mt="10"
          disabled={selectedPlan?._id !== activeSubscription?.plan_id?._id ? false : true}
          onClick={placeOrderHandle}
        >
          Place Order
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePlan;
