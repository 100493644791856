import React, { useState } from "react";

import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import { useSelector } from "react-redux";
import {
  LiveChatProfilesBody,
  LiveChatProfilesHading,
  LiveChatProfilesText,
  LiveChatProfileItem,
} from "./CampaignsCard.styles";

const CampaignsCard = ({ heading }) => {
  const [iconManage, setIconManage] = useState();

  const iconChangeHandle = () => {
    setIconManage(!iconManage);
  };

  const campaignsData = useSelector((state) => state?.thread?.campaignsData);

  return (
    <LiveChatProfilesBody>
      <Box
        display="flex"
        justifyContent="space-between"
        pb={!!campaignsData?.length && "15px"}
      >
        <LiveChatProfilesHading>{heading}</LiveChatProfilesHading>
        {!iconManage ? (
          <Icon iconName="plus" color="#5E3EC5" onClick={iconChangeHandle} />
        ) : (
          <Icon iconName="minus" color="#5E3EC5" onClick={iconChangeHandle} />
        )}
      </Box>
      <LiveChatProfileItem iconManage={iconManage}>
        {campaignsData?.length > 0 ? (
          campaignsData?.map((item) => {
            return (
              <LiveChatProfilesText color="#757575" key={item._id}>
                {item.name}
                {item?.name && (
                  <LiveChatProfilesText color="#212121">
                    <Icon iconName="doubleTick" />
                  </LiveChatProfilesText>
                )}
              </LiveChatProfilesText>
            );
          })
        ) : (
          <LiveChatProfilesText pt="1rem" pb="1rem">
            No Campaign
          </LiveChatProfilesText>
        )}
      </LiveChatProfileItem>
    </LiveChatProfilesBody>
  );
};

export default CampaignsCard;
