import React from "react";
import propTypes from "prop-types";
import Icon from "konnekt/Icon";
import styled from "styled-components";
import { space } from "styled-system";

const StyledSpan = styled.span`
  ${space}
`;

const checkBoxIcons = ["checkboxEmpty", "squareWithTick"];
const switchBoxIcons = ["switchOff", "switchOn"];
const radioButtonIcons = ["circle", "circleWithDot"];

const Checkbox = ({
  type,
  checked,
  disabled,
  iconName,
  iconSize,
  onChange,
  ...props
}) => {
  const handleClick = () => {
    if (onChange && !disabled) {
      onChange(!checked);
    }
  };

  const getIconName = () => {
    if (iconName) {
      return checked ? iconName[1] : iconName[0];
    }
    switch (type) {
      case "checkbox":
        return checked ? checkBoxIcons[1] : checkBoxIcons[0];

      case "switch":
        return checked ? switchBoxIcons[1] : switchBoxIcons[0];

      case "radio":
        return checked ? radioButtonIcons[1] : radioButtonIcons[0];

      default:
        return "";
    }
  };

  const getIconSize = () => {
    if (iconSize) {
      return iconSize;
    }
    if (type === "switch") {
      return "normal";
    }
    return "xs";
  };

  const renderIcon = () => (
    <Icon
      iconSize={getIconSize()}
      onClick={handleClick}
      iconName={getIconName()}
    />
  );

  return <StyledSpan {...props}>{renderIcon()}</StyledSpan>;
};

Checkbox.propTypes = {
  onChange: propTypes.func,
  type: propTypes.oneOf(["checkbox", "switch", "radio"]),
  checked: propTypes.bool.isRequired,
  disabled: propTypes.bool,
  iconName: propTypes.arrayOf(propTypes.string),
};

Checkbox.defaultProps = {
  onChange: null,
  disabled: false,
  type: "checkbox",
  iconName: null,
};
export default Checkbox;
