import moment from "moment";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const allowedOrgs = [
  "ba4308f1-a506-44d2-a8c3-17380216cf91",
  "80a1681a-3fbd-4580-beff-eb93478211a5",
];

export const getAuthenticatedState = (state) => state.app.isAuthenticated;
export const getSessionUser = (state) => state.app.user;
export const getSessionUserAgentId = (state) => state.app.user._id ? state.app.user._id : "63727cd4b4aa776f3c8e3d5a";
export const getCatalogId = (state) =>
  state.app.organizations?.[0]?.organization_id?.metadata?.catalog_id;
export const getCatalogSyncStatus = (state) =>
  state.app.organizations?.[0]?.organization_id?.metadata?.catalog_sync_status;
export const getLastProductSyncAt = (state) =>
  state.app.organizations?.[0]?.organization_id?.last_product_synced_at;
export const getToken = (state) => state.app.token;
export const getLoggingState = (state) => state.app.isLoggingIn;
export const getAppLoadingState = (state) => state.app.isLoadingApp;
export const getBottomNavBarState = (state) => state.app.isBottomNavVisible;
export const getVerifyingState = (state) => state.app.isVerifying;
export const getVerifyingTokenState = (state) => state.app.isVerifyingToken;
export const getUserOrganizationState = (state) =>
  state.app.isFetchingOrganizationDetails;
export const getUserOrganizations = (state) => state.app.organizations;
export const getOrganizationUpdateStatus = (state) =>
  state.app.isUpdatingOrganization;
export const getUserActiveOrganization = (state) =>
  state.app.organizations && state.app.organizations.length > 0
    ? state.app.organizations[0].organization_id
    : {};
export const getSelectedOrganizationId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.id
    : "";
export const getSelectedOrganizationWAOnlyState = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.only_wa_notifications
    : false;
export const getUserOrganizationAgents = (state) => state.app.activeAgents;
export const getUserOrganizationAgentsCount = (state) =>
  state.app.activeAgents.length;
export const getAllAgents = (state) => state.app.allAgents;
export const getAllAgentsObjectMap = (state) => state.app.allAgentsObjectMap;
export const getUserOrganizationId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.id
    : "";
export const getPaymentEnabledStatus = (state) =>
  state.app.organizations?.[0]?.organization_id?.is_payment_enabled || false;
export const getOrganizationStripeCustomerId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.stripe_customer_id
    : "";
export const getUserChannels = (state) => state.app.channels;
export const getSelectedPhoneExtension = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].country_code
    : ""; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getInternalPhoneNumber = (state) => state.app.activeProject && state.app.activeProject.wa_phone_number ? state.app.activeProject.wa_phone_number : ""; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getSelectedWhatsappNumber = getInternalPhoneNumber; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getSelectedChannelId = (state) =>
  state.app.channels && state.app.channels[0] ? state.app.channels[0].id : "";
export const getSelectedChannelPhotoUrl = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].profile_photo_url
    : "";
export const getSelectedChannelContainerNamespace = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].container_namespace
    : "";
export const getSelectedChannelStatus = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].wa_approval_status
    : "";
export const getOrganizationTagList = (state) => state.app.tagList;
export const getOrganizationAllTagObject = (state) => state.app.allTags;
export const getOranizationTagsFetchStatus = (state) =>
  state.app.isFetchingOrganizationTags;
export const getOrganizationCreatingStatus = (state) =>
  state.app.isCreatingOrganizationTag;

export const getOrganizationsLength = (state) =>
  state.app.organizations ? state.app.organizations.length : 0;

// * This selector needs to be replaced fully by getOrganizationOnboardingStatus
// ! Don't use this selector
// ? use getOrganizationOnboardedStatus in place of this
export const getOrganizationOnboardStatus = (state) =>
  state.app.isOrganizationOnboarded;
export const getOrganizationOnboardingStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.onboarding_status
    : "";
export const getOrganizationOnboardedStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id.onboarding_status
    ? state.app.organizations[0].organization_id.onboarding_status ===
    onboardingStatuses.ONBOARDED
    : false;
export const getFbBusinessManagerId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.FB_business_manager_id
    : "";
// export const getFbBusinessManagerId = state => state.app.organizations && state.app.organizations[0] &&
// state.app.organizations[0].organization_id ? '' : state.app.organizations[0].organization_id.FB_business_manager_id;
export const getFbBusinessVerificationStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.FB_business_verification_status
    : "";
export const getWhatsAppAPIApprovalStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.wa_api_approval_status
    : "";

export const getVerifyingBusinessManagerIdState = (state) =>
  state.app.isVerifyingBusinessManagerId;
export const getVerifiedBusinessManagerIdState = (state) =>
  state.app.isVerifiedBusinessManagerId;
export const getUpdatingBusinessManagerIdState = (state) =>
  state.app.isUpdatingBusinessManagerId;
export const getUpdatedBusinessManagerIdState = (state) =>
  state.app.isUpdatedBusinessManagerId;

export const getWebClientId = (state) => state.app.webClientId;
export const getOTPSendingState = (state) => state.app.isSendingOTP;
export const getOTPSentState = (state) => state.app.isOTPSent;
export const getOTPVerifyingState = (state) => state.app.isVerifyingOTP;
export const getVerifiedState = (state) => state.app.isVerified;
export const getNetworkStatus = (state) => state.app.isOnline;
export const getSocketStatus = (state) => state.app.isSocketConnected;
export const getAppTokenFound = (state) => state.app.isTokenFound;
export const getOrganizationQuickReplies = (state) => state.app.orgQuickReplies;
export const getQuickReplyFetchingState = (state) =>
  state.app.isFetchingQuickReplies;
export const getQuickReplyAddingState = (state) =>
  state.app.isAddingQuickReplies;
export const getQuickReplyUpdatingState = (state) =>
  state.app.isUpdatingQuickReplies;
export const getQuickReplyRemovingState = (state) =>
  state.app.isRemovingQuickReplies;
export const getOrganizationQuickRepliesLength = (state) =>
  state.app.orgQuickReplies.length;
export const getQuickReplyAlreadyLoadedState = (state) =>
  state.app.isQuickRepliesLoadedAlready;
export const getIsFetchingQuickReplies = (state) =>
  state.app.isFetchingQuickReplies;
export const getOrganizationEventSources = (state) => state.app.orgEventSources;
export const getIsFetchingEventSources = (state) =>
  state.app.isFetchingEventSources;
export const getEventSourceAlreadyLoadedState = (state) =>
  state.app.isEventSourcesLoadedAlready;

export const getOrganizationTagsAlreadyLoadedState = (state) =>
  state.app.isOrganizationtagsLoadedAlready;
export const getCountryCodeList = (state) => state.app.countryCodeList;
export const getOrganizationMembers = (state) =>
  state.app.orgMembers.filter((item) => !!!item.is_deleted);
export const getMemberFetchingState = (state) => state.app.isFetchingMembers;
export const getMemberAddingState = (state) => state.app.isAddingMembers;
export const getMemberUpdatingState = (state) => state.app.isUpdatingMembers;
export const getMemberRemovingState = (state) => state.app.isRemovingMembers;
export const getOrganizationMembersLength = (state) =>
  state.app.orgMembers.filter((item) => !!!item.is_deleted).length;

export const checkIsUserDeviceMobile = (state) => state.app.IsUserDeviceMobile;
export const getQualityList = (state) => state.app.qualityList;
export const getActiveQuality = (state) => state.app.activeQuality;
export const getLanguageList = (state) => state.app.languages;
export const getLanguageDict = (state) => state.app.languageDict;
export const getUserRegisteringState = (state) => state.app.isRegistering;
export const getUserRegisteredState = (state) => state.app.isRegistered;
export const getGeneratingEmailState = (state) => state.app.isGeneratingEmail;
export const getAddingUserChannelState = (state) =>
  state.app.isAddingUserChannel;
export const getFetchingUserChannelState = (state) =>
  state.app.isFetchingUserChannels;
export const getSelectedAccountSegment = (state) =>
  state.app.selectedAccountSegment;
export const getSelectedRecoveryPasswordSegment = (state) =>
  state.app.selectedRecoveryPasswordSegment;
export const getStatusSendingMailForResetPasswordSegment = (state) =>
  state.app.isSendingMailForResetPassword;
export const getRecoveryPasswordStatus = (state) =>
  state.app.isRecoveryPassword;
export const getSelectedResetPasswordSegment = (state) =>
  state.app.selectedResetPasswordSegment;
export const getStatusUpdatingResetPasswordSegment = (state) =>
  state.app.isUpdatingResetPassword;
export const getStatusResendingInvitationForMember = (state) =>
  state.app.isResendingInvitationForMember;
export const getStatusShowBox = (state) => state.app.isShowBox;
export const getFirstTimeOrgOnboardedStatus = (state) =>
  state.app.isOrganizationFirstOnboarded;
export const getReconnectingStateForSocket = (state) =>
  state.app.isReconnectingToSocket;

export const getSaveWidgetPreferencesStatus = (state) =>
  state.app.isSavingWidgetPreferences;
export const getWidgetPreferences = (state) => state.app.widgetPreferences;
export const getAllUserTablePreferences = (state) =>
  state.app.allUserPreferences;
export const getBulkUploadSelectedTags = (state) =>
  state.app.selectedBulkUploadTags;
export const getBulkUploadSelectedSegment = (state) =>
  state.app.selectedBulkUploadSegment;
export const getCountryList = (state) => state.app.countryList;
export const getStateList = (state) => state.app.stateList;
export const getShopifySelectedSegment = (state) =>
  state.app.selectedShopifySegment;

export const getAddQuickReplyModalState = (state) =>
  state.app.isOpenAddReplyForm;
export const getEditQuickReplyModalState = (state) =>
  state.app.isOpenEditReplyForm;
export const getDeleteQuickReplyModalState = (state) =>
  state.app.isOpenDeleteReplyForm;
export const getDeleteTeamSettingModalState = (state) =>
  state.app.isOpenDeleteMember;
export const getEditTeamSettingModalState = (state) =>
  state.app.isOpenEditMember;
export const getQuickReplyFieldsState = (state) => state.app.quickReplyFields;
export const getQuickReplyVariableCount = (state) =>
  state.app.quickReplyVariableCount;
export const getQuickReplyInputFields = (state) =>
  state.app.quickReplyInputfields;

export const getBugReportModalState = (state) => state.app.isOpenBugReportModal;
export const getPaymentModalStatus = (state) =>
  state.app.isOpenSubscriptionStartPaymentModal;
export const getBugReportSubjectText = (state) => state.app.bugReportSubject;
export const getBugReportDetailText = (state) => state.app.bugReportDetail;
export const getBugReportIssueWithText = (state) =>
  state.app.bugReportIssueWith;

export const getEmojiCardModalState = (state) => state.app.isOpenEmojiCard;

export const getTeamMemberInputFields = (state) =>
  state.app.teamMemberInputFields;
export const getAddTeamMemberModalState = (state) =>
  state.app.isOpenAddTeamMemberModal;

export const getOrganizationDetailInputValues = (state) =>
  state.app.organizationDetailInputValues;
export const getRedirectAppliedFilter = (state) =>
  state.app.redirectAppliedFilters;
export const getUserRedirectStatus = (state) => state.app.isRedirectedFromUser;

export const getUserRoleGroup = (state) =>
  state.app.user && state.app.user.role ? state.app.user.role : "";
export const getUserFeaturePermissions = (state) =>
  state.app.user && state.app.user.userPermissions
    ? state.app.user.userPermissions
    : [];
export const getIfProductTourEnabled = (state) =>
  state.app.user &&
  state.app.user.userPermissions &&
  state.app.user.userPermissions.length > 0
    ? state.app.user.userPermissions.indexOf("inbox_all_chats") > -1 &&
    state.app.user.userPermissions.indexOf("users_add_contacts") > -1 &&
    !state.app.IsUserDeviceMobile
    : false;
export const getIfUserHasInboxAllChatsAccess = (state) =>
  state.app.user &&
  state.app.user.userPermissions &&
  state.app.user.userPermissions.length > 0
    ? state.app.user.userPermissions.indexOf("inbox_all_chats") > -1
    : false;
export const getIfUserHasAddContactAccess = (state) =>
  state.app.user &&
  state.app.user.userPermissions &&
  state.app.user.userPermissions.length > 0
    ? state.app.user.userPermissions.indexOf("users_add_contacts") > -1
    : false;
export const getIfOrganizationIsOrgCreatedPost29042021 = (state) =>
  state.app &&
  state.app.organizations &&
  state.app.organizations[0] &&
  moment(parseInt(process.env.REACT_APP_USERPILOT_ORG_USAGE)).isBefore(
    moment(
      new Date(
        state.app.organizations[0].organization_id
          ? state.app.organizations[0].organization_id.created_at_utc
          : "",
      ),
    ),
  );
export const getOrganizationTraitByKeyName = (state, keyName) =>
  state.app &&
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id[keyName]
    ? state.app.organizations[0].organization_id[keyName]
    : null;

export const getIfOrganizationHasCatalogLinked = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.catalog_id;
};

export const getIfOrganizationHasCatalogStatus = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.catalog_sync_status;
};
export const getComplianceInfoStatus = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.compliace_info_status;
};
export const getIsFetchingFBEmbeddedData = (state) =>
  state.app.isFetchingFbEmbeddedData;
export const getIfOrganizationHasEmbeddedSignupActive = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id.is_embedded_signup_enabled;

export const getUserJourney = (state) => state.app.user_journey;
export const getSidebarStatus = (state) => state.app.isShowSidebar;
export const getUserPreferencesLoadedState = (state) =>
  state.app.isUserPreferencesLoaded;
export const getUserJourneyStatusCheck = (state) =>
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? state.app.organizations &&
    state.app.organizations[0] &&
    state.app.organizations[0].organization_id
      ? allowedOrgs.indexOf(state.app.organizations[0].organization_id.id) > -1
      : ""
    : true && state.app.isMobileDevice;
export const getCreatingConversationLabelStatus = (state) =>
  state.app.isCreatingOrganizationConvLabel;
export const getFetchingConversationLabelsStatus = (state) =>
  state.app.isFetchingConvLabels;
export const getOrganizationConversationLabelsList = (state) =>
  state.app.orgConvLabels ? Object.values(state.app.orgConvLabels) : [];
export const getOrganizationConvLabelsAlreadyLoadedState = (state) =>
  state.app.isConvLabelsLoadedAlready;

export const getKeyValueFromChannels = (state, keyName) =>
  state.app.channels &&
  state.app.channels[0] &&
  state.app.channels[0][`${keyName}`]
    ? state.app.channels[0][`${keyName}`]
    : null;

export const getKeyValueFromOrganizations = (state, keyName) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id[keyName]
    ? state.app.organizations[0].organization_id[keyName]
    : null;

export const getWigetStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0]?.organization_id?.metadata
    .has_active_shopify_widget;

export const getListMessageDetails = (state) => state.app.listMessageDetails;
export const getActiveProjectId = (state) => state.app.activeProjectId;
export const getActiveProject = (state) => state.app.activeProject;

export const getCampaignLastSyncTime = (campaignId) => (state) =>
  state.app?.organizations?.[0]?.organization_id
    ?.campaigns_last_synced_at_utc?.[campaignId];

export const getSandBoxCode = (state) => state.app?.organizations[0]?.organization_id?.metadata?.sandbox_code;
export const getProjectId = (state) => state.app?.activeProjectId || localStorage.getItem('projectId');
export const getActiveProjectDetails = (state) => state.app?.activeProject?.project ;
export const getProjectList = (state) => state.app?.projectList;
export const getProjectUpdatingState = (state) => state.app?.isUpdatingProject;
