import { getUserOrganizationId } from "modules/App/AppSelectors";
import callApi from "../../util/apiCaller";
import { showNotification } from "../../util/utility";
import { changeOrganizationDetails } from "../App/AppActions";

export const FETCH_TEMPLATE_LIST_STATUS = "FETCH_TEMPLATE_LIST_STATUS";
export const FETCH_TEMPLATE_LIST = "FETCH_TEMPLATE_LIST";
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_STATUS = "CREATE_TEMPLATE_STATUS";
export const FETCH_TEMPLATE_STATUS = "FETCH_TEMPLATE_STATUS";
export const UPDATING_TEMPLATE_DETAIL = "UPDATING_TEMPLATE_DETAIL";
export const UPDATE_TEMPLATE_DETAIL = "UPDATE_TEMPLATE_DETAIL";
export const UPDATE_TEMPLATE_LIST = "UPDATE_TEMPLATE_LIST";
export const UPDATE_ACTIVE_TEMPLATE_LIST = "UPDATE_ACTIVE_TEMPLATE_LIST";
export const UPDATE_DELETED_TEMPLATE_LIST = "UPDATE_DELETED_TEMPLATE_LIST";
export const FETCH_ACTIVE_TEMPLATE_LIST = "FETCH_ACTIVE_TEMPLATE_LIST";
export const FETCH_ACTIVE_TEMPLATE_LIST_V2 = "FETCH_ACTIVE_TEMPLATE_LIST_V2";
export const FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2 =
  "FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2";
export const FETCH_ACTIVE_TEMPLATE_LIST_STATUS =
  "FETCH_ACTIVE_TEMPLATE_LIST_STATUS";
export const FETCH_DELETED_TEMPLATE_LIST = "FETCH_DELETED_TEMPLATE_LIST";
export const FETCH_DELETED_TEMPLATE_LIST_STATUS =
  "FETCH_DELETED_TEMPLATE_LIST_STATUS";
export const UNARCHIVING_TEMPLATE_DEATILS = "UNARCHIVING_TEMPLATE_DEATILS";
export const UNARCHIVE_TEMPLATE_DEATILS = "UNARCHIVE_TEMPLATE_DEATILS";
export const ARCHIVING_TEMPLATE_DEATILS = "ARCHIVING_TEMPLATE_DEATILS";
export const ARCHIVE_TEMPLATE_DEATILS = "ARCHIVE_TEMPLATE_DEATILS";
export const SYNC_TEMPLATE = "SYNC_TEMPLATE";
export const TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION =
  "TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION";
export const TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL =
  "TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL";
export const UPDATE_ORG_TEMPLATES = "UPDATE_ORG_TEMPLATES";

export function toggleAddSampleModal(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL,
      isTemplateSampleModalVisible: val,
      isTemplateSampleModalConfirmationVisible: false,
    });
  };
}

export function toggleAddSampleConfirmation(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION,
      isTemplateSampleModalConfirmationVisible: val,
    });
  };
}

export function updateTemplateList(originalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATE_LIST,
      templateList: originalTemplateList,
    });
  };
}

export function updateActiveTemplateList(activeOriginalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_TEMPLATE_LIST,
      activeTemplateListV2: activeOriginalTemplateList,
    });
  };
}
export function updateDeletedTemplateList(deletedOriginalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELETED_TEMPLATE_LIST,
      deletedTemplateList: deletedOriginalTemplateList,
    });
  };
}

export function getAllActiveTemplatesV2() {
  return (dispatch, getState) => {
    const orgId = getUserOrganizationId(getState());
    dispatch({
      type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
      isFetchingActiveTemplateListV2: true,
    });
    return callApi(
      `v1/organizations/${orgId}/message-templates/v2/`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_V2,
          activeTemplateListV2: res.data,
          activeTotalCount: res.data.length,
          isFetchingActiveTemplateListV2: false,
          isFetchingInitialActiveTemplateList: false,
        });
      } else {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
          isFetchingActiveTemplateListV2: false,
          isFetchingInitialActiveTemplateList: false,
        });
      }
    });
  };
}

export function getAllDeletedTemplates(
  organizationId,
  pageSize,
  isFetchingInitialDeletedTemplateList = true,
  pageNum = 1,
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
      isFetchingTemplateList: true,
      isFetchingDeletedTemplateList: true,
      isFetchingInitialDeletedTemplateList: !!isFetchingInitialDeletedTemplateList,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/archived/?offset=${
        (pageNum - 1) * pageSize
      }&limit=${pageSize}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST,
          deletedTemplateList: res.data,
          pageNum: pageNum,
          pageSize: pageSize,
          deletedTotalCount: res.data.length,
          isFetchingDeletedTemplateList: false,
          isFetchingInitialDeletedTemplateList: false,
        });
      } else {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
          isFetchingDeletedTemplateList: false,
          isFetchingInitialDeletedTemplateList: false,
        });
      }
    });
  };
}

export function getTemplateById(organizationId, templateId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TEMPLATE_STATUS,
      isFetchingTemplate: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/`,
      "get",
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_TEMPLATE,
          activeTemplate: res.data,
          isFetchingTemplate: false,
        });
      } else {
        dispatch({
          type: FETCH_TEMPLATE_STATUS,
          isFetchingTemplate: false,
        });
      }
    });
  };
}

export function getTemplateByTemplatename(organizationId, templateName) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TEMPLATE_STATUS,
      isFetchingTemplate: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/v2/?templateName=${templateName}`,
      "get",
    ).then((res) => {
      if (res.result && res.data) {
        let items = res.data;
        let obj = {};
        obj.id = items[0] && items[0].id ? items[0].id : "";
        obj.name = items[0] && items[0].name ? items[0].name : "";
        obj.display_name =
          items[0] && items[0].display_name ? items[0].display_name : "";
        obj.category = items[0] && items[0].category ? items[0].category : "";
        obj.templates = [];
        items.forEach((item) => {
          let template = {};
          template.header = item.header;
          template.header_format = item.header_format;
          template.header_text = item.header_text;
          template.header_handle = item.header_handle;
          template.header_handle_file_url = item.header_handle_file_url;
          template.header_handle_file_name = item.header_handle_file_name;
          template.body = item.body;
          template.body_text = item.body_text;
          template.button_text = item.button_text;
          template.footer = item.footer;
          template.language = item.language;
          template.approval_status = item.approval_status;
          template.created_at_utc = item.created_at_utc;
          template.modified_at_utc = item.modified_at_utc;
          template.buttons = item.buttons;
          template.wa_template_id = item.wa_template_id;
          template.created_by_user_id = item.created_by_user_id;
          template.organization_id = item.organization_id;
          obj.templates.push(template);
        });
        dispatch({
          type: FETCH_TEMPLATE,
          activeTemplate: obj,
          isFetchingTemplate: false,
        });
      } else {
        dispatch({
          type: FETCH_TEMPLATE_STATUS,
          isFetchingTemplate: false,
        });
      }
    });
  };
}

export function createNewTemplate(
  organizationId,
  channelId,
  template,
  history,
  path,
) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TEMPLATE_STATUS,
      isCreatingTemplate: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/channels/${channelId}/`,
      "post",
      template,
    ).then((res) => {
      if (res && res.id && res.result) {
        template.id = res.id;
        dispatch({
          type: CREATE_TEMPLATE,
          isCreatingTemplate: false,
          template: template,
        });
        history.push(path);
      } else {
        dispatch({
          type: CREATE_TEMPLATE_STATUS,
          isCreatingTemplate: false,
        });
      }
    });
  };
}

export function updateTemplateDetails(organizationId, templateId, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_TEMPLATE_DETAIL,
      isUpdatingTemplateDetail: true,
    });
    let body = {
      display_name: update,
    };
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/`,
      "put",
      body,
    ).then((res) => {
      if (res && res.result) {
        dispatch(getAllActiveTemplatesV2());
        dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
        showNotification("success", "Updated");
        dispatch({
          type: UPDATE_TEMPLATE_DETAIL,
          isUpdatingTemplateDetail: false,
        });
      } else {
        dispatch({
          type: UPDATING_TEMPLATE_DETAIL,
          isUpdatingTemplateDetail: false,
        });
      }
    });
  };
}

export function archiveTemplateDetails(
  organizationId,
  templateId,
  action = "Archive",
) {
  return (dispatch) => {
    dispatch({
      type: ARCHIVING_TEMPLATE_DEATILS,
      isArchivingTemplateDetail: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/?action=${action}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
          isFetchingActiveTemplateListV2: true,
        });
        return callApi(
          `v1/organizations/${organizationId}/message-templates/v2/`,
          "get",
        ).then((res2) => {
          if (res2 && res2.result) {
            dispatch({
              type: FETCH_ACTIVE_TEMPLATE_LIST_V2,
              activeTemplateListV2: res2.data,
              activeTotalCount: res2.data.length,
              isFetchingActiveTemplateListV2: false,
              isFetchingInitialActiveTemplateList: false,
            });
            dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
            showNotification("success", "Deleted Successfully");
            dispatch({
              type: ARCHIVING_TEMPLATE_DEATILS,
              isArchivingTemplateDetail: false,
            });
          } else {
            dispatch({
              type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
              isFetchingActiveTemplateListV2: false,
              isFetchingInitialActiveTemplateList: false,
            });
          }
        });
      } else {
        dispatch({
          type: ARCHIVING_TEMPLATE_DEATILS,
          isArchivingTemplateDetail: false,
        });
      }
    });
  };
}

export function unarchiveTemplateDetails(
  organizationId,
  templateId,
  action = "Unarchive",
  pageNum = 1,
  pageSize = 2000,
  isFetchingInitialDeletedTemplateList = true,
) {
  return (dispatch) => {
    dispatch({
      type: UNARCHIVING_TEMPLATE_DEATILS,
      isUnarchivingTemplateDetail: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/?action=${action}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
          isFetchingTemplateList: true,
          isFetchingDeletedTemplateList: true,
          isFetchingInitialDeletedTemplateList: !!isFetchingInitialDeletedTemplateList,
        });
        return callApi(
          `v1/organizations/${organizationId}/message-templates/archived/?offset=${
            (pageNum - 1) * pageSize
          }&limit=${pageSize}`,
          "get",
        ).then((res2) => {
          if (res2 && res2.result) {
            dispatch({
              type: FETCH_DELETED_TEMPLATE_LIST,
              deletedTemplateList: res2.data,
              pageNum: pageNum,
              pageSize: pageSize,
              deletedTotalCount: res2.data.length,
              isFetchingDeletedTemplateList: false,
              isFetchingInitialDeletedTemplateList: false,
            });
            dispatch(getAllActiveTemplatesV2());
            showNotification("success", "Restored Successfully");
            dispatch({
              type: UNARCHIVE_TEMPLATE_DEATILS,
              isUnarchivingTemplateDetail: false,
              isUnarchiveTemplateDetail: true,
            });
          } else {
            dispatch({
              type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
              isFetchingDeletedTemplateList: false,
              isFetchingInitialDeletedTemplateList: false,
            });
          }
        });
      } else {
        dispatch({
          type: UNARCHIVING_TEMPLATE_DEATILS,
          isUnarchivingTemplateDetail: false,
        });
      }
    });
  };
}

export const updateSyncStatus = (isSyncing) => ({
  type: SYNC_TEMPLATE,
  isSyncing,
});

export function getTemplateSyncStatus(organizationId, organizations) {
  return (dispatch) => {
    dispatch(updateSyncStatus(true));
    return callApi(
      `v1/organizations/${organizationId}/message-templates/sync`,
      "get",
    )
      .then((res) => {
        if (res.result) {
          if (res.refresh_required) {
            if (
              res &&
              res.last_template_sync_at_utc !== "" &&
              organizations &&
              organizations.length > 0
            ) {
              let org = organizations;
              org[0].organization_id.templates_last_synced_at_utc =
                res.last_template_sync_at_utc;
              dispatch(changeOrganizationDetails(org));
            }
            showNotification("success", "Template Sync started");
            dispatch(getAllActiveTemplatesV2());
            dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
          } else {
            if (
              res &&
              res.last_template_sync_at_utc !== "" &&
              organizations &&
              organizations.length > 0
            ) {
              let org = organizations;
              org[0].organization_id.templates_last_synced_at_utc =
                res.last_template_sync_at_utc;
              dispatch(changeOrganizationDetails(org));
            }
            showNotification("success", "Template sync started");
          }
        }
      })
      .catch(() => {
        dispatch(updateSyncStatus(false));
      });
  };
}

export const updateTemplateMessage = (event) => ({
  type: UPDATE_ORG_TEMPLATES,
  event,
});
