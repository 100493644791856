import { callApiV2 } from "util/apiCaller";
import {
  FETCH_ALL_AGENT_DETAILS,
  FETCH_ALL_AGENT_DETAILS_SUCCESS,
  UPDATE_AGENT_DETAILS,
  INITIAL_AGENT_DETAILS,
  FETCH_AGENT_DETAILS,
  ADD_AGENT_DETAILS,
  ADD_AGENT_DETAILS_SUCCESS,
  UPDATE_AGENT_DATA,
  UPDATE_AGENT_DATA_SUCCESS,
  DELETE_AGENT_DETAILS,
  DELETE_AGENT_DETAILS_SUCCESS,
  UPDATE_INITIAL_TAGS_DATA,
  AGENT_DETAILS_FAILURE,
  FETCH_ALL_TAGS_DETAILS,
  FETCH_ALL_TAGS_DETAILS_SUCCESS,
  FETCH_TAG_DETAILS,
  UPDATE_TAG_DETAILS,
  INITIAL_TAG_DETAILS,
  ADD_TAG_DETAILS,
  ADD_TAG_DETAILS_SUCCESS,
  TAG_DETAILS_FAILURE,
  UPDATE_TAG_DATA,
  UPDATE_TAG_DATA_SUCCESS,
  DELETE_TAG_DETAILS,
  DELETE_TAG_DETAILS_SUCCESS,
  FETCH_ALL_CANNED_MESSAGE_DETAILS,
  FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS,
  CANNED_MESSAGE_DETAILS_FAILURE,
  FETCH_CANNED_MESSAGE_DETAILS,
  UPDATE_CANNED_MESSAGE_DETAILS,
  INITIAL_CANNED_MESSAGE_DETAILS,
  ADD_CANNED_MESSAGE_DETAILS,
  ADD_CANNED_MESSAGE_DETAILS_SUCCESS,
  UPDATE_CANNED_MESSAGE_DATA,
  UPDATE_CANNED_MESSAGE_DATA_SUCCESS,
  DELETE_CANNED_MESSAGE_DETAILS,
  DELETE_CANNED_MESSAGE_DETAILS_SUCCESS,
  FETCH_ALL_TEMPLATE_MESSAGE_DETAILS,
  FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  TEMPLATE_MESSAGE_DETAILS_FAILURE,
  FETCH_TEMPLATE_MESSAGE_DETAILS,
  UPDATE_TEMPLATE_MESSAGE_DETAILS,
  INITIAL_TEMPLATE_MESSAGE_DETAILS,
  ADD_TEMPLATE_MESSAGE_DETAILS,
  ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  UPDATE_TEMPLATE_MESSAGE_DATA,
  UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS,
  DELETE_TEMPLATE_MESSAGE_DETAILS,
  DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
  FETCH_ALL_USER_ATTRIBUTE_DETAILS,
  FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS,
  USER_ATTRIBUTE_DETAILS_FAILURE,
  FETCH_USER_ATTRIBUTE_DETAILS,
  UPDATE_USER_ATTRIBUTE_DETAILS,
  INITIAL_USER_ATTRIBUTE_DETAILS,
  UPDATE_INITIAL_USER_ATTRIBUTE_DATA,
  ADD_USER_ATTRIBUTE_DETAILS,
  UPDATE_USER_ATTRIBUTE_DATA,
  UPDATE_USER_ATTRIBUTE_DATA_SUCCESS,
  DELETE_USER_ATTRIBUTE_DETAILS,
  DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS,
  BILLING_DATE_RANGE_STATUS,
  BILLING_DATE_RANGE,
  BILLING_CREDIT_AMOUNT_STATUS,
  BILLING_CREDIT_AMOUNT,
  BILLING_PAYMANT_PLANS_LIST_STATUS,
  BILLING_PAYMANT_PLANS_LIST,
  BILLING_USER_COUNT_STATS,
  BILLING_USER_COUNT_STATUS,
  BILLING_NOTIFICATION_COUNT_STATUS,
  BILLING_NOTIFICATION_COUNT,
  FETCH_NEW_BUSINESS_STATUS,
  FETCH_NEW_BUSINESS,
  FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS,
  FETCH_ANALYTICS_OVERVIEW_STATS,
  FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS,
  FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE,
  ANALYTICS_BODY_DETAILS,
  ANALYTICS_AGENT_PERFORMANCE_DETAILS,
  LIST_BILLING_PAYMENT_METHOD,
  LIST_BILLING_PAYMENT_METHOD_SUCCESS,
  LIST_BILLING_PAYMENT_METHOD_FAILURE,
  ADD_BILLING_PAYMENT_METHOD,
  ADD_BILLING_PAYMENT_METHOD_SUCCESS,
  ADD_BILLING_PAYMENT_METHOD_FAILURE,
  LIST_BILLING_ADDRESS_FAILURE,
  LIST_BILLING_ADDRESS_SUCCESS,
  LIST_BILLING_ADDRESS_STATUS,
  ADD_NEW_BILLING_ADDRESS_STATUS,
  ADD_NEW_BILLING_ADDRESS_FAILURE,
  ADD_NEW_BILLING_ADDRESS_SUCCESS,
  NEW_BILLING_ADDRESS_UPDATE_FAILURE,
  NEW_BILLING_ADDRESS_UPDATE_STATUS,
  NEW_BILLING_ADDRESS_UPDATE_SUCCESS,
  BILLING_PLAN_LIST_FAILURE,
  BILLING_PLAN_LIST_STATUS,
  BILLING_PLAN_LIST_SUCCESS,
  UPDATE_INITIAL_TEMPLATE_DATA,
  UPDATE_INITIAL_CANNED_DATA,
  UPDATE_INITIAL_ALL_AGENT_DATA,
  LIST_PUSH_DEVICE_SUCCESS,
  UPDATE_PUSH_DEVICE_SUCCESS,
  ADD_PUSH_DEVICE_SUCCESS,
  DETAILS_PUSH_DEVICE_STATUS,
  DELETE_PUSH_DEVICE_SUCCESS,
  UPDATE_PUSH_DEVICE_SOUND,
  ACTIVE_SUBSCRIPTION_LIST_DETAILS,
  ACTIVE_SUBSCRIPTION_LIST_SUCCESS,
  UPDATE_BUTTON_CLICKED,
  SYNC_TEMPLATE_MESSAGE,
  SYNC_TEMPLATE_MESSAGE_SUCCESS,
  SYNC_TEMPLATE_MESSAGE_FAILURE,
  ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS_EMPTY,
  FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
  UPDATE_WORKING_HOURS,
  FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
  UPDTAE_LIVE_CHAT_SETTINGS_CONFIG,
  UPDTAING_LIVE_CHAT_SETTINGS_CONFIG,
  FETCHING_LIVE_CHAT_SETTINGS,
  UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY,
  FETCH_ALL_TAG_CATEGORIES,
  ADD_NEW_TAG_CATEGORY,
  FETCH_PREDIFINED_TEMPLATES,
  UPDATE_TEMPLATE_MESSAGE,
  FETCH_BILLING_STATS,
  FETCH_BILLING_STATS_STATUS,
  UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY,
  OPT_OUT_KEYWORDS,
  UPDATING_OPT_OUT_CONFIG_DATA,
  UPDATE_OPT_OUT_CONFIG_DATA,
  UPDATE_OPT_IN_CONFIG_DATA,
  OPT_IN_KEYWORDS,
  GET_QUALITY_RATING,
  UPDATE_BUSINESS_ADMIN_DETAILS,
  FETCH_CONVERSATION_USAGE,
  FETCH_CONVERSATION_CREDIT_USAGE,
} from "modules/Manage/ManagePageActionTypes";
import { showNotification } from "util/utility";
import callChatApi from "../../util/chatApiCaller";
import isEmpty from "lodash/isEmpty";

export const updateInitialAllAgentData = (keyName, keyValue) => ({
  type: UPDATE_INITIAL_ALL_AGENT_DATA,
  keyName,
  keyValue,
});

export const fetchAllAgentDetails =
  (searchValue, createdData, data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ALL_AGENT_DETAILS,
      });
      let activeProjectId = data?.isProject
        ? data?.project_id
        : createdData?.isCreate
        ? createdData?.project_id
        : createdData?.isACreate
        ? createdData?.project_id
        : getState()?.app?.activeProjectId;
      const pageNum = getState().manage?.teamSetting?.pageNum;
      const pageSize = getState().manage?.teamSetting?.pageSize;
      const business_id = data?.isBusiness
        ? data?.business_id
        : createdData?.isCreate
        ? createdData?.business_id
        : getState().app?.activeProject?.business_id;
      let body = {
        ...(activeProjectId && {
          filters: {
            project_id: activeProjectId,
          },
        }),
        pageNum,
        pageSize,
      };
      if (searchValue) {
        body = {
          ...body,
          filters: {
            ...body.filters,
            name: searchValue,
          },
        };
      }

      const res =
        business_id &&
        (await callApiV2({
          method: "post",
          endpoint: `team-settings/business/${business_id}/all-agent`,
          body,
        }));
      dispatch({
        type: FETCH_ALL_AGENT_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AGENT_DETAILS_FAILURE,
      });
    }
  };

export const fetchAgentDetails = (id) => ({
  type: FETCH_AGENT_DETAILS,
  id,
});

export const agentDetailsUpdate = (keyName, payload) => ({
  type: UPDATE_AGENT_DETAILS,
  keyName,
  payload,
});

export const initialAgentDetails = () => ({
  type: INITIAL_AGENT_DETAILS,
});

export const addAgentDetails =
  (body, isSuperAdmin, isAdmin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_AGENT_DETAILS,
      });
      const business_id = isSuperAdmin
        ? body?.business_id
        : getState().app?.activeProject?.business_id;
      let project_id = isSuperAdmin
        ? body?.project_id
        : isAdmin
        ? body?.project_id
        : getState()?.app?.activeProjectId;
      //const business_id = "632f0128f4c795625de601e4";
      const res = await callApiV2({
        method: "post",
        endpoint: `team-settings/business/${business_id}/project/${project_id}/invite-agent`,
        body: {
          agent: body,
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Invite agent successfully");
        dispatch({
          type: ADD_AGENT_DETAILS_SUCCESS,
          payload: res?.data?.agent,
        });
        if (isSuperAdmin) {
          const data = {
            business_id: body?.business_id,
            project_id: body?.project_id,
            isCreate: true,
          };
          dispatch(fetchAllAgentDetails(null, data));
        } else if (isAdmin) {
          const data = {
            project_id: body?.project_id,
            isACreate: true,
          };
          dispatch(fetchAllAgentDetails(null, data));
        } else {
          dispatch(fetchAllAgentDetails());
        }
      } else if (res?.status === "Error") {
        showNotification("error", res?.data?.message);
        dispatch({
          type: AGENT_DETAILS_FAILURE,
        });
      }
    } catch (err) {
      dispatch({
        type: AGENT_DETAILS_FAILURE,
      });
    }
  };

export const updateAgentDetails = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_AGENT_DATA,
    });
    const business_id = getState().app?.activeProject?.business_id;
    //const business_id = "632f0128f4c795625de601e4";
    const res = await callApiV2({
      method: "post",
      endpoint: `team-settings/business/${business_id}/agent/${id}/update`,
      body: {
        agent: body,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Agent updated successfully");
      dispatch({
        type: UPDATE_AGENT_DATA_SUCCESS,
        payload: res?.data?.result?.updatedAgent,
      });
      dispatch(fetchAllAgentDetails());
    }
  } catch (err) {
    dispatch({
      type: AGENT_DETAILS_FAILURE,
    });
  }
};

export const deleteAgent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_AGENT_DETAILS,
    });
    const business_id = getState().app?.activeProject?.business_id;
    //const business_id = "632f0128f4c795625de601e4";
    const res = await callApiV2({
      method: "post",
      endpoint: `team-settings/business/${business_id}/agent/${id}/delete`,
    });
    if (res?.status === "Success") {
      showNotification("success", "Agent deleted successfully");
      dispatch(fetchAllAgentDetails());
      dispatch({
        type: DELETE_AGENT_DETAILS_SUCCESS,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: AGENT_DETAILS_FAILURE,
    });
  }
};

// Tags
export const updateInitialTagsData = (keyName, keyValue) => ({
  type: UPDATE_INITIAL_TAGS_DATA,
  keyName,
  keyValue,
});

export const fetchAllTagsDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_ALL_TAGS_DETAILS,
    });
    let activeProjectId =
      getState()?.app?.activeProjectId || localStorage.getItem("projectId");
    const pageNum = getState()?.manage?.tags?.pageNum;
    const pageSize = getState()?.manage?.tags?.pageSize;
    if (activeProjectId) {
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/tags/list`,
        body: {
          filters: {
            project_id: activeProjectId,
          },
          pageNum,
          pageSize,
        },
      });
      dispatch({
        type: FETCH_ALL_TAGS_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TAG_DETAILS_FAILURE,
    });
  }
};

export const fetchTagDetails = (id) => ({
  type: FETCH_TAG_DETAILS,
  id,
});

export const tagDetailsUpdate = (keyName, payload) => ({
  type: UPDATE_TAG_DETAILS,
  keyName,
  payload,
});

export const initialTagDetails = () => ({
  type: INITIAL_TAG_DETAILS,
});

export const addTagDetails = (body, cb) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TAG_DETAILS,
    });
    let activeProjectId = getState()?.app?.activeProjectId;
    if (activeProjectId) {
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/tags/new`,
        body: {
          tags: {
            ...body,
            project_id: activeProjectId,
          },
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Tag Created successfully");
        dispatch({
          type: ADD_TAG_DETAILS_SUCCESS,
          payload: res?.data.tag,
        });
        if (cb) {
          cb(res?.data.tag);
        }
        dispatch(fetchAllTagsDetails());
        dispatch(fetchAllTagCategories());
      } else if (res?.status === "Error") {
        showNotification("error", res?.data?.message);
        dispatch({
          type: TAG_DETAILS_FAILURE,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: TAG_DETAILS_FAILURE,
    });
  }
};

export const updateTagDetails = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TAG_DATA,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/tags/${id}/update`,
      body: {
        tags: body,
      },
      isErrorSuppressed: true,
    });
    if (res?.status === "Success") {
      showNotification("success", "Tag Edited Successfully");
      dispatch({
        type: UPDATE_TAG_DATA_SUCCESS,
        payload: res?.data,
      });
      dispatch(fetchAllTagsDetails());
    } else if (res?.status === "Error") {
      showNotification("error", res?.data?.message);
      dispatch({
        type: TAG_DETAILS_FAILURE,
      });
    }
  } catch (err) {
    dispatch({
      type: TAG_DETAILS_FAILURE,
    });
  }
};

export const deleteTag = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TAG_DETAILS,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/tags/${id}/remove`,
    });
    if (res?.status === "Success") {
      dispatch(fetchAllTagsDetails());
      showNotification("success", "Tag Removed Successfully");
      dispatch({
        type: DELETE_TAG_DETAILS_SUCCESS,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: TAG_DETAILS_FAILURE,
    });
  }
};

// Canned Message

export const updateInitialCannedData = (keyName, keyValue) => ({
  type: UPDATE_INITIAL_CANNED_DATA,
  keyName,
  keyValue,
});

export const fetchAllCannedMessageDetails =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ALL_CANNED_MESSAGE_DETAILS,
      });
      let activeProjectId = getState()?.app?.activeProjectId;

      const pageNum = getState().manage?.cannedMessages?.pageNum;
      const pageSize = getState().manage?.cannedMessages?.pageSize;

      if (activeProjectId) {
        const res = await callApiV2({
          method: "post",
          endpoint: `elevate/canned_message/list`,
          body: {
            project_id: activeProjectId,
            pageNum,
            pageSize,
          },
        });
        dispatch({
          type: FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: CANNED_MESSAGE_DETAILS_FAILURE,
      });
    }
  };

export const fetchCannedMessageDetails = (id) => ({
  type: FETCH_CANNED_MESSAGE_DETAILS,
  id,
});

export const cannedMessageDetailsUpdate = (keyName, payload) => ({
  type: UPDATE_CANNED_MESSAGE_DETAILS,
  keyName,
  payload,
});

export const initialCannedMessageDetails = () => ({
  type: INITIAL_CANNED_MESSAGE_DETAILS,
});

export const addCannedMessageDetails = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CANNED_MESSAGE_DETAILS,
    });
    let activeProjectId = getState()?.app?.activeProjectId;
    if (activeProjectId) {
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/canned_message/new`,
        body: {
          canned_message: {
            ...body,
            project_id: activeProjectId,
          },
        },
      });
      if (res?.status === "Success") {
        showNotification("success", "Canned message added successfully");
        dispatch({
          type: ADD_CANNED_MESSAGE_DETAILS_SUCCESS,
          payload: res?.data.canned_message,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CANNED_MESSAGE_DETAILS_FAILURE,
    });
  }
};

export const updateCannedMessageDetails = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CANNED_MESSAGE_DATA,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/canned_message/${id}/update`,
      body: {
        canned_message: body,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Canned message updated successfully");
      dispatch({
        type: UPDATE_CANNED_MESSAGE_DATA_SUCCESS,
        payload: res?.data?.canned_message,
      });
    }
  } catch (err) {
    dispatch({
      type: CANNED_MESSAGE_DETAILS_FAILURE,
    });
  }
};

export const deleteCannedMessage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CANNED_MESSAGE_DETAILS,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/canned_message/${id}/remove`,
    });
    if (res?.status === "Success") {
      showNotification("success", "Canned message deleted successfully");
      dispatch({
        type: DELETE_CANNED_MESSAGE_DETAILS_SUCCESS,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: CANNED_MESSAGE_DETAILS_FAILURE,
    });
  }
};

// Template

export const updateInitialTemplateData = (keyName, keyValue) => ({
  type: UPDATE_INITIAL_TEMPLATE_DATA,
  keyName,
  keyValue,
});

export const fetchAllTemplateMessageDetails =
  (sortBy, searchValue) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ALL_TEMPLATE_MESSAGE_DETAILS,
      });
      let activeProjectId = getState()?.app?.activeProjectId;
      const isSuperAdmin = localStorage.getItem("is_super_admin") === "true";
      const pageNum = getState().manage?.templateMessages?.pageNum;
      const pageSize = getState().manage?.templateMessages?.pageSize;
      if (activeProjectId || isSuperAdmin) {
        let body = {
          ...(activeProjectId && {
            filters: {
              project_id: activeProjectId,
            },
          }),
          pageNum,
          pageSize,
        };
        if (searchValue) {
          body = {
            ...body,
            filters: {
              ...body.filters,
              name: searchValue,
            },
          };
        }
        if (sortBy && sortBy !== "all") {
          body = {
            ...body,
            filters: {
              ...body.filters,
              status: sortBy,
            },
          };
        }
        const res = await callApiV2({
          method: "post",
          endpoint: `elevate/template-messages/list`,
          body,
        });
        if (res.status === "Success") {
          dispatch({
            type: FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: TEMPLATE_MESSAGE_DETAILS_FAILURE,
      });
    }
  };

export const fetchAllPredifinedTemplateMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PREDIFINED_TEMPLATES,
      status: "pending",
      data: [],
    });
    const pageNum = 1;
    const pageSize = "all";
    const body = {
      filters: {
        predifined: true,
      },
      pageNum,
      pageSize,
    };
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/template-messages/list`,
      body,
    });
    if (res.status === "Success") {
      dispatch({
        type: FETCH_PREDIFINED_TEMPLATES,
        status: "success",
        data: res?.data?.template_messagesList || [],
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_PREDIFINED_TEMPLATES,
      status: "failure",
      data: [],
    });
  }
};

export const syncTemplateMessage = (activeProjectId) => async (dispatch) => {
  try {
    dispatch({
      type: SYNC_TEMPLATE_MESSAGE,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `elevate/template-messages/status-sync?project_id=${activeProjectId}`,
    });
    if (res?.status === "Success") {
      showNotification("success", "Template message Sync successfully");
      dispatch(fetchAllTemplateMessageDetails());
      dispatch({
        type: SYNC_TEMPLATE_MESSAGE_SUCCESS,
      });
    }
  } catch (err) {
    dispatch({
      type: SYNC_TEMPLATE_MESSAGE_FAILURE,
    });
  }
};

export const fetchTemplateMessageDetails = (id) => ({
  type: FETCH_TEMPLATE_MESSAGE_DETAILS,
  id,
});

export const templateMessageDetailsUpdate = (keyName, payload) => ({
  type: UPDATE_TEMPLATE_MESSAGE_DETAILS,
  keyName,
  payload,
});

export const initialTemplateMessageDetails = () => ({
  type: INITIAL_TEMPLATE_MESSAGE_DETAILS,
});

export const updateButtonClicked = (val) => ({
  type: UPDATE_BUTTON_CLICKED,
  isSaveBtnClick: val,
});

export const addTemplateMessageDetails =
  (body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_TEMPLATE_MESSAGE_DETAILS,
      });
      let activeProjectId = getState()?.app?.activeProjectId;
      if (activeProjectId) {
        delete body?.tag;
        delete body?.icon_url;
        const res = await callApiV2({
          method: "post",
          endpoint: `elevate/template-messages/new`,
          body: {
            template_messages: {
              ...body,
              project_id: activeProjectId,
            },
          },
          isErrorSuppressed: true,
        });
        if (!isEmpty(res?.data?.template_messages?.kaleyraResponse?.error)) {
          showNotification(
            "error",
            res?.data?.template_messages?.kaleyraResponse?.error?.message ||
              "Something went wrong.."
          );
          dispatch({
            type: ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
          });
        } else if (
          res?.status === "Success" &&
          res?.data?.template_messages?.savedTemplate
        ) {
          showNotification("success", "Template message created successfully");
          dispatch(fetchAllTemplateMessageDetails());
          dispatch({
            type: ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
            payload: res?.data?.template_messages?.savedTemplate,
          });
        } else {
          showNotification(
            "error",
            res?.data?.message || "Something went wrong.."
          );
          dispatch({
            type: ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
      });
    }
  };

export const addTemplateMessageDetailsForSuperAdmin =
  (body) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_TEMPLATE_MESSAGE_DETAILS,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/template-messages/super-admin/new`,
        body: {
          template_messages: {
            ...body,
            predifined: true,
          },
        },
        isErrorSuppressed: true,
      });
      if (
        res?.status === "Success" &&
        res?.data?.template_messages?.savedTemplate
      ) {
        showNotification("success", "Template message created successfully");
        dispatch(fetchAllTemplateMessageDetails());
        dispatch({
          type: ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
          payload: res?.data?.template_messages?.savedTemplate,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Something went wrong.."
        );
        dispatch({
          type: ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE,
      });
    }
  };

export const updateAndPublishTemplateMessageDetails =
  (id, body) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_TEMPLATE_MESSAGE_DATA,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/template-messages/${id}/update-and-publish`,
        body: {
          template_messages: body,
        },
      });
      if (res?.status === "Success") {
        showNotification("success", "Updated Template message details");
        dispatch({
          type: UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS,
          payload: res?.data?.template_messages,
        });
      }
    } catch (err) {
      dispatch({
        type: TEMPLATE_MESSAGE_DETAILS_FAILURE,
      });
    }
  };

export const updateTemplateMessageDetails = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TEMPLATE_MESSAGE_DATA,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/template-messages/${id}/update`,
      body: {
        template_messages: body,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Updated Template message details");
      dispatch({
        type: UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS,
        payload: res?.data?.template_messages,
      });
    }
  } catch (err) {
    dispatch({
      type: TEMPLATE_MESSAGE_DETAILS_FAILURE,
    });
  }
};

export const deleteTemplateMessage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEMPLATE_MESSAGE_DETAILS,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/template-messages/${id}/remove`,
    });
    if (res?.status === "Success") {
      showNotification("success", "Template message deleted successfully");
      dispatch(fetchAllTemplateMessageDetails());
      dispatch({
        type: DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: TEMPLATE_MESSAGE_DETAILS_FAILURE,
    });
  }
};

//user-attribute
export const updateInitialUserAttributeData = (keyName, keyValue) => ({
  type: UPDATE_INITIAL_USER_ATTRIBUTE_DATA,
  keyName,
  keyValue,
});

export const fetchAllUserAttributeDetails =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ALL_USER_ATTRIBUTE_DETAILS,
      });
      let activeProjectId = getState()?.app?.activeProjectId;
      const pageNum = getState()?.manage?.userAttributes?.pageNum;
      const pageSize = getState()?.manage?.userAttributes?.pageSize;
      if (activeProjectId) {
        const res = await callApiV2({
          method: "post",
          endpoint: `elevate/v1/project/${activeProjectId}/customer-attributes`,
          body: {
            // project_id: activeProjectId,
            pageNum,
            pageSize: "all",
          },
        });
        dispatch({
          type: FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS,
          payload: res?.data?.customer_attributes,
        });
      }
    } catch (err) {
      dispatch({
        type: USER_ATTRIBUTE_DETAILS_FAILURE,
      });
    }
  };

export const fetchUserAttributeDetails = (id) => ({
  type: FETCH_USER_ATTRIBUTE_DETAILS,
  id,
});

export const userAttributeDetailsUpdate = (keyName, payload) => ({
  type: UPDATE_USER_ATTRIBUTE_DETAILS,
  keyName,
  payload,
});

export const initialUserAttributeDetails = () => ({
  type: INITIAL_USER_ATTRIBUTE_DETAILS,
});

export const addUserAttributeDetails = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_USER_ATTRIBUTE_DETAILS,
    });
    let activeProjectId = getState().app?.activeProjectId;
    const res =
      activeProjectId &&
      (await callApiV2({
        method: "post",
        endpoint: `elevate/v1/project/${activeProjectId}/customer-attribute/new`,
        body: {
          attributes: body,
        },
      }));
    if (res?.status === "Success") {
      showNotification("success", "Attribute created successfully");
      dispatch({
        type: FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS,
        payload: res?.data?.customer_attributes?.[0] ?? {},
      });
    }
  } catch (err) {
    dispatch({
      type: USER_ATTRIBUTE_DETAILS_FAILURE,
    });
  }
};

export const updateUserAttributeDetails =
  (id, body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_USER_ATTRIBUTE_DATA,
      });
      let activeProjectId = getState().app?.activeProjectId;
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/v1/project/${activeProjectId}/customer-attribute/update`,
        body: {
          attributes: {
            id,
            is_deleted: false,
            name: body?.name,
            data_type: body?.data_type,
          },
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Attribute Updated Successfully");
        dispatch({
          type: UPDATE_USER_ATTRIBUTE_DATA_SUCCESS,
          payload: res?.data?.customer_attributes?.[0],
        });
      } else if (res?.status === "Error") {
        showNotification("error", res?.data?.message);
        dispatch({
          type: USER_ATTRIBUTE_DETAILS_FAILURE,
        });
      }
    } catch (err) {
      dispatch({
        type: USER_ATTRIBUTE_DETAILS_FAILURE,
      });
    }
  };

export const deleteUserAttribute = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_USER_ATTRIBUTE_DETAILS,
    });
    let activeProjectId = getState().app?.activeProjectId;
    const activeAttributesList =
      getState().manage?.userAttributes?.activeAttributesList;
    const attr = activeAttributesList.filter((item) => item?._id === id)?.[0];
    const res =
      activeProjectId &&
      (await callApiV2({
        method: "post",
        endpoint: `elevate/v1/project/${activeProjectId}/customer-attribute/update`,
        body: {
          attributes: {
            id,
            is_deleted: true,
            name: attr?.name,
            data_type: attr?.data_type,
          },
        },
      }));
    if (res?.status === "Success") {
      showNotification("success", "Attribute Deleted successfully");
      dispatch({
        type: DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_USER_ATTRIBUTE_DETAILS,
    });
  }
};
export const fetchBillingDateRange = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_DATE_RANGE_STATUS,
      isFetchingBillingDateRange: true,
    });
    let projectId = window.localStorage.getItem("projectId");
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/project/${projectId}/payments/billing-date-ranges`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_DATE_RANGE,
        dateRange: res?.data?.billingDates,
        isFetchingBillingDateRange: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_DATE_RANGE_STATUS,
      isFetchingBillingDateRange: false,
    });
  }
};
export const fetchBillingAmount = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_CREDIT_AMOUNT_STATUS,
      isFetchingBillingAmount: true,
    });
    let projectId = window.localStorage.getItem("projectId");
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/project/${projectId}/payments/credit-history`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_CREDIT_AMOUNT,
        creditData: res?.data,
        isFetchingBillingAmount: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_CREDIT_AMOUNT_STATUS,
      isFetchingBillingAmount: false,
    });
  }
};

export const fetchBillingPlansList = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_PAYMANT_PLANS_LIST_STATUS,
      isFetchingBillingPlansList: true,
    });
    let projectId = window.localStorage.getItem("projectId");
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/projects/${projectId}/payments/plans`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_PAYMANT_PLANS_LIST,
        plansList: res?.data,
        isFetchingBillingPlansList: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_PAYMANT_PLANS_LIST_STATUS,
      isFetchingBillingPlansList: false,
    });
  }
};

export const fetchUserCountStats = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_USER_COUNT_STATUS,
      isFetchingUserCountStats: true,
    });
    let projectId = window.localStorage.getItem("projectId");
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/project/${projectId}/chats/unique-user/count?from=2022-10-20&to=2022-11-19`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_USER_COUNT_STATS,
        userCountList: res?.data,
        isFetchingUserCountStats: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_USER_COUNT_STATUS,
      isFetchingUserCountStats: false,
    });
  }
};

export const fetchNotificationCount = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_NOTIFICATION_COUNT_STATUS,
      isFetchingNotificationCount: true,
    });
    let projectId = window.localStorage.getItem("projectId");
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/project/${projectId}/chats/notification/count?month=july&year=2021`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_NOTIFICATION_COUNT,
        notificationLists: res?.data,
        isFetchingNotificationCount: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_NOTIFICATION_COUNT_STATUS,
      isFetchingNotificationCount: false,
    });
  }
};
export const fetchNewBusiness = (businessData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/business/new`,
      body: {
        business: businessData,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Business Added Successfully");
      dispatch({
        type: FETCH_NEW_BUSINESS,
        businessData: res?.data,
        isFetchingNewBusiness: false,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: false,
    });
  }
};

//analytics
export const fetchAnalyticsOverviewStats =
  (body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ANALYTICS_OVERVIEW_STATS,
        isFetching: false,
      });
      let activeProjectId = getState()?.app?.activeProjectId;
      if (activeProjectId) {
        const res = await callChatApi(
          `api/v1/analytics/project/${activeProjectId}/overview-stats`,
          "POST",
          body
        );
        if (res.status === "Success") {
          dispatch({
            type: FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS,
            overviewDailyStats: res?.data?.dailyStats ?? [],
            overviewTotalStats: res?.data?.totalStats ?? [],
            isFetching: true,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchAnalyticsAgentPerformanceStats =
  (body, agentId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS,
        isFetching: true,
      });
      let activeProjectId = getState()?.app?.activeProjectId;
      if (activeProjectId) {
        const res = await callChatApi(
          `api/v1/analytics/project/${activeProjectId}/agent/${agentId}/stats`,
          "POST",
          body
        );
        if (res.status === "Success") {
          dispatch({
            type: FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS,
            agentDailyStats: res?.data?.dailyStats ?? [],
            agentTotalStats: res?.data?.totalStats ?? [],
            agentDailyStatusData:
              res?.data?.getProjectAgentStatsDayByDayByIntervenedAndClosed ??
              [],
            isFetching: false,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE,
        isFetching: false,
      });
    }
  };

export const downloadAnalyticExcelData = async (activeProjectId, filters) => {
  try {
    const response = await callChatApi(
      `api/v1/analytics/project/${activeProjectId}/report/csv`,
      "post",
      { ...filters }
    );
    return response;
  } catch (error) {
    return null;
  }
};
export const analyticsDetailsUpdate = (keyName, keyValue) => ({
  type: ANALYTICS_BODY_DETAILS,
  keyName,
  keyValue,
});

export const analyticsAgentPerformanceDetailsUpdate = (keyName, keyValue) => ({
  type: ANALYTICS_AGENT_PERFORMANCE_DETAILS,
  keyName,
  keyValue,
});

//Billings

export const listPaymentMethodBillings = () => async (dispatch) => {
  try {
    dispatch({
      type: LIST_BILLING_PAYMENT_METHOD,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/billing/v1/project/632edf29ae935d4f09dde4f0/payments/paymentmethods/list`,
      body: {
        pageSize: 100,
      },
    });
    if (res.status === "Success") {
      dispatch({
        type: LIST_BILLING_PAYMENT_METHOD_SUCCESS,
        paymentList: res?.data?.payment_methods?.list ?? [],
        paymentListStats: res?.data?.payment_methods?.count ?? [],
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: LIST_BILLING_PAYMENT_METHOD_FAILURE,
      isFetching: false,
    });
  }
};

export const addPaymentMethodBillings = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_BILLING_PAYMENT_METHOD,
      isFetching: false,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `elevate/billing/v1/project/632edf29ae935d4f09dde4f0/payments/paymentmethod/add`,
      body: {
        payment_method: body,
      },
    });
    if (res.status === "Success") {
      dispatch({
        type: ADD_BILLING_PAYMENT_METHOD_SUCCESS,
        paymentList: res?.data?.payment_methods,
        isFetching: true,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_BILLING_PAYMENT_METHOD_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchBillingaddressList =
  (project_id, address_type) => async (dispatch) => {
    try {
      dispatch({
        type: LIST_BILLING_ADDRESS_STATUS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/billing/v1/project/${project_id}/billing/addresses/list`,
        body: {
          pageSize: 10,
          filters: {
            address_type,
          },
        },
      });
      if (res.status === "Success") {
        if (address_type === "billing") {
          dispatch({
            type: LIST_BILLING_ADDRESS_SUCCESS,
            billing_address_List: res?.data?.billing_addresses?.list,
            billing_address_List_Count: res?.data?.billing_addresses?.count,
            isFetching: false,
            address_type,
          });
        }

        if (address_type === "shipping") {
          dispatch({
            type: LIST_BILLING_ADDRESS_SUCCESS,
            shipping_address_List: res?.data?.billing_addresses?.list,
            shipping_address_List_Count: res?.data?.billing_addresses?.count,
            isFetchingShippingAddress: false,
            address_type,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: LIST_BILLING_ADDRESS_FAILURE,
        isFetching: false,
      });
    }
  };

export const addNewBillingaddress =
  (activeProjectId, body, address_type) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_NEW_BILLING_ADDRESS_STATUS,
        isFetching: true,
      });
      body["address_type"] = address_type;
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/billing/v1/project/${activeProjectId}/billing/addresses/add`,
        body: {
          billing_address: body,
        },
      });
      if (res.status === "Success") {
        showNotification("success", "Address updated successfully");
        if (address_type === "billing") {
          dispatch({
            type: ADD_NEW_BILLING_ADDRESS_SUCCESS,
            billing_address: res?.data?.billing_address,
            isFetching: false,
            address_type,
          });
        } else if (address_type === "shipping") {
          dispatch({
            type: ADD_NEW_BILLING_ADDRESS_SUCCESS,
            shipping_address: res?.data?.billing_address,
            isFetchingShippingAddress: false,
            address_type,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: ADD_NEW_BILLING_ADDRESS_FAILURE,
        isFetching: false,
      });
    }
  };

export const billingAddressUpdate =
  (project_id, body, address_type) => async (dispatch) => {
    try {
      console.log("update action type", address_type);
      dispatch({
        type: NEW_BILLING_ADDRESS_UPDATE_STATUS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `elevate/billing/v1/project/${project_id}/billing/addresses/${body._id}/update`,
        body: {
          billing_address: body,
        },
      });
      if (res.status === "Success") {
        showNotification("success", "Address updated successfully");
        if (address_type === "billing") {
          dispatch({
            type: NEW_BILLING_ADDRESS_UPDATE_SUCCESS,
            billing_address: res?.data?.billing_address,
            isFetching: false,
            address_type,
          });
        } else if (address_type === "shipping") {
          dispatch({
            type: NEW_BILLING_ADDRESS_UPDATE_SUCCESS,
            shipping_address: res?.data?.billing_address,
            isFetchingShippingAddress: false,
            address_type,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: NEW_BILLING_ADDRESS_UPDATE_FAILURE,
        isFetching: false,
      });
    }
  };

export const getBillingPlansList = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_PLAN_LIST_STATUS,
      isFetching: true,
    });
    let res = await callApiV2({
      method: "get",
      endpoint: `elevate/billing/v1/billing/plans`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: BILLING_PLAN_LIST_SUCCESS,
        activePlansList: res?.data?.plans?.list,
        activePlansListCount: res?.data?.plans?.count,
        isFetching: false,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_PLAN_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const getActiveSubscriptionList = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVE_SUBSCRIPTION_LIST_DETAILS,
      isActiveSubscriptionList: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/billing/v1/project/${projectId}/payments/subscriptions/list`,
      body: {
        pageSize: 100,
        filters: {
          status: "active",
        },
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: ACTIVE_SUBSCRIPTION_LIST_SUCCESS,
        payload: res?.data?.subscriptions?.list || [],
        isActiveSubscriptionList: false,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTIVE_SUBSCRIPTION_LIST_DETAILS,
      isActiveSubscriptionList: false,
    });
  }
};

// Push Device

export const getPushDeviceList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: true,
    });
    const activeProjectId = getState()?.app?.activeProjectId;
    const agentId = getState()?.app?.user?._id;
    let res =
      activeProjectId &&
      (await callApiV2({
        method: "post",
        endpoint: `elevate/push_notification/list`,
        body: {
          filters: {
            project_id: activeProjectId,
            agent_id: agentId,
          },
        },
      }));
    if (res?.status === "Success") {
      dispatch({
        type: LIST_PUSH_DEVICE_SUCCESS,
        list: res?.data?.list ?? [],
        count: res?.data?.count ?? 0,
        isFetching: false,
      });
    }
  } catch (error) {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: false,
    });
  }
};

export const addPushDevice = (key) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: true,
    });
    const activeProjectId = getState()?.app?.activeProjectId;
    const agentId = getState()?.app?.user?._id;
    const deviceCount = getState()?.manage?.pushDevice?.deviceCount;
    const deviceId = `device_${agentId}_${(deviceCount || 0) + 1}`;
    const deviceName = window.navigator.userAgent;
    let res =
      activeProjectId &&
      (await callApiV2({
        method: "post",
        endpoint: `elevate/push_notification/new`,
        body: {
          details: {
            project_id: activeProjectId,
            agent_id: agentId,
            device_id: deviceId,
            device_key: key,
            device_name: deviceName,
          },
        },
      }));
    if (res?.status === "Success") {
      dispatch({
        type: ADD_PUSH_DEVICE_SUCCESS,
        details: res?.data?.details ?? {},
        isFetching: false,
      });
    }
  } catch (error) {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: false,
    });
  }
};

export const UpdatePushDevice = (id, flag) => async (dispatch) => {
  try {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/push_notification/${id}/update`,
      body: {
        details: {
          enabled: flag,
        },
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: UPDATE_PUSH_DEVICE_SUCCESS,
        details: res?.data ?? {},
        isFetching: false,
      });
    }
  } catch (error) {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: false,
    });
  }
};

export const deletePushDevice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/push_notification/${id}/remove`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: DELETE_PUSH_DEVICE_SUCCESS,
        id,
        isFetching: false,
      });
    }
  } catch (error) {
    dispatch({
      type: DETAILS_PUSH_DEVICE_STATUS,
      isFetching: false,
    });
  }
};

export const updateSoundPreference = (status) => async (dispatch) => {
  dispatch({
    type: UPDATE_PUSH_DEVICE_SOUND,
    payload: status,
  });
};

export const businessDetailsUpdate = (keyName, keyValue, adminFlag) => ({
  type: UPDATE_BUSINESS_DETAILS,
  keyName,
  keyValue,
  // adminFlag,
});
export const updateAdminDetails = (keyName, keyValue, adminFlag) => ({
  type: UPDATE_BUSINESS_ADMIN_DETAILS,
  keyName,
  keyValue,
  // adminFlag,
});

export const newBusinessV2 = (businessData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/business/v2/new`,
      body: {
        business: businessData,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Business Added Successfully");
      dispatch({
        type: FETCH_NEW_BUSINESS,
        businessData: res?.data,
        isFetchingNewBusiness: false,
      });
      dispatch({
        type: UPDATE_BUSINESS_DETAILS_EMPTY,
      });
    }
  } catch (error) {
    showNotification("error", res?.data?.message);
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: false,
    });
  }
};

export const editBusinessV2 = (businessData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/business/v2/edit`,
      body: {
        business: businessData,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Business Updated Successfully");
      dispatch({
        type: FETCH_NEW_BUSINESS,
        businessData: res?.data,
        isFetchingNewBusiness: false,
      });
      dispatch({
        type: UPDATE_BUSINESS_DETAILS_EMPTY,
      });
    }
  } catch (error) {
    showNotification("error", res?.data?.message);
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: false,
    });
  }
};

export const deleteBusinessV2 = (businessData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/business/v2/change`,
      body: {
        business: businessData,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Business Deleted Successfully");
      dispatch({
        type: FETCH_NEW_BUSINESS,
        businessData: res?.data,
        isFetchingNewBusiness: false,
      });
      dispatch({
        type: UPDATE_BUSINESS_DETAILS_EMPTY,
      });
    }
  } catch (error) {
    showNotification("error", res?.data?.message);
    dispatch({
      type: FETCH_NEW_BUSINESS_STATUS,
      isFetchingNewBusiness: false,
    });
  }
};

export const fetchLiveChatSettings = (projectId) => async (dispatch) => {
  dispatch({
    type: FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
    isFetching: true,
  });
  dispatch({
    type: FETCHING_LIVE_CHAT_SETTINGS,
    isFetching: true,
  });
  const response = await callChatApi(
    `v1/project/${projectId}/chat-setting`,
    "get"
  );
  const data = response.data;

  if (data) {
    const workingHours = data?.working_hours ?? [];
    const workingConfig = data?.working_config ?? {};
    const optOutConfig = data?.opted_out_config ?? {};
    const optInConfig = data?.opted_in_config ?? {};
    const nonWorkingConfig = data?.non_working_config ?? {};
    const checkedData = workingHours.map((item) => {
      return {
        ...item,
        isChecked: Boolean(item.start_time || item.end_time),
      };
    });
    if (checkedData.length) {
      dispatch({
        type: FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
        workingHours: {
          data: checkedData,
          isFetching: false,
        },
      });
    } else {
      dispatch({
        type: FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
        isFetching: false,
      });
    }
    dispatch({
      type: UPDTAE_LIVE_CHAT_SETTINGS_CONFIG,
      data: {
        data: workingConfig,
        isFetching: false,
      },
      dataType: "workingConfig",
    });
    dispatch({
      type: UPDTAE_LIVE_CHAT_SETTINGS_CONFIG,
      data: {
        data: nonWorkingConfig,
        isFetching: false,
      },
      dataType: "nonWorkingConfig",
    });
    dispatch({
      type: UPDATE_OPT_OUT_CONFIG_DATA,
      data: {
        data: optOutConfig,
        isFetching: false,
      },
      dataType: "optOutConfig",
    });
    dispatch({
      type: UPDATE_OPT_OUT_CONFIG_DATA,
      data: {
        data: optInConfig,
        isFetching: false,
      },
      dataType: "optInConfig",
    });
    dispatch({
      type: UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY,
      isAutomateReply: data?.is_automate_reply,
      isAutomateReplyOffHour: data?.is_automate_reply_off_hours,
    });
    dispatch({
      type: UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY,
      isOptedOutAutomateReply: data?.is_opted_out_automate_reply,
      isOptedOut: data?.is_opted_out,
      isOptedIn: data?.is_opted_in,
      isOptedInAutomateReply: data?.is_opted_in_automate_reply,
    });
    dispatch({
      type: OPT_OUT_KEYWORDS,
      optOutKeywords: data?.opted_out_keywords,
    });
    dispatch({
      type: OPT_IN_KEYWORDS,
      optInKeywords: data?.opted_in_keywords,
    });
  } else {
    dispatch({
      type: FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
      isFetching: false,
    });
    dispatch({
      type: FETCHING_LIVE_CHAT_SETTINGS,
      isFetching: false,
    });
  }
};

export const updateWorkingHours = (wh) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
    isFetching: true,
  });
  const activeProjectId = getState()?.app?.activeProjectId;
  const agentId = getState()?.app?.user?._id;
  const response = await callChatApi("v1/project/chat-setting", "post", {
    working_hours: wh,
    project_id: activeProjectId,
    active_agent_id: agentId,
  });
  if (response?.data) {
    const checkedData = response.data.working_hours.map((item) => {
      return {
        ...item,
        isChecked: Boolean(item.start_time || item.end_time),
      };
    });
    dispatch({
      type: FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
      workingHours: {
        data: checkedData,
        isFetching: false,
      },
    });
  } else {
    dispatch({
      type: FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS,
      isFetching: false,
    });
  }
};

export const updateLiveChatSettingsConfig =
  (configData) => async (dispatch) => {
    const dataType = configData?.is_working_config
      ? "workingConfig"
      : "nonWorkingConfig";
    dispatch({
      type: UPDTAING_LIVE_CHAT_SETTINGS_CONFIG,
      isFetching: true,
      dataType,
    });
    const response = await callChatApi(
      "v1/project/chat-setting_automate_on_off_config",
      "post",
      configData
    );
    if (response.data) {
      showNotification("success", "Configure Message Saved Successfully");
      const workingConfig =
        (configData?.is_working_config
          ? response.data?.working_config
          : response.data?.non_working_config) || {};
      dispatch({
        type: UPDTAE_LIVE_CHAT_SETTINGS_CONFIG,
        data: {
          data: workingConfig,
          isFetching: false,
        },
        dataType,
      });
    } else {
      showNotification("error", "Failed to Save Configure Message.");
      dispatch({
        type: UPDTAING_LIVE_CHAT_SETTINGS_CONFIG,
        isFetching: false,
        dataType,
      });
    }
  };

export const updateAutomateReply = (data) => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callChatApi(
    "v1/project/chat-setting_automate_on_off",
    "post",
    data
  );
  if (response.data) {
    if (data.is_automate_reply) {
      showNotification(
        "success",
        "Welcome messsage configuration successfully updated"
      );
    } else {
      showNotification(
        "success",
        "Off Hours configuration successfully updated"
      );
    }
    dispatch(fetchLiveChatSettings(activeProjectId));
  }
};

export const fetchAllTagCategories = () => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callApiV2({
    method: "get",
    endpoint: `elevate/tags/project/${activeProjectId}/tag/category/list`,
  });
  if (response.data?.list) {
    dispatch({
      type: FETCH_ALL_TAG_CATEGORIES,
      tagCategories: response.data?.list,
    });
  } else {
    showNotification(
      "error",
      "Something went wrong while fetching tag categories.dd"
    );
  }
};

export const addNewTagCategory = (name) => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callApiV2({
    method: "post",
    endpoint: `elevate/project/${activeProjectId}/tag/category`,
    body: {
      name,
    },
  });
  if (response.data) {
    dispatch(fetchAllTagCategories());
    dispatch({
      type: ADD_NEW_TAG_CATEGORY,
      selectedTagCategory: response.data.tagCategory._id,
    });
    showNotification("success", "New tag category added successfully");
  } else {
    showNotification(
      "error",
      "Something went wrong while creating tag category"
    );
  }
};

export const updateSelectedTemplateMessage = (template) => (dispatch) => {
  dispatch({
    type: UPDATE_TEMPLATE_MESSAGE,
    data: template,
  });
};

export const fetchBillingStats = (start_date, end_date) => async (dispatch) => {
  dispatch({
    type: FETCH_BILLING_STATS_STATUS,
    status: "pending",
  });
  try {
    const projectId = localStorage.getItem("projectId");
    const response = await callChatApi(
      `api/v1/analytics/project/${projectId}/chat-stats`,
      "post",
      {
        start_date,
        end_date,
      }
    );
    if (response.status === "Success") {
      dispatch({
        type: FETCH_BILLING_STATS,
        status: "success",
        data: response.data?.result || {},
      });
    } else {
      dispatch({
        type: FETCH_BILLING_STATS_STATUS,
        status: "error",
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_BILLING_STATS_STATUS,
      status: "error",
    });
  }
};

export const fetchConversationCreditUsage =
  (start_date, end_date) => async (dispatch) => {
    const project_id = localStorage.getItem("projectId");
    try {
      const response = await callApiV2({
        endpoint: `elevate/project/${project_id}/getConversationCreditAndUsageStats`,
        method: "post",
        body: {
          project_id,
          start_date,
          end_date,
        },
      });
      if (response.status === "Success") {
        dispatch({
          type: FETCH_CONVERSATION_CREDIT_USAGE,
          data: response?.data || [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const fetchConversationUsage =
  (start_date, end_date) => async (dispatch) => {
    const project_id = localStorage.getItem("projectId");
    try {
      const response = await callApiV2({
        endpoint: `elevate/project/${project_id}/getConversationUsage`,
        method: "post",
        body: {
          project_id,
          start_date,
          end_date,
        },
      });
      if (response.status === "Success") {
        dispatch({
          type: FETCH_CONVERSATION_USAGE,
          data: response?.data || [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const apiCampaignOptOut = (data, key) => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callChatApi(
    "v1/project/chat-setting_opted_out_on_off",
    "post",
    data
  );
  if (response.data) {
    if (key === "optOut") {
      showNotification("success", "Opt out Setting updated");
    }
    if (key === "optIn") {
      showNotification("success", "Opt in Setting updated");
    }
    dispatch(fetchLiveChatSettings(activeProjectId));
  }
};

export const automateReplyOptOut = (data) => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callChatApi(
    "v1/project/chat-setting_opted_out_automate_reply_on_off",
    "post",
    data
  );
  if (response.data) {
    if (data) {
      showNotification("success", "Automate reply setting updated");
    }
    dispatch(fetchLiveChatSettings(activeProjectId));
  }
};

export const chatOptedKeyWords = (data, type) => async (dispatch, getState) => {
  const activeProjectId = getState()?.app?.activeProjectId;
  const response = await callChatApi(
    "v1/project/chat-opted_out_keywords",
    "post",
    data
  );
  if (response.data) {
    if (type === "optOut") {
      showNotification("success", "Opt out Keywords updated");
    } else if (type === "optIn") {
      showNotification("success", "Opt in Keywords updated");
    } else {
      showNotification("warning", "Something went wrong");
    }
    dispatch(fetchLiveChatSettings(activeProjectId));
  }
};

export const updateOptOutSettingConfig =
  (configData) => async (dispatch, getState) => {
    const activeProjectId = getState()?.app?.activeProjectId;
    const dataType = configData?.flag ? "optOutConfig" : "optInConfig";
    dispatch({
      type: UPDATING_OPT_OUT_CONFIG_DATA,
      isFetching: true,
      dataType,
    });
    const response = await callChatApi(
      "v1/project/chat-setting_opted_out_config",
      "post",
      configData
    );
    if (response.data) {
      showNotification("success", "Configure Message Saved Successfully");
      const optOutConfig =
        (configData?.flag
          ? response.data?.opted_out_config
          : response.data?.opted_in_config) || {};
      dispatch({
        type: UPDATING_OPT_OUT_CONFIG_DATA,
        data: {
          data: optOutConfig,
          isFetching: false,
        },
        dataType,
      });
      dispatch(fetchLiveChatSettings(activeProjectId));
    } else {
      showNotification("error", "Failed to Save Configure Message.");
      dispatch({
        type: UPDATING_OPT_OUT_CONFIG_DATA,
        isFetching: false,
        dataType,
      });
    }
  };

export function phoneNumberQualityRating(projectId) {
  return (dispatch) => {
    callChatApi(
      `v1/project/${projectId}/fetchPhoneNumberQualityRating`,
      "get"
    ).then((res) => {
      if (res) {
        dispatch({
          type: GET_QUALITY_RATING,
          qualityRating: res?.quality_rating,
        });
      }
    });
  };
}

export const forgotPassword = (phone_number) => async (dispatch, getState) => {
  let body = {
    phone_number: phone_number,
  };
  const response = await callApiV2({
    method: "post",
    endpoint: `team-settings/find/user`,
    body,
  });
  if (response.status === "Success") {
    showNotification("success", "OTP sent Successfully");
    return response;
  }
  if (response.status === "Error") {
    return response;
  }
};

export const getOTP = (otp) => async (dispatch, getState) => {
  let body = {
    otp: otp,
  };
  const response = await callApiV2({
    method: "post",
    endpoint: `team-settings/verify/otp`,
    body,
  });
  if (response.status === "Success") {
    showNotification("success", "OTP verified successfully");
    return response;
  }
  if (response.status === "Error") {
    return response;
  }
};

export const submitPassword =
  (password, phone_number) => async (dispatch, getState) => {
    let body = {
      local_password: password,
      phone_number: phone_number,
    };
    const response = await callApiV2({
      method: "post",
      endpoint: `team-settings/reset/pasword`,
      body,
    });
    if (response.status === "Success") {
      showNotification("success", "password reset successfully");
      return response;
    }
    if (response.status === "Error") {
      return response;
    }
  };
