export const CREATE_RAZORPAY_ORDER = 'CREATE_RAZORPAY_ORDER';
export const CREATE_RAZORPAY_ORDER_STATUS = 'CREATE_RAZORPAY_ORDER_STATUS';
export const CLEAR_RAZORPAY_ORDER = 'CLEAR_RAZORPAY_ORDER';
export const FETCH_PENDING_INVOICES = 'FETCH_PENDING_INVOICES';
export const CREATE_NEW_SUBSCRIPTION = 'CREATE_NEW_SUBSCRIPTION';
export const CREATE_NEW_SUBSCRIPTION_STATUS = 'CREATE_NEW_SUBSCRIPTION_STATUS';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_PAYMENT_METHOD_STATUS = 'FETCH_PAYMENT_METHOD_STATUS';
export const CONFIRM_SUBSCRIPTION_PAYMENT_STATUS = 'CONFIRM_SUBSCRIPTION_PAYMENT_STATUS';
export const CONFIRM_SUBSCRIPTION_PAYMENT = 'CONFIRM_SUBSCRIPTION_PAYMENT';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_STATUS = 'FETCH_INVOICES_STATUS';
export const BUY_CONVERSATION_CREDIT = 'BUY_CONVERSATION_CREDIT';
export const BUY_CONVERSATION_CREDIT_STATUS = 'BUY_CONVERSATION_CREDIT_STATUS';
export const CONFIRM_CONVERSION_CREDIT = 'CONFIRM_CONVERSION_CREDIT';
export const CONFIRM_CONVERSION_CREDIT_STATUS = 'CONFIRM_CONVERSION_CREDIT_STATUS';
export const CLEAR_CONVERSATION_CREDIT = 'CLEAR_CONVERSATION_CREDIT';