import styled from "styled-components";
import { space, layout, typography, border } from "styled-system";

export const EditButton = styled.button`
  color: #3a11c5;
  font-size: 16px;

  background-color: transparent;
  border: none;
  line-height: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    font-size: 14px;
  }

  ${typography}
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.theme.colors.white};

  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${space}
  ${border}
  ${layout}
`;

export const StyledListBottomDropdown = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 100%;
  padding-bottom: 100px;
  background-color: ${(props) => props.theme.colors.white};

  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ${space}
  ${layout}
`;

export const StyledItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {

  }

  & > h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 1;
    margin-bottom: 10px;
    word-break: break-word;

    @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
      font-weight: 500;
    }
  }

  & > p {
    margin: 0;
    font-weight: 400;
    color: ${(props) => props.theme.colors.darkGrey["60"]};
    line-height: 1;
    word-break: break-word;
    font-size: 12px;

    @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
      font-size: 14px;
    }
  }
`;

export const InitialIcon = styled.p`
  background-color: #8870d4;
  font-size: 14px;
  font-weight: 500;
  padding: 11px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    height: 36px;
    width: 36px;
    font-size: 18px;
  }
`;
