/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { ErrorBoundary } from "@sentry/react";
import Error404Page from "components/Error404Page";
import CacheBuster from "./CacheBuster";
import App from "./App";
import BasicLoader from "./components/Loaders/BasicLoader";

class MegaApp extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return <BasicLoader />;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return <App />;
        }}
      </CacheBuster>
    );
  }
}

export default MegaApp;
