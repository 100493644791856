import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ErrorMessage from "konnekt/ErrorMessage";
import Icon from "konnekt/Icon";
import useOutsideClick from "customHooks/useOutsideClick";
import {
  DropDownContainer,
  DropDownHeader,
  DropdownScroll,
  DropdownScrollInnerDiv,
  DropDownList,
  StyledSearch,
  ListItem,
  NoResultFoundText,
  SelectedValue,
  MarginRightTextP,
} from "./DropDownStyles";

// Todo - Combine Dropdown and controlled dropdown
export default function Dropdown({
  listFontSize,
  listHeight,
  customWdith,
  options,
  selectedValue,
  value,
  onOptionClick,
  leftIcon,
  rightIconProps,
  pointer,
  error,
  styles = {
    header: {},
    scroll: {},
    rightIcon: {},
  },
  triggerClose = false,
  errorMessage,
  errorVisibility,
  variant,
  allowSearch,
  allowSearchAndAdd,
  getFilterOptions = (options, searchText) =>
    options.filter((item) => item?.value?.toLowerCase().includes(searchText)),
  notFoundText,
  defaultText,
  isClearOption,
  selectedId,
  bottomScrollComponent,
  dontOpen = false,
  searchBy,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [clickedSecondaryItems, setClickedSecondaryItem] = useState({});
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (searchString !== "") {
      const filteredData = options.filter((item) => {
        const propertyToSearch =
          searchBy === "label" ? item?.label : item?.value;
        return propertyToSearch
          ?.toLowerCase()
          .includes(searchString.toLowerCase());
      });
      setFilteredOptions(
        filteredData.length
          ? filteredData
          : allowSearchAndAdd
          ? [{ label: searchString, value: searchString }]
          : []
      );
    } else {
      if (options) {
        setFilteredOptions([...options]);
      }
    }
  }, [searchString, options, allowSearchAndAdd]);

  const setInitialValue = () => {
    setIsOpen(false);
    setSearchString("");
  };

  const ref = useOutsideClick(() => setInitialValue());

  useEffect(() => {
    setIsOpen(false);
  }, [triggerClose]);

  const handleOptionClicked = (event, item) => {
    event.stopPropagation();
    if (onOptionClick) {
      onOptionClick(item);
    }
    if (!item.noClose) {
      setIsOpen(false);
      setSearchString("");
    }
  };

  const handleGroupedOptionClicked = (event, item, optionItem) => {
    event.stopPropagation();
    if (item.groupedOptions) {
      const newItem = { ...item };
      newItem.selectedGroupedItem = optionItem;
      onOptionClick(newItem);
    } else {
      onOptionClick(item);
    }
    if (!item.noClose) {
      setIsOpen(false);
    }
  };
  const onSecondaryItemClick = (event, index) => {
    event.stopPropagation();
    setClickedSecondaryItem((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleHeaderClick = () => {
    if (variant !== "disabled" && !dontOpen) {
      setIsOpen(!isOpen);
    }
  };

  const renderPrimaryList = () => {
    // const filteredOptions = getFilterOptions(
    //   options,
    //   searchString.toLowerCase()
    // );

    if (filteredOptions && filteredOptions.length > 0) {
      return filteredOptions.map((item, index) => (
        <div key={`${item.value} ${index}`}>{renderListItem(item, index)}</div>
      ));
    }
    if (allowSearchAndAdd) {
      return null;
    }
    return (
      <NoResultFoundText>
        <div style={{ paddingBottom: "10px" }}>
          <Icon
            iconName="searchBG"
            style={{ display: "contents" }}
            iconSize="large"
          />
        </div>
        <div style={{ fontSize: "14px", color: "#989898" }}>
          {notFoundText || "No results found"}
        </div>
      </NoResultFoundText>
    );
  };

  const renderListItem = (item, index) => {
    if (item) {
      if (item.groupedOptions) {
        return renderSecondaryListItem(item, index);
      }
      return renderPrimaryListItem(item, index);
    }
    return null;
  };

  const renderSecondaryListItem = (item, index) => {
    if (item.groupedOptions) {
      return (
        <>
          <ListItem
            value={item.value}
            isDisabled={item.isDisabled}
            active={item.value === selectedValue}
            onClick={(event) =>
              !item.isDisabled ? onSecondaryItemClick(event, index) : null
            }
            variant={variant}
          >
            {item.icon ? item.icon : null}
            <p>{typeof item.value !== "object" && item.label}</p>
            {item.value === selectedValue ? (
              <Icon iconSize="tiny" iconName="tick" color="currentColor" />
            ) : null}
          </ListItem>
          {clickedSecondaryItems[index] &&
            item.groupedOptions.map((optionItem) => (
              <ListItem
                value={optionItem.value}
                isDisabled={optionItem.isDisabled}
                active={optionItem.value === selectedValue}
                onClick={(event) =>
                  !optionItem.isDisabled
                    ? handleGroupedOptionClicked(event, item, optionItem)
                    : null
                }
                variant={variant}
              >
                {optionItem.icon ? optionItem.icon : null}
                <MarginRightTextP>
                  {typeof optionItem.value !== "object" && optionItem.label}
                </MarginRightTextP>
                {optionItem.value === selectedValue ? (
                  <Icon iconSize="tiny" iconName="tick" color="currentColor" />
                ) : null}
              </ListItem>
            ))}
        </>
      );
    }
    return null;
  };

  const renderSelectedValue = (item) => {
    if (selectedId) {
      return item.id === selectedId;
    }
    return item.value === selectedValue;
  };

  const renderPrimaryListItem = (item, index) => {
    if (item) {
      return (
        <ListItem
          value={item.value}
          isDisabled={item.isDisabled}
          active={renderSelectedValue(item)}
          onClick={(event) =>
            !item.isDisabled ? handleOptionClicked(event, item) : null
          }
          variant={variant}
          listFontSize={listFontSize}
        >
          {item.icon || null}
          <p>{typeof item.value !== "object" && item.label}</p>
          {renderSelectedValue(item) && (
            <Icon iconSize="tiny" iconName="tick" color="currentColor" />
          )}
        </ListItem>
      );
    }

    return null;
  };

  const showHeader = () => {
    if (value) {
      return <SelectedValue variant={variant}>{value}</SelectedValue>;
    }
    if (selectedValue) {
      return <SelectedValue variant={variant}>{selectedValue}</SelectedValue>;
    }
    return `${defaultText ?? "Select Option"}`;
  };

  const showSearchInput = () => (
    <StyledSearch
      variant={variant}
      autoFocus
      value={searchString}
      onChange={(event) => setSearchString(event.target.value)}
      {...rest}
    />
  );

  const getSearchInputComponent = () => {
    const { searchInput: SearchInput } = rest;
    if (SearchInput) {
      return (
        <SearchInput
          onChange={(event) => setSearchString(event.target.value)}
          searchString={searchString}
        />
      );
    }

    return showSearchInput();
  };

  const renderDropDown = () => {
    if (isOpen) {
      return (
        <DropDownList
          isOpen={isOpen}
          variant={variant}
          style={styles.list}
          customWdith={customWdith}
        >
          {rest.allowInsideSearch && getSearchInputComponent()}
          <DropdownScroll
            variant={variant}
            style={styles.scroll}
            listHeight={listHeight}
          >
            <DropdownScrollInnerDiv>
              {renderPrimaryList()}
            </DropdownScrollInnerDiv>
          </DropdownScroll>
          {bottomScrollComponent}
        </DropDownList>
      );
    }
    return null;
  };

  const renderHeaderRightSegment = () => {
    if (selectedValue && isClearOption) {
      return (
        <Icon
          iconName="close"
          style={{ position: "absolute", right: "8%", ...styles.rightIcon }}
          iconSize="tiny"
          onClick={(event) => handleOptionClicked(event, selectedValue)}
        />
      );
    }
    return (
      <Icon
        iconName="greyFillArrow"
        style={{ position: "absolute", right: "4%", ...styles.rightIcon }}
        iconSize="tiny"
        {...rightIconProps}
      />
    );
  };

  const renderError = () => {
    if (!isOpen && !selectedValue && errorVisibility) {
      return <ErrorMessage mt="5px">{errorMessage}</ErrorMessage>;
    }
  };

  return (
    <DropDownContainer {...rest} tabIndex="1" ref={ref}>
      <DropDownHeader
        variant={variant}
        onClick={handleHeaderClick}
        style={styles.header}
        isOpen={isOpen}
        pointer={pointer}
        {...rest}
      >
        {leftIcon}
        {isOpen && (allowSearch || allowSearchAndAdd)
          ? showSearchInput()
          : !rest.hideHeader && showHeader()}
        {!rest.hideRightSegment && renderHeaderRightSegment()}
      </DropDownHeader>
      {renderError()}
      {renderDropDown()}
    </DropDownContainer>
  );
}

Dropdown.propTypes = {
  /**
   List of all the items
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.string.isRequired,
      noClose: PropTypes.bool,
    })
  ).isRequired,
  /**
   Selected item
   */
  selectedValue: PropTypes.string,
  onOptionClick: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    scroll: PropTypes.shape({}),
    header: PropTypes.shape({}),
  }),
  leftIcon: PropTypes.element,
  variant: PropTypes.oneOf([
    "dark",
    "light",
    "outlined",
    "disabled",
    "outlinedGreen",
    "outlinedGrey",
    "lighterGrey",
    "white",
    "green",
    "coloured",
  ]).isRequired,
  errorMessage: PropTypes.string,
  errorVisibility: PropTypes.bool,
  allowSearch: PropTypes.bool,
  allowSearchAndAdd: PropTypes.bool,
};

Dropdown.defaultProps = {
  selectedValue: "",
  leftIcon: null,
  errorMessage: "Required",
  errorVisibility: false,
  allowSearch: false,
  allowSearchAndAdd: false,
};
