import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import componentLoader from "../components/componentLoader";
import ProtectRoute from "./protectRoute";
import RegisterPage from "../modules/App/pages/RegisterPage/Register";
import packageJson from "../meta.json";
import ForgotPassword from "modules/App/pages/ForgotPasswordPage/ForgotPassword";
import DashboardPage from "../modules/App/pages/DashboardPage/DashboardPage";
import Login from "../modules/App/pages/LoginPage/Login";
import CreateNewProjectDashboard from "../modules/App/pages/CreateNewProjectDashboard/CreateNewProjectDashboard";
import SettingHeader from "../modules/SettingHeader/SettingHeader";
import Profile from "../modules/Profile/Profile";
import CustomerDashboardPage from "../modules/Customer/pages/CustomerDashboard/CustomerDashboardPage";
import { PAGE_LOAD_ATTEMPT } from "../constants/enums/appEnums";
import AdminRoutes from "./adminRoutes";
import SuperAdminRoutes from "./superAdminRoutes";
import { logoutAndSwitchApp } from "../modules/App/AppActions";
import If from "components/If";
import { fetchAllPlans } from "modules/Plans/PlansActions";

const AppPage = React.lazy(() =>
  componentLoader(
    () => import("../modules/App/pages/AppPage/AppPage"),
    PAGE_LOAD_ATTEMPT
  )
);
const AppPageOld = React.lazy(() =>
  componentLoader(
    () => import("../modules/App/pages/AppPage/AppPageOld"),
    PAGE_LOAD_ATTEMPT
  )
);
const RedirectPage = React.lazy(() =>
  componentLoader(
    () => import("../modules/App/pages/RedirectPage/RedirectPage"),
    PAGE_LOAD_ATTEMPT
  )
);
const CustomerListPage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Customer/pages/CustomerListPage/CustomerListPage"),
    PAGE_LOAD_ATTEMPT
  )
);
// TODO: Remove commented code after Bulk Upload V2 works fine in production
const ResetPassword = React.lazy(() =>
  componentLoader(
    () => import("../modules/App/pages/ForgotPassword/ResetPassword"),
    PAGE_LOAD_ATTEMPT
  )
);
const InboxPage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Inbox/pages/InboxPage/InboxPage"),
    PAGE_LOAD_ATTEMPT
  )
);

const ManagePermissionsPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "../modules/Settings/pages/ManagePermissions/ManagePermissionsPage"
      ),
    PAGE_LOAD_ATTEMPT
  )
);

const CampaignsPage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Campaigns/Campaigns"),
    PAGE_LOAD_ATTEMPT
  )
);

const Manage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Manage/ManagePage"),
    PAGE_LOAD_ATTEMPT
  )
);

const NoPageFound = React.lazy(() =>
  componentLoader(
    () => import("modules/NoPageFound/NoFoundPage"),
    PAGE_LOAD_ATTEMPT
  )
);

const MainRoutes = ({
  isAuthenticated,
  isOrganizationOnboarded,
  isTakeMeToInteraktTriggered,
  updateOnboardingStatusForOrg,
  checkIfToShowLoader,
  loginUser,
  loginSuperAdmin,
  loginAdmin,
  checkToken,
  UpdateAuthenticatedState,
  selectedAccountSegment,
  isSandboxMode,
  headerData,
  manageData,
  activeSideBar,
  adminPanel,
  organizations,
  setFaviconCount,
  user,
  getRedirectPath,
}) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [switchingApp, setSwitchingApp] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.get("source") === "external") {
      sessionStorage.setItem("isExternalSource", "true");
    }
    if (queryParams.get("phoneNumber")) {
      sessionStorage.setItem("phoneNumber", queryParams.get("phoneNumber"));
    }
    if (queryParams.get("conversationStatus")) {
      sessionStorage.setItem(
        "conversationStatus",
        queryParams.get("conversationStatus")
      );
    }
    return () => {
      sessionStorage.removeItem("isExternalSource");
    };
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(fetchAllPlans());
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const loginVia = localStorage.getItem("login_via");
    const is_super_admin = localStorage.getItem("is_super_admin");
    if (loginVia) {
      const appName =
        loginVia === "admin"
          ? is_super_admin == "true"
            ? "super-admin"
            : "admin"
          : "agent";
      const isNextAppAdmin = pathname.search(`/admin/`) === 0;
      const isNextAppSuperAdmin = pathname.search(`/super-admin/`) === 0;
      const isNextAppAgent =
        loginVia === "admin" && !isNextAppAdmin && !isNextAppSuperAdmin;
      if (appName == "agent") {
        if (isNextAppAdmin || isNextAppSuperAdmin) {
          if (
            confirm(
              "Are you sure want's to switch the app? You are logged in agent app now."
            )
          ) {
            setSwitchingApp(true);
            dispatch(
              logoutAndSwitchApp(isNextAppSuperAdmin ? "super-admin" : "admin")
            );
          } else {
            window.location = "/project-dashboard";
          }
        }
      } else {
        // || (appName !== 'super-admin' && !isNextAppSuperAdmin)
        const isAppSwitching = appName === "admin" && !isNextAppAdmin;
        const isAppSwitchingSuperAdmin =
          appName === "super-admin" && !isNextAppSuperAdmin;
        if (isAppSwitching || isAppSwitchingSuperAdmin) {
          if (
            confirm(
              "Are you sure want's to switch the app? You are logged in " +
                appName +
                " app now."
            )
          ) {
            setSwitchingApp(true);
            dispatch(
              logoutAndSwitchApp(
                isNextAppAgent
                  ? "agent"
                  : isNextAppSuperAdmin
                  ? "super-admin"
                  : "admin"
              )
            );
          } else {
            window.location =
              appName === "super-admin"
                ? "/business-dashboard"
                : "/admin/project-dashboard";
          }
        }
      }
    }
  }, [pathname, isAuthenticated]);
  if (switchingApp) {
    return null;
  }

  const hideSettingsHeader =
    sessionStorage.getItem("isExternalSource") === "true" &&
    (pathname.search("/inbox") === 0 ||
      pathname.search("/history") === 0 ||
      pathname.search("/bic-history") === 0);
  return (
    <>
      {!window.location.pathname.includes("/order/") && isAuthenticated && (
        // Do not change this to ProtectRoute. If you do, then page refresh will always
        // redirect to inbox page
        <>
          <Route
            path="/"
            isAuthenticated={isAuthenticated}
            isOrganizationOnboarded={isOrganizationOnboarded}
            component={(props) => (
              <AppPage
                {...props}
                isTakeMeToInteraktTriggered={isTakeMeToInteraktTriggered}
                updateOnboardingStatusForOrg={updateOnboardingStatusForOrg}
              />
            )}
          />
          <Route
            path="/"
            isAuthenticated={isAuthenticated}
            isOrganizationOnboarded={isOrganizationOnboarded}
            component={(props) => (
              <AppPageOld
                {...props}
                isTakeMeToInteraktTriggered={isTakeMeToInteraktTriggered}
                updateOnboardingStatusForOrg={updateOnboardingStatusForOrg}
              />
            )}
          />
        </>
      )}
      <div className={checkIfToShowLoader() ? "hide" : "kiwi-main-container"}>
        <Switch>
          <Route path="/" exact component={RedirectPage} />
          <Route
            path="/login"
            exact
            component={(props) => <Login {...props} loginUser={loginUser} />}
          />
          <Route path="/super-admin">
            <SuperAdminRoutes
              isAuthenticated={isAuthenticated}
              loginUser={loginSuperAdmin}
            />
          </Route>
          <Route path="/admin">
            <AdminRoutes
              loginAdmin={loginAdmin}
              isAuthenticated={isAuthenticated}
            />
          </Route>
          <Route
            path="/register"
            exact
            component={(props) => (
              <RegisterPage {...props} checkToken={checkToken} />
            )}
          />
          <Route
            path="/Forgot-password"
            exact
            component={(props) => (
              <ForgotPassword {...props} checkToken={checkToken} />
            )}
          />
          <Route
            path="/reset-password"
            exact
            component={(props) => (
              <ResetPassword
                {...props}
                UpdateAuthenticatedState={UpdateAuthenticatedState}
              />
            )}
          />
          {isAuthenticated && (
            <>
              <div
                className={
                  selectedAccountSegment === "thankYouScreen" ||
                  (selectedAccountSegment === "paymentPlanSelection" &&
                    !isSandboxMode())
                    ? ""
                    : "kiwi-container"
                }
              >
                <If test={!hideSettingsHeader}>
                  <SettingHeader
                    HeaderData={headerData}
                    manageData={manageData}
                  />
                </If>
                <Switch>
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    path="/project-dashboard"
                    component={(props) => (
                      <CreateNewProjectDashboard {...props} />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    path="/campaigns"
                    component={(props) => <CampaignsPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    path="/dashboard"
                    component={(props) => <DashboardPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    path="/profile"
                    component={(props) => <Profile {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    path="/manage"
                    component={(props) => (
                      <Manage
                        {...props}
                        values={activeSideBar}
                        adminPanel={adminPanel}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    path="/inbox"
                    exact
                    component={(props) => (
                      <InboxPage
                        {...props}
                        organizations={organizations}
                        setFaviconCount={setFaviconCount}
                      />
                    )}
                  />

                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    path="/history"
                    exact
                    component={(props) => (
                      <InboxPage
                        {...props}
                        organizations={organizations}
                        setFaviconCount={setFaviconCount}
                      />
                    )}
                  />

                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    path="/bic-history"
                    exact
                    component={(props) => (
                      <InboxPage
                        {...props}
                        organizations={organizations}
                        setFaviconCount={setFaviconCount}
                      />
                    )}
                  />

                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    shouldHaveRoles={[]}
                    userRoles={[user.role]}
                    shouldHavePermissions={[]}
                    userPermissions={user.userPermissions}
                    path="/contacts/list"
                    exact
                    component={(props) => <CustomerListPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    path="/contacts/:id/page"
                    exact
                    component={(props) => <CustomerDashboardPage {...props} />}
                  />
                  {/* TODO: Remove v1 code after Bulk Upload V2 works fine in production */}

                  <ProtectRoute
                    isAuthenticated={isAuthenticated}
                    isOrganizationOnboarded={isOrganizationOnboarded}
                    isRestrictedPage={true}
                    shouldHaveRoles={["Owner"]}
                    userRoles={[user.role]}
                    shouldHavePermissions={[]}
                    userPermissions={user.userPermissions}
                    path="/settings/permissions"
                    exact
                    component={(props) => (
                      <ManagePermissionsPage
                        {...props}
                        organizations={organizations}
                      />
                    )}
                  />
                  <Route>
                    <NoPageFound />
                  </Route>
                </Switch>
              </div>
            </>
          )}
          <Route>
            <NoPageFound />
          </Route>
          <Route
            render={(props) => (
              <Redirect
                to={{
                  pathname: isAuthenticated ? getRedirectPath() : "/login",
                  pathname: "/login",
                  ...(!isAuthenticated && {
                    state: {
                      _refurl: `${props.location.pathname}${
                        !!props.location.search ? props.location.search : ""
                      }`,
                    },
                  }),
                }}
              />
            )}
          />
        </Switch>
      </div>
      <div style={{ display: "none" }}>Version - {packageJson.version}</div>
    </>
  );
};

export default MainRoutes;
