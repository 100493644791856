import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { space } from "styled-system";

import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";

const OuterContainer = styled(Box)`
  background-color: #f5f5f5;

  padding-bottom: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding-bottom: 0;
  }

  ${space}
`;

const Header = ({ text, onBackClick, onBtnClick, btnText, ...rest }) => {
  const theme = useContext(ThemeContext);

  return (
    <OuterContainer {...rest}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="0 18px"
        bg={{ _: "white", desktop: "#F5F5F5" }}
        minHeight={{ _: "55px", desktop: "70px" }}
        maxHeight={{ _: "55px", desktop: "70px" }}
      >
        <Box display="flex" alignItems="center">
          <Icon
            mr="18px"
            iconName="back"
            width="16px"
            stroke={theme.colors.darkGrey["100"]}
            onClick={onBackClick}
          />
          <Label fontSize={{ _: "16px", desktop: "18px" }} fontWeight={400}>
            {text}
          </Label>
        </Box>

        {/*{btnText && (*/}
        {/*  <Label*/}
        {/*    color="#2E8B53"*/}
        {/*    fontWeight={500}*/}
        {/*    lineHeight="1"*/}
        {/*    fontSize="16px"*/}
        {/*    onClick={onBtnClick}*/}
        {/*    mt="1px"*/}
        {/*  >*/}
        {/*    {btnText}*/}
        {/*  </Label>*/}
        {/*)}*/}
      </Box>
    </OuterContainer>
  );
};

export default Header;
