import React from "react";
import styled from "styled-components";

import Icon from "konnekt/Icon/Icon";
import Label from "konnekt/Label";
import TextInput from "konnekt/TextInput";
import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import DropDownControlled from "konnekt/Dropdown";
import CheckBoxWithLabel from "../../components/CheckBoxWithLabel";

const Title = styled.h1`
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
`;
const CheckBoxTitle = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 3px;
`;

const MessageInput = styled.div`
  background-color: #ffffff;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    overflow-x: hidden;
  }
`;
const TextInputStyle = styled(TextInput)`
  background: rgb(239, 241, 243);
  width: 100%;
  border: 0;
  height: 4.4rem;
  border-radius: 0.4rem;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
`;
const MessageInputItem = styled.div`
  background-color: #ffffff;
  margin: 0 30 30 30;
  border-radius: 10px;
  padding: 1 24 36 24;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 20 20 20 20;
    padding: 1 36 36 36;
  }
`;
const BodyItem = styled.div`
  display: flex;
  margin-top: 36px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-top: 0;
    display: block;
    width: 100%;
    align-items: center;
  }
`;

const BodyItemInput = styled.div`
  width: 100%;
  margin-right: 10px;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    margin-right: 0px;
    margin-top: 15px;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 20px;
  margin-right: 10px;
`;
const HeaderTitleContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  padding: 18 18 18 18;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  }
`;

const FormUpdater = ({
  title,
  modalHandler,
  selectedItem,
  updateDetails,
  saveHandler,
  errors,
  formKeys,
  plans,
}) => {
  const checkBoxHandler = (e) => {
    const { name } = e;
    let options = checkBoxValue.map((obj) => {
      obj.isChecked = obj.name == name;
      return obj;
    });
    setCheckBoxValue(options);
  };

  const getAttributeName = (key) => {
    let str1 = key.replaceAll("_", " ");
    let str2 = str1.charAt(0).toUpperCase() + str1.slice(1);
    if (key === "ac_credit_price") {
      return "Authentication Conversation Price";
    }
    if (key === "sc_credit_price") {
      return "Service Conversation Price";
    }
    if (key === "mc_credit_price") {
      return "Marketing Conversation Price";
    }
    if (key === "uc_credit_price") {
      return "Utility Conversation Price";
    }
    return str2;
  };
  console.log("=====plans", plans);
  const getDropdownPlans = () =>
    plans.map(({ name, _id }) => ({ label: name, value: _id }));

  const getSelectedPlan = (planId) => {
    return getDropdownPlans().find((item) => item.value === planId);
  };

  const checkFbGenToken = (key) => {
    if (key === "facebook_generated_token") {
      if (selectedItem[key]) {
        return true;
      } else return false;
    } else {
      return false;
    }
  };
  return (
    <MessageInput>
      <HeaderTitleContainer>
        <Icon
          cursor="pointer"
          iconName="back"
          width="40px"
          onClick={() => modalHandler()}
        />
        <Title>{title}</Title>
      </HeaderTitleContainer>
      <MessageInputItem>
        {formKeys
          ? formKeys?.map((key) => {
              if (key == "is_active") {
                return (
                  <CheckBoxWithLabel
                    alignItems="flex-end"
                    label="Active"
                    checked={selectedItem[key]}
                    onChange={() => updateDetails(key, !selectedItem[key])}
                  />
                );
              } //plan_name
              if (key === "plan_name") {
                return (
                  <BodyItem Key={key}>
                    <BodyItemInput>
                      <Box display="flex" justifyContent="space-between">
                        <Label variant="text4" mb="12px">
                          {getAttributeName(key)}
                        </Label>
                      </Box>
                      <DropDownControlled
                        width="100%"
                        height="4.4rem"
                        options={getDropdownPlans()}
                        variant="lighterGrey"
                        selectedValue={
                          getSelectedPlan(selectedItem[key])?.label || ""
                        }
                        onOptionClick={(item) => updateDetails(key, item.value)}
                      />
                      {errors[key] && (
                        <p style={{ color: "red" }}>{errors[key]}</p>
                      )}
                    </BodyItemInput>
                  </BodyItem>
                );
              }
              if (key === "status") {
                return (
                  <BodyItem Key={key}>
                    <BodyItemInput>
                      <Box display="flex" justifyContent="space-between">
                        <Label variant="text4" mb="12px">
                          {getAttributeName(key)}
                        </Label>
                      </Box>
                      <DropDownControlled
                        width="100%"
                        height="4.4rem"
                        variant="lighterGrey"
                        options={[
                          { label: "open", value: "open" },
                          { label: "close", value: "close" },
                        ]}
                        selectedValue={selectedItem[key]}
                        onOptionClick={(item) => updateDetails(key, item.value)}
                      />
                      {errors[key] && (
                        <p style={{ color: "red" }}>{errors[key]}</p>
                      )}
                    </BodyItemInput>
                  </BodyItem>
                );
              }
              if (key == "whatsapp_api_status") {
                return (
                  <BodyItem Key={key}>
                    <BodyItemInput>
                      <Box display="flex" justifyContent="space-between">
                        <Label variant="text4" mb="12px">
                          {getAttributeName(key)}
                        </Label>
                      </Box>
                      <DropDownControlled
                        width="100%"
                        height="4.4rem"
                        variant="lighterGrey"
                        options={[
                          { label: "LIVE", value: "LIVE" },
                          {
                            label: "IN_ACTIVE",
                            value: "IN_ACTIVE",
                          },
                          { label: "NOT_CONNECTED", value: "NOT_CONNECTED" },
                        ]}
                        selectedValue={selectedItem[key]}
                        onOptionClick={(item) => updateDetails(key, item.value)}
                      />
                      {errors[key] && (
                        <p style={{ color: "red" }}>{errors[key]}</p>
                      )}
                    </BodyItemInput>
                  </BodyItem>
                );
              }
              return (
                <BodyItem Key={key}>
                  <BodyItemInput>
                    <Box display="flex" justifyContent="space-between">
                      <Label variant="text4" mb="12px">
                        {getAttributeName(key)}
                      </Label>
                    </Box>
                    <TextInputStyle
                      type="text"
                      onChange={(value) => updateDetails(key, value)}
                      value={selectedItem[key]}
                      bodyTextLimit={
                        key === "facebook_generated_token" ? 150 : 120
                      }
                      placeholder={`Enter ` + getAttributeName(key)}
                      disabled={checkFbGenToken(key)}
                    />
                    {errors[key] && (
                      <p style={{ color: "red" }}>{errors[key]}</p>
                    )}
                  </BodyItemInput>
                </BodyItem>
              );
            })
          : null}
      </MessageInputItem>
      <BtnContainer>
        <Button text="Update" type="primary" onClick={() => saveHandler()} />
      </BtnContainer>
    </MessageInput>
  );
};

export default FormUpdater;
