export const SET_REPLY_MESSAGE_TEXT = "SET_REPLY_MESSAGE_TEXT";
export const SET_REPLY_MESSAGE_TEMPLATE = "SET_REPLY_MESSAGE_TEMPLATE";
export const SET_REPLY_MESSAGE_ATTACHMENT = "SET_REPLY_MESSAGE_ATTACHMENT";
export const SET_CHAT_GALLERY_STATUS = "SET_CHAT_GALLERY_STATUS";
export const SET_SELECTED_QUICK_REPLY = "SET_SELECTED_QUICK_REPLY";

export function setReplyMessageText(chatId, message) {
  return (dispatch) => {
    dispatch({
      type: SET_REPLY_MESSAGE_TEXT,
      text: message,
      chat_id: chatId,
    });
  };
}

export function setReplyMessageTemplate(chatId, template) {
  return (dispatch) => {
    dispatch({
      type: SET_REPLY_MESSAGE_TEMPLATE,
      template: template,
      chat_id: chatId,
    });
  };
}

export function setReplyMessageAttachment(chatId, attachment) {
  return (dispatch) => {
    dispatch({
      type: SET_REPLY_MESSAGE_ATTACHMENT,
      attachment: attachment,
      chat_id: chatId,
    });
  };
}

export function setSelectedQuickReply(chatId, quickReply) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_QUICK_REPLY,
      quickReply: quickReply,
      chat_id: chatId,
    });
  };
}

export function setChatGalleryStatus(value) {
  return (dispatch) => {
    dispatch({
      type: SET_CHAT_GALLERY_STATUS,
      showGallery: value,
    });
  };
}
