import React, { useState, useEffect } from "react";
import FavIcon from "favico.js";

function WithFavicon(WrappedComponent) {
  return (props) => {
    const [favIconInstance, setFavIconInstance] = useState(null);
    const [faviconCount, setFaviconCount] = useState(0);

    useEffect(() => {
      favIconInstance?.badge(faviconCount);
    }, [favIconInstance, faviconCount]);

    useEffect(() => {
      setFavIconInstance(
        new FavIcon({
          animation: "none",
          position: "up",
        }),
      );
    }, []);

    return <WrappedComponent setFaviconCount={setFaviconCount} {...props} />;
  };
}

export default WithFavicon;
