import callApi from "util/apiCaller";
import { showNotification } from "util/utility";
import { razorpayApiKey } from "./credentials";

// ------------ Root Function For adding Plan Subscrition------------ //
/* Payment Parameters require 
    -projectId
    -orderDetails -> amount, currency are required rest are optional
    -plan_id
    -billing_address_id
    -payment_method_id 
*/

export const initiateRazorpayPayment = (options) => {
  const rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", function (response) {
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
    showNotification("error", `Payment failed with code ${response.error.code}`)
  });

  rzp1.open();
}

// ------------ Root Function For Buying Top Up ------------ //
export const handleSubscriptionPayment = async (paymentParameters, paymentConfirmationCallback) => {

  let result = await handleSubscriptionOrder(paymentParameters)

  if(!result){
    return 
  }

  if(result?.subscription_type == 'downgrade'){
    paymentConfirmationCallback() 
    showNotification('success', 'Subscription Downgraded')
    return
  }

  const subscriptionConfirmationData = {
    url :   `elevate/billing/v1/project/${result?.project_id}/billing/subscription/${result?.subscription_id}/confirm-subscription`,
    successMessage : "Subscription confirmed!",
    errorMessage : "Subscription not confirmed!"
  }

  const options = {
    key: process.env.RAZORPAY_API_KEY, 
    amount: result?.invoiced_amount, 
    currency: "INR",
    name: "Automate Easy",
    order_id: result?.order_id,                       //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      const {razorpay_payment_id, razorpay_order_id, razorpay_signature} = response
      console.log("razor pay res", response)
      confirmHandler({razorpay_payment_id, razorpay_order_id, razorpay_signature, invoice_id : result._id, ...result}, paymentConfirmationCallback, subscriptionConfirmationData)
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  initiateRazorpayPayment(options)

};


export const handleSubscriptionOrder = async (input) => {
  try {

    const result = await callApi(
        `elevate/billing/v1/project/${input.project_id}/billing/new-subscriptions`, "post",
        {
          "subscription" : input
       },
      )
      .then(res => {
        if(res.status === "Success"){
          return res?.data?.subscriptions
        }

        if(res.status === 'Error'){
          showNotification('error', res?.data?.message)
          return null
        }
      })
      .catch(err => {
        console.log(err)
      })
      if (result) {
        return result
      }

  } catch (err) {
    console.log(err)
  }

};

// ------------ Root Function For Buying Top Up ------------ //
export const handleTopUpPayment = async (paymentParameters, paymentConfirmationCallback) => {

  let result = await handleTopUpOrder(paymentParameters)

  if(!result){
    return 
  }

  const topUpConfirmationData = {
    url : `elevate/billing/v1/project/${result?.project_id}/billing/subscription/${result?.subscription_id}/confirm-top-up`,
    successMessage : "Top up confirmed!",
    errorMessage : "Top up not confirmed!"
  }
  
  const options = {
    key: process.env.RAZORPAY_API_KEY, 
    amount: result?.invoiced_amount, 
    currency: "INR",
    order_id: result?.order_id,                       //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      const {razorpay_payment_id, razorpay_order_id, razorpay_signature} = response

      confirmHandler({razorpay_payment_id, razorpay_order_id, razorpay_signature, invoice_id : result._id, ...paymentParameters}, paymentConfirmationCallback, topUpConfirmationData)
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  initiateRazorpayPayment(options)
};

export const handleTopUpOrder = async (input) => {
  try {
    const subRes = await callApi(
        `elevate/billing/v1/project/${input.project_id}/billing/subscription/new-top-up`, "post",
        {
          subscription : input
       },
      )
      .then(res => {
        if(res.status === "Success"){
          return res?.data?.subscriptions
        }
        if(res.status === 'Error'){
          showNotification('error', res?.data?.message)
          return null
        }
      })
      .catch(err => {
        console.log(err)
      })
      if (subRes) {
        return subRes
      }

  } catch (err) {
    console.log(err)
  }

};


export const confirmHandler = ({razorpay_payment_id = null, razorpay_order_id = null, razorpay_signature = null , ...input}, paymentConfirmationCallback, confirmData) => {

  let confirmationBody = null

  if(razorpay_payment_id && razorpay_order_id && razorpay_signature){
    confirmationBody = {
      payment_status : "paid",
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
      ...input
    } 
    
  } else {
    confirmationBody = {
      payment_status : "unpaid",
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
      ...input
  }
  }

  callApi(confirmData.url, "post",
      confirmationBody
      )
    .then(res => {
      if(res.status === "Success"){
        showNotification("success", confirmData.successMessage)
        paymentConfirmationCallback()
      }
    })
    .catch(err => {
      showNotification("error", confirmData.errorMessage)
    })
}

export const handleInvoicePayment = async (input, paymentConfirmationCallback) => {

  let endpoint = input?.invoice?.invoice_type === "fixed" ? 'confirm-subscription' : 'confirm-top-up'
  const subscriptionConfirmationData = {
    url :   `elevate/billing/v1/project/${input?.project_id}/billing/subscription/${input?.subscription_id}/${endpoint}`,
    successMessage : "Invoice confirmed!",
    errorMessage : "Invoice not confirmed!"
  }

  const options = {
    key: process.env.RAZORPAY_API_KEY, 
    amount: input?.invoice?.invoiced_amount, 
    currency: "INR",
    name: "Automate Easy",
    order_id: input?.invoice?.order_id,                       //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      const {razorpay_payment_id, razorpay_order_id, razorpay_signature} = response
      console.log("razor pay res", response)
      confirmHandler({razorpay_payment_id, razorpay_order_id, razorpay_signature, ...input}, paymentConfirmationCallback, subscriptionConfirmationData)
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  initiateRazorpayPayment(options)

};

