import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProtectRoute from "./protectRoute";
import CreateNewProjectDashboard from "modules/App/pages/CreateNewProjectDashboard/CreateNewProjectDashboard";
import AdminLogin from "modules/App/pages/LoginPage/AdminLogin";
import { PAGE_LOAD_ATTEMPT } from "constants/enums/appEnums";
import componentLoader from "../components/componentLoader";
import SettingHeader from "modules/SettingHeader/SettingHeader";

const Manage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Manage/ManagePageAdmin"),
    PAGE_LOAD_ATTEMPT
  )
);

const NoPageFound = React.lazy(() =>
  componentLoader(
    () => import("modules/NoPageFound/NoFoundPage"),
    PAGE_LOAD_ATTEMPT
  )
);

const data = {
  headerData: [
    {
      link: "/admin/manage/template",
      icon: "manage",
      name: "Manage",
      isExpand: true,
    },
  ],
  manageData: [
    {
      link: "/admin/manage/template",
      icon: "template",
      name: "Template Message",
    },
    {
      link: "/admin/manage/team-setting",
      icon: "team",
      name: "Team Setting",
    },
  ],
};

const adminSideBar = [
  {
    url: "/admin/manage",
    iconPropsFn: (isActive) => ({
      iconName: "template",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Project",
  },
  {
    url: "/admin/manage/team-setting",
    iconPropsFn: (isActive) => ({
      iconName: "team",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
      fill: "red",
    }),
    label: "Agents",
  },
];

const AdminRoutes = ({ isAuthenticated, loginAdmin }) => {
  const { path } = useRouteMatch();
  return (
    <>
      {isAuthenticated && (
        <SettingHeader
          headerData={data.headerData}
          manageData={data.manageData}
        />
      )}
      <Switch>
        <Route
          path={path}
          exact
          component={(props) => (
            <AdminLogin {...props} loginUser={loginAdmin} />
          )}
        />
        <Route
          path={`${path}/login`}
          exact
          component={(props) => (
            <AdminLogin {...props} loginUser={loginAdmin} />
          )}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/project-dashboard`}
          exact
          component={(props) => <CreateNewProjectDashboard {...props} />}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/manage`}
          component={(props) => (
            <Manage {...props} values={adminSideBar} adminPanel />
          )}
        />
        <Route path="*">
          <NoPageFound />
        </Route>
      </Switch>
    </>
  );
};

export default AdminRoutes;
