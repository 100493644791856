import React from "react";
import styled from "styled-components";
import { space, color, layout, position, flexbox } from "styled-system";

import { hexToRGBA } from "util/colorUtility";

const Header = styled.div`
  text-align: center;
  padding-bottom: 14px;
  border-bottom: 1px solid ${hexToRGBA("#172024", ".08")};
  margin: 0;

  ${space}
  ${color}
  ${layout}
  ${position}
  ${flexbox}
`;

const BottomDropdownHeader = ({ children, ...rest }) => {
  return <Header {...rest}>{children}</Header>;
};

export default BottomDropdownHeader;
