/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./MegaApp";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
if (
  process.env.REACT_APP_ENVIRONMENT !== "local" &&
  process.env.REACT_APP_SENTRY_URL
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
      const isNonErrorException =
        event &&
        event.message &&
        event.message.match(/SentryError: HTTP Error \(429\)/) &&
        (event.exception.values[0].value.startsWith(
          "Non-Error exception captured"
        ) ||
          hint.originalException["message"].startsWith(
            "Non-Error exception captured"
          ));
      if (isNonErrorException) {
        return null;
      }
      return event;
    },
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.npm_package_version,
  });
}

const store = configureStore({});
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

window.$store = store;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
