import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import { showNotification } from "util/utility";
import publicApiCaller from "util/publicApiCaller";
import queryString from "util/queryString";
import Button from "konnekt/Buttons";
import Box from "konnekt/Box";
import Checkbox from "konnekt/Checkbox";
import Label from "konnekt/Label";
import Icon from "konnekt/Icon";
import { LOGIN_SUCCESS } from "modules/App/AppActions";
import jwt_decode from "jwt-decode";
import { getAuthenticatedState } from "modules/App/AppSelectors";
import TextInput from "konnekt/TextInput";
import ErrorMessage from "konnekt/ErrorMessage";
import {
  forgotPassword,
  getOTP,
  submitPassword,
} from "modules/Manage/ManagePageAction";

const ImageBackground = styled(Box)`
  background-image: url("https://automateeazy.s3.us-east-1.amazonaws.com/images/LgBg.svg");
  height: 100vh;
  background-size: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 300px;
    margin-bottom: 25px;
  }

  h4 {
    line-height: 38px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 30px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
    img {
      width: 100%;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 20px;
    }
  }
`;

const SignupSuccess = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

const RegisterForm = styled(Box)`
  padding: 30px 35px 0px 35px;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  a {
    text-align: center;
    display: block;
    font-weight: 600;
  }

  img {
    position: absolute;
    right: 12px;
    top: 14px;
    width: 21px;
  }

  input {
    box-sizing: border-box;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 30px 0px 15px;
    width: 100%;
  }

  button {
    background: #5e3ec5;
    border-radius: 8px;
    color: #fff;
    padding: 13px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  a {
    text-align: center;
    display: block;
    font-weight: 600;
    color: #5e3ec5;
    margin-left: 10px;
  }

  label {
    color: red;
    font-size: 12px;
    display: flex;
    margin-top: 7px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 40px 20px;
    h2 {
      font-size: 25px;
    }
  }
`;
const ErrorImage = styled(Icon)`
  margin-right: 7px;
`;

const ResendOTPButton = styled(Button)`
  max-width: 14rem;
  font-size: 1.4rem !important;
  margin: 1rem 0 !important;
  padding: 1rem !important;
  font-weight: 500 !important;
  cursor: ${(props) => props.cursorType} !important;
  background: ${(props) =>
    props.cursorType === "not-allowed" ? "#f2f2f2" : ""} !important;
  color: ${(props) =>
    props.cursorType === "not-allowed" ? "#333" : ""} !important;
`;

const SecretKeyInputIcon = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputBox = styled(TextInput)`
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.16);
  }
`;

const ForgotPassword = ({ checkToken, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [firstScreen, setFirstScreen] = useState(true);
  const [secondScreen, setSecondScreen] = useState(false);
  const [thirdScreen, setThirdScreen] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [disableGetOPT, setDisableGetOPT] = useState(true);
  const [disableSubmitOTP, setDisableSubmitOTP] = useState(true);
  const [disableResetPassword, setDisableResetPassword] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const [otp, setOtp] = useState("");
  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    gender: "male",
    username: "",
    local_password: "",
  });
  // const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const isAuthenticated = useSelector((state) => getAuthenticatedState(state));
  const [timer, setTimer] = useState(0); // 2 minutes in seconds
  const [timerElement, setTimerElement] = useState("");
  const [enableSendOTP, setEnableSendOTP] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      checkToken();
      history.push("/manage/user");
    }
  }, [isAuthenticated]);

  const handleChangeUser = (keyName, keyValue) => {
    const update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
    setErrors({});
  };

  const handleValidation = () => {
    let isValid = true;
    let newErrors = {};
    return {
      isValid,
      errors: newErrors,
    };
  };

  const handleLoginAPI = (creds) => {
    setIsRegistering(true);
    const queryObj = queryString.parse(window.location.search);
    if (queryObj) {
      creds.business_id = queryObj?.business_id || "";
    }
    const fullName = creds?.contact_name.split(" ");
    creds.first_name = fullName[0];
    creds.last_name = fullName.length > 1 ? fullName[fullName.length - 1] : "";
    creds.created_by = "default";
    const agent = {
      agent: creds,
    };
    publicApiCaller("auth/agent/sign-up", "post", agent).then((res) => {
      if (res) {
        if (res.status === "Success") {
          const { token } = res?.data;
          window.localStorage.setItem("token", token);
          const tempUser = jwt_decode(token);
          dispatch({
            type: LOGIN_SUCCESS,
            isLoggingIn: false,
            isAuthenticated: true,
            token: token,
            user: tempUser,
          });
          showNotification("success", "Registration is done");
          setIsRegistered(true);
          setIsRegistering(false);
        } else {
          setIsRegistered(false);
          setIsRegistering(false);
        }
      } else {
        showNotification(
          "error",
          res?.data?.message || "Something went wrong..."
        );
        setIsRegistered(false);
        setIsRegistering(false);
      }
    });
  };

  useEffect(() => {
    // Start the timer when the component mounts
    const interval = setInterval(() => {
      // Decrease the timer value
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    // Convert the timer value to minutes and seconds
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    // Format the timer display
    const formattedTimer = `${minutes}m ${seconds}s`;

    // Update the timer element
    setTimerElement(formattedTimer);

    // Check if the timer has reached 0
    if (timer === 0) {
      setEnableSendOTP(true);
    }
  }, [timer]);

  const handleSubmit = async () => {
    if (contactNumber === "" || contactNumber.length !== 10) {
      errors["contact_number"] = "Please enter valid number";
      setErrors({ ...errors });
    }
    if (contactNumber !== "" && contactNumber.length === 10 && disableGetOPT) {
      setDisableGetOPT(false);
      errors["contact_number"] = "";
      setErrors({ ...errors });
      const res = await dispatch(forgotPassword(contactNumber));

      if (res?.status === "Success") {
        setDisableGetOPT(true);
        setFirstScreen(false);
        setSecondScreen(true);
        setThirdScreen(false);
        setTimer(300);
      }
      if (res?.status === "Error") {
        setDisableGetOPT(true);
      }
    }
  };
  const resendOTP = async () => {
    setDisableResend(false);
    const res = await dispatch(forgotPassword(contactNumber));
    if (res?.status === "Success") {
      setTimer(300);
      setDisableResend(true);
    }
    if (res?.status === "Error") {
      setDisableResend(true);
    }
  };
  const handleSubmitOTP = async () => {
    if (otp === "" || otp.length < 6) {
      errors["otp"] = "Please enter valid OTP";
      setErrors({ ...errors });
    }
    if (otp !== "" && otp.length === 6 && disableSubmitOTP) {
      setDisableSubmitOTP(false);
      errors["otp"] = "";
      setErrors({ ...errors });
      const res = await dispatch(getOTP(otp));
      if (res?.status === "Success") {
        setFirstScreen(false);
        setSecondScreen(false);
        setThirdScreen(true);
        setDisableSubmitOTP(true);
      }
      if (res?.status === "Error") {
        setDisableSubmitOTP(true);
      }
    }
  };
  const handleSubmitPassword = async () => {
    setDisableResetPassword(false);
    const res = await dispatch(submitPassword(confirmPassword, contactNumber));
    if (res?.status === "Success") {
      history.push("/login");
      setDisableResetPassword(true);
    }
    if (res?.status === "Error") {
      setDisableResetPassword(true);
    }
  };
  const handleValidPassword = () => {
    let isValid = true;
    const regex = /^.{8,20}$/;
    if (confirmPassword === "" || newPassword === "") {
      isValid = false;
      errors["password"] = "Password can not we empty";
      setErrors({ ...errors });
    }
    if (confirmPassword !== newPassword) {
      isValid = false;
      errors["password"] = "Password is not same as Confirm Password ";
      setErrors({ ...errors });
    }
    if (!regex.test(confirmPassword)) {
      isValid = false;
      errors["password"] = "Password must be of 8-20 characters";
      setErrors({ ...errors });
    }
    if (isValid && disableResetPassword) {
      handleSubmitPassword();
      errors["password"] = "";
      setErrors({ ...errors });
    }
  };

  const getOTPSection = () => {
    return (
      <Box className="col-lg-6 col-md-6">
        <RegisterForm>
          <Box className="content-box">
            <Box mb="2rem" className="form-box">
              <h6>Welcome Back</h6>
              <h2>Log in to AutomatEazy</h2>
              <Box mb="3rem">
                <h4>Trouble Logging In?</h4>
              </Box>
              <Box mb="1rem">
                <h4>
                  Enter your Phone Number to help us find your account.
                  Otherwise contact project owner.
                </h4>
              </Box>

              <InputBox
                value={contactNumber}
                onChange={(value) => setContactNumber(value)}
                type="text"
                placeholder="Phone Number"
                bodyTextLimit={10}
                regexValidation="^[0-9]*$"
              />
              {errors.contact_number && (
                <ErrorMessage>{errors.contact_number}</ErrorMessage>
              )}
              <Button onClick={() => handleSubmit()} backgroundColor="#e4e4e4">
                {disableGetOPT ? "Send OTP" : "Sending..."}
              </Button>

              <Box className="input-box">
                <Box display="flex" justifyContent="center">
                  Already have an account?
                  <Link to="/login">Login</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </RegisterForm>
      </Box>
    );
  };
  const newPasswordSection = () => {
    return (
      <Box className="col-lg-6 col-md-6">
        <RegisterForm>
          <Box className="content-box">
            <Box className="form-box">
              <h6>Welcome Back</h6>
              <h2>Log in to AutomatEazy</h2>

              <Box mt="2rem" className="input-box position-relative">
                <input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter New Password"
                  maxlength="20"
                  type={hidePassword ? "password" : "text"}
                />
                <SecretKeyInputIcon
                  onClick={() => setHidePassword(!hidePassword)}
                  iconProps={hidePassword}
                >
                  {hidePassword ? (
                    <Icon iconName="eyeCrossed" width="22px" />
                  ) : (
                    <Icon iconName="eyes" width="22px" height="22px" />
                  )}
                </SecretKeyInputIcon>
              </Box>
              <Box mt="1rem" className="input-box position-relative">
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  maxlength="20"
                  type={hideConfirmPassword ? "password" : "text"}
                />
                <SecretKeyInputIcon
                  onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  iconProps={hideConfirmPassword}
                >
                  {hideConfirmPassword ? (
                    <Icon iconName="eyeCrossed" width="22px" />
                  ) : (
                    <Icon iconName="eyes" width="22px" height="22px" />
                  )}
                </SecretKeyInputIcon>
              </Box>
              {errors.password && (
                <ErrorMessage>{errors.password}</ErrorMessage>
              )}
              <Box className="input-box">
                <Button onClick={handleValidPassword}>
                  {disableResetPassword ? "Reset Password" : "Resetting..."}
                </Button>
                <Box display="flex" justifyContent="center">
                  Already have an account?
                  <Link to="/login">Login</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </RegisterForm>
      </Box>
    );
  };

  const enterOTPSection = () => {
    return (
      <Box className="col-lg-6 col-md-6">
        <RegisterForm>
          <Box className="content-box">
            <Box className="form-box">
              <h6>Welcome Back</h6>
              <h2>Log in to AutomatEazy</h2>

              <Box>
                <h4>Trouble Logging In?</h4>
              </Box>

              <Box mt="2rem" className="input-box position-relative">
                <InputBox
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  type="text"
                  placeholder="Enter OTP"
                  regexValidation="^[0-9]*$"
                  bodyTextLimit={6}
                />
                {errors.otp && <ErrorMessage>{errors.otp}</ErrorMessage>}
              </Box>

              <Box className="input-box">
                <Button onClick={() => handleSubmitOTP()}>
                  {disableSubmitOTP ? "Submit OTP" : "Submitting..."}
                </Button>
                <Box display="flex" justifyContent="center" id="timer">
                  <Box>{timerElement}</Box>
                </Box>
                <Box display="flex" justifyContent="center">
                  <ResendOTPButton
                    onClick={disableResend ? resendOTP : ""}
                    disabled={timer > 0}
                    cursorType={timer > 0 ? "not-allowed" : "pointer"}
                  >
                    {disableResend ? "Resend OTP" : "Resending..."}
                  </ResendOTPButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  Already have an account?
                  <Link to="/login">Login</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </RegisterForm>
      </Box>
    );
  };
  return (
    <>
      <section className="container-fluid">
        <Box className="row">
          <Box className="col-lg-6 col-md-6 p-0">
            <ImageBackground>
              <img src="/images/automate-logo.svg" alt="" />
              <h4>
                Send Personalized Campaigns <br />
                on WhatsApp
              </h4>
            </ImageBackground>
          </Box>
          {firstScreen && getOTPSection()}
          {secondScreen && enterOTPSection()}
          {thirdScreen && newPasswordSection()}
        </Box>
      </section>
    </>
  );
};

export default ForgotPassword;
