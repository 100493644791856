import {
    FETCH_PLANS_STATUS, 
    FETCH_PLANS,
    CREATE_NEW_PLAN,
    CREATE_NEW_PLAN_STATUS,
    UPDATE_PLAN,
    UPDATE_PLAN_STATUS,
    UPDATE_PLAN_PAGINATION_DATA,
    FETCH_ALL_PLANS
} from './PlansActions';

const INITIAL_STATE = {
    plans: {
        data: [],
        status: 'none',
        count: 0,
        pageSize: 10,
        pageNum: 1
    },
    plan: {
        type: 'create',
        status: 'none',
        data: {}
    },
    allPlans: [],
};

const PlanReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_PLANS_STATUS:
            return {
                ...state,
                plans:{
                    ...state.plans,
                    status: action.status
                }
            }
        case FETCH_PLANS:
            return {
                ...state,
                plans:{
                    ...state.plans,
                    status: action.status,
                    data: action.plans,
                    count: action.count,
                }
            }
        case CREATE_NEW_PLAN_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: action.status,
                    type: 'create'
                }
            }
        case CREATE_NEW_PLAN:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    status: action.status,
                    data: action.data
                }
            }
        case UPDATE_PLAN_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: action.status,
                    type: 'update'
                }
            }
        case UPDATE_PLAN:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    status: action.status,
                    data: action.data
                }
            }
        case UPDATE_PLAN_PAGINATION_DATA:
            return {
                ...state,
                plans: {
                    ...state.plans,
                    pageNum: action.pageNum,
                    pageSize: action.pageSize
                }
            }
        case FETCH_ALL_PLANS: 
            return {
                ...state,
                allPlans: [...action.plans]
            }
        default: 
            return {
                ...state,
            }
    }
};

export default PlanReducer;