import React from "react";
import styled from "styled-components";
import { space, color, layout, position, flexbox } from "styled-system";

import Box from "konnekt/Box";

const Body = styled(Box)`
  padding: 12px 0 0 0;
  overflow-x: hidden;

  ${space}
  ${color}
  ${layout}
  ${position}
  ${flexbox}
`;

const SmartCardBody = ({ children, ...rest }) => {
  return <Body {...rest}>{children}</Body>;
};

export default SmartCardBody;
