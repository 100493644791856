// Active Smart Cards
export const PERSONAL_DETAILS = "PERSONAL_DETAILS";
export const NOTES = "NOTES";
export const TAGS = "TAGS";
export const CONVERSATION_HISTORY = "CONVERSATION_HISTORY";
export const ORDER_EVENT = "ORDER_EVENT";
export const ORDER_HISTORY = "ORDER_HISTORY";

// Activer Smart Cards Type
export const VIEW = "VIEW";
export const EDIT = "EDIT";
export const ADD = "ADD";
