import React, { useState } from "react";
import useMediaQuery from "customHooks/useMediaQuery";
import Table from "konnekt/Table";
import Icon from "konnekt/Icon";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import BusinessTableData from "constants/newConstants/BusinessTableData.json";
// import MobileTemplateTableData from "constants/newConstants/MobileTemplateTableData.json";
import { CommonBox, CommonContainer } from "../BusinessStyles";
import { getFormattedTime } from "util/timeUtility";
import Box from "konnekt/Box";
import Modal, { ModalHeader } from "konnekt/Modal";
import { DeleteMessageText } from "CommonStyle/CommonStyles";
import Button from "konnekt/Buttons";
import { ModalFooterItem } from "modules/Manage/pages/TagModules/TagModules.styles";
import { deleteBusinessV2 } from "modules/Manage/ManagePageAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const StyledPara = styled("p")`
  margin-top: 0;
  margin-bottom: 0;
`;
const BusinessListTable = ({ handleBack, businessList, handleEditAction }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [businessItem, setBusinessItem] = useState("");
  const isMobile = useMediaQuery("tablet", "down");
  const renderList = () => {
    return renderItem(businessList);
  };
  const actionClick = (item, check) => {
    handleEditAction(item, check);
    if (!check) {
      setDeleteModal(true);
      setBusinessItem(item);
    }
  };
  const handleDeleteBussines = () => {
    setDeleteModal(false);
  };

  const actionFn = (item) => {
    return (
      <>
        <StyledPara>
          <Icon
            iconName="editVector"
            color="error.600"
            onClick={() => actionClick(item, true)}
            noWrapper
          />
          <Icon
            onClick={() => actionClick(item, false)}
            iconName="deleteVector"
            color="error.600"
            noWrapper
          />
        </StyledPara>
      </>
    );
  };

  const renderItem = (item) => {
    let rowData = [];
    const tableKeys = [
      "name",
      "business_address",
      "contact_number",
      "tax_number",
      "country",
      "created_at",
    ];
    for (let key of tableKeys) {
      if (key === "created_at") {
        item.created_at = item?.created_at
          ? getFormattedTime(item.created_at, "MMMM DD, YYYY")
          : "-";
      }
      rowData.push(item[key]);
    }
    rowData.push(actionFn(item));
    return [rowData];
  };

  return (
    <CommonContainer>
      <CommonBox marginBottom="2rem" justifyContent="space-between">
        <Box display="flex">
          <Icon
            cursor="pointer"
            iconName="back"
            width="2rem"
            style={{ marginRight: "1rem" }}
            onClick={() => handleBack()}
          />
          <h3>Business List</h3>
        </Box>
        <Button
          text="View Project"
          onClick={() => {
            history.push(`/super-admin/project/${businessList?._id}`);
          }}
        />
      </CommonBox>
      <Table
        gridTemplateColumnsHeader={
          isMobile ? "30% 30% 40%" : "13% 18% 12% 15% 18% 12% 12%"
        }
        gridTemplateColumnsBody={
          isMobile ? "30% 30% 40%" : "13% 18% 12% 15% 18% 12% 12%"
        }
        columns={BusinessTableData}
        data={renderList()}
        tableProps={{
          theadProps: {
            position: "unset",
          },
        }}
      />
      {deleteModal && (
        <Box width="100%">
          <Modal
            isOpen={deleteModal}
            onHide={handleDeleteBussines}
            pl="24"
            pr="24"
            maxHeight="90%"
            overflowY="auto"
          >
            <ModalHeader
              variant="white"
              onHide={handleDeleteBussines}
              padding="0"
            >
              Delete Business
            </ModalHeader>
            <DeleteMessageText>
              Are you sure you want to delete Business ?
            </DeleteMessageText>
            <ModalFooterItem>
              <Button
                type="outline"
                text={"No"}
                onClick={() => setDeleteModal(false)}
              />
              <Button
                type="primary"
                text={"Yes"}
                marginLeft="10px"
                onClick={() => {
                  dispatch(deleteBusinessV2(businessItem));
                  handleBack();
                }}
              />
            </ModalFooterItem>
          </Modal>
        </Box>
      )}
      ;
    </CommonContainer>
  );
};

export default BusinessListTable;
