import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import styled from "styled-components";

import Modal, { ModalHeader } from "konnekt/Modal";
import {
  fetchProjectList,
  removeProjectFromList,
  updateProjectUserDetails,
} from "modules/App/AppActions";
import { getAllPlans } from "modules/Plans/PlansSelectors";
import FormUpdater from "modules/Common/formUpdater";
import { showNotification } from "util/utility";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProjectTable from "./components/ProjectTable";

const Heading = styled(Box)`
  margin-top: 5rem;
  padding: 30px 0px 0px 30px;
  font-weight: 600;
  font-size: 2rem;

  h4 {
    font-size: 18px;
  }

  h6 {
    font-size: 13px;
    padding-top: 13px;
  }

  @media only screen and (max-width: 767px) {
    padding: 30px 0px 0px 0px;
    margin: 0px 13px;
    h4 {
      font-size: 18px;
    }
  }
`;

const ModalFooterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: right;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const SuperAdminProjectPage = () => {
  const dispatch = useDispatch();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [errors, setErrors] = useState({});
  const params = useParams();
  const activeBusinessId = params?.id;

  const formKeys = [
    "name",
    "plan_name",
    "business_id",
    "status",
    "quality_status",
    "whatsapp_api_status",
    "website",
    "email",
    "description",
    "is_active",
    "has_chat_auto_resolve",
    "address",
    "phone_number",
    "wa_display_name",
    "wa_business_description",
    "wa_about_description",
    "wa_business_logo_url",
    "wa_business_website",
    "wa_country_code",
    "wa_phone_number",
    "fb_business_manager_status",
    "fb_business_verification_status",
    "facebook_generated_token",
    "business_api_key",
    "wa_api_key",
    "waba_id",
    "whatsapp_service",
    "ac_credit_price",
    "mc_credit_price",
    "uc_credit_price",
    "sc_credit_price",
    "created_at",
    "credit",
  ];
  const tableKeys = [
    "name",
    "plan_name",
    "status",
    "website",
    "email",
    "description",
    "address",
    "phone_number",
    "is_active",
  ];
  let projectList = useSelector((state) => state.app?.projectList);
  const projectListCopy = useSelector((state) => state.app?.projectListCopy);
  let isProjectRemoved = useSelector((state) => state.app?.isProjectRemoved);

  const plans = useSelector(getAllPlans);

  useEffect(() => {
    if (activeBusinessId) {
      dispatch(fetchProjectList(activeBusinessId));
    }
  }, [activeBusinessId]);

  const actionHandler = (editFlag, deleteFlag, selected) => {
    if (editFlag) {
      const projectItem = projectListCopy.find((it) => it._id === selected._id);
      setOpenUpdateModal(editFlag);
      setSelectedProject(projectItem);
    }
    if (deleteFlag) {
      const projectItem = projectListCopy.find((it) => it._id === selected._id);
      setOpenDeleteModal(deleteFlag);
      setSelectedProject(projectItem);
    }
  };

  const modalHandler = () => {
    setOpenUpdateModal(false);
    setOpenDeleteModal(false);
    setErrors({});
  };

  const updateDetails = (keyName, keyValue) => {
    let update = { ...selectedProject };
    update[keyName] = keyValue;
    setSelectedProject(update);
  };

  const headerTitle = () => {
    if (openUpdateModal) {
      return "Edit Project";
    }
    if (openDeleteModal) {
      return "Delete Project";
    }
  };

  const getAttributeName = (key) => {
    let str1 = key.replace("_", " ");
    return str1.charAt(0).toUpperCase() + str1.slice(1);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    for (let key of formKeys) {
      if (
        typeof selectedProject[key] !== "boolean" &&
        typeof selectedProject[key] !== "number" &&
        (!selectedProject[key] || selectedProject[key] === "")
      ) {
        isValid = false;
        error[key] = `Please enter ${getAttributeName(key)}`;
      }
      if (
        typeof selectedProject[key] == "number" &&
        selectedProject[key] === ""
      ) {
        isValid = false;
        error[key] = `Please ===== ${getAttributeName(key)}`;
      }
      if (key === "phone_number") {
        if (selectedProject[key] && selectedProject[key].length !== 10) {
          isValid = false;
          error[key] = "Invalid phone number";
        }
      }
      if (key === "email") {
        const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!checkEmail.test(selectedProject[key])) {
          isValid = false;
          error[key] = "Invalid email address";
        }
      }
      if (key === "website") {
        if (!selectedProject[key] || selectedProject[key] === "") {
          isValid = false;
          error[key] = "Invalid website address";
        }
      }
      if (key === "name") {
        if (!selectedProject[key] || selectedProject[key] === "") {
          isValid = false;
          error[key] = "Please enter name";
        }
      }
      if (key === "plan_name") {
        if (!selectedProject[key] || selectedProject[key] === "") {
          isValid = false;
          error[key] = "Invalid plan";
        }
      }
      if (key === "description") {
        if (!selectedProject[key] || selectedProject[key] === "") {
          isValid = false;
          error[key] = "Please enter description";
        }
      }
      if (key === "address") {
        if (!selectedProject[key] || selectedProject[key] === "") {
          isValid = false;
          error[key] = "Please enter valid address";
        }
      }
    }

    return {
      isValid,
      error,
    };
  };

  const handleDeleteProject = (id) => {
    dispatch(removeProjectFromList(selectedProject?._id));
    setOpenDeleteModal(false);
  };

  const saveHandler = () => {
    let validate = handleValidation();
    // let validate = { isValid: true, error: {} };
    if (validate.isValid) {
      const data = { ...selectedProject, update: "crm" };
      dispatch(updateProjectUserDetails(selectedProject?._id, data));

      if (openUpdateModal) {
        setOpenUpdateModal(false);
        setErrors({});
      }
    } else {
      setErrors(validate.error);
      showNotification("error", "There are some errors!");
    }

    if (openDeleteModal) {
      setOpenDeleteModal(false);
    }
    dispatch(fetchProjectList());
  };

  if (openUpdateModal) {
    return (
      <FormUpdater
        title={headerTitle()}
        modalHandler={modalHandler}
        selectedItem={selectedProject}
        updateDetails={updateDetails}
        saveHandler={saveHandler}
        errors={errors}
        formKeys={formKeys}
        tableKeys={tableKeys}
        plans={plans}
      />
    );
  }
  return (
    <div>
      <Heading>Project List</Heading>
      <ProjectTable
        projectList={projectList}
        projectListCopy={projectListCopy}
        actionHandler={actionHandler}
        tableKeys={tableKeys}
        // formKeys={formKeys}
        plans={plans}
      />
      <Modal isOpen={openDeleteModal} onHide={modalHandler} pl="24" pr="24">
        <ModalHeader
          variant="white"
          onHide={modalHandler}
          padding="0"
          border-bottom="none"
          border="none"
          style={{ borderBottom: "none" }}
        >
          {headerTitle()}
        </ModalHeader>
        <Box>Are you sure you want to delete this project ?</Box>
        <ModalFooterItem>
          <Button
            type="outline"
            text={openDeleteModal ? "No" : "Cancel"}
            onClick={() => modalHandler()}
          />
          <Button
            type="primary"
            text={openDeleteModal ? "Yes" : "Save"}
            marginLeft="10px"
            onClick={() => handleDeleteProject()}
          />
        </ModalFooterItem>
      </Modal>
    </div>
  );
};

export default SuperAdminProjectPage;
