import {
  SET_REPLY_MESSAGE_TEXT,
  SET_REPLY_MESSAGE_TEMPLATE,
  SET_REPLY_MESSAGE_ATTACHMENT,
  SET_CHAT_GALLERY_STATUS,
  SET_SELECTED_QUICK_REPLY,
} from "./ReplyMessageActions";

const initialState = {
  replyMessageThread: {},
  showGallery: false,
};

const replyMessageReducer = (state = initialState, action) => {
  let replyMessageThread = {};

  switch (action.type) {
    case SET_REPLY_MESSAGE_TEXT:
      replyMessageThread = state.replyMessageThread;
      replyMessageThread[action.chat_id] = {
        ...replyMessageThread[action.chat_id],
        text: action.text,
      };
      return {
        ...state,
        replyMessageThread: replyMessageThread,
      };

    case SET_REPLY_MESSAGE_TEMPLATE:
      replyMessageThread = state.replyMessageThread;
      replyMessageThread[action.chat_id] = {
        ...replyMessageThread[action.chat_id],
        template: action.template,
      };
      return {
        ...state,
        replyMessageThread: replyMessageThread,
      };

    case SET_REPLY_MESSAGE_ATTACHMENT:
      replyMessageThread = state.replyMessageThread;
      replyMessageThread[action.chat_id] = {
        ...replyMessageThread[action.chat_id],
        attachment: action.attachment,
      };
      return {
        ...state,
        replyMessageThread: replyMessageThread,
      };

    case SET_SELECTED_QUICK_REPLY:
      replyMessageThread = state.replyMessageThread;
      replyMessageThread[action.chat_id] = {
        ...replyMessageThread[action.chat_id],
        quickReply: action.quickReply,
      };
      return {
        ...state,
        replyMessageThread: replyMessageThread,
      };

    case SET_CHAT_GALLERY_STATUS:
      return {
        ...state,
        showGallery: action.showGallery,
      };

    default:
      return { ...state };
  }
};

export default replyMessageReducer;

export const getReplyMessageThread = (state) =>
  state.replyMessage.replyMessageThread;

export const getShowGalleryStatus = (state) => state.replyMessage.showGallery;
