import moment from "moment";
import {
  ARCHIVE_TEMPLATE_DEATILS,
  ARCHIVING_TEMPLATE_DEATILS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_STATUS,
  FETCH_ACTIVE_TEMPLATE_LIST_STATUS,
  FETCH_ACTIVE_TEMPLATE_LIST_V2,
  FETCH_DELETED_TEMPLATE_LIST,
  FETCH_DELETED_TEMPLATE_LIST_STATUS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_LIST,
  FETCH_TEMPLATE_LIST_STATUS,
  FETCH_TEMPLATE_STATUS,
  SYNC_TEMPLATE,
  TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL,
  UNARCHIVE_TEMPLATE_DEATILS,
  UNARCHIVING_TEMPLATE_DEATILS,
  UPDATE_ACTIVE_TEMPLATE_LIST,
  UPDATE_DELETED_TEMPLATE_LIST,
  UPDATE_TEMPLATE_DETAIL,
  UPDATE_TEMPLATE_LIST,
  UPDATING_TEMPLATE_DETAIL,
  TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION,
  UPDATE_ORG_TEMPLATES,
} from "./TemplateActions";

const initialState = {
  templateList: [],
  activeTemplateListV2: [],
  deletedTemplateList: [],
  pageNum: 1,
  pageSize: 2000,
  groupListCount: 0,
  totalCount: 0,
  activeTotalCount: 0,
  deletedTotalCount: 0,
  isCreatingTemplate: false,
  isFetchingTemplateList: false,
  isFetchingActiveTemplateListV2: false,
  isFetchingActiveTemplateList: false,
  isFetchingDeletedTemplateList: false,
  isFetchingInitialActiveTemplateList: true,
  isFetchingInitialDeletedTemplateList: false,
  isTemplateListLoaded: false,
  originalTemplateList: [],
  activeOriginalTemplateList: [],
  activeOriginalTemplateListV2: [],
  deletedOriginalTemplateList: [],
  isArchivingTemplateDetail: false,
  isArchiveTemplateDetail: false,
  isUnarchivingTemplateDetail: false,
  isUnarchiveTemplateDetail: false,
  isUpdatingTemplateDetail: false,
  isSyncing: false,
  isTemplateSampleModalVisible: false,
  isTemplateSampleModalConfirmationVisible: false,
};

const groupList = (list) => {
  let newListObj = list.reduce((r, a) => {
    r[a.name] = r[a.name] || [];
    r[a.name].push(a);
    return r;
  }, Object.create({}));
  let newList = [];
  for (let key in newListObj) {
    if (newListObj.hasOwnProperty(key)) {
      newList.push(newListObj[key]);
    }
  }
  newList = newList.map((items) => {
    let obj = {};
    obj.id = items[0].id;
    obj.name = items[0].name;
    obj.display_name = items[0].display_name;
    obj.category = items[0].category;
    obj.modified_at_utc = moment().subtract(6, "years");
    obj.created_by_user = items[0].created_by_user
      ? items[0].created_by_user
      : {};
    obj.created_at_utc = items[0].created_at_utc ? items[0].created_at_utc : "";
    obj.templates = [];
    items.forEach((item) => {
      let template = {};
      template.id = item.id;
      template.header = item.header;
      template.header_format = item.header_format;
      template.body = item.body;
      template.footer = item.footer;
      template.language = item.language;
      template.approval_status = item.approval_status;
      template.created_at_utc = item.created_at_utc;
      template.modified_at_utc = item.modified_at_utc;
      template.buttons = item.buttons;
      template.wa_template_id = item.wa_template_id;
      template.created_by_user_id = item.created_by_user_id;
      template.organization_id = item.organization_id;
      obj.templates.push(template);
      if (moment(item.modified_at_utc).diff(moment(obj.modified_at_utc))) {
        obj.modified_at_utc = item.modified_at_utc;
      }
    });
    return obj;
  });

  // newList = newList.sort((a, b) => moment(a.modified_at_utc).isBefore(b.modified_at_utc) ? 1 : -1);
  // newList = newList.sort((a, b) => moment(a.created_at_utc).isBefore(b.created_at_utc) ? 1 : -1);

  return newList;
};

const templateReducer = (state = initialState, action) => {
  let newList = [];
  switch (action.type) {
    case SYNC_TEMPLATE:
      return {
        ...state,
        isSyncing: action.isSyncing,
      };
    case FETCH_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingTemplateList: action.isFetchingTemplateList,
        isFetchingInitialTemplateList: action.isFetchingInitialTemplateList,
      };

    case FETCH_ACTIVE_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingActiveTemplateList: action.isFetchingActiveTemplateList,
        isFetchingInitialActiveTemplateList:
          action.isFetchingInitialActiveTemplateList,
      };

    case FETCH_DELETED_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingDeletedTemplateList: action.isFetchingDeletedTemplateList,
        isFetchingInitialDeletedTemplateList:
          action.isFetchingInitialDeletedTemplateList,
      };

    case FETCH_TEMPLATE_LIST:
      newList = groupList([...action.templateList]);
      return {
        ...state,
        templateList:
          action.pageNum === 1 ? newList : [...state.templateList, ...newList],
        originalTemplateList:
          action.pageNum === 1
            ? action.templateList
            : [...state.originalTemplateList, ...action.templateList],
        // templateList: action.templateList,
        pageNum: action.pageNum,
        pageSize: action.pageSize,
        isTemplateListLoaded: true,
        groupListCount:
          action.pageNum === 1
            ? newList.length
            : [...state.templateList, ...newList].length,
        totalCount: action.totalCount,
        isFetchingTemplateList: action.isFetchingTemplateList,
        isFetchingInitialTemplateList: action.isFetchingInitialTemplateList,
      };

    case FETCH_ACTIVE_TEMPLATE_LIST_V2:
      let sortedTemplateList = action.activeTemplateListV2;
      sortedTemplateList = sortedTemplateList.sort((a, b) =>
        moment(a.created_at_utc).isBefore(b.created_at_utc) ? 1 : -1,
      );
      newList = groupList(sortedTemplateList);
      return {
        ...state,
        activeTemplateListV2: newList,
        activeOriginalTemplateListV2: sortedTemplateList,
        activeTotalCount: newList.length,
        isFetchingActiveTemplateListV2: action.isFetchingActiveTemplateListV2,
      };

    case FETCH_DELETED_TEMPLATE_LIST:
      newList = groupList([...action.deletedTemplateList]);
      return {
        ...state,
        deletedTemplateList:
          action.pageNum === 1
            ? newList
            : [...state.deletedTemplateList, ...newList],
        deletedOriginalTemplateList:
          action.pageNum === 1
            ? action.deletedTemplateList
            : [
                ...state.deletedOriginalTemplateList,
                ...action.deletedTemplateList,
              ],
        // deletedTemplateList: action.deletedTemplateList,
        pageNum: action.pageNum,
        pageSize: action.pageSize,
        deletedTotalCount:
          action.pageNum === 1
            ? newList.length
            : [...state.deletedTemplateList, ...newList].length, // action.deletedTotalCount,
        isFetchingDeletedTemplateList: action.isFetchingDeletedTemplateList,
        isFetchingInitialDeletedTemplateList:
          action.isFetchingInitialDeletedTemplateList,
      };

    case FETCH_TEMPLATE_STATUS:
      return {
        ...state,
        isFetchingTemplate: action.isFetchingTemplate,
      };

    case FETCH_TEMPLATE:
      return {
        ...state,
        activeTemplate: action.activeTemplate,
        isFetchingTemplate: action.isFetchingTemplate,
      };

    case CREATE_TEMPLATE_STATUS:
      return {
        ...state,
        isCreatingTemplate: action.isCreatingTemplate,
      };

    case CREATE_TEMPLATE:
      return {
        ...state,
        isCreatingTemplate: action.isCreatingTemplate,
        templateList: [action.template, ...state.templateList],
      };

    case UPDATE_TEMPLATE_LIST:
      newList = groupList(action.templateList);
      return {
        ...state,
        templateList: newList,
      };

    case UPDATE_ACTIVE_TEMPLATE_LIST:
      newList = groupList(action.activeTemplateListV2);
      return {
        ...state,
        activeTemplateListV2: newList,
      };

    case UPDATE_DELETED_TEMPLATE_LIST:
      newList = groupList(action.deletedTemplateList);
      return {
        ...state,
        deletedTemplateList: newList,
      };

    case UNARCHIVING_TEMPLATE_DEATILS:
      return {
        ...state,
        isUnarchivingTemplateDetail: action.isUnarchivingTemplateDetail,
      };

    case ARCHIVING_TEMPLATE_DEATILS:
      return {
        ...state,
        isArchivingTemplateDetail: action.isArchivingTemplateDetail,
      };

    case TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL:
      return {
        ...state,
        isTemplateSampleModalVisible: action.isTemplateSampleModalVisible,
        isTemplateSampleModalConfirmationVisible:
          action.isTemplateSampleModalConfirmationVisible,
      };

    case UNARCHIVE_TEMPLATE_DEATILS:
      return {
        ...state,
        isUnarchivingTemplateDetail: action.isUnarchivingTemplateDetail,
        isUnarchiveTemplateDetail: action.isUnarchiveTemplateDetail,
      };

    case ARCHIVE_TEMPLATE_DEATILS:
      return {
        ...state,
        isArchivingTemplateDetail: action.isArchivingTemplateDetail,
        isArchiveTemplateDetail: action.isArchiveTemplateDetail,
      };

    case UPDATING_TEMPLATE_DETAIL:
      return {
        ...state,
        isUpdatingTemplateDetail: action.isUpdatingTemplateDetail,
      };

    case UPDATE_TEMPLATE_DETAIL:
      return {
        ...state,
        isUpdatingTemplateDetail: action.isUpdatingTemplateDetail,
        activeTemplate: state.activeTemplate,
      };

    case TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION:
      return {
        ...state,
        isTemplateSampleModalConfirmationVisible:
          action.isTemplateSampleModalConfirmationVisible,
      };

    case UPDATE_ORG_TEMPLATES: {
      let isOldTemplate = false;
      let oldTemplateList = state.activeTemplateListV2;
      if (action.event && oldTemplateList) {
        oldTemplateList = oldTemplateList.map((item) => {
          if (item.name === action.event?.name) {
            item.templates = item.templates.map((template) => {
              if (template.id === action.event?.id) {
                isOldTemplate = true;
                return action.event;
              }
              return template;
            });
          }
          return item;
        });
        if (!isOldTemplate) {
          let obj = {};
          obj.id = action.event?.id;
          obj.name = action.event?.name;
          obj.display_name = action.event?.display_name;
          obj.category = action.event?.category;
          obj.modified_at_utc = action.event?.modified_at_utc;
          obj.created_by_user = action.event?.created_by_user
            ? action.event?.created_by_user
            : {};
          obj.created_at_utc = action.event?.created_at_utc
            ? action.event?.created_at_utc
            : "";
          obj.templates = [];
          obj.templates.push({ ...action.event });
          oldTemplateList.unshift(obj);
        }
      }
      const getActiveOriginalTemplateListV2 = () => {
        if (typeof action?.event !== "object") {
          return [...state.activeOriginalTemplateListV2];
        }
        const objIndex = state.activeOriginalTemplateListV2.findIndex(
          (item) => item.id === action?.event?.id,
        );
        // Condition when object is already there
        if (objIndex !== -1) {
          return state.activeOriginalTemplateListV2.map((item) => {
            if (item.id === action.event?.id) {
              return { ...action.event };
            }
            return { ...item };
          });
        }
        return [{ ...action.event }, ...state.activeOriginalTemplateListV2];
      };
      return {
        ...state,
        activeOriginalTemplateListV2: getActiveOriginalTemplateListV2(),
        activeTemplateListV2: oldTemplateList,
        groupListCount: oldTemplateList.length,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default templateReducer;

export const getTemplateList = (state) => state.template.templateList;
export const getActiveTemplateList = (state) =>
  state.template.activeTemplateListV2;
export const getDeletedTemplateList = (state) =>
  state.template.deletedTemplateList;
export const getApprovedTemplateList = (state) =>
  state.template.activeOriginalTemplateList &&
  state.template.activeOriginalTemplateList.length > 0
    ? state.template.activeOriginalTemplateList.filter(
        (item) => item.approval_status === "APPROVED",
      )
    : [];
export const getApprovedTemplateListV2 = (state) =>
  state.template.activeOriginalTemplateListV2 &&
  state.template.activeOriginalTemplateListV2.length > 0
    ? state.template.activeOriginalTemplateListV2.filter(
        (item) => item.approval_status === "APPROVED",
      )
    : [];
export const getTemplate = (state, templateId) =>
  state.template.activeOriginalTemplateListV2.find(
    ({ id }) => templateId === id,
  );
export const getTemplatePageNum = (state) => state.template.pageNum;
export const getTemplatePageSize = (state) => state.template.pageSize;
export const getTemplateTotalCount = (state) => state.template.totalCount;
export const getTemplateGroupCount = (state) => state.template.groupListCount;
export const getActiveTemplateTotalCount = (state) =>
  state.template.activeTotalCount;
export const getDeletedTemplateTotalCount = (state) =>
  state.template.deletedTotalCount;
export const getCreateTemplateStatus = (state) =>
  state.template.isCreatingTemplate;
export const getTemplateListFetchStatus = (state) =>
  state.template.isFetchingTemplateList;
export const getActiveTemplateListFetchStatus = (state) =>
  state.template.isFetchingActiveTemplateList;
export const getDeletedTemplateListFetchStatus = (state) =>
  state.template.isFetchingDeletedTemplateList;
export const getInitialTemplateListFetchStatus = (state) =>
  state.template.isFetchingInitialTemplateList;
// export const getInitialActiveTemplateListFetchStatus = (state) => state.template.isFetchingInitialActiveTemplateList;
export const getInitialActiveTemplateListFetchStatus = (state) =>
  state.template.isFetchingActiveTemplateListV2;
export const getInitialDeletedTemplateListFetchStatus = (state) =>
  state.template.isFetchingInitialDeletedTemplateList;
export const getTemplateFetchStatus = (state) =>
  state.template.isFetchingTemplate;
export const getPageNUm = (state) => state.template.pageNum;
export const getPageSize = (state) => state.template.pageSize;
export const getTemplateCount = (state) => state.template.templateList.length;
export const getTotalTemplateCount = (state) => state.template.totalCount;
export const getActiveTemplate = (state) => state.template.activeTemplate;
export const getOriginalTemplateList = (state) =>
  state.template.originalTemplateList;
export const getOriginalActiveTemplateList = (state) =>
  state.template.activeOriginalTemplateListV2;
export const getOriginalDeletedTemplateList = (state) =>
  state.template.deletedOriginalTemplateList;
export const getIsTemplateListLoaded = (state) =>
  state.template.isTemplateListLoaded;
export const getArchivingTemplateFetchStatus = (state) =>
  state.template.isArchivingTemplateDetail;
export const getUnarchivingTemplateFetchStatus = (state) =>
  state.template.isUnarchivingTemplateDetail;
export const getArchiveTemplateFetchStatus = (state) =>
  state.template.isArchiveTemplateDetail;
export const getUnarchiveTemplateFetchStatus = (state) =>
  state.template.isUnarchiveTemplateDetail;
export const getUpdatingTemplateDetailsStatus = (state) =>
  state.template.isUpdatingTemplateDetail;
export const getSyncingTemplateDetailsStatus = (state) =>
  state.template.isSyncing;
export const getTemplateSampleModalState = (state) =>
  state.template.isTemplateSampleModalVisible;
export const getTemplateSampleConfirmationModalState = (state) =>
  state.template.isTemplateSampleModalConfirmationVisible;
// selectors
