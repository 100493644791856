export const ADD_MEDIA_PREVIEW_ITEM_START = "ADD_MEDIA_PREVIEW_ITEM_START";
export const ADD_MEDIA_PREVIEW_ITEM_SUCCESS = "ADD_MEDIA_PREVIEW_ITEM_SUCCESS";
export const ADD_MEDIA_PREVIEW_ITEM_FAILURE = "ADD_MEDIA_PREVIEW_ITEM_FAILURE";

export const DELETE_MEDIA_PREVIEW_ITEM = "DELETE_MEDIA_PREVIEW_ITEM";
export const CLOSE_MEDIA_PREVIEW_VISIBILITY = "CLOSE_MEDIA_PREVIEW_VISIBILITY";
export const SET_ACTIVE_INDEX_MEDIA_PREVIEW = "SET_ACTIVE_INDEX_MEDIA_PREVIEW";
export const UPDATE_TEXT_MEDIA_PREVEIW = "UPDATE_TEXT_MEDIA_PREVEIW";
export const UPDATE_BOLD_FORMAT = "UPDATE_BOLD_FORMAT";
export const UPDATE_ITALIC_FORMAT = "UPDATE_ITALIC_FORMAT";
export const UPDATE_STRIKE_THROUGH_FORMAT = "UPDATE_STRIKE_THROUGH_FORMAT";
export const INITIAL_FORMAT_DATA = "INITIAL_FORMAT_DATA";
