/* eslint-disable */
import _ from "lodash";
import emojiStrip from "emoji-strip";
import { XmlEntities } from "html-entities";
import replaceAll from "replaceall";
import sanitizeHtml from "sanitize-html";
import ReactHtmlParser from "react-html-parser";
import { getFormattedTime } from "util/timeUtility";
import { whatsappFormat } from "./whatsappFormatter";
import { isJson } from "./jsonUtility";
import { MEDIA_TEMPLATE, TEMPLATE } from "./messageType";

const HtmlEntities = new XmlEntities();

const imageTypes = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
const audioTypes = [
  "wav",
  "mp3",
  "audio",
  "opus",
  "3gp",
  "aa",
  "aac",
  "webm",
  "m4a",
  "ogg",
  "oga",
  "mogg",
  "aax",
  "act",
  "aiff",
  "alac",
  "amr",
  "ape",
  "au",
  "awb",
  "dct",
  "flac",
  "gsm",
  "iklax",
  "ivs",
  "m4b",
  "m4p",
  "mmf",
  "mpc",
  "msv",
  "nmf",
  "rm",
  "ra",
  "raw",
  "rf64",
  "sln",
  "tta",
  "voc",
  "vox",
  "wma",
  "wv",
  "8svx",
  "cda",
];
const videoTypes = ["mp4", "3gpp"];

export function sortArrayWithTimeStamp(array) {
  if (_.isArray(array)) {
    return array.sort((obj1, obj2) => obj1.timestamp - obj2.timestamp);
  }
}

export function sortArrayWithTimeStampNewest(array) {
  if (_.isArray(array)) {
    return array.sort((obj1, obj2) => obj2.timestamp - obj1.timestamp);
  }
}

export function reverseSortArrayWithTimeStamp(array) {
  if (_.isArray(array)) {
    return array.sort((obj1, obj2) => obj1.timestamp - obj2.timestamp);
  }
}

export function reverseSortArrayWithCreatedAtUtc(array) {
  if (_.isArray(array)) {
    return array.sort(
      (obj1, obj2) =>
        new Date(obj2.created_at_utc).getTime() -
        new Date(obj1.created_at_utc).getTime()
    );
  }
}

export function sanitizeFromNumber(phone_number) {
  phone_number = _.toString(phone_number);
  phone_number = replaceAll(" ", "", phone_number);
  phone_number = phone_number.replace(/-/g, "");
  phone_number = phone_number.replace(/\+/g, "");
  return phone_number;
}

export function getValueFromTemplateParameter(parameter) {
  const value = "";
  if (parameter) {
    if (parameter.key_value) {
      return parameter.key_value;
    }
    if (parameter.type === "text") {
      return parameter.text;
    }
    if (parameter.type === "date_time") {
      const params = parameter.date_time;
      const date = new Date(
        params.year,
        params.month,
        params.day_of_month,
        params.hour,
        params.minute
      );
      return getFormattedTime(date, "dddd, MMMM d, YYYY hh:mm:ss a");
    }
  }
  return value;
}

export const getConversationMessage = (last_message) => {
  let text = "";
  const messageType =
    last_message.message_content_type || last_message.message_type;
  // console.log(last_message);
  if (messageType === MEDIA_TEMPLATE || messageType === TEMPLATE) {
    let template = null;
    if (isJson(last_message.raw_template)) {
      let raw_template = JSON.parse(last_message.raw_template);
      template = raw_template.templates;
    }
    // console.log("template", template, last_message);
    let headerMessage = "";
    let bodyMessage = template?.body_text;
    let footerMessage = "";
    if (template?.footer_text) {
      footerMessage = template.footer_text;
    }
    let bodyParams = last_message.merge_variables_body;
    let headerParams = last_message.merge_variables_header;
    if (template?.header_type === "text") {
      headerMessage = template?.header_text;
      if (headerParams && headerParams.length > 0 && headerMessage) {
        headerParams.forEach((item, index) => {
          const replaceStr = `{{${index + 1}}}`;
          headerMessage = replaceAll(
            replaceStr,
            _.toString(item?.key_value),
            headerMessage
          );
        });
      }
    } else {
      headerMessage =
        template?.header_type !== "none"
          ? `${_.startCase(template?.header_type)}`
          : "";
    }
    if (bodyParams && bodyParams.length > 0 && bodyMessage) {
      bodyParams.forEach((item, index) => {
        const replaceStr = `{{${index + 1}}}`;
        bodyMessage = replaceAll(
          replaceStr,
          _.toString(item?.key_value),
          bodyMessage
        );
      });
    }
    text = `${headerMessage} ${bodyMessage} ${footerMessage}`;
  } else if (last_message.message_text) {
    text = last_message?.message_text ? last_message.message_text : "";
  }
  return text.trim();
};

export const getCustomerNameOrNumber = (customer, shouldMaskNumber = false) => {
  if (!_.isEmpty(customer)) {
    if (customer && customer.contact_name) {
      return _.startCase(customer.contact_name);
    }
    if (customer.contact_phone) {
      return `${customer?.country_code}${customer?.contact_phone}`;
    }
    if (customer.wa_phone_number) {
      return `${customer?.wa_phone_number}`;
    }
    return "No Message";
  }
  return "";
};
// agents --> activeAgentList --> is_deleted = false
// code --> fullAgentList --> 2 [ChatWindow, ChatHistoryWindow] --> 11, activeAgentList --> 12-13 places --> 7 -->
// is_deleted = false

export const getAgentName = (userId, agents) => {
  if (agents && userId) {
    let userIdString = "";
    if (userId && _.isObject(userId)) {
      userIdString = userId._id;
    } else if (userId) {
      userIdString = userId;
    }
    const filterItem = agents.filter(
      (item) => _.toString(item._id) === _.toString(userIdString)
    );
    return filterItem && filterItem[0]
      ? _.startCase(
          `${filterItem[0].first_name ? filterItem[0].first_name : ""} ${
            filterItem[0].last_name ? filterItem[0].last_name : ""
          }`
        ).trim()
      : "User";
  }
  return "User";
};

export const getShortName = (str) => {
  if (str) {
    const strCopy = typeof str !== "string" ? str.toString() : str;
    const name = emojiStrip(strCopy)
      .match(/(^\S\S?|\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
    return name;
  }
  return str;
};
export const getCustomerFullName = (customer) => {
  if (!_.isEmpty(customer)) {
    if (customer.contact_name) {
      return _.startCase(customer.contact_name);
    }
    // Add additional checks for other possible name fields if needed
  }
  return "Unknown Customer";
};

export const getUrlExtension = (url) => {
  return url?.split(/[#?]/)?.[0]?.split(".").pop().trim().toLowerCase();
};

export const getFileType = (mediaUrl, media_id) => {
  if (mediaUrl) {
    const ext = getUrlExtension(mediaUrl);
    if (imageTypes.indexOf(ext) > -1) {
      return "image";
    }
    if (audioTypes.indexOf(ext) > -1) {
      return "audio";
    }
    if (videoTypes.indexOf(ext) > -1) {
      return "video";
    }
    return "document";
  }
  if (media_id) {
    return "document";
  }
  return "failed to load";
};

export const getHTMLDecodedText = (str) => {
  if (str) {
    return HtmlEntities.decode(str);
  }
  return "";
};

export const getHTMLEncodedText = (str) => {
  if (str) {
    return HtmlEntities.encode(str);
  }
  return "";
};

export function getImageMeta(url, callback) {
  const img = new Image();
  img.src = url;
  img.onload = function () {
    callback(this.width, this.height);
  };
}

export function getConvLabelById(convLabelId, convLabelList) {
  let convLabelsListFilteredById = {};
  if (convLabelId && convLabelList && convLabelList.length > 0) {
    convLabelsListFilteredById = convLabelList.filter(
      (item) => _.toString(item.id) === _.toString(convLabelId)
    )[0];
  }
  return convLabelsListFilteredById;
}

export const getNameIntials = (name) => {
  const splitedName = name && name.split(" ");

  return `${splitedName[0] ? splitedName[0][0] : ""}${
    splitedName[1] ? splitedName[1][0] : ""
  }`;
};

export const getSanitizeData = (text, initalLoad = false) => {
  let msg = text;
  if (msg && msg.length > 0) {
    if (!initalLoad) {
      msg = msg.replace(/\n/g, "");
    }
    msg = msg.replace(/<p[^>]*>/g, "\n");
    msg = msg.replace(/<br[^>]*>/g, "\n");
    msg = sanitizeHtml(msg, {
      allowedTags: ["b", "i", "em", "strong", "del"],
    });
    msg = replaceAll("<strong>", "*", msg);
    msg = replaceAll(" </strong>", "* ", msg);
    msg = replaceAll("</strong>", "*", msg);
    msg = replaceAll("<b>", "*", msg);
    msg = replaceAll(" </b>", "* ", msg);
    msg = replaceAll("</b>", "*", msg);
    msg = replaceAll("<i>", "_", msg);
    msg = replaceAll(" </i>", "_ ", msg);
    msg = replaceAll("</i>", "_", msg);
    msg = replaceAll("<em>", "_", msg);
    msg = replaceAll(" </em>", "_ ", msg);
    msg = replaceAll("</em>", "_", msg);
    msg = replaceAll("<del>", "~", msg);
    msg = replaceAll(" </del>", "~ ", msg);
    msg = replaceAll("</del>", "~", msg);
    msg = getHTMLDecodedText(msg);
    msg = msg.trim();
    return msg;
  }
};

export const messageBoxStyle = (isOpenedFromHistory, isCustomerReplies) => ({
  backgroundColor: isOpenedFromHistory ? "#A6F2B2" : null,
  boxShadow: !isCustomerReplies
    ? "2px 2px 4px rgb(155 155 155 / 25%)"
    : "unset",
});

export const renderBodyMessage = (message, isAutomatedMessage) => {
  let updatedMessage;
  if (typeof message === "string" && isJson(message)) {
    updatedMessage = JSON.parse(message);
    if (typeof updatedMessage === "string" && isJson(updatedMessage)) {
      updatedMessage = JSON.parse(updatedMessage);
    }
  } else {
    updatedMessage = message;
  }
  const bodyMessage = updatedMessage?.body?.text || updatedMessage;
  if (isAutomatedMessage) {
    return ReactHtmlParser(whatsappFormat(bodyMessage));
  }

  return ReactHtmlParser(whatsappFormat(message));
};
