import React, { useState, useEffect } from "react";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import Button from "konnekt/Buttons";
import TextInput from "konnekt/TextInput";
import { useSelector, useDispatch } from "react-redux";
import {
  agentPasswordUpdate,
  fetchUpdateAgentDetail,
  updateAgentProfile,
} from "./ProfileAction";
import Modal, { ModalHeader } from "konnekt/Modal";
import {
  ProfileBody,
  ProfileContainer,
  HadingLabel,
  ImageBody,
  ProfileDetails,
  ProfileDetailsHading,
  ProfileDetailsMessage,
  ProfileBorder,
  ProfileInput,
  ProfileInputBody,
  ProfileBtn,
  ProfileInputItem,
  ProfilesInput,
  ProfileInputIcon,
  ProfileBox,
  ModalFooterItem,
} from "./Profile.styles";

const Profile = () => {
  const activeUserId = useSelector((state) => state.app?.user?._id);
  const profileDetail = useSelector(
    (state) => state?.profile?.activeAgent?.fetchUpdateAgent?.agent
  );
  const dispatch = useDispatch();

  const [inputDisable, setInputDisable] = useState(true);
  const [profileInput, setProfileInput] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newPasswordChange, setNewPasswordChange] = useState("");
  const [oldPasswordChange, setOldPasswordChange] = useState("");
  useEffect(() => {
    if (activeUserId) {
      dispatch(fetchUpdateAgentDetail(activeUserId));
    }
  }, [activeUserId]);
  useEffect(() => {
    setProfileInput(profileDetail);
  }, [profileDetail]);

  const profileEditHandle = () => {
    setInputDisable(false);
  };
  const submitHandle = () => {
    let combindedString = [
      profileInput?.first_name,
      " ",
      profileInput?.last_name,
    ];
    let fullName = combindedString.join("");
    profileInput.contact_name = fullName;
    dispatch(updateAgentProfile(activeUserId, profileInput));
  };
  const updateHandler = (keyName, keyValue) => {
    let update = { ...profileInput };
    update[keyName] = keyValue;
    setProfileInput(update);
  };
  const modalHandler = () => {
    setOpenDeleteModal(false);
    setNewPasswordChange("");
    setOldPasswordChange("");
  };
  const changePasswordHandle = () => {
    setOpenDeleteModal(true);
  };
  const handleChangePassword = () => {
    dispatch(
      agentPasswordUpdate(
        newPasswordChange,
        oldPasswordChange,
        profileInput?.contact_email
      )
    );
    setOpenDeleteModal(false);
  };
  return (
    <Box pt="8.6rem">
      <ProfileBody>
        <HadingLabel>Profile</HadingLabel>
        <ProfileContainer>
          <ImageBody>
            <ProfileDetails>
              <Box display="flex">
                <ProfileBox>
                  <ProfileDetailsHading>
                    {profileInput?.first_name?.[0]}
                  </ProfileDetailsHading>
                  <ProfileDetailsHading>
                    {profileInput?.last_name?.[0]}
                  </ProfileDetailsHading>
                </ProfileBox>
                <Box
                  display="flex"
                  paddingLeft="2rem"
                  alignItems="start"
                  flexDirection="column"
                  justifyContent=" center"
                >
                  <ProfileDetailsHading>
                    {profileInput?.first_name}&nbsp;
                    {profileInput?.last_name}
                  </ProfileDetailsHading>
                  <ProfileDetailsMessage>
                    Update your profile details here
                  </ProfileDetailsMessage>
                </Box>
              </Box>
              <ProfileBtn>
                <Button
                  mr="10px"
                  type="outlineColor"
                  text="Password Change"
                  onClick={() => changePasswordHandle()}
                  maxHeight="3.6rem"
                  minWidth="8.8rem"
                />
              </ProfileBtn>
            </ProfileDetails>
            <ProfileBorder></ProfileBorder>
            <ProfileInputBody>
              <ProfileInput>
                <Box width="100%">
                  <Label variant="text4" mb=".5rem">
                    First Name
                  </Label>
                  <TextInput
                    type="text"
                    onChange={(e) => updateHandler("first_name", e)}
                    value={profileInput?.first_name}
                    maxWidth="30rem"
                    bodyTextLimit={24}
                    placeholder="First Name"
                    border="1px solid rgba(0,0,0,.08)"
                    height="4.2rem"
                    disabled={inputDisable}
                    color={inputDisable ? "#dbd7d7" : "#080808"}
                    fontSize="1.3rem"
                  />
                </Box>
                <Box width="100%">
                  <Label variant="text4" mb="5px">
                    Last Name
                  </Label>
                  <TextInput
                    type="text"
                    onChange={(e) => updateHandler("last_name", e)}
                    value={profileInput?.last_name}
                    maxWidth="30rem"
                    bodyTextLimit={24}
                    fontSize="1.3rem"
                    placeholder="Last Name"
                    border="1px solid rgba(0,0,0,.08)"
                    height="4.2rem"
                    disabled={inputDisable}
                    color={inputDisable ? "#dbd7d7" : "#080808"}
                  />
                </Box>
              </ProfileInput>
              <ProfileInput>
                <Box width="100%">
                  <Label variant="text4" mb=".5rem">
                    Phone. no
                  </Label>
                  <TextInput
                    type="text"
                    onChange={(e) => updateHandler("phone_number", e)}
                    value={profileInput?.phone_number}
                    maxWidth="30rem"
                    fontSize="1.3rem"
                    bodyTextLimit={24}
                    placeholder="Phone Number"
                    border="1px solid rgba(0,0,0,.08)"
                    height="4.2rem"
                    disabled={inputDisable}
                    color={inputDisable ? "#dbd7d7" : "#080808"}
                  />
                </Box>
                <Box width="100%">
                  <Label variant="text4" mb=".5rem">
                    Email ID
                  </Label>
                  <TextInput
                    type="text"
                    onChange={(e) => updateHandler("contact_email", e)}
                    value={profileInput?.contact_email}
                    maxWidth="30rem"
                    bodyTextLimit={24}
                    fontSize="1.3rem"
                    placeholder="Email Id"
                    border="1px solid rgba(0,0,0,.08)"
                    height="4.2rem"
                    disabled={inputDisable}
                    color={inputDisable ? "#dbd7d7" : "#080808"}
                  />
                </Box>
              </ProfileInput>
              <ProfileInput>
                <Box width="100%">
                  <Label variant="text4" mb=".5rem">
                    User Name
                  </Label>
                  <TextInput
                    type="text"
                    onChange={(e) => updateHandler("username", e)}
                    value={profileInput?.username}
                    maxWidth="30rem"
                    bodyTextLimit={24}
                    fontSize="1.3rem"
                    placeholder="User Name"
                    border="1px solid rgba(0,0,0,.08)"
                    height="4.2rem"
                    mb=".5rem"
                    disabled={true}
                    color="#dbd7d7"
                  />
                </Box>
              </ProfileInput>
              <Box display="flex" justifyContent="end" pt="1.6rem">
                <Button
                  type="primary"
                  text="Edit"
                  onClick={profileEditHandle}
                  minHeight="3.6rem"
                  minWidth="8.8rem"
                  mr="1rem"
                />
                <Button
                  type="primary"
                  text="Submit"
                  onClick={() => submitHandle()}
                  minHeight="3.6rem"
                  minWidth="8.8rem"
                />
              </Box>
            </ProfileInputBody>
          </ImageBody>
        </ProfileContainer>
      </ProfileBody>
      <Modal isOpen={openDeleteModal} onHide={modalHandler} pl="24" pr="24">
        <ModalHeader
          variant="white"
          onHide={modalHandler}
          padding="0"
          border-bottom="none"
          border="none"
          style={{ borderBottom: "none" }}
        >
          Password Change
        </ModalHeader>
        <Box width="100%">
          <Label variant="text6" mb=".5rem">
            Enter Old Password
          </Label>
          <ProfileInputItem>
            <ProfilesInput
              value={oldPasswordChange}
              type="text"
              placeholder="Enter Old Password"
              onChange={(e) => setOldPasswordChange(e.target.value)}
            />
          </ProfileInputItem>
          <Label variant="text6" mb=".5rem" mt="1.5rem">
            Enter New Password
          </Label>
          <ProfileInputItem>
            <ProfilesInput
              value={newPasswordChange}
              placeholder="Enter New Password"
              onChange={(e) => setNewPasswordChange(e.target.value)}
              mt="3.5rem"
            />
          </ProfileInputItem>
        </Box>
        <ModalFooterItem>
          <Button
            type="primary"
            text="Submit"
            onClick={() => handleChangePassword()}
            minHeight="3.6rem"
            minWidth="8.8rem"
          >
            Save
          </Button>
        </ModalFooterItem>
      </Modal>
    </Box>
  );
};

export default Profile;
