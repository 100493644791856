export const CONVERSATION_LABEL = "ConversationLabel";
export const QUICK_REPLY = "QuickReply";
export const LIST_MESSAGE = "ListMessage";
export const PRODUCT_CATALOG = "ProductCatalog";
export const MESSAGE_TEMPLATE = "MessageTemplate";
export const BULK_UPLOAD = "BulkUpload";
export const APP_INTEGRATION = "AppIntegration";
export const APP_HEADER_MENU_LABELS = {
  ANALYTICS: "analytics-menu",
  CAMPAIGN_DETAIL: "campaign-detail",
  CHAT_WINDOW: "chat-window-menu",
  CHOOSE_SAMPLE_CAMPAIGNS: "choose-sample-campaigns-menu",
  COMING_SOON: "coming-soon-menu",
  COMMERCE_SETTINGS: "commerce-settings",
  CREATE_CAMPAIGN: "create-campaign-menu",
  CREDITS: "credits-menu",
  CUSTOMER_DASHBOARD: "customer-dashboard-menu",
  CUSTOMER_LIST: "customer-list-menu",
  CUSTOMIZE_WIDGET: "customize-widget-menu",
  DEVELOPER_SETTING: "developer-setting-menu",
  INBOX: "inbox-menu",
  INSTALL_WIDGET: "install-widget-menu",
  INVOICE_HISTORY: "invoice-histoy-menu",
  MOBILE_REDIRECT: "mobile-redirect-menu",
  NOTIFICATION_DASHBOARD: "notification-dashboard-menu",
  ORGANISATION_DETAILS: "organization-details-menu",
  PROFILE_SETTING: "profile-setting-menu",
  SETTINGS_SIGNUP_BUSINESS: "settings-signup-business-menu",
  SETTING_TAGS: "setting-tags-menu",
  SIGNUP_BUSINESS: "signup-business-menu",
  SIGN_UP_DEMO: "sign-up-demo-menu",
  SIGN_UP_WHATSAPP: "sign-up-whatsapp-menu",
  SUBSCRIPTION_DETAILS: "subscription-details-menu",
  TEAM_SETTING: "team-setting-menu",
  USAGE_HISTORY: "usage-history-menu",
};

export const DEFAULT_PAGINATION_INFO = {
  pageNum: 1,
  pageSize: '10'
}