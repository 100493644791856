import { useEffect, useRef } from "react";

/**
 * Use this hook to register an event
 * @param {keyof GlobalEventHandlersEventMap | keyof WindowEventHandlersEventMap} eventType the event type
 * @param {(e:Event)=>any} callback the callback toi be executed when event is fired
 * @param {MutableRefObject<any>} element the element ref
 */
export default function useEventListener(
  eventType,
  callback,
  element = window,
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (e) => callbackRef.current(e);

    if (element !== null && typeof element.addEventListener === "function") {
      element.addEventListener(eventType, handler);
    } else if (element !== null && typeof element.addListener === "function") {
      // This is for older browser safari and IE
      element.addListener(handler);
    }

    return () => {
      if (
        element !== null &&
        typeof element.removeEventListener === "function"
      ) {
        element.removeEventListener(eventType, handler);
      } else if (
        element !== null &&
        typeof element.removeListener === "function"
      ) {
        // This is for older browser safari and IE
        element.removeListener(handler);
      }
    };
  }, [eventType, element]);
}
