import fetch from "isomorphic-fetch";
import { showNotification } from "./utility";

const API_URL = process.env.REACT_APP_API_URL;
//const API_URL = `http://localhost:8000`;

export const fetchApi = (endpoint, method = "get", body) => {
  const token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  const metaVersion = window.localStorage.getItem("metaVersion")
    ? window.localStorage.getItem("metaVersion")
    : null;
  const organizationId = window.localStorage.getItem("organizationId")
    ? window.localStorage.getItem("organizationId")
    : null;
  const customerId = window.localStorage.getItem("customerId")
    ? window.localStorage.getItem("customerId")
    : null;
  const headers = {};
  headers["content-type"] = "application/json";
  if (token && token !== "") {
    headers.Authorization = `Token ${token}`;
  }
  if (metaVersion && metaVersion !== "") {
    headers[`x-interakt-version`] = `${metaVersion}`;
  }
  if (organizationId && organizationId !== "") {
    headers[`x-interakt-org-id`] = `${organizationId}`;
  }
  if (customerId && customerId !== "") {
    headers[`x-interakt-customer-id`] = `${customerId}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body: JSON.stringify(body),
  });
};

export default function callApi(
  endpoint,
  method            = "get",
  body,
  isErrorSuppressed = false,
) {
  return fetchApi(endpoint, method, body)
  .then((response) => response.json().then((json) => ({ json, response })))
  .then(({ json, response }) => {
    const responseStatus = parseInt(response.status);
    if (responseStatus >= 400) {
      if (responseStatus !== 404 || responseStatus !== 405) {
        if (responseStatus < 500) {
          if (json.message === "Token Expired") {
            window.localStorage.clear();
            window.location.href = "/login";
          }
          if (json.message) {
            if (!isErrorSuppressed) {
              showNotification("error", json.message);
            }
          } else if (
            json &&
            json.non_field_errors &&
            json.non_field_errors.length > 0
          ) {
            if (!isErrorSuppressed) {
              showNotification("error", json.non_field_errors[0]);
            }
          } else if (!isErrorSuppressed) {
            showNotification("error", JSON.stringify(json));
          }
        } else if (!isErrorSuppressed) {
          showNotification("error", "Server Error, Please try again!");
        }
      }
    }
    if (!response.ok) {
      const data = { ...json, status_code: response.status };
      // return Promise.reject(json);
      return Promise.reject(data);
    }
    return { ...json, status_code: response.status };
  })
  .then(
    (response) => response,
    (error) => error,
  );
}

export function callBulkUpload(endpoint, method = "get", body) {
  const token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  const headers = {};
  if (token && token !== "") {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body,
  })
  .then((response) => response.json().then((json) => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  })
  .then(
    (response) => response,
    (error) => error,
  );
}

function withTimeout(ms, error, promise) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error(error));
    }, ms);

    promise
    .then((value) => {
      clearTimeout(timer);
      resolve(value);
    })
    .catch((reason) => {
      clearTimeout(timer);
      reject(reason);
    });
  });
}

/**
 * * Use this to have custom errors in different status.
 * TODO:  Migrate to this apiCaller function
 * @param {{method?:import("axios").Method,endpoint:string,body?:Record<string,any>,isErrorSuppressed?:boolean,errors?:Record<string|number,string>,timeout?:number}} param0
 * @returns Promise<any>
 */
export function callApiV2({
  method = "get",
  endpoint,
  body,
  isErrorSuppressed = false,
  errors = {
    timeout: "Timeout error",
    500: "Server Error, Please try again!",
  },
  timeout = 30000,
}) {
  return withTimeout(
    timeout,
    errors.timeout,
    fetchApi(endpoint, method, body)
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      const responseStatus = parseInt(response.status, 10);
      if (responseStatus >= 400) {
        if (responseStatus !== 404 || responseStatus !== 405) {
          if (responseStatus < 500) {
            if (json.message === "Token Expired") {
              window.localStorage.clear();
              window.location.href = "/login";
            }
            if (json.message) {
              if (!isErrorSuppressed) {
                showNotification("error", json.message);
              }
            } else if (
              json &&
              json.non_field_errors &&
              json.non_field_errors.length > 0
            ) {
              if (!isErrorSuppressed) {
                showNotification("error", json.non_field_errors[0]);
              }
            } else if (!isErrorSuppressed) {
              showNotification("error", JSON.stringify(json));
            }
          } else if (!isErrorSuppressed) {
            showNotification("error",  json?.data?.message ?  json.data.message :errors[500]);
          }
        }
      }
      if (!response.ok) {
        const data = { ...json, status_code: response.status };
        // return Promise.reject(json);
        return Promise.reject(data);
      }
      return { ...json, status_code: response.status };
    })
    .then(
      (response) => response,
      (error) => error,
    ),
  );
}

export function callApiForShopify(
  endpoint,
  method            = "get",
  signuptoken,
  body,
  isErrorSuppressed = false,
) {
  const headers = {};
  headers["content-type"] = "application/json";
  if (signuptoken && signuptoken !== "") {
    headers["Signup-Token"] = signuptoken;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body: JSON.stringify(body),
  })
  .then((response) => response.json().then((json) => ({ json, response })))
  .then(({ json, response }) => {
    const responseStatus = parseInt(response.status);
    if (responseStatus >= 400) {
      if (responseStatus !== 404 || responseStatus !== 405) {
        if (responseStatus < 500) {
          if (json.message === "Token Expired") {
            window.localStorage.clear();
            window.location.href = "/login";
          }
          if (json.message) {
            if (!isErrorSuppressed) {
              showNotification("error", json.message);
            }
          } else if (
            json &&
            json.non_field_errors &&
            json.non_field_errors.length > 0
          ) {
            if (!isErrorSuppressed) {
              showNotification("error", json.non_field_errors[0]);
            }
          } else if (!isErrorSuppressed) {
            showNotification("error", JSON.stringify(json));
          }
        } else if (!isErrorSuppressed) {
          showNotification("error", "Server Error, Please try again!");
        }
      }
    }
    if (!response.ok) {
      const data = { ...json, status_code: response.status };
      // return Promise.reject(json);
      return Promise.reject(data);
    }

    return { ...json, status_code: response.status };
  })
  .then(
    (response) => response,
    (error) => error,
  );
}

export function callUploadApi(endpoint, method = "get", body) {
  const token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  const headers = {};
  if (token && token !== "") {
    headers.Authorization = `Token ${token}`;
    // headers.Authorization = `Token 339619eba96d49d03d5ba85724cd839250589f5f`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body,
  })
  .then((response) => response.json().then((json) => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  })
  .then(
    (response) => response,
    (error) => error,
  );
}
