import {
  ATTACH_PAYMENT_METHOD_STATUS,
  ATTACH_PAYMENT_METHOD,
  FETCH_INVOICE,
  INVOICE_FETCH_STATUS,
  FETCH_SUBSCRIPTION,
  SUBSCRIPTION_FETCH_STATUS,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  PAYMENT_INVOICE_FETCH_STATUS,
  FETCH_PAYMENT_INVOICE,
  FETCHING_ALL_PAYMENT_METHODS,
  FETCH_ALL_PAYMENT_METHODS,
  NOTIFICATION_USAGE_FETCH_STATUS,
  FETCH_NOTIFICATION_USAGE,
  BILLING_CYCLE_RANGE_FETCH_STATUS,
  FETCH_BILLING_CYCLE_RANGE,
  FETCH_UNIQUE_USER_COUNT,
  UNIQUE_USER_COUNT_FETCH_STATUS,
  ADDING_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD,
  UPDATE_PAYMENT_DONE_STATUS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_STATUS,
  SET_DEFAULT_PAYMENT_METHOD_STATUS,
  SET_DEFAULT_PAYMENT_METHOD,
  FETCH_CREDIT_LIST,
  FETCHING_CREDIT_LIST,
  SET_TOGGLE_ORGANIZATION_DETAIL_MODAL_STATE,
  SET_USAGE_HISTORY_ACTIVE_TAB,
  TOGGLE_USAGE_HISTORY_CALENDER,
  SET_USAGE_HISTORY_CALENDER_FIELDS,
  TOGGLE_ADD_PAYMENT_METHOD_MODAL,
  TOGGLE_EDIT_NAME_MODAL,
  TOGGLE_EDIT_EMAIL_MODAL,
  TOGGLE_EDIT_ADDRESS_MODAL,
  TOGGLE_EDIT_GST_NUMBER_MODAL,
  TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE,
  IS_PAYMENT_DONE_RIBBON,
  IS_DAYS_LEFT_FREE_TRAIL_RIBBON,
  SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS,
  FETCHING_ORGANIZATION_PLANS,
  FETCH_ORGANIZATION_PLANS,
  TOGGLE_REDIRECT_TO_SHOPIFY_STATE,
  UPDATE_PAYMENT_PLAN_CHANGE_STATE,
  FETCHING_ORG_BUSINESS_INFO,
  FETCH_ORG_BUSINESS_INFO,
  SET_WHATSAPP_CONVERSATION_CHARGES,
} from "./PaymentTypes";

const initialState = {
  isAttachingPaymentMethod: false,
  isFetchingPaymentInvoice: false,
  isFetchingPaymentMethods: false,
  paymentInvoice: {},
  invoiceList: [],
  payment3dSecurePinUrl: "",
  activeSubscriptionList: [],
  trialSubscriptionList: [],
  paidSubscriptionList: [],
  allSubscriptionList: [],
  isFetchingSubscription: false,
  isProcessingPayment: false,
  paymentProcessStatusMessages: "",
  isPaymentDone: false,
  paymentError: {},
  isFetchingPaymentInvoiceList: false,
  paymentInvoiceList: [],
  isShowPendingInvoiceNotification: false,
  uniqueUserCountList: [],
  uniqueUserTotalCount: 0,
  isFetchingUniqueUserCount: false,
  billingCycleDateRanges: [],
  isFetchingBillingCycle: false,
  notificationUsageList: [],
  notificationUsageTotalCount: 0,
  isOpenEditNameModal: false,
  isOpenEditEmailModal: false,
  isOpenEditAddressModal: false,
  isOpenEditGSTNumberModal: false,
  isOpenEditGSTNumberModalMobile: false,
  isOpenUsageHistoryCalender: false,
  usageHistoryActiveTab: 1,
  usageHistoryCalenderFields: {
    activeYear: new Date().getFullYear(),
    activeMonth: new Date().getMonth() + 1,
  },
  isOpenAddPaymentModal: false,
  paymentMobileTabs: [
    {
      name: "Subscription",
      url: "/billing/subscription",
    },
    {
      name: "Invoice",
      url: "/billing/invoice",
    },
    {
      name: "Usage",
      url: "/billing/usage-history",
    },
    {
      name: "Credits",
      url: "/billing/credits",
    },
    {
      name: "Organization",
      url: "/billing/org-details",
    },
  ],
  isFetchingNotificationUsage: false,
  paymentMethod: {},
  isAddingPaymentMethods: false,
  isSettingDefaultPaymentMethod: false,
  isDeletingPaymentMethod: false,
  isFetchingCreditHistory: false,
  creditHistoryList: [],
  currency: "inr",
  creditBalance: 0,
  isFetchSubscriptionAPIDone: false,
  isPaymentDoneRibbon: true,
  isHaveUncollectiblePayment: false,
  isHaveOpenInvoice: false,
  isDaysLeftFreeTrailRibbon: true,
  SubscriptionExpiredRibbonStatus: true,
  paymentPlans: [],
  isFetchingOrganizationPlans: false,
  isPaymentPlanLoaded: false,
  isRedirectingToShopify: false,
  activeSubscriptionFeatureList: [],
  isUpdatingPaymentPlan: false,
  isFetchingOrgBusinessInfo: false,
  orgBusinessInfo: "",
  whatsappConversationChargesData: {
    loading: false,
  },
};

const paymentReducer = (state = initialState, action) => {
  let paymentMethods = [];
  switch (action.type) {
    case IS_DAYS_LEFT_FREE_TRAIL_RIBBON:
      return {
        ...state,
        isDaysLeftFreeTrailRibbon: action.isDaysLeftFreeTrailRibbon,
      };
    case SET_WHATSAPP_CONVERSATION_CHARGES:
      return {
        ...state,
        whatsappConversationChargesData: {
          ...state.whatsappConversationChargesData,
          ...action.payload,
        },
      };
    case IS_PAYMENT_DONE_RIBBON:
      return {
        ...state,
        isPaymentDoneRibbon: action.isPaymentDoneRibbon,
      };
    case SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS:
      return {
        ...state,
        SubscriptionExpiredRibbonStatus: action.SubscriptionExpiredRibbonStatus,
      };

    case FETCH_CREDIT_LIST:
      return {
        ...state,
        isFetchingCreditHistory: action.isFetchingCreditHistory,
        creditBalance: action.creditBalance,
        currency: action.currency,
        creditHistoryList: action.creditHistoryList,
      };

    case FETCHING_CREDIT_LIST:
      return {
        ...state,
        isFetchingCreditHistory: action.isFetchingCreditHistory,
      };

    case DELETE_PAYMENT_METHOD:
      paymentMethods = [...state.paymentMethods];
      paymentMethods = paymentMethods.filter(
        (item) => item.id !== action.paymentMethodId,
      );
      return {
        ...state,
        isDeletingPaymentMethod: action.isDeletingPaymentMethod,
        paymentMethods,
      };

    case DELETE_PAYMENT_METHOD_STATUS:
      return {
        ...state,
        isDeletingPaymentMethod: action.isDeletingPaymentMethod,
      };

    case SET_DEFAULT_PAYMENT_METHOD_STATUS:
      return {
        ...state,
        isSettingDefaultPaymentMethod: action.isSettingDefaultPaymentMethod,
      };

    case SET_DEFAULT_PAYMENT_METHOD:
      paymentMethods = [...state.paymentMethods];
      paymentMethods = paymentMethods.map((item) => {
        item.is_default = false;
        if (item.id === action.paymentMethodId) {
          item.is_default = true;
        }
        return item;
      });
      return {
        ...state,
        isSettingDefaultPaymentMethod: action.isSettingDefaultPaymentMethod,
        paymentMethods,
      };

    case UPDATE_PAYMENT_DONE_STATUS:
      return {
        ...state,
        isPaymentDone: action.isPaymentDone,
      };

    case ADDING_PAYMENT_METHOD:
      return {
        ...state,
        isAddingPaymentMethods: action.isAddingPaymentMethods,
      };

    case ADD_PAYMENT_METHOD:
      if (
        state.paymentMethods &&
        state.paymentMethods[0] &&
        state.paymentMethods[0].is_default
      ) {
        paymentMethods = [
          state.paymentMethods[0],
          action.paymentMethod,
          ...state.paymentMethods.slice(1),
        ];
      } else {
        paymentMethods = [action.paymentMethod, ...state.paymentMethods];
      }
      return {
        ...state,
        isAddingPaymentMethods: action.isAddingPaymentMethods,
        paymentMethod: action.paymentMethod,
        paymentMethods,
      };

    case BILLING_CYCLE_RANGE_FETCH_STATUS:
      return {
        ...state,
        isFetchingBillingCycle: action.isFetchingBillingCycle,
      };

    case FETCH_BILLING_CYCLE_RANGE:
      return {
        ...state,
        billingCycleDateRanges: action.billingCycleDateRanges,
        isFetchingBillingCycle: action.isFetchingBillingCycle,
      };

    case UNIQUE_USER_COUNT_FETCH_STATUS:
      return {
        ...state,
        isFetchingUniqueUserCount: action.isFetchingUniqueUserCount,
      };

    case FETCH_UNIQUE_USER_COUNT:
      let uniqueUserTotalCount = 0;
      if (action.uniqueUserCountList && action.uniqueUserCountList.length > 0) {
        action.uniqueUserCountList.forEach((item) => {
          uniqueUserTotalCount += item.count;
        });
      }
      return {
        ...state,
        uniqueUserTotalCount,
        uniqueUserCountList: action.uniqueUserCountList,
        isFetchingUniqueUserCount: action.isFetchingUniqueUserCount,
      };

    case NOTIFICATION_USAGE_FETCH_STATUS:
      return {
        ...state,
        isFetchingNotificationUsage: action.isFetchingNotificationUsage,
      };

    case FETCH_NOTIFICATION_USAGE:
      let totalCount = 0;
      if (
        action.notificationUsageList &&
        action.notificationUsageList.length > 0
      ) {
        action.notificationUsageList.forEach((item) => {
          totalCount += item.count;
        });
      }
      return {
        ...state,
        notificationUsageTotalCount: totalCount,
        notificationUsageList: action.notificationUsageList,
        isFetchingNotificationUsage: action.isFetchingNotificationUsage,
      };

    case FETCH_PAYMENT_INVOICE:
      let isPending = false;
      let isHaveUncollectiblePayment = false;
      let isHaveOpenInvoice = false;
      if (action.paymentInvoiceList && action.paymentInvoiceList.length > 0) {
        action.paymentInvoiceList.forEach((item) => {
          if (item.status !== "paid" && !isPending) {
            isPending = true;
          }
          if (item.status === "open") {
            isHaveOpenInvoice = true;
          }
          if (item.status === "uncollectible") {
            isHaveUncollectiblePayment = true;
          }
        });
      }
      return {
        ...state,
        paymentInvoiceList: action.paymentInvoiceList,
        isShowPendingInvoiceNotification: isPending,
        isHaveUncollectiblePayment,
        isHaveOpenInvoice,
        isFetchingPaymentInvoiceList: action.isFetchingPaymentInvoiceList,
      };

    case PAYMENT_INVOICE_FETCH_STATUS:
      return {
        ...state,
        isFetchingPaymentInvoiceList: action.isFetchingPaymentInvoiceList,
      };

    case UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        isProcessingPayment: action.isProcessingPayment,
        isPaymentDone: action.isPaymentDone,
        paymentError: action.paymentError,
        paymentProcessStatusMessages: action.paymentProcessStatusMessages,
      };

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isProcessingPayment: action.isProcessingPayment,
        isPaymentDone: action.isPaymentDone,
        paymentError: action.paymentError,
        paymentProcessStatusMessages: action.paymentProcessStatusMessages,
      };

    case UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE:
      return {
        ...state,
        paymentProcessStatusMessages: action.paymentProcessStatusMessages,
      };

    case UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        isProcessingPayment: action.isProcessingPayment,
      };

    // created_at_utc: "2021-02-23T10:23:58+0000"
    // created_by_user_id: null
    // id: "00455771-4caa-4f13-a022-f1d07c759b1d"
    // modified_at_utc: "2021-02-23T10:23:58+0000"
    // next_payment_date: null
    // organization_id: "91e5634a-33b0-44b4-a075-884778f02feb"
    // stripe_subscription_id: "sub_IzxzpK4Igjf36j"
    // subscription:
    //     created_by_user_id: "0d84e190-f2ef-4d57-92a5-95fde6f1d1ce"
    // id: "d79c375a-9d13-4ab9-a9e7-dd8709e4cee5"
    // is_active: true
    // name: "₹2,499.00 Plan"
    // value: 2499
    // __proto__: Object
    // subscription_end_date: null
    // subscription_start_date: "2021-02-23T10:23:43+0000"

    case FETCH_SUBSCRIPTION:
      let activeSubscriptionFeatureList = [];
      if (
        action.activeSubscriptionList &&
        action.activeSubscriptionList.length > 0
      ) {
        activeSubscriptionFeatureList = action.activeSubscriptionList[0]?.subscription?.features.map(
          (item) => item.feature_code,
        );
      }
      return {
        ...state,
        isFetchingSubscription: action.isFetchingSubscription,
        isFetchSubscriptionAPIDone: action.isFetchSubscriptionAPIDone,
        activeSubscriptionList: action.activeSubscriptionList,
        trialSubscriptionList: action.trialSubscriptionList,
        paidSubscriptionList: action.paidSubscriptionList,
        allSubscriptionList: action.allSubscriptionList,
        isPaymentDone: action.activeSubscriptionList.length > 0,
        activeSubscriptionFeatureList,
      };

    case SUBSCRIPTION_FETCH_STATUS:
      return {
        ...state,
        isFetchingSubscription: action.isFetchingSubscription,
        isFetchSubscriptionAPIDone: action.isFetchSubscriptionAPIDone,
      };

    case INVOICE_FETCH_STATUS:
      return {
        ...state,
        isFetchingPaymentInvoice: action.isFetchingPaymentInvoice,
      };

    case FETCH_INVOICE:
      return {
        ...state,
        paymentInvoice: action.paymentInvoice,
        isFetchingPaymentInvoice: action.isFetchingPaymentInvoice,
      };

    case ATTACH_PAYMENT_METHOD:
      return {
        ...state,
        payment3dSecurePinUrl: action.payment3dSecurePinUrl,
        isAttachingPaymentMethod: action.isAttachingPaymentMethod,
      };

    case ATTACH_PAYMENT_METHOD_STATUS:
      return {
        ...state,
        isAttachingPaymentMethod: action.isAttachingPaymentMethod,
      };

    case FETCHING_ALL_PAYMENT_METHODS:
      return {
        ...state,
        isFetchingPaymentMethods: action.isFetchingPaymentMethods,
      };

    case FETCH_ALL_PAYMENT_METHODS:
      const defaultPayments = [];
      if (action.paymentMethods && action.paymentMethods.length > 0) {
        action.paymentMethods = action.paymentMethods.filter((item) => {
          if (item.is_default) {
            defaultPayments.push(item);
          }
          return !item.is_default;
        });
      }
      return {
        ...state,
        isFetchingPaymentMethods: action.isFetchingPaymentMethods,
        paymentMethods: [...defaultPayments, ...action.paymentMethods],
      };

    case SET_TOGGLE_ORGANIZATION_DETAIL_MODAL_STATE:
      return {
        ...state,
        organizationDetalModalState: action.organizationDetalModalState,
      };

    case SET_USAGE_HISTORY_ACTIVE_TAB:
      return {
        ...state,
        usageHistoryActiveTab: action.usageHistoryActiveTab,
      };

    case TOGGLE_USAGE_HISTORY_CALENDER:
      return {
        ...state,
        isOpenUsageHistoryCalender: action.isOpenUsageHistoryCalender,
      };

    case SET_USAGE_HISTORY_CALENDER_FIELDS:
      return {
        ...state,
        usageHistoryCalenderFields: action.usageHistoryCalenderFields,
      };

    case TOGGLE_ADD_PAYMENT_METHOD_MODAL:
      return {
        ...state,
        isOpenAddPaymentModal: action.isOpenAddPaymentModal,
      };

    case TOGGLE_EDIT_NAME_MODAL:
      return {
        ...state,
        isOpenEditNameModal: action.isOpenEditNameModal,
      };

    case TOGGLE_EDIT_EMAIL_MODAL:
      return {
        ...state,
        isOpenEditEmailModal: action.isOpenEditEmailModal,
      };

    case TOGGLE_EDIT_ADDRESS_MODAL:
      return {
        ...state,
        isOpenEditAddressModal: action.isOpenEditAddressModal,
      };

    case TOGGLE_EDIT_GST_NUMBER_MODAL:
      return {
        ...state,
        isOpenEditGSTNumberModal: action.isOpenEditGSTNumberModal,
      };

    case TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE:
      return {
        ...state,
        isOpenEditGSTNumberModalMobile: action.isOpenEditGSTNumberModalMobile,
      };

    case FETCHING_ORGANIZATION_PLANS:
      return {
        ...state,
        isFetchingOrganizationPlans: action.isFetchingOrganizationPlans,
      };

    case TOGGLE_REDIRECT_TO_SHOPIFY_STATE:
      return {
        ...state,
        isRedirectingToShopify: action.isRedirectingToShopify,
      };

    case UPDATE_PAYMENT_PLAN_CHANGE_STATE:
      return {
        ...state,
        isUpdatingPaymentPlan: action.isUpdatingPaymentPlan,
      };

    case FETCH_ORGANIZATION_PLANS:
      return {
        ...state,
        isFetchingOrganizationPlans: action.isFetchingOrganizationPlans,
        paymentPlans: action.paymentPlans,
        isPaymentPlanLoaded: action.isPaymentPlanLoaded,
      };

    case FETCHING_ORG_BUSINESS_INFO:
      return {
        ...state,
        isFetchingOrgBusinessInfo: action.isFetchingOrgBusinessInfo,
      };

    case FETCH_ORG_BUSINESS_INFO:
      return {
        ...state,
        orgBusinessInfo: action.orgBusinessInfo,
        isFetchingOrgBusinessInfo: action.isFetchingOrgBusinessInfo,
      };

    default:
      return {
        ...state,
      };
  }
};

export default paymentReducer;
