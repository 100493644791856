import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  getLoggingState,
  getAuthenticatedState,
} from "modules/App/AppSelectors";
import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import Icon from "konnekt/Icon";
import { LoginTitle } from "CommonStyle/CommonStyles";

const ImageBackground = styled(Box)`
  background-image: url("https://automateeazy.s3.us-east-1.amazonaws.com/images/LgBg.svg");
  height: 100vh;
  background-size: 100%;
  width: 100%;
  text-align: center;
  padding-top: 15rem;

  img {
    width: 300px;
    margin-bottom: 25px;
  }

  h4 {
    line-height: 38px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 30px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
    img {
      width: 100%;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 20px;
    }
  }
`;
const LoginContent = styled(Box)`
  padding: 150px 35px 0px 35px;

  a {
    text-align: center;
    display: block;
    font-weight: 600;
    color: #5e3ec5;
  }

  img {
    position: absolute;
    right: 12px;
    top: 14px;
    width: 21px;
  }

  input {
    box-sizing: border-box;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 50px 0px 15px;
  }

  button {
    background: #5e3ec5;
    border-radius: 8px;
    color: #fff;
    padding: 13px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 40px 20px;
    h2 {
      font-size: 25px;
    }
  }
`;
const IconImage = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

const ErrorImage = styled(Icon)`
  margin-right: 7px;
`;

export const SecretKeyInputIcon = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AdminLogin = ({ loginUser, ...props }) => {
  const [user, setUserData] = useState({
    username: "",
    password: "",
  });
  const history = useHistory();
  const isLoginLoading = useSelector((state) => getLoggingState(state));
  const isAuthenticated = useSelector((state) => getAuthenticatedState(state));
  const [errors, setErrors] = useState({});
  const [iconHandle, setIconHandle] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        props.location &&
        props.location.state &&
        props.location.state._refurl &&
        props.location.state._refurl !== "/"
      ) {
        history.push(props.location.state._refurl);
      } else {
        history.push("/admin/project-dashboard");
      }
    }
  }, [isAuthenticated]);

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleValidation = (client) => {
    let isValid = true;
    let newErrors = {};
    if (!client.username) {
      isValid = false;
      newErrors["username"] = "Please enter username";
    }
    if (!client.password) {
      isValid = false;
      newErrors["password"] = "Please enter password";
    }
    return {
      isValid,
      errors: newErrors,
    };
  };

  const handleLoginAPI = (creds) => {
    const USER = {
      admin: creds,
    };
    loginUser(USER);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const check = handleValidation(user);
    if (check.isValid) {
      handleLoginAPI(user);
    } else {
      setErrors(check.errors);
    }
  };

  return (
    <>
      <Box className="grid-container">
        <ImageBackground>
          <img src="/images/automate-logo.svg" alt="" />
          <h4>
            Send Personalized Campaigns <br />
            on WhatsApp
          </h4>
        </ImageBackground>
        <LoginContent>
          <Box className="content-box ">
            <Box className="form-box">
              <LoginTitle>Admin Account</LoginTitle>
              <h6>Welcome Back</h6>
              <h2>Login to AutomatEazy</h2>
              <form onSubmit={handleSubmit}>
                <Box className="input-box position-relative">
                  <input
                    value={user.username}
                    onChange={(e) =>
                      handleChangeUser("username", e.target.value)
                    }
                    type="text"
                    placeholder="Enter Username"
                  />
                  {errors && errors.username ? (
                    <label className="d-flex align-items-center">
                      <ErrorImage
                        iconName="info"
                        width="15px"
                        height="15px"
                        color="red"
                      ></ErrorImage>
                      {errors.username}
                    </label>
                  ) : null}

                  <IconImage
                    iconName="puser"
                    width="22px"
                    height="22px"
                  ></IconImage>
                </Box>
                <br />
                <Box className="input-box position-relative">
                  <input
                    value={user.password}
                    onChange={(e) =>
                      handleChangeUser("password", e.target.value)
                    }
                    type={iconHandle ? "password" : "text"}
                    placeholder="Enter Password"
                  />
                  <SecretKeyInputIcon
                    onClick={() => setIconHandle(!iconHandle)}
                    iconProps={iconHandle}
                  >
                    {iconHandle ? (
                      <Icon iconName="eyeCrossed" width="22px" />
                    ) : (
                      <Icon iconName="eyes" width="22px" height="22px" />
                    )}
                  </SecretKeyInputIcon>
                  {errors && errors.password ? (
                    <label className="d-flex align-items-center">
                      <ErrorImage
                        iconName="info"
                        width="15px"
                        height="15px"
                        color="red"
                      ></ErrorImage>
                      {errors.password}
                    </label>
                  ) : null}
                </Box>

                <Box className="input-box">
                  <Button>{isLoginLoading ? "Login..." : "Login"}</Button>
                  {/* <Box display="flex" justifyContent="center">
                    <Box mr="4px">Don't have an account?</Box>
                    <Link to="/register"> Register</Link>
                  </Box> */}
                </Box>
              </form>
            </Box>
          </Box>
        </LoginContent>
      </Box>
    </>
  );
};

export default AdminLogin;
