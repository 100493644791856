import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "konnekt/Icon";
import moment from "moment";
import Button from "konnekt/Buttons";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import CreateNewBusinessPage from "./components/CreateNewBusinessPage";
import BusinessListTable from "./components/BusinessListTable";
import {
  UPDATE_BUSINESS_DATA,
  UPDATE_BUSINESS_DETAILS_EMPTY,
} from "modules/Manage/ManagePageActionTypes";
import { fetchBusinessList, fetchBusinessAdmin } from "modules/App/AppActions";
import { deleteBusinessV2 } from "modules/Manage/ManagePageAction";
import {
  CommonContainer,
  BusinessHeader,
  CreateProjectCards,
  Cards,
} from "./BusinessStyles";

const CreateNewBusinessDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreatedProject, setIsCreatedProject] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [business, setbusiness] = useState(null);

  useEffect(() => {
    dispatch(fetchBusinessList());
  }, [dispatch]);

  const BusinessData = useSelector((state) => state.app?.businessList);
  const adminData = useSelector((state) => state.app?.adminList);
  const handleAction = () => {
    setOpenCreateModal(true);
    dispatch({
      type: UPDATE_BUSINESS_DETAILS_EMPTY,
    });
  };

  const handleEditAction = (item, check) => {
    const data = item;
    data.admin = adminData;
    if (check) {
      setOpenCreateModal(true);
      dispatch({
        type: UPDATE_BUSINESS_DATA,
        businessData: data,
        isFetchingNewBusiness: true,
      });
    }
  };
  const handleBack = () => {
    setOpenCreateModal(false);
    setOpenTable(false);
    dispatch(fetchBusinessList());
  };

  if (openCreateModal) {
    return <CreateNewBusinessPage handleBack={handleBack} />;
  }
  if (openTable) {
    return (
      <BusinessListTable
        handleBack={handleBack}
        businessList={business}
        handleEditAction={handleEditAction}
      />
    );
  }

  const handleOpenTable = (data) => {
    setOpenTable(true);
    dispatch(fetchBusinessAdmin(data._id));
    data.admin = adminData;
    setbusiness(data);
  };

  if (BusinessData) {
    return (
      <>
        <CommonContainer>
          <BusinessHeader mb="2rem">
            <h3>Recent Business </h3>
            <Button
              type="primary"
              iconName="plusIcons"
              text="New"
              onClick={() => handleAction()}
            />
          </BusinessHeader>
          <Box>
            <CreateProjectCards>
              {BusinessData.map((item, index) => (
                <Cards key={index}>
                  <h6>{item?.name}</h6>
                  <Label color="#212121">
                    Tax Number:<span>{item?.tax_number}</span>
                  </Label>
                  <Label color="#212121">
                    Onboarding Status:
                    <span>
                      {item?.is_onboarded === true ? "Active" : "Inactive"}
                    </span>
                  </Label>
                  <Label color="#423F3F" fontSize="14px">
                    {moment(item?.created_at).format("DD MMMM, YYYY")}
                  </Label>
                  <Button
                    type="outlineColor"
                    width="100%"
                    fontSize="18px"
                    fontWeight="500"
                    onClick={() => handleOpenTable(item)}
                  >
                    View
                  </Button>
                </Cards>
              ))}
            </CreateProjectCards>
          </Box>
        </CommonContainer>
      </>
    );
  }
};

export default CreateNewBusinessDashboard;
