import callChatApi from "../../util/chatApiCaller";
import _ from "lodash";
import { TOGGLE_FOR_NEW_CHANGE } from "./ConversationActions";

export const FETCH_TRACKER_DATA_BY_CHAT_LIST =
               "FETCH_TRACKER_DATA_BY_CHAT_LIST";
export const UPDATE_TRACKER_DATA = "UPDATE_TRACKER_DATA";

export function fetchTrackerReadByChatIds(chat_ids) {
  return (dispatch) => {
    callChatApi(
      `v1/conversation/read-tracker/list`,
      "post",
      {
        read_tracker: {
          "conversation_ids": chat_ids
        },
      },
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_TRACKER_DATA_BY_CHAT_LIST,
          trackers: res.data?.read_tracker?.list,
        });
      }
    });
  };
}

export function updateTrackerData(trackerData) {
  return (dispatch) => {
    let update = _.clone(trackerData);
    dispatch({
      type: UPDATE_TRACKER_DATA,
      isUpdatingTrackerData: false,
      trackerData: update,
    });
    dispatch({
      type: TOGGLE_FOR_NEW_CHANGE,
    });
    callChatApi(
      `v1/conversation/read-tracker`,
      "post",
      {
        "read_tracker": trackerData
      },
    ).then((res) => {
      if (!res) {
        dispatch({
          type: UPDATE_TRACKER_DATA,
          isUpdatingTrackerData: false,
        });
      }
    });
  };
}
