import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Icon from "konnekt/Icon";
import { updateActiveSmartCard } from "modules/Inbox/inboxStateActions";

const StyledHeader = styled("div")`
  position: fixed;
  width: 100%;
  padding: 5px 15px;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: #fff;
  color: #222;
  display: flex;
  background-color: #dddeff;
  justify-content: space-between;
  align-items: center;
  z-index: 102;
`;

class ChatWindowHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  handleToggleMenu = (state) => {
    this.setState({
      isMenuOpen: state,
    });
  };

  render() {
    return (
      <StyledHeader className="mobile">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span onClick={() => this.props.handleGoBack()}>
            <Icon noWrapper iconName="back" stroke="#222" />
          </span>
          <span
            style={{
              fontSize: "1.4rem",
              fontWeight: "600",
              marginLeft: "15px",
            }}
          >
            {this.props.headerText}
          </span>
        </div>
        {/* {this.props.handleRedirectbtnClick && (
          <Icon
            onClick={this.props.handleRedirectbtnClick}
            mr="7px"
            iconName="sendGreen"
          />
        )} */}
      </StyledHeader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeSmartCard: state.inboxState.smartCard.activeSmartCard,
  };
};

export default connect(mapStateToProps, { updateActiveSmartCard })(
  ChatWindowHeader
);
