import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import keyCodes from "constants/keyboard-codes.json";
import { layout, space } from "styled-system";

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #222;
  opacity: 0.5;
  pointer-events: auto;
`;

const StyledModal = styled.div`
  position: fixed;
  z-index: 1040;
  height: auto;
  background-color: #fff;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba(214, 214, 214, 0.25);
  border-radius: 8px;
  ${layout}
  ${space}
  @media only screen and (max-width: 768px) {
    max-width: 380px;
    width: 95%;
  }
`;

const Modal = ({ children, isOpen, onHide, style, ...rest }) => {
  // ? the modal can hide on pressing esc or backscape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === keyCodes.ESC && typeof onHide === "function") {
        onHide();
      }
    };

    document.addEventListener("keydown", (e) => {
      handleKeyDown(e);
    });

    // remove event listner(cleanup)
    return () => {
      document.removeEventListener("mousedown", handleKeyDown, true);
    };
  }, [onHide]);

  if (isOpen) {
    //document.body.style.overflowY = "hidden";
  }
  if (!isOpen) {
    //document.body.style.overflowY = "auto";
    return null;
  }
  return (
    <>
      <StyledOverlay onClick={onHide} />
      <StyledModal {...rest} style={style}>
        {children}
      </StyledModal>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  iconName: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Modal.defaultProps = {
  isOpen: false,
  iconName: null,
  maxWidth: "700px",
  width: "100%",
};

export default Modal;
