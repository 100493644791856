import callApi from "util/apiCaller";

export const FETCHING_ALL_PLATFORM_PERMISSIONS =
  "FETCHING_ALL_PLATFORM_PERMISSIONS";
export const FETCH_ALL_PLATFORM_PERMISSIONS = "FETCH_ALL_PLATFORM_PERMISSIONS";

export const FETCHING_ORGANIZATION_ROLES = "FETCHING_ORGANIZATION_ROLES";
export const FETCH_ORGANIZATION_ROLES = "FETCH_ORGANIZATION_ROLES";

export const SELECT_ROLE_TAB = "SELECT_ROLE_TAB";

export const UPDATE_PERMISSION_ROLE = "UPDATE_PERMISSION_ROLE";

export function fetchPlatformPermissions() {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ALL_PLATFORM_PERMISSIONS,
      isFetchingCreditHistory: true,
    });
    return callApi(`v1/organizations/platform-supported-features/`, "get").then(
      (res) => {
        if (res && res.result) {
          dispatch({
            type: FETCH_ALL_PLATFORM_PERMISSIONS,
            isFetchingCreditHistory: false,
            basePermissions: res.data ? res.data : 0,
          });
        } else {
          dispatch({
            type: FETCHING_ALL_PLATFORM_PERMISSIONS,
            isFetchingCreditHistory: false,
          });
        }
      },
    );
  };
}

export function fetchOrganizationRoles(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ORGANIZATION_ROLES,
      isFetchingOrganizationRoles: true,
    });
    return callApi(`v1/organizations/${organizationId}/roles/`, "get").then(
      (res) => {
        if (res && res.result) {
          dispatch({
            type: FETCH_ORGANIZATION_ROLES,
            isFetchingOrganizationRoles: false,
            organizationRoles: res.data,
          });
        } else {
          dispatch({
            type: FETCHING_ORGANIZATION_ROLES,
            isFetchingOrganizationRoles: false,
          });
        }
      },
    );
  };
}

export function setSelectedRoleTab(role) {
  return (dispatch) => {
    dispatch({
      type: SELECT_ROLE_TAB,
      selectedRole: role,
    });
  };
}

export function togglePermission(organizationId, roleId, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PERMISSION_ROLE,
      roleId: roleId,
      update: update,
    });
    callApi(
      `v1/organizations/${organizationId}/roles/${roleId}/update/`,
      "put",
      {
        features: [update],
      },
    ).then((res) => {
      console.log(res, "update_role");
    });
  };
}
