import React, { Component } from "react";

class CountryCodeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCodeList: [],
      isShowCountryCodeDropDown: false,
      inputTxt: "",
    };
  }
  componentDidMount() {
    this.setState({
      countryCodeList: this.props.countryCodeList,
    });
  }

  handleToggleCodeDropDown = (state, countryCode) => {
    this.setState({
      isShowCountryCodeDropDown: !!state,
      inputTxt: countryCode,
    });
  };

  handleSelectCountryCode = (keyValue) => {
    this.setState(
      {
        isShowCountryCodeDropDown: false,
        inputTxt: keyValue,
      },
      () => {
        this.props.handleChange(keyValue);
      },
    );
  };

  handleFilterCode = (txt) => {
    let countryCodeList = [...this.props.countryCodeList];
    let query = new RegExp(txt.indexOf("+") > -1 ? `\\${  txt}` : txt, "i");
    // let query = '\\' + txt;
    countryCodeList = countryCodeList.filter(
      (item) => !!item.code.match(query),
    );
    this.setState({
      inputTxt: txt,
      countryCodeList: countryCodeList,
    });
  };

  render() {
    let style = this.props.style ? this.props.style : {};
    return (
      <div style={{ position: "relative", width: "80px" }}>
        <input
          autoComplete="new-password"
          style={{
            ...style,
            borderBottomLeftRadius: `${
              this.state.isShowCountryCodeDropDown
                ? 0
                : this.props.style && this.props.style.borderRadius
                ? this.props.style.borderRadius
                : "6px"
            }`,
            borderBottomRightRadius: `${
              this.state.isShowCountryCodeDropDown
                ? 0
                : this.props.isAppend
                ? 0
                : this.props.style && this.props.style.borderRadius
                ? this.props.style.borderRadius
                : "6px"
            }`,
          }}
          onFocus={() =>
            this.handleToggleCodeDropDown(true, this.props.countryCode)
          }
          className="form-control kiwi-dropdown-icon"
          value={
            this.state.isShowCountryCodeDropDown
              ? this.state.inputTxt
              : this.props.countryCode
          }
          defaultValue={this.props.countryCode}
          onChange={(e) => this.handleFilterCode(e.target.value)}
        />
        <div
          onClick={() =>
            this.handleToggleCodeDropDown(false, this.state.inputTxt)
          }
          className={`transparent-full-screen-background ${
            this.state.isShowCountryCodeDropDown ? "show" : ""
          }`}
        ></div>
        {this.state.isShowCountryCodeDropDown ? (
          <div
            className="d-flex flex-column inbox-scrollbar"
            style={{
              position: "absolute",
              zIndex: 2000,
              backgroundColor: "#fff",
              maxHeight: "150px",
              overflowY: "auto",
              width: "100%",
              boxShadow: "-2px 0px" + " 15px 0px #cdcdcd",
              border: "1px solid #222222",
              borderTop: "none",
              borderBottomRightRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
          >
            {this.state.countryCodeList.map((item) => (
              <span
                className="country-dropdown-item"
                style={{ padding: "4px 10px", cursor: "pointer" }}
                onClick={(e) => this.handleSelectCountryCode(item.code)}
              >
                {item.code}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default CountryCodeSelection;
