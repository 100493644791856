import React from "react";
import styled from "styled-components";
import { space, layout } from "styled-system";

const Loader = styled.div`
  border: 2px solid #5e3ec5;
  border-radius: 50%;
  border-top: 2px solid #ffffff00;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  ${space}
  ${layout}
`;

const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isloaderSmall ? "" : "100vh")};
  width: ${(props) => (props.isloaderSmall ? "" : "100vw")};
`;

const OnlyLoader = ({ size, isloaderSmall, ...props }) => {
  return (
    <LoaderBox
      className="text-center"
      data-cy="only-loader"
      isloaderSmall={isloaderSmall}
    >
      <Loader
        {...props}
        width={size ? size : "16px"}
        height={size ? size : "16px"}
      />
    </LoaderBox>
  );
};

export default OnlyLoader;
