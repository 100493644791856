import _ from "lodash";
import {
  ATTEMPT_TO_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  VERIFY_FAILED,
  VERIFY_SUCCESS,
  ATTEMPT_TO_VERIFY,
  FETCH_USER_PROFILE,
  FETCH_USER_ORG,
  ATTEMPT_TO_LOGOUT,
  FETCH_USER_CHANNELS,
  ADD_USER_CHANNEL,
  ADDING_USER_CHANNEL,
  ORGANIZATION_TAG_LIST_FETCH_STATUS,
  ORGANIZATION_TAG_LIST,
  CREATE_ORGANIZATION_TAG_STATUS,
  CREATE_ORGANIZATION_TAG,
  FETCHING_ALL_ORG_AGENTS,
  FETCH_ALL_ORG_AGENTS,
  UPDATE_WEB_CLIENT_ID,
  CHECK_ORGANIZATION_ONBOARD_STATUS,
  FETCHING_OTP,
  FETCH_OTP,
  VERIFYING_OTP,
  VERIFY_OTP,
  FETCHING_ORGANIZATION_DETAILS,
  UPDATE_ORGANIZATION_ONBOARD_STATUS,
  FETCHING_ORG_EVENT_SOURCES,
  FETCH_ORG_EVENT_SOURCES,
  FETCHING_ORG_QUICK_REPLIES,
  FETCH_ORG_QUICK_REPLIES,
  REMOVING_ORG_QUICK_REPLIES,
  UPDATING_ORG_QUICK_REPLIES,
  FETCHING_ORG_MEMBERS,
  FETCH_ORG_MEMBERS,
  ADDING_ORG_MEMBERS,
  UPDATING_ORG_MEMBERS,
  REMOVING_ORG_MEMBERS,
  ADDING_ORG_QUICK_REPLIES,
  ADDED_ORG_QUICK_REPLIES,
  ADDED_ORG_MEMBERS,
  UPDATED_ORG_MEMBERS,
  REMOVED_ORG_MEMBERS,
  UPDATED_ORG_QUICK_REPLIES,
  REMOVED_ORG_QUICK_REPLIES,
  FETCH_USER_DEVICE_TYPE,
  REQUEST_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GENERATE_VERIFICATION_EMAIL,
  EMAIL_VERIFYING,
  EMAIL_VERIFY,
  VERIFYING_BUSINESS_MANAGER,
  VERIFIED_BUSINESS_MANAGER,
  UPDATING_BUSINESS_MANAGER,
  UPDATED_BUSINESS_MANAGER,
  TOGGLE_CREATE_ACCOUNT_SEGMENT,
  CREATING_ORGANIZATION_CHANNEL,
  CREATED_ORGANIZATION_CHANNEL,
  SET_NETWORK_STATUS,
  SET_QUALITY_RATING,
  TOGGLE_BOTTOM_NAVBAR,
  FETCHING_USER_CHANNELS,
  SET_SOCKET_STATUS,
  SEND_EMAIL_RESET_PASSWORD,
  TOGGLE_RECOVERY_PASSWORD_SEGMENT,
  TOGGLE_RESET_PASSWORD_SEGMENT,
  UPDATING_RESET_PASSWORD,
  RESEND_INVITATION_MEMBER,
  SET_BOX_VALUE,
  RECOVERY_PASSWORD_STATUS,
  SET_AUTHENTICATION,
  UPDATE_RECONNECTING_STATUS,
  SAVE_WIDGET_PREFERENCES,
  SAVE_WIDGET_PREFERENCES_STATUS,
  ADD_NEW_BULK_UPLOAD_SELECTED_TAGS,
  REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS,
  TOGGLE_BULK_UPLOAD_SEGMENT,
  UPDATE_BULK_UPLOAD_SELECTED_TAGS,
  CLEAR_BULK_UPLOAD_NON_CREATED_TAGS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_STATUS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_STATUS,
  TOGGLE_SHOPIFY_SEGMENT,
  FETCHING_BUNDLED_DATA,
  FETCH_BUNDLED_DATA,
  TOGGLE_ADD_QUICKREPLY_MODAL,
  TOGGLE_EDIT_QUICKREPLY_MODAL,
  TOGGLE_DELETE_QUICKREPLY_MODAL,
  TOGGLE_DELETE_TEAMSETTING_MODAL,
  TOGGLE_EDIT_TEAMSETTING_MODAL,
  TOGGLE_BUG_REPORT_MODAL,
  TOGGLE_PAYMENT_MODAL,
  SET_BUG_REPORT_ISSUE_WITH,
  SET_BUG_REPORT_SUBJECT,
  SET_BUG_REPORT_DETAIL,
  TOGGLE_EMOJI_CARD_MODAL,
  SET_QUICK_REPLY_FIELDS,
  SET_QUICK_REPLY_INPUT_FIELDS,
  SET_QUICK_REPLY_VARIABLE_COUNT,
  SET_TEAM_MEMBER_FIELDS,
  TOGGLE_ADD_TEAM_MEMBER_MODAL,
  SET_ORGANIZATION_DETAIL_INPUT_VALUES,
  SET_USER_TO_CAMP_REDIRECT_DATA,
  FETCHING_FB_EMBEDDED_DATA,
  SAVE_USER_JOURNEY,
  UPDATE_BUG_REPORT,
  TOGGLE_SIDEBAR_STATUS,
  FETCHING_ORG_CONVERSATION_LABELS,
  FETCH_ORG_CONVERSATION_LABELS,
  CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS,
  UPDATE_ORG_CONVERSATION_LABELS,
  FETCH_LIST_MESSAGE_DETAILS_SUCCESS,
  UPDATE_LIST_MESSAGE_DETAILS,
  UPDATE_ORG_FOR_FAQ_BOT,
  UPDATE_LIST_MESSAGE_DATA,
  UPDATE_ORG_FOR_E_MANDATE,
  SET_CATALOG_ID,
  FETCH_PROJECT_LIST,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_LIST_FAILURE,
  ADD_PROJECT_DETAILS_SUCCESS,
  SELECT_PROJECT,
  UPDATE_USER_PROJECT_DETAILS,
  UPDATE_USER_PROJECT_DETAILS_UPDATE_SUCCESS,
  USER_PROJECT_DETAILS_UPDATE_FAILURE,
  FETCH_CAMPAIGN_DETAIL,
  FETCH_CAMPAIGN_LIST_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  ADD_NEW_CAMPAIGN_DETAILS_STATUS,
  ADD_NEW_CAMPAIGN_DETAILS,
  FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS,
  FETCH_ALL_CUSTOMER_LIST_DETAILS,
  HANDLE_USER_PROJECT_REMOVAL,
  FETCH_BUSINESS_LIST,
  FETCH_BUSINESS_LIST_SUCCESS,
  FETCH_BUSINESS_LIST_FAILURE,
  FETCH_BUSINESS_ADMIN,
  FETCH_BUSINESS_ADMIN_SUCCESS,
  FETCH_BUSINESS_ADMIN_FAILURE,
} from "./AppActions";
import { makeNameVariableFirstInList } from "../../util/utility";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const initialState = {
  activeProjectId: "",
  activeProject: {},
  isOpenAddReplyForm: false,
  isOpenEditReplyForm: false,
  isOpenDeleteReplyForm: false,
  isOpenDeleteMember: false,
  isOpenEditMember: false,
  isOpenBugReportModal: false,
  isVerifyingToken: false,
  isTokenFound: true,
  isAuthenticated: false,
  isLoadingApp: false,
  isBottomNavVisible: true,
  user: {},
  isLoggingIn: false,
  isVerifying: false,
  token: "",
  organizations: [],
  organizationDetailInputValues: {},
  isFetchingOrganizationDetails: false,
  isOrganizationOnboarded: true,
  isOrganizationFirstOnboarded: false,
  channels: [],
  isAddingUserChannel: false,
  channelId: "",
  isCreatingOrganizationTag: false,
  isFetchingOrganizationTags: false,
  tagList: [],
  allTags: {},
  isFetchingAgents: false,
  activeAgents: [],
  allAgents: [],
  allAgentsObjectMap: {},
  webClientId: "",
  customer_attributes: [],
  isSendingOTP: false,
  isOTPSent: false,
  isVerifyingOTP: false,
  isVerified: false,
  isFetchingUserChannels: true,
  isQuickRepliesLoadedAlready: false,
  isOrganizationtagsLoadedAlready: false,
  isEventSourcesLoadedAlready: false,
  isFetchingEventSources: false,
  orgEventSources: [],
  orgQuickReplies: [],
  isAddingQuickReplies: false,
  isUpdatingQuickReplies: false,
  isRemovingQuickReplies: false,
  quickReplyFields: {
    slash_command: "",
    quick_reply: "",
  },
  quickReplyVariableCount: 0,
  quickReplyInputfields: [],
  teamMemberInputFields: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "+91",
  },
  isOpenAddTeamMemberModal: false,
  isOnline: true,
  isSocketConnected: false,
  orgMembers: [],
  isAddingMembers: false,
  isUpdatingMembers: false,
  isUserDeviceMobile: false,
  qualityList: [],
  activeQuality: {},
  bugReportSubject: "",
  bugReportDetail: "",
  bugReportIssueWith: "",
  isOpenEmojiCard: false,
  // Make a seperate file for this
  countryList: [
    {
      label: "India",
      value: "IN",
    },
  ],
  stateList: [
    {
      key: "AN",
      name: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    {
      key: "AP",
      name: "Andhra Pradesh",
      label: "Andhra Pradesh",
      value: "Andhra Pradesh",
    },
    {
      key: "AR",
      name: "Arunachal Pradesh",
      label: "Arunachal Pradesh",
      value: "Arunachal Pradesh",
    },
    {
      key: "AS",
      name: "Assam",
      label: "Assam",
      value: "Assam",
    },
    {
      key: "BR",
      name: "Bihar",
      label: "Bihar",
      value: "Bihar",
    },
    {
      key: "CG",
      name: "Chandigarh",
      label: "Chandigarh",
      value: "Chandigarh",
    },
    {
      key: "CH",
      name: "Chhattisgarh",
      label: "Chhattisgarh",
      value: "Chhattisgarh",
    },
    {
      key: "DH",
      name: "Dadra and Nagar Haveli",
      label: "Dadra and Nagar Haveli",
      value: "Dadra and Nagar Haveli",
    },
    {
      key: "DD",
      name: "Daman and Diu",
      label: "Daman and Diu",
      value: "Daman and Diu",
    },
    {
      key: "DL",
      name: "Delhi",
      label: "Delhi",
      value: "Delhi",
    },
    {
      key: "GA",
      name: "Goa",
      label: "Goa",
      value: "Goa",
    },
    {
      key: "GJ",
      name: "Gujarat",
      label: "Gujarat",
      value: "Gujarat",
    },
    {
      key: "HR",
      name: "Haryana",
      label: "Haryana",
      value: "Haryana",
    },
    {
      key: "HP",
      name: "Himachal Pradesh",
      label: "Himachal Pradesh",
      value: "Himachal Pradesh",
    },
    {
      key: "JK",
      name: "Jammu and Kashmir",
      label: "Jammu and Kashmir",
      value: "Jammu and Kashmir",
    },
    {
      key: "JH",
      name: "Jharkhand",
      label: "Jharkhand",
      value: "Jharkhand",
    },
    {
      key: "KA",
      name: "Karnataka",
      label: "Karnataka",
      value: "Karnataka",
    },
    {
      key: "KL",
      name: "Kerala",
      label: "Kerala",
      value: "Kerala",
    },
    {
      key: "LD",
      name: "Lakshadweep",
      label: "Lakshadweep",
      value: "Lakshadweep",
    },
    {
      key: "MP",
      name: "Madhya Pradesh",
      label: "Madhya Pradesh",
      value: "Madhya Pradesh",
    },
    {
      key: "MH",
      name: "Maharashtra",
      label: "Maharashtra",
      value: "Maharashtra",
    },
    {
      key: "MN",
      name: "Manipur",
      label: "Manipur",
      value: "Manipur",
    },
    {
      key: "ML",
      name: "Meghalaya",
      label: "Meghalaya",
      value: "Meghalaya",
    },
    {
      key: "MZ",
      name: "Mizoram",
      label: "Mizoram",
      value: "Mizoram",
    },
    {
      key: "NL",
      name: "Nagaland",
      label: "Nagaland",
      value: "Nagaland",
    },
    {
      key: "OR",
      name: "Odisha",
      label: "Odisha",
      value: "Odisha",
    },
    {
      key: "PY",
      name: "Puducherry",
      label: "Puducherry",
      value: "Puducherry",
    },
    {
      key: "PB",
      name: "Punjab",
      label: "Punjab",
      value: "Punjab",
    },
    {
      key: "RJ",
      name: "Rajasthan",
      label: "Rajasthan",
      value: "Rajasthan",
    },
    {
      key: "SK",
      name: "Sikkim",
      label: "Sikkim",
      value: "Sikkim",
    },
    {
      key: "TN",
      name: "Tamil Nadu",
      label: "Tamil Nadu",
      value: "Tamil Nadu",
    },
    {
      key: "TS",
      name: "Telangana",
      label: "Telangana",
      value: "Telangana",
    },
    {
      key: "TR",
      name: "Tripura",
      label: "Tripura",
      value: "Tripura",
    },
    {
      key: "UK",
      name: "Uttar Pradesh",
      label: "Uttar Pradesh",
      value: "Uttar Pradesh",
    },
    {
      key: "UP",
      name: "Uttarakhand",
      label: "Uttarakhand",
      value: "Uttarakhand",
    },
    {
      key: "WB",
      name: "West Bengal",
      label: "West Bengal",
      value: "West Bengal",
    },
  ],
  countryCodeList: [
    {
      name: "India",
      code: "+91",
      uniCode: "",
    },
    {
      name: "Afghanistan",
      code: "+93",
      uniCode: "",
    },
    {
      name: "Albania",
      code: "+355",
      uniCode: "",
    },
    {
      name: "Algeria",
      code: "+213",
      uniCode: "",
    },
    {
      name: "American Samoa",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Andorra",
      code: "+376",
      uniCode: "",
    },
    {
      name: "Angola",
      code: "+244",
      uniCode: "",
    },
    {
      name: "Anguilla",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Antigua and Barbuda",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Argentina",
      code: "+54",
      uniCode: "",
    },
    {
      name: "Armenia",
      code: "+374",
      uniCode: "",
    },
    {
      name: "Aruba",
      code: "+297",
      uniCode: "",
    },
    {
      name: "Australia",
      code: "+61",
      uniCode: "",
    },
    {
      name: "Austria",
      code: "+43",
      uniCode: "",
    },
    {
      name: "Azerbaijan",
      code: "+994",
      uniCode: "",
    },
    {
      name: "Bahamas",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Bahrain",
      code: "+973",
      uniCode: "",
    },
    {
      name: "Bangladesh",
      code: "+880",
      uniCode: "",
    },
    {
      name: "Barbados",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Belarus",
      code: "+375",
      uniCode: "",
    },
    {
      name: "Belgium",
      code: "+32",
      uniCode: "",
    },
    {
      name: "Belize",
      code: "+501",
      uniCode: "",
    },
    {
      name: "Benin",
      code: "+229",
      uniCode: "",
    },
    {
      name: "Bermuda",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Bhutan",
      code: "+975",
      uniCode: "",
    },
    {
      name: "Bolivia",
      code: "+591",
      uniCode: "",
    },
    {
      name: "Bosnia and Herzegovina",
      code: "+387",
      uniCode: "",
    },
    {
      name: "Botswana",
      code: "+267",
      uniCode: "",
    },
    {
      name: "Brazil",
      code: "+55",
      uniCode: "",
    },
    {
      name: "British Indian Ocean Territory",
      code: "+246",
      uniCode: "",
    },
    {
      name: "British Virgin Islands",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Brunei",
      code: "+673",
      uniCode: "",
    },
    {
      name: "Bulgaria",
      code: "+359",
      uniCode: "",
    },
    {
      name: "Burkina Faso",
      code: "+226",
      uniCode: "",
    },
    {
      name: "Burundi",
      code: "+257",
      uniCode: "",
    },
    {
      name: "Cambodia",
      code: "+855",
      uniCode: "",
    },
    {
      name: "Cameroon",
      code: "+237",
      uniCode: "",
    },
    {
      name: "Canada",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Cape Verde",
      code: "+238",
      uniCode: "",
    },
    {
      name: "Cayman Islands",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Central African Republic",
      code: "+236",
      uniCode: "",
    },
    {
      name: "Chad",
      code: "+235",
      uniCode: "",
    },
    {
      name: "Chile",
      code: "+56",
      uniCode: "",
    },
    {
      name: "Christmas Island",
      code: "+61",
      uniCode: "",
    },
    {
      name: "Cocos Islands",
      code: "+61",
      uniCode: "",
    },
    {
      name: "Colombia",
      code: "+57",
      uniCode: "",
    },
    {
      name: "Comoros",
      code: "+269",
      uniCode: "",
    },
    {
      name: "Cook Islands",
      code: "+682",
      uniCode: "",
    },
    {
      name: "Costa Rica",
      code: "+506",
      uniCode: "",
    },
    {
      name: "Croatia",
      code: "+385",
      uniCode: "",
    },
    {
      name: "Curacao",
      code: "+599",
      uniCode: "",
    },
    {
      name: "Cyprus",
      code: "+357",
      uniCode: "",
    },
    {
      name: "Czech Republic",
      code: "+420",
      uniCode: "",
    },
    {
      name: "Democratic Republic of the Congo",
      code: "+243",
      uniCode: "",
    },
    {
      name: "Denmark",
      code: "+45",
      uniCode: "",
    },
    {
      name: "Djibouti",
      code: "+253",
      uniCode: "",
    },
    {
      name: "Dominica",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Dominican Republic",
      code: "+1",
      uniCode: "",
    },
    {
      name: "East Timor",
      code: "+670",
      uniCode: "",
    },
    {
      name: "Ecuador",
      code: "+593",
      uniCode: "",
    },
    {
      name: "Egypt",
      code: "+20",
      uniCode: "",
    },
    {
      name: "El Salvador",
      code: "+503",
      uniCode: "",
    },
    {
      name: "Equatorial Guinea",
      code: "+240",
      uniCode: "",
    },
    {
      name: "Eritrea",
      code: "+291",
      uniCode: "",
    },
    {
      name: "Estonia",
      code: "+372",
      uniCode: "",
    },
    {
      name: "Ethiopia",
      code: "+251",
      uniCode: "",
    },
    {
      name: "Falkland Islands",
      code: "+500",
      uniCode: "",
    },
    {
      name: "Faroe Islands",
      code: "+298",
      uniCode: "",
    },
    {
      name: "Fiji",
      code: "+679",
      uniCode: "",
    },
    {
      name: "Finland",
      code: "+358",
      uniCode: "",
    },
    {
      name: "France",
      code: "+33",
      uniCode: "",
    },
    {
      name: "French Polynesia",
      code: "+689",
      uniCode: "",
    },
    {
      name: "Gabon",
      code: "+241",
      uniCode: "",
    },
    {
      name: "Gambia",
      code: "+220",
      uniCode: "",
    },
    {
      name: "Georgia",
      code: "+995",
      uniCode: "",
    },
    {
      name: "Germany",
      code: "+49",
      uniCode: "",
    },
    {
      name: "Ghana",
      code: "+233",
      uniCode: "",
    },
    {
      name: "Gibraltar",
      code: "+350",
      uniCode: "",
    },
    {
      name: "Greece",
      code: "+30",
      uniCode: "",
    },
    {
      name: "Greenland",
      code: "+299",
      uniCode: "",
    },
    {
      name: "Grenada",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Guam",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Guatemala",
      code: "+502",
      uniCode: "",
    },
    {
      name: "Guernsey",
      code: "+44",
      uniCode: "",
    },
    {
      name: "Guinea",
      code: "+224",
      uniCode: "",
    },
    {
      name: "Guinea-Bissau",
      code: "+245",
      uniCode: "",
    },
    {
      name: "Guyana",
      code: "+592",
      uniCode: "",
    },
    {
      name: "Haiti",
      code: "+509",
      uniCode: "",
    },
    {
      name: "Honduras",
      code: "+504",
      uniCode: "",
    },
    {
      name: "Hong Kong",
      code: "+852",
      uniCode: "",
    },
    {
      name: "Hungary",
      code: "+36",
      uniCode: "",
    },
    {
      name: "Iceland",
      code: "+354",
      uniCode: "",
    },
    {
      name: "Indonesia",
      code: "+62",
      uniCode: "",
    },
    {
      name: "Iraq",
      code: "+964",
      uniCode: "",
    },
    {
      name: "Ireland",
      code: "+353",
      uniCode: "",
    },
    {
      name: "Isle of Man",
      code: "+44",
      uniCode: "",
    },
    {
      name: "Israel",
      code: "+972",
      uniCode: "",
    },
    {
      name: "Italy",
      code: "+39",
      uniCode: "",
    },
    {
      name: "Ivory Coast",
      code: "+225",
      uniCode: "",
    },
    {
      name: "Jamaica",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Japan",
      code: "+81",
      uniCode: "",
    },
    {
      name: "Jersey",
      code: "+44",
      uniCode: "",
    },
    {
      name: "Jordan",
      code: "+962",
      uniCode: "",
    },
    {
      name: "Kazakhstan",
      code: "+7",
      uniCode: "",
    },
    {
      name: "Kenya",
      code: "+254",
      uniCode: "",
    },
    {
      name: "Kiribati",
      code: "+686",
      uniCode: "",
    },
    {
      name: "Kosovo",
      code: "+383",
      uniCode: "",
    },
    {
      name: "Kuwait",
      code: "+965",
      uniCode: "",
    },
    {
      name: "Kyrgyzstan",
      code: "+996",
      uniCode: "",
    },
    {
      name: "Laos",
      code: "+856",
      uniCode: "",
    },
    {
      name: "Latvia",
      code: "+371",
      uniCode: "",
    },
    {
      name: "Lebanon",
      code: "+961",
      uniCode: "",
    },
    {
      name: "Lesotho",
      code: "+266",
      uniCode: "",
    },
    {
      name: "Liberia",
      code: "+231",
      uniCode: "",
    },
    {
      name: "Libya",
      code: "+218",
      uniCode: "",
    },
    {
      name: "Liechtenstein",
      code: "+423",
      uniCode: "",
    },
    {
      name: "Lithuania",
      code: "+370",
      uniCode: "",
    },
    {
      name: "Luxembourg",
      code: "+352",
      uniCode: "",
    },
    {
      name: "Macau",
      code: "+853",
      uniCode: "",
    },
    {
      name: "Macedonia",
      code: "+389",
      uniCode: "",
    },
    {
      name: "Madagascar",
      code: "+261",
      uniCode: "",
    },
    {
      name: "Malawi",
      code: "+265",
      uniCode: "",
    },
    {
      name: "Malaysia",
      code: "+60",
      uniCode: "",
    },
    {
      name: "Maldives",
      code: "+960",
      uniCode: "",
    },
    {
      name: "Mali",
      code: "+223",
      uniCode: "",
    },
    {
      name: "Malta",
      code: "+356",
      uniCode: "",
    },
    {
      name: "Marshall Islands",
      code: "+692",
      uniCode: "",
    },
    {
      name: "Mauritania",
      code: "+222",
      uniCode: "",
    },
    {
      name: "Mauritius",
      code: "+230",
      uniCode: "",
    },
    {
      name: "Mayotte",
      code: "+262",
      uniCode: "",
    },
    {
      name: "Mexico",
      code: "+52",
      uniCode: "",
    },
    {
      name: "Micronesia",
      code: "+691",
      uniCode: "",
    },
    {
      name: "Moldova",
      code: "+373",
      uniCode: "",
    },
    {
      name: "Monaco",
      code: "+377",
      uniCode: "",
    },
    {
      name: "Mongolia",
      code: "+976",
      uniCode: "",
    },
    {
      name: "Montenegro",
      code: "+382",
      uniCode: "",
    },
    {
      name: "Montserrat",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Morocco",
      code: "+212",
      uniCode: "",
    },
    {
      name: "Mozambique",
      code: "+258",
      uniCode: "",
    },
    {
      name: "Myanmar",
      code: "+95",
      uniCode: "",
    },
    {
      name: "Namibia",
      code: "+264",
      uniCode: "",
    },
    {
      name: "Nauru",
      code: "+674",
      uniCode: "",
    },
    {
      name: "Nepal",
      code: "+977",
      uniCode: "",
    },
    {
      name: "Netherlands",
      code: "+31",
      uniCode: "",
    },
    {
      name: "Netherlands Antilles",
      code: "+599",
      uniCode: "",
    },
    {
      name: "New Caledonia",
      code: "+687",
      uniCode: "",
    },
    {
      name: "New Zealand",
      code: "+64",
      uniCode: "",
    },
    {
      name: "Nicaragua",
      code: "+505",
      uniCode: "",
    },
    {
      name: "Niger",
      code: "+227",
      uniCode: "",
    },
    {
      name: "Nigeria",
      code: "+234",
      uniCode: "",
    },
    {
      name: "Niue",
      code: "+683",
      uniCode: "",
    },
    {
      name: "Northern Mariana Islands",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Norway",
      code: "+47",
      uniCode: "",
    },
    {
      name: "Oman",
      code: "+968",
      uniCode: "",
    },
    {
      name: "Pakistan",
      code: "+92",
      uniCode: "",
    },
    {
      name: "Palau",
      code: "+680",
      uniCode: "",
    },
    {
      name: "Palestine",
      code: "+970",
      uniCode: "",
    },
    {
      name: "Panama",
      code: "+507",
      uniCode: "",
    },
    {
      name: "Papua New Guinea",
      code: "+675",
      uniCode: "",
    },
    {
      name: "Paraguay",
      code: "+595",
      uniCode: "",
    },
    {
      name: "Peru",
      code: "+51",
      uniCode: "",
    },
    {
      name: "Philippines",
      code: "+63",
      uniCode: "",
    },
    {
      name: "Pitcairn",
      code: "+64",
      uniCode: "",
    },
    {
      name: "Poland",
      code: "+48",
      uniCode: "",
    },
    {
      name: "Portugal",
      code: "+351",
      uniCode: "",
    },
    {
      name: "Puerto Rico",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Qatar",
      code: "+974",
      uniCode: "",
    },
    {
      name: "Republic of the Congo",
      code: "+242",
      uniCode: "",
    },
    {
      name: "Reunion",
      code: "+262",
      uniCode: "",
    },
    {
      name: "Romania",
      code: "+40",
      uniCode: "",
    },
    {
      name: "Russia",
      code: "+7",
      uniCode: "",
    },
    {
      name: "Rwanda",
      code: "+250",
      uniCode: "",
    },
    {
      name: "Saint Barthelemy",
      code: "+590",
      uniCode: "",
    },
    {
      name: "Saint Helena",
      code: "+290",
      uniCode: "",
    },
    {
      name: "Saint Kitts and Nevis",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Saint Lucia",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Saint Martin",
      code: "+590",
      uniCode: "",
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "+508",
      uniCode: "",
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Samoa",
      code: "+685",
      uniCode: "",
    },
    {
      name: "San Marino",
      code: "+378",
      uniCode: "",
    },
    {
      name: "Sao Tome and Principe",
      code: "+239",
      uniCode: "",
    },
    {
      name: "Saudi Arabia",
      code: "+966",
      uniCode: "",
    },
    {
      name: "Senegal",
      code: "+221",
      uniCode: "",
    },
    {
      name: "Serbia",
      code: "+381",
      uniCode: "",
    },
    {
      name: "Seychelles",
      code: "+248",
      uniCode: "",
    },
    {
      name: "Sierra Leone",
      code: "+232",
      uniCode: "",
    },
    {
      name: "Singapore",
      code: "+65",
      uniCode: "",
    },
    {
      name: "Sint Maarten",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Slovakia",
      code: "+421",
      uniCode: "",
    },
    {
      name: "Slovenia",
      code: "+386",
      uniCode: "",
    },
    {
      name: "Solomon Islands",
      code: "+677",
      uniCode: "",
    },
    {
      name: "Somalia",
      code: "+252",
      uniCode: "",
    },
    {
      name: "South Africa",
      code: "+27",
      uniCode: "",
    },
    {
      name: "South Korea",
      code: "+82",
      uniCode: "",
    },
    {
      name: "South Sudan",
      code: "+211",
      uniCode: "",
    },
    {
      name: "Spain",
      code: "+34",
      uniCode: "",
    },
    {
      name: "Sri Lanka",
      code: "+94",
      uniCode: "",
    },
    {
      name: "Sudan",
      code: "+249",
      uniCode: "",
    },
    {
      name: "Suriname",
      code: "+597",
      uniCode: "",
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "+47",
      uniCode: "",
    },
    {
      name: "Swaziland",
      code: "+268",
      uniCode: "",
    },
    {
      name: "Sweden",
      code: "+46",
      uniCode: "",
    },
    {
      name: "Switzerland",
      code: "+41",
      uniCode: "",
    },
    {
      name: "Taiwan",
      code: "+886",
      uniCode: "",
    },
    {
      name: "Tajikistan",
      code: "+992",
      uniCode: "",
    },
    {
      name: "Tanzania",
      code: "+255",
      uniCode: "",
    },
    {
      name: "Thailand",
      code: "+66",
      uniCode: "",
    },
    {
      name: "Togo",
      code: "+228",
      uniCode: "",
    },
    {
      name: "Tokelau",
      code: "+690",
      uniCode: "",
    },
    {
      name: "Tonga",
      code: "+676",
      uniCode: "",
    },
    {
      name: "Trinidad and Tobago",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Tunisia",
      code: "+216",
      uniCode: "",
    },
    {
      name: "Turkey",
      code: "+90",
      uniCode: "",
    },
    {
      name: "Turkmenistan",
      code: "+993",
      uniCode: "",
    },
    {
      name: "Turks and Caicos Islands",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Tuvalu",
      code: "+688",
      uniCode: "",
    },
    {
      name: "U.S. Virgin Islands",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Uganda",
      code: "+256",
      uniCode: "",
    },
    {
      name: "Ukraine",
      code: "+380",
      uniCode: "",
    },
    {
      name: "United Arab Emirates",
      code: "+971",
      uniCode: "",
    },
    {
      name: "United Kingdom",
      code: "+44",
      uniCode: "",
    },
    {
      name: "United States",
      code: "+1",
      uniCode: "",
    },
    {
      name: "Uruguay",
      code: "+598",
      uniCode: "",
    },
    {
      name: "Uzbekistan",
      code: "+998",
      uniCode: "",
    },
    {
      name: "Vanuatu",
      code: "+678",
      uniCode: "",
    },
    {
      name: "Vatican",
      code: "+379",
      uniCode: "",
    },
    {
      name: "Venezuela",
      code: "+58",
      uniCode: "",
    },
    {
      name: "Vietnam",
      code: "+84",
      uniCode: "",
    },
    {
      name: "Wallis and Futuna",
      code: "+681",
      uniCode: "",
    },
    {
      name: "Western Sahara",
      code: "+212",
      uniCode: "",
    },
    {
      name: "Yemen",
      code: "+967",
      uniCode: "",
    },
    {
      name: "Zambia",
      code: "+260",
      uniCode: "",
    },
    {
      name: "Zimbabwe",
      code: "+263",
      uniCode: "",
    },
  ],
  isRegistering: false,
  isRegistered: false,
  isGeneratingEmail: false,
  isVerifyingBusinessManagerId: false,
  isVerifiedBusinessManagerId: false,
  isUpdatingBusinessManagerId: false,
  isUpdatedBusinessManagerId: false,
  selectedAccountSegment: "firstScreen",
  languages: [
    {
      value: "af",
      label: "Afrikaans",
      isActive: true,
    },
    {
      value: "sq",
      label: "Albanian",
      isActive: true,
    },
    {
      value: "ar",
      label: "Arabic",
      isActive: true,
    },
    {
      value: "az",
      label: "Azerbaijani",
      isActive: true,
    },
    {
      value: "bn",
      label: "Bengali",
      isActive: true,
    },
    {
      value: "bg",
      label: "Bulgarian",
      isActive: true,
    },
    {
      value: "ca",
      label: "Catalan",
      isActive: true,
    },
    {
      value: "zh_CN",
      label: "Chinese (CHN)",
      isActive: true,
    },
    {
      value: "zh_HK",
      label: "Chinese (HKG)",
      isActive: true,
    },
    {
      value: "zh_TW",
      label: "Chinese (TAI)",
      isActive: true,
    },
    {
      value: "hr",
      label: "Croatian",
      isActive: true,
    },
    {
      value: "cs",
      label: "Czech",
      isActive: true,
    },
    {
      value: "da",
      label: "Danish",
      isActive: true,
    },
    {
      value: "nl",
      label: "Dutch",
      isActive: true,
    },
    {
      value: "en",
      label: "English",
      isActive: true,
    },
    {
      value: "en_GB",
      label: "English (UK)",
      isActive: true,
    },
    {
      value: "en_US",
      label: "English (US)",
      isActive: true,
    },
    {
      value: "et",
      label: "Estonian",
      isActive: true,
    },
    {
      value: "fil",
      label: "Filipino",
      isActive: true,
    },
    {
      value: "fi",
      label: "Finnish",
      isActive: true,
    },
    {
      value: "fr",
      label: "French",
      isActive: true,
    },
    {
      value: "de",
      label: "German",
      isActive: true,
    },
    {
      value: "el",
      label: "Greek",
      isActive: true,
    },
    {
      value: "gu",
      label: "Gujarati",
      isActive: true,
    },
    {
      value: "ha",
      label: "Hausa",
      isActive: true,
    },
    {
      value: "he",
      label: "Hebrew",
      isActive: true,
    },
    {
      value: "hi",
      label: "Hindi",
      isActive: true,
    },
    {
      value: "hu",
      label: "Hungarian",
      isActive: true,
    },
    {
      value: "id",
      label: "Indonesian",
      isActive: true,
    },
    {
      value: "ga",
      label: "Irish",
      isActive: true,
    },
    {
      value: "it",
      label: "Italian",
      isActive: true,
    },
    {
      value: "ja",
      label: "Japanese",
      isActive: true,
    },
    {
      value: "kn",
      label: "Kannada",
      isActive: true,
    },
    {
      value: "kk",
      label: "Kazakh",
      isActive: true,
    },
    {
      value: "ko",
      label: "Korean",
      isActive: true,
    },
    {
      value: "lo",
      label: "Lao",
      isActive: true,
    },
    {
      value: "lv",
      label: "Latvian",
      isActive: true,
    },
    {
      value: "lt",
      label: "Lithuanian",
      isActive: true,
    },
    {
      value: "mk",
      label: "Macedonian",
      isActive: true,
    },
    {
      value: "ms",
      label: "Malay",
      isActive: true,
    },
    {
      value: "ml",
      label: "Malayalam",
      isActive: true,
    },
    {
      value: "mr",
      label: "Marathi",
      isActive: true,
    },
    {
      value: "nb",
      label: "Norwegian",
      isActive: true,
    },
    {
      value: "fa",
      label: "Persian",
      isActive: true,
    },
    {
      value: "pl",
      label: "Polish",
      isActive: true,
    },
    {
      value: "pt_BR",
      label: "Portuguese (BR)",
      isActive: true,
    },
    {
      value: "pt_PT",
      label: "Portuguese (POR)",
      isActive: true,
    },
    {
      value: "pa",
      label: "Punjabi",
      isActive: true,
    },
    {
      value: "ro",
      label: "Romanian",
      isActive: true,
    },
    {
      value: "ru",
      label: "Russian",
      isActive: true,
    },
    {
      value: "sr",
      label: "Serbian",
      isActive: true,
    },
    {
      value: "sk",
      label: "Slovak",
      isActive: true,
    },
    {
      value: "sl",
      label: "Slovenian",
      isActive: true,
    },
    {
      value: "es",
      label: "Spanish",
      isActive: true,
    },
    {
      value: "es_AR",
      label: "Spanish (ARG)",
      isActive: true,
    },
    {
      value: "es_ES",
      label: "Spanish (SPA)",
      isActive: true,
    },
    {
      value: "es_MX",
      label: "Spanish (MEX)",
      isActive: true,
    },
    {
      value: "sw",
      label: "Swahili",
      isActive: true,
    },
    {
      value: "sv",
      label: "Swedish",
      isActive: true,
    },
    {
      value: "ta",
      label: "Tamil",
      isActive: true,
    },
    {
      value: "te",
      label: "Telugu",
      isActive: true,
    },
    {
      value: "th",
      label: "Thai",
      isActive: true,
    },
    {
      value: "tr",
      label: "Turkish",
      isActive: true,
    },
    {
      value: "uk",
      label: "Ukrainian",
      isActive: true,
    },
    {
      value: "ur",
      label: "Urdu",
      isActive: true,
    },
    {
      value: "uz",
      label: "Uzbek",
      isActive: true,
    },
    {
      value: "vi",
      label: "Vietnamese",
      isActive: true,
    },
    {
      value: "zu",
      label: "Zulu",
      isActive: true,
    },
  ],
  languageDict: {
    af: "Afrikaans",
    ar: "Arabic",
    az: "Azerbaijani",
    bg: "Bulgarian",
    bn: "Bengali",
    ca: "Catalan",
    cs: "Czech",
    da: "Danish",
    de: "German",
    el: "Greek",
    en: "English",
    en_GB: "English (UK)",
    en_US: "English (US)",
    es: "Spanish",
    es_AR: "Spanish (ARG)",
    es_ES: "Spanish (SPA)",
    es_MX: "Spanish (MEX)",
    et: "Estonian",
    fa: "Persian",
    fi: "Finnish",
    fil: "Filipino",
    fr: "French",
    ga: "Irish",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    kk: "Kazakh",
    kn: "Kannada",
    ko: "Korean",
    lo: "Lao",
    lt: "Lithuanian",
    lv: "Latvian",
    mk: "Macedonian",
    ml: "Malayalam",
    mr: "Marathi",
    ms: "Malay",
    nb: "Norwegian",
    nl: "Dutch",
    pa: "Punjabi",
    pl: "Polish",
    pt_BR: "Portuguese (BR)",
    pt_PT: "Portuguese (POR)",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sq: "Albanian",
    sr: "Serbian",
    sv: "Swedish",
    sw: "Swahili",
    ta: "Tamil",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    zh_CN: "Chinese (CHN)",
    zh_HK: "Chinese (HKG)",
    zh_TW: "Chinese (TAI)",
    zu: "Zulu",
  },
  selectedRecoveryPasswordSegment: "firstScreen",
  isSendingMailForResetPassword: false,
  isRecoveryPassword: false,
  selectedResetPasswordSegment: "firstScreen",
  isUpdatingResetPassword: false,
  isResendingInvitationForMember: false,
  isShowBox: false,
  isReconnectingToSocket: false,
  widgetPreferences: [
    {
      id: "Personal Detail",
      isVisible: true,
      displayOrder: 1,
    },
    {
      id: "Attributes",
      isVisible: true,
      displayOrder: 7,
    },
    {
      id: "Campaigns",
      isVisible: true,
      displayOrder: 8,
    },
    {
      id: "Tags",
      isVisible: true,
      displayOrder: 2,
    },
    {
      id: "Customer Journey",
      isVisible: true,
      displayOrder: 9,
    },
    {
      id: "Notes",
      isVisible: true,
      displayOrder: 10,
    },
    {
      id: "Block incoming message",
      isVisible: true,
      displayOrder: 11,
    },
  ],
  allUserPreferences: [],
  selectedBulkUploadSegment: "firstScreen",
  selectedBulkUploadTags: [],
  isSavingWidgetPreferences: false,
  isUpdatingOrganization: false,
  selectedShopifySegment: "installScreen",
  isOpenSubscriptionStartPaymentModal: false,
  isRedirectedFromUser: false,
  redirectAppliedFilters: {},
  isFetchingFbEmbeddedData: false,
  isUserPreferencesLoaded: false,
  user_journey: {},
  isShowSidebar: true,
  convLabelId: "",
  isCreatingOrganizationConvLabel: false,
  isFetchingConvLabels: false,
  isConvLabelsLoadedAlready: false,
  orgConvLabels: [],
  isFetchingOutOfOfficeDetails: false,
  outOfOfficeDetails: {},
  isUpdatingOutOfOfficeDetails: false,
  listMessage: [],
  listMessageDetails: {},
  projectListFetching: false,
  projectList: [],
  projectListCopy: [],
  projectListCount: 0,
  businessListFetching: false,
  businessList: [],
  businessListCount: 0,
  isProjectRemoved: false,
  isRemovingProject: false,
  projectData: {},
  isGettingData: false,
  projectCampaignList: [],
  campaignData: {
    addCampaign: {},
    isAddingNewCampaign: false,
  },
  customersListData: {
    customersContactList: {},
    isFetchingCustomersList: false,
  },
};

const getSanitizeData = (text) => {
  let newBody = "";
  if (text) {
    newBody = text.replace(/&gt;/gi, ">");
    newBody = newBody.replace(/&lt;/gi, "<");
    newBody = newBody.replace(/&nbsp;/gi, " ");
    newBody = newBody.replace(/\u21b5/gi, "\n");
  }
  return newBody;
};

const sortAgentData = (list) => {
  if (list.length > 0) {
    return list.sort((a, b) => {
      if (
        `${a?.first_name}${a?.last_name}`.toLowerCase() <
        `${b?.first_name}${b?.last_name}`.toLowerCase()
      ) {
        return -1;
      }
      if (
        `${a?.first_name}${a?.last_name}`.toLowerCase() >
        `${b?.first_name}${b?.last_name}`.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });
  }

  return list;
};

const sortConversationLabel = (list) => {
  if (list.length > 0) {
    return list.sort((a, b) => {
      if (a?.name.toLowerCase() < b?.name.toLowerCase()) {
        return -1;
      }
      if (a?.name.toLowerCase() > b?.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return list;
};

const appReducer = (state = initialState, action) => {
  let orgQuickReplies = [];
  let orgMembers = [];
  let channels = [];
  let organizations = [];
  let tagList = [];
  let selectedBulkUploadTags = [];
  let allTags = {};
  const orgEventSources = [];
  const allAgentsObjectMap = {};
  let allUserPreferences = [];
  let convLabelList = [];

  const changeShopifyWidgetStatus = (status) => {
    const newState = { ...state };
    if (newState.organizations[0]) {
      newState.organizations[0].organization_id.metadata.has_active_shopify_widget =
        status;
    }
    return newState;
  };

  switch (action.type) {
    case SELECT_PROJECT:
      return {
        ...state,
        activeProjectId: action.projectId ? action.projectId : state.projectId,
        activeProject: action.activeProject,
      };

    case SET_USER_TO_CAMP_REDIRECT_DATA:
      return {
        ...state,
        redirectAppliedFilters: action.redirectAppliedFilters,
        isRedirectedFromUser: action.isRedirectedFromUser,
      };

    case TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        isOpenSubscriptionStartPaymentModal:
          action.isOpenSubscriptionStartPaymentModal,
      };

    case FETCH_ORGANIZATION_STATUS:
      return {
        ...state,
        isFetchingOrganization: action.isFetchingOrganization,
      };

    case FETCH_ORGANIZATION:
      organizations = state.organizations.map((item) => {
        if (item.organization_id.id === action.organization.id) {
          item.organization_id = action.organization;
        }
        return item;
      });
      return {
        ...state,
        isFetchingOrganization: action.isFetchingOrganization,
        organizations,
      };

    case UPDATE_ORGANIZATION_STATUS:
      return {
        ...state,
        isUpdatingOrganization: action.isUpdatingOrganization,
      };

    case UPDATE_ORGANIZATION:
      organizations = state.organizations.map((item) => {
        if (item.organization_id.id === action.organization.id) {
          item.organization_id = action.organization;
        }
        return item;
      });
      return {
        ...state,
        organizations,
        isUpdatingOrganization: action.isUpdatingOrganization,
      };

    case SAVE_WIDGET_PREFERENCES_STATUS:
      return {
        ...state,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
      };

    case SAVE_WIDGET_PREFERENCES:
      allUserPreferences =
        !!action.allUserPreferences &&
        !_.isEmpty(action.allUserPreferences) &&
        action.allUserPreferences.length > 0
          ? action.allUserPreferences
          : state.allUserPreferences;
      if (allUserPreferences && allUserPreferences.length > 0) {
        allUserPreferences = makeNameVariableFirstInList(allUserPreferences);
      }
      return {
        ...state,
        isUserPreferencesLoaded: action.isUserPreferencesLoaded,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
        widgetPreferences:
          !!action.widgetPreferences &&
          !_.isEmpty(action.widgetPreferences) &&
          action.widgetPreferences.length > 0
            ? action.widgetPreferences
            : state.widgetPreferences,
        allUserPreferences,
        user_journey: action.user_journey,
      };

    case ATTEMPT_TO_LOGIN:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
      };

    case TOGGLE_CREATE_ACCOUNT_SEGMENT:
      return {
        ...state,
        selectedAccountSegment: action.selectedAccountSegment,
      };

    case CREATING_ORGANIZATION_CHANNEL:
      return {
        ...state,
        isCreatingOrganizationChannel: action.isCreatingOrganizationChannel,
      };

    case CREATED_ORGANIZATION_CHANNEL:
      channels = state.channels;
      if (action.channel) {
        channels.push(action.channel);
      }
      return {
        ...state,
        channels,
        isCreatingOrganizationChannel: action.isCreatingOrganizationChannel,
      };

    case UPDATE_WEB_CLIENT_ID:
      return {
        ...state,
        webClientId: action.webClientId,
      };

    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isAuthenticated: action.isAuthenticated,
        token: action.token,
        user: action.user,
      };
    }

    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isAuthenticated: action.isAuthenticated,
      };

    case VERIFY_FAILED:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        isTokenFound: action.isTokenFound,
      };

    case VERIFY_SUCCESS:
      allUserPreferences =
        !!action.allUserPreferences &&
        !_.isEmpty(action.allUserPreferences) &&
        action.allUserPreferences.length > 0
          ? action.allUserPreferences
          : state.allUserPreferences;
      if (allUserPreferences && allUserPreferences.length > 0) {
        allUserPreferences = makeNameVariableFirstInList(allUserPreferences);
      }
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        token: action.token,
        isUserPreferencesLoaded: action.isUserPreferencesLoaded,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
        widgetPreferences:
          !!action.widgetPreferences &&
          !_.isEmpty(action.widgetPreferences) &&
          action.widgetPreferences.length > 0
            ? action.widgetPreferences
            : state.widgetPreferences,
        allUserPreferences,
        user_journey: action.user_journey,
      };

    case FETCHING_ORGANIZATION_DETAILS:
      return {
        ...state,
        isFetchingOrganizationDetails: action.isFetchingOrganizationDetails,
      };

    case UPDATE_RECONNECTING_STATUS:
      return {
        ...state,
        isReconnectingToSocket: action.isReconnectingToSocket,
      };

    case ATTEMPT_TO_LOGOUT:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        token: action.token,
      };

    case ATTEMPT_TO_VERIFY:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isTokenFound: action.isTokenFound,
      };

    case FETCH_USER_PROFILE:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
      };

    case FETCH_USER_ORG:
      const isVerified =
        action.organizations &&
        action.organizations[0] &&
        action.organizations[0].organization_id &&
        action.organizations[0].organization_id.onboarding_status
          ? action.organizations[0].organization_id.onboarding_status ===
            onboardingStatuses.ONBOARDED
          : false;
      return {
        ...state,
        organizations: action.organizations,
        isVerified,
        isFetchingOrganizationDetails: action.isFetchingOrganizationDetails,
        isOrganizationOnboarded: action.isOrganizationOnboarded,
        organizationDetailInputValues: action.organizations[0].organization_id,
      };

    case UPDATE_ORG_FOR_FAQ_BOT:
      const updatedOrganizationId = state.organizations[0]?.organization_id;
      updatedOrganizationId.is_faq_bot_enabled = action.payload;
      return {
        ...state,
        organizations: {
          ...state.organizations,
          updatedOrganizationId,
        },
      };

    case FETCH_USER_CHANNELS:
      return {
        ...state,
        channels: action.channels,
        isFetchingUserChannels: action.isFetchingUserChannels,
        channelId: action.channelId,
      };

    case ADD_USER_CHANNEL:
      channels = state.channels;
      if (action.channel) {
        channels.push(action.channel);
      }
      return {
        ...state,
        channels,
        isAddingUserChannel: action.isAddingUserChannel,
      };

    case ADDING_USER_CHANNEL:
      return {
        ...state,
        isAddingUserChannel: action.isAddingUserChannel,
      };

    case ORGANIZATION_TAG_LIST_FETCH_STATUS:
      return {
        ...state,
        isFetchingOrganizationTags: action.isFetchingOrganizationTags,
      };
    case ORGANIZATION_TAG_LIST:
      allTags = {};
      action.tagList.forEach((item) => {
        allTags[item.id] = item;
      });
      return {
        ...state,
        isFetchingOrganizationTags: action.isFetchingOrganizationTags,
        tagList: action.tagList,
        isOrganizationtagsLoadedAlready:
          !!action.isOrganizationtagsLoadedAlready,
        allTags,
      };
    case CREATE_ORGANIZATION_TAG_STATUS:
      return {
        ...state,
        isCreatingOrganizationTag: action.isCreatingOrganizationTag,
      };

    case CREATE_ORGANIZATION_TAG:
      state.allTags[action.tag.id] = action.tag;
      return {
        ...state,
        isCreatingOrganizationTag: action.isCreatingOrganizationTag,
        tagList: [action.tag, ...state.tagList],
        allTags: { ...state.allTags },
      };

    case FETCHING_ALL_ORG_AGENTS:
      return {
        ...state,
        isFetchingAgents: action.isFetchingAgents,
      };

    case FETCH_ALL_ORG_AGENTS:
      if (action.allAgents && action.allAgents.length > 0) {
        action.allAgents.forEach((item) => {
          allAgentsObjectMap[item.id] = item;
        });
      }

      return {
        ...state,
        isFetchingAgents: action.isFetchingAgents,
        activeAgents: sortAgentData(action.activeAgents),
        allAgentsObjectMap,
        allAgents: action.allAgents,
      };

    case CHECK_ORGANIZATION_ONBOARD_STATUS:
      return {
        ...state,
        isOrganizationOnboarded: action.isOrganizationOnboarded,
      };

    case FETCHING_OTP:
      return {
        ...state,
        isSendingOTP: action.isSendingOTP,
      };

    case FETCH_OTP:
      return {
        ...state,
        isSendingOTP: action.isSendingOTP,
        isOTPSent: action.isOTPSent,
      };

    case VERIFYING_OTP:
      return {
        ...state,
        isVerifyingOTP: action.isVerifyingOTP,
      };

    case VERIFY_OTP:
      return {
        ...state,
        isVerifyingOTP: action.isVerifyingOTP,
        isSendingOTP: !!action.isSendingOTP,
        isVerified: action.isVerified,
      };

    case FETCHING_ORG_EVENT_SOURCES:
      return {
        ...state,
        isFetchingEventSources: action.isFetchingEventSources,
      };

    case FETCH_ORG_EVENT_SOURCES:
      if (action.orgEventSources && action.orgEventSources.length > 0) {
        action.orgEventSources.forEach((item) => {
          if (item.app_type == "Custom") {
            orgEventSources.push({
              secret_key: item.secret_key,
              organization_id: item.organization_id,
            });
          }
        });
      }
      return {
        ...state,
        isFetchingEventSources: action.isFetchingEventSources,
        isEventSourcesLoadedAlready: action.isEventSourcesLoadedAlready,
        orgEventSources,
      };

    case FETCHING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isFetchingQuickReplies: action.isFetchingQuickReplies,
      };

    case FETCH_ORG_QUICK_REPLIES:
      action.orgQuickReplies.sort(
        (a, b) =>
          new Date(b.created_at_utc).getTime() -
          new Date(a.created_at_utc).getTime()
      );
      if (action.orgQuickReplies && action.orgQuickReplies.length > 0) {
        action.orgQuickReplies.forEach((item) => {
          orgQuickReplies.push({
            id: item.id,
            slash_command: getSanitizeData(item.slash_command),
            quick_reply: getSanitizeData(item.quick_reply),
            media_url: getSanitizeData(item.media_url),
            variable_fallback_values: getSanitizeData(
              item.variable_fallback_values
            ),
            created_by_user: item.created_by_user,
            created_at_utc: item.created_at_utc,
          });
        });
      }
      return {
        ...state,
        isFetchingQuickReplies: action.isFetchingQuickReplies,
        isQuickRepliesLoadedAlready: !!action.isQuickRepliesLoadedAlready,
        orgQuickReplies,
      };

    case UPDATE_ORGANIZATION_ONBOARD_STATUS:
      organizations = state.organizations.map((item) => {
        if (item && item.organization_id) {
          item.organization_id.is_onboarded = action.isOrganizationOnboarded;
          item.organization_id.onboarding_status = action.onBoardingStatus;
        }
        return item;
      });
      return {
        ...state,
        isOrganizationFirstOnboarded: action.isOrganizationFirstOnboarded,
        organizations,
      };

    case ADDING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isAddingQuickReplies: action.isAddingQuickReplies,
      };

    case ADDED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies;
      const quickReply = {
        id: action.quickReply.id,
        slash_command: getSanitizeData(action.quickReply.slash_command),
        quick_reply: getSanitizeData(action.quickReply.quick_reply),
        media_url: action.quickReply.media_url,
        variable_fallback_values: action.quickReply.variable_fallback_values,
        created_by_user: action.quickReply.created_by_user,
      };
      if (orgQuickReplies && _.isArray(orgQuickReplies)) {
        orgQuickReplies.unshift(quickReply);
      } else {
        orgQuickReplies = [];
        orgQuickReplies.push(quickReply);
      }
      return {
        ...state,
        orgQuickReplies,
        isAddingQuickReplies: action.isAddingQuickReplies,
      };

    case UPDATING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isUpdatingQuickReplies: action.isUpdatingQuickReplies,
      };

    case UPDATED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies;
      orgQuickReplies = state.orgQuickReplies.map((item) => {
        if (item.id === action.quickReply.id) {
          item = {
            id: action.quickReply.id,
            slash_command: getSanitizeData(action.quickReply.slash_command),
            quick_reply: getSanitizeData(action.quickReply.quick_reply),
            media_url: action.quickReply.media_url,
            created_by_user: item.created_by_user,
            variable_fallback_values:
              action.quickReply.variable_fallback_values,
          };
        }
        return item;
      });
      return {
        ...state,
        orgQuickReplies,
        isUpdatingQuickReplies: action.isUpdatingQuickReplies,
      };

    case REMOVING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isRemovingQuickReplies: action.isRemovingQuickReplies,
      };

    case REMOVED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies.filter(
        (item) => item.id !== action.quickReply.id
      );
      return {
        ...state,
        orgQuickReplies,
        isRemovingQuickReplies: action.isRemovingQuickReplies,
      };

    case SET_NETWORK_STATUS:
      return {
        ...state,
        isOnline: action.isOnline,
      };

    case SET_SOCKET_STATUS:
      return {
        ...state,
        isSocketConnected: action.isSocketConnected,
      };

    case FETCHING_ORG_MEMBERS:
      return {
        ...state,
        isFetchingMembers: action.isFetchingMembers,
      };

    case FETCHING_USER_CHANNELS:
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
      };

    case FETCH_ORG_MEMBERS:
      if (action.orgMembers && action.orgMembers.length > 0) {
        action.orgMembers.forEach((item) => {
          orgMembers.push({
            id: item.user.id,
            first_name:
              item.user && item.user.first_name ? item.user.first_name : "",
            last_name:
              item.user && item.user.last_name ? item.user.last_name : "",
            email: item.user && item.user.email ? item.user.email : "",
            phone: item.user && item.user.phone ? item.user.phone : "",
            country_code:
              item.user && item.user.country_code ? item.user.country_code : "",
            created_by_user: item.created_by_user,
            last_logged_in_at_utc:
              item.user && item.user.last_logged_in_at_utc
                ? item.user.last_logged_in_at_utc
                : "",
            role: item.role ? item.role : "",
          });
        });
      }
      orgMembers = orgMembers.sort((a, b) => {
        if (_.toLower(a.first_name) < _.toLower(b.first_name)) {
          return -1;
        }
        if (_.toLower(a.first_name) > _.toLower(b.first_name)) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        isFetchingMembers: action.isFetchingMembers,
        orgMembers,
      };

    case ADDING_ORG_MEMBERS:
      return {
        ...state,
        isAddingMembers: action.isAddingMembers,
      };

    case ADDED_ORG_MEMBERS:
      orgMembers = state.orgMembers;
      if (orgMembers && _.isArray(orgMembers)) {
        orgMembers.push(action.member);
      } else {
        orgMembers = [];
        orgMembers.push(action.member);
      }
      orgMembers = orgMembers.sort((a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        orgMembers,
        isAddingMembers: action.isAddingMembers,
      };

    case UPDATING_ORG_MEMBERS:
      return {
        ...state,
        isUpdatingMembers: action.isUpdatingMembers,
      };

    case UPDATED_ORG_MEMBERS:
      orgMembers = state.orgMembers;
      orgMembers = state.orgMembers.map((item) => {
        if (item.id === action.member.id) {
          item.first_name = action.member.first_name;
          item.last_name = action.member.last_name;
          item.phone = action.member.phone;
          item.role = action.member.role;
        }
        return item;
      });
      return {
        ...state,
        orgMembers,
        isUpdatingMembers: action.isUpdatingMembers,
      };

    case REMOVING_ORG_MEMBERS:
      return {
        ...state,
        isRemovingMembers: action.isRemovingMembers,
      };

    case REMOVED_ORG_MEMBERS:
      orgMembers = state.orgMembers.filter(
        (item) => item.id !== action.member.id
      );
      return {
        ...state,
        orgMembers,
        isRemovingMembers: action.isRemovingMembers,
      };

    case FETCH_USER_DEVICE_TYPE:
      return {
        ...state,
        IsUserDeviceMobile: action.IsUserDeviceMobile,
      };

    case REQUEST_REGISTER:
      return {
        ...state,
        isRegistering: action.isRegistering,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegistering: action.isRegistering,
        isRegistered: action.isRegistered,
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        isRegistering: action.isRegistering,
      };

    case GENERATE_VERIFICATION_EMAIL:
      return {
        ...state,
        isGeneratingEmail: action.isGeneratingEmail,
      };

    case EMAIL_VERIFYING:
      return {
        ...state,
        isVerifyingEmail: action.isVerifyingEmail,
      };

    case EMAIL_VERIFY:
      return {
        ...state,
        isVerifyingEmail: action.isVerifyingEmail,
        isVerifiedEmail: action.isVerifiedEmail,
      };

    case VERIFYING_BUSINESS_MANAGER:
      return {
        ...state,
        isVerifyingBusinessManagerId: action.isVerifyingBusinessManagerId,
      };

    case VERIFIED_BUSINESS_MANAGER:
      return {
        ...state,
        isVerifyingBusinessManagerId: action.isVerifyingBusinessManagerId,
        isVerifiedBusinessManagerId: action.isVerifiedBusinessManagerId,
      };

    case UPDATING_BUSINESS_MANAGER:
      return {
        ...state,
        isUpdatingBusinessManagerId: action.isUpdatingBusinessManagerId,
      };

    case UPDATED_BUSINESS_MANAGER:
      organizations = state.organizations.map((item) => {
        if (item.organization_id.id === action.organization.id) {
          return { organization_id: action.organization };
        }
        return item;
      });
      return {
        ...state,
        organizations,
        isUpdatingBusinessManagerId: action.isUpdatingBusinessManagerId,
        isUpdatedBusinessManagerId: action.isUpdatedBusinessManagerId,
      };

    case SET_QUALITY_RATING:
      return {
        ...state,
        activeQuality: action.activeQuality,
        qualityList: action.qualityList,
      };

    case TOGGLE_BOTTOM_NAVBAR:
      return {
        ...state,
        isBottomNavVisible: action.isBottomNavVisible,
      };

    case TOGGLE_RECOVERY_PASSWORD_SEGMENT:
      return {
        ...state,
        selectedRecoveryPasswordSegment: action.selectedRecoveryPasswordSegment,
      };

    case RECOVERY_PASSWORD_STATUS:
      return {
        ...state,
        isRecoveryPassword: action.isRecoveryPassword,
      };

    case SEND_EMAIL_RESET_PASSWORD:
      return {
        ...state,
        isSendingMailForResetPassword: action.isSendingMailForResetPassword,
      };

    case TOGGLE_RESET_PASSWORD_SEGMENT:
      return {
        ...state,
        selectedResetPasswordSegment: action.selectedResetPasswordSegment,
      };

    case UPDATING_RESET_PASSWORD:
      return {
        ...state,
        isUpdatingResetPassword: action.isUpdatingResetPassword,
      };

    case RESEND_INVITATION_MEMBER:
      orgMembers = state.orgMembers.map((item) => {
        if (item.email === action.email) {
          item.isResendingInvitationForMember =
            !item.isResendingInvitationForMember;
        }
        return item;
      });
      return {
        ...state,
        orgMembers,
        isResendingInvitationForMember: action.isResendingInvitationForMember,
      };

    case SET_BOX_VALUE:
      return {
        ...state,
        isShowBox: action.isShowBox,
      };

    case SET_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case TOGGLE_BULK_UPLOAD_SEGMENT:
      return {
        ...state,
        selectedBulkUploadSegment: action.selectedBulkUploadSegment,
      };

    case ADD_NEW_BULK_UPLOAD_SELECTED_TAGS:
      tagList = state.tagList;
      allTags = state.allTags;
      tagList.push(action.tag);
      allTags[action.tag.id] = action.tag;
      selectedBulkUploadTags = state.selectedBulkUploadTags;
      selectedBulkUploadTags.push(action.tag.id);
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags,
      };

    case REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS:
      allTags = state.allTags;
      tagList = state.tagList;
      if (allTags[action.tagId] && allTags[action.tagId].isTagNotCreated) {
        tagList = state.tagList.filter((item) => item.id !== action.tagId);
        delete allTags[action.tagId];
      }
      selectedBulkUploadTags = state.selectedBulkUploadTags.filter(
        (item) => item !== action.tagId
      );
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags,
      };

    case UPDATE_BULK_UPLOAD_SELECTED_TAGS:
      return {
        ...state,
        selectedBulkUploadTags: action.selectedBulkUploadTags,
      };

    case CLEAR_BULK_UPLOAD_NON_CREATED_TAGS: {
      allTags = state.allTags;
      const nonCreatedTags = state.tagList.filter(
        (item) => item.isTagNotCreated
      );
      tagList = state.tagList.filter((item) => !item.isTagNotCreated);
      nonCreatedTags.forEach((item) => {
        delete allTags[item.id];
      });
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags: [],
      };
    }

    case TOGGLE_SHOPIFY_SEGMENT:
      return {
        ...state,
        selectedShopifySegment: action.selectedShopifySegment,
      };

    case FETCHING_BUNDLED_DATA:
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
        isFetchingAgents: action.isFetchingAgents,
      };

    case FETCH_BUNDLED_DATA:
      if (action.allAgents && action.allAgents.length > 0) {
        action.allAgents.forEach((item) => {
          allAgentsObjectMap[item.id] = item;
        });
      }
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
        isFetchingAgents: action.isFetchingAgents,
        channels: action.channels,
        channelId: action.channelId,
        allAgents: action.allAgents,
        allAgentsObjectMap,
        activeAgents: sortAgentData(action.activeAgents),
        catalogId: action.catalogId,
      };

    case TOGGLE_ADD_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenAddReplyForm: action.isOpenAddReplyForm,
      };

    case TOGGLE_EDIT_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenEditReplyForm: action.isOpenEditReplyForm,
      };

    case TOGGLE_DELETE_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenDeleteReplyForm: action.isOpenDeleteReplyForm,
      };

    case TOGGLE_DELETE_TEAMSETTING_MODAL:
      return {
        ...state,
        isOpenDeleteMember: action.isOpenDeleteMember,
      };
    case TOGGLE_EDIT_TEAMSETTING_MODAL:
      return {
        ...state,
        isOpenEditMember: action.isOpenEditMember,
      };
    case TOGGLE_BUG_REPORT_MODAL:
      return {
        ...state,
        isOpenBugReportModal: action.isOpenBugReportModal,
      };

    case TOGGLE_EMOJI_CARD_MODAL:
      return {
        ...state,
        isOpenEmojiCard: action.isOpenEmojiCard,
      };

    case SET_BUG_REPORT_ISSUE_WITH:
      return {
        ...state,
        bugReportIssueWith: action.text,
      };

    case SET_BUG_REPORT_SUBJECT:
      return {
        ...state,
        bugReportSubject: action.text,
      };

    case SET_BUG_REPORT_DETAIL:
      return {
        ...state,
        bugReportDetail: action.text,
      };
    case UPDATE_BUG_REPORT:
      return {
        ...state,
        bugReportSubject: action.bugReportSubject,
        bugReportDetail: action.bugReportDetail,
        bugReportIssueWith: action.bugReportIssueWith,
      };
    case SET_QUICK_REPLY_FIELDS:
      return {
        ...state,
        quickReplyFields: action.data,
      };

    case SET_QUICK_REPLY_VARIABLE_COUNT:
      return {
        ...state,
        quickReplyVariableCount: action.count,
      };

    case SET_QUICK_REPLY_INPUT_FIELDS:
      return {
        ...state,
        quickReplyInputfields: action.data,
      };

    case SET_TEAM_MEMBER_FIELDS:
      return {
        ...state,
        teamMemberInputFields: action.data,
      };

    case TOGGLE_ADD_TEAM_MEMBER_MODAL:
      return {
        ...state,
        isOpenAddTeamMemberModal: action.isOpenAddTeamMemberModal,
      };

    case SET_ORGANIZATION_DETAIL_INPUT_VALUES:
      return {
        ...state,
        organizationDetailInputValues: action.organizationDetailInputValues,
      };

    case FETCHING_FB_EMBEDDED_DATA:
      return {
        ...state,
        isFetchingFbEmbeddedData: action.val,
      };

    case SAVE_USER_JOURNEY:
      return {
        ...state,
        user_journey: action.user_journey,
      };

    case TOGGLE_SIDEBAR_STATUS:
      return {
        ...state,
        isShowSidebar: action.isShowSidebar,
      };

    case FETCHING_ORG_CONVERSATION_LABELS:
      return {
        ...state,
        isFetchingConvLabels: action.isFetchingConvLabels,
      };

    case FETCH_ORG_CONVERSATION_LABELS:
      return {
        ...state,
        isFetchingConvLabels: action.isFetchingConvLabels,
        isConvLabelsLoadedAlready: action.isConvLabelsLoadedAlready,
        orgConvLabels: sortConversationLabel(action.orgConvLabels),
      };

    case CREATE_ORGANIZATION_CONVERSATION_LABEL_STATUS:
      return {
        ...state,
        isCreatingOrganizationConvLabel: action.isCreatingOrganizationConvLabel,
      };

    case UPDATE_ORG_CONVERSATION_LABELS:
      convLabelList = state.orgConvLabels;
      if (action.event && convLabelList && _.isArray(convLabelList)) {
        convLabelList.push(action.event);
      } else {
        convLabelList = [];
        convLabelList.push(action.event);
      }
      return {
        ...state,
        orgConvLabels: sortConversationLabel(convLabelList),
      };

    case FETCH_LIST_MESSAGE_DETAILS_SUCCESS: {
      // Todo remove this logic after BE fixed
      const listMessage = action.payload?.filter(
        (item) => item?.message_content?.length
      );

      return {
        ...state,
        listMessage,
        listMessageDetails: listMessage?.[0],
      };
    }

    case UPDATE_LIST_MESSAGE_DATA:
      return {
        ...state,
        listMessageDetails: action.payload,
      };

    case UPDATE_LIST_MESSAGE_DETAILS: {
      const updatedData = {
        body_text: action.payload.body_text,
      };

      return {
        ...state,
        listMessageDetails: { ...state.listMessageDetails, ...updatedData },
      };
    }

    case UPDATE_ORG_FOR_E_MANDATE: {
      const org = state.organizations[0]?.organization_id;
      org.is_mandate_migrated = action.payload;
      return {
        ...state,
        organizations: {
          ...state.organizations,
          org,
        },
      };
    }

    case SET_CATALOG_ID: {
      const orgCopy = [...state.organizations];
      orgCopy[0] = {
        ...orgCopy[0],
        organization_id: {
          ...orgCopy[0].organization_id,
          metadata: {
            ...orgCopy[0].organization_id.metadata,
            catalog_id: action.payload,
          },
        },
      };

      return {
        ...state,
        organizations: orgCopy,
      };
    }

    case FETCH_PROJECT_LIST:
      return {
        ...state,
        projectListFetching: true,
      };

    case FETCH_PROJECT_LIST_SUCCESS:
      const {list} = action.payload; 
      return {
        ...state,
        projectListFetching: false,
        projectList: JSON.parse(JSON.stringify(list)),
        projectListCopy: JSON.parse(JSON.stringify(list)),
        projectListCount: action.payload?.count,
      };

    case FETCH_PROJECT_LIST_FAILURE:
      return {
        ...state,
        projectList: [],
        projectListFetching: false,
      };

    case FETCH_BUSINESS_LIST:
      return {
        ...state,
        businessListFetching: true,
      };

    case FETCH_BUSINESS_LIST_SUCCESS:
      return {
        ...state,
        businessListFetching: false,
        businessList: action.payload?.businessList,
        businessListCount: action.payload?.businessCount,
      };

    case FETCH_BUSINESS_LIST_FAILURE:
      return {
        ...state,
        businessList: [],
        businessListFetching: false,
      };

    case ADD_PROJECT_DETAILS_SUCCESS: {
      const newData = action.payload?.project;
      let projectList = _.clone(state.projectList);
      projectList.unshift(newData);

      return {
        ...state,
        projectList: projectList,
        activeProjectId: newData._id ? newData._id : state.projectId,
      };
    }
    case UPDATE_USER_PROJECT_DETAILS: {
      return {
        ...state,
        isUpdatingProject: action.isUpdatingProject,
      };
    }

    case UPDATE_USER_PROJECT_DETAILS_UPDATE_SUCCESS:
      let projectList = state.projectList?.map((item) => {
        if (item._id === action.activeProject._id) {
          return action.activeProject;
        }
        return item;
      });
      return {
        ...state,
        isUpdatingProject: action.isUpdatingProject,
        projectList: projectList,
        activeProject: action.activeProject,
      };

    case USER_PROJECT_DETAILS_UPDATE_FAILURE:
      return {
        ...state,
        // projectList: [],
        projectListFetching: false,
        projectData: {},
      };

    case HANDLE_USER_PROJECT_REMOVAL:
      return {
        ...state,
        isRemovingProject: action.isRemovingProject,
        isProjectRemoved: action.isProjectRemoved,
      };

    case FETCH_CAMPAIGN_DETAIL:
      return {
        ...state,
        isGettingData: action?.isGettingData,
      };

    case FETCH_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        projectCampaignList: action?.payload,
        isGettingData: action?.isGettingData,
      };

    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        isGettingData: action?.isGettingData,
      };

    case ADD_NEW_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          addCampaign: action?.addCampaign,
        },
      };
    case ADD_NEW_CAMPAIGN_DETAILS_STATUS: {
      return {
        ...state,
        isAddingNewCampaign: action?.isAddingNewCampaign,
      };
    }
    case FETCH_ALL_CUSTOMER_LIST_DETAILS:
      return {
        ...state,
        customersListData: {
          ...state.customersListData,
          customersContactList: action?.payload,
        },
      };
    case FETCH_ALL_CUSTOMER_LIST_DETAILS_STATUS: {
      return {
        ...state,
        isFetchingCustomersList: action?.isFetchingCustomersList,
      };
    }

    case FETCH_BUSINESS_ADMIN_SUCCESS:
      return {
        ...state,
        adminList: action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
