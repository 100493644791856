import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  variant as systemVariants,
  shadow,
  space,
  border,
  color,
} from "styled-system";
import Icon from "../Icon";
import Box from "../Box";

const StyledAccordion = styled(Box)`
  background: ${({ theme }) => theme.colors.white};
  overflow: auto;
  overflow-x: hidden;
  ${() =>
    systemVariants({
      variants: {
        joint: {
          // border: "0.5px solid #C4C4C4",
          // boxShadow: "2px 2px 4px rgba(131, 131, 131, 0.15)",
          // borderRadius: "6px",
          marginBottom: "10px",
        },
        disjoint: {
          display: "flex",
          flexDirection: "column",
          gridGap: "10px",
        },
      },
    })};

  ${border}
  ${shadow}
`;
const StyledLine = styled("hr")`
  display: ${({ visible }) => (visible ? "block" : "none")};
  // border-top: 1px solid #172024;
  opacity: 0.08;
  margin: 0px 17px;

  ${border}
  ${space}
  ${color}
`;
const StyledTitle = styled("h2")`
  font-size: 14.5px;
  font-style: normal;
  font-weight: 550;
  text-align: left;
  margin: 0;
  ${() =>
    systemVariants({
      variants: {
        disjoint: {
          fontWeight: 600,
        },
      },
    })};
`;
const AccordionBody = styled ("div")`
border: 1px solid #F3F3F3;
  border-radius: 10px;
    margin: 16px 17px;
    width: 97%;
    @media (max-width: 768px) {
      margin: 16px 12px;
      width: 94%;
    }
`
const StyledAccordionButton = styled("button")`
  display: ${({ withCount }) => (withCount ? "grid" : "flex")};
  grid-template-columns: ${({ withCount }) =>
    withCount ? "31px 4fr 0.1fr" : "none"};
  grid-gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px 15px;
  width: 97%;
  border: none;
  outline: none;
  border-radius: 10px;
  // border-top: 1px solid #f3f3f3;
  
  ${props =>
    systemVariants({
      variants: {
        disjoint: {
          border: `${
            props.isActive ? "1px solid #278351" : "1px solid #222222"
          }`,
          borderRadius: "6px",
          borderBottomLeftRadius: `${props.isActive ? "0px" : "6px"}`,
          borderBottomRightRadius: `${props.isActive ? "0px" : "6px"}`,
          [`@media (min-width: ${props.theme.breakpoints.tablet})`]: {
            padding: "18px 40px",
          },
        },
      },
    })};

  ${space}
`;
const SyledCount = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000000;
  color: #ffffff;
`;

const StyledIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000000;
  color: #ffffff;
  margin-right: 15px;
`;
const StyledArrow = styled(Icon)`
  justify-self: flex-end;
`;
const AccordionHeading = styled.div`
  display: flex;
  align-items: center;
`;
export default function Accordion({
  accordionData,
  withCount,
  variant,
  defaultOpenIndex,
  children,
  isControlled = false,
  activeIndex,
  setActiveIndex,
  accordionButtonProps,
  hrProps = {},
  withIcon,
  ...props
}) {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (defaultOpenIndex !== null && !isControlled) {
      setActive(defaultOpenIndex);
    }
  }, [defaultOpenIndex, isControlled]);

  const changeActiveAccordion = index => {
    if (isControlled) {
      setActiveIndex(activeIndex !== index ? index : null);
    } else {
      setActive(active !== index ? index : null);
    }
  };

  const getIsActive = index => {
    if (isControlled) {
      return activeIndex === index;
    }
    return active === index;
  };

  return (
    <StyledAccordion variant={variant} {...props}>
      {accordionData?.map(
        (
          { title, content, iconName, iconColor, disabled, rightComponent },
          index,
        ) => (
          <AccordionBody>
            <StyledAccordionButton
              variant={variant}
              isActive={getIsActive(index)}
              disabled={!!disabled}
              withCount={withCount}
              onClick={() => changeActiveAccordion(index)}
              {...accordionButtonProps}
            >
              {withIcon && (
                <AccordionHeading>
                  <StyledIcon>
                    <Icon iconName={iconName} color={iconColor} />
                  </StyledIcon>
                  <StyledTitle variant={variant}>{title}</StyledTitle>
                </AccordionHeading>
              )}
              {withCount && <SyledCount>{index + 1}</SyledCount>}

              {rightComponent || (
                <StyledArrow
                  iconName="downArrow"
                  color="rgba(0, 0, 0, 0.8)"
                  transform={getIsActive(index) ? "none" : "rotate(-180deg)"}
                  transition="transform 0.3s"
                />
              )}
            </StyledAccordionButton>
            {variant === "joint" && (
              <StyledLine
                visible={
                  !getIsActive(index) && index !== accordionData.length - 1
                }
                {...hrProps}
              />
            )}
            <Box display={getIsActive(index) ? "block" : "none"}>
              {content ?? children}
            </Box>
          </AccordionBody>
        ),
      )}
    </StyledAccordion>
  );
}

Accordion.propTypes = {
  accordionData: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.element,
      disabled: PropTypes.bool,
      rightComponent: PropTypes.element,
    }),
  ]).isRequired,
  variant: PropTypes.oneOf(["joint", "disjoint"]),
  withCount: PropTypes.bool,
  withIcon: PropTypes.bool,
  defaultOpenIndex: PropTypes.number,
};

Accordion.defaultProps = {
  variant: "joint",
  withCount: false,
  defaultOpenIndex: null,
};
