import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import keyCodes from "constants/keyboard-codes.json";

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #222;
  opacity: 0.5;
  pointer-events: auto;
`;

const StyledModal = styled.div`
  position: fixed;
  z-index: 1040;
  width: ${({ width }) => width};
  height: auto;
  background-color: #fff;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba(214, 214, 214, 0.25);
  border-radius: 8px;
`;

const MiniModal = ({ children, isOpen, onHide, width, style }) => {
  // ? the modal can hide on pressing esc or backscape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === keyCodes.ESC && typeof onHide === "function") {
        onHide();
      }
    };
    document.addEventListener("keydown", (e) => {
      handleKeyDown(e);
    });
    return () => document.removeEventListener("mousedown", handleKeyDown, true);
  }, [onHide]);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <StyledOverlay onClick={onHide} />
      <StyledModal style={style} width={width}>
        {children}
      </StyledModal>
    </>
  );
};

MiniModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  iconName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MiniModal.defaultProps = {
  isOpen: false,
  iconName: null,
  width: "80%",
};

export default MiniModal;
