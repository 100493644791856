import { toast } from "react-toastify";
import React from "react";
import _ from "lodash";
import JSON5 from "json5";
import libphonenumber from "google-libphonenumber";
import callApi from "./apiCaller";
import moment from "moment";
import { getUrlExtension, getHTMLDecodedText } from "./chatUtil";
import colors from "util/constant";
import mediaSupportedSize from "constants/media-supported-size.json";
import sanitizeHtml from "sanitize-html";
import startCase from "lodash/startCase";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export function isValidMobileNo(phone) {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone));
  } catch (err) {
    return false;
  }
}

export const definedDateOptions = [
  {
    label: "Today",
    value: "Today",
    pastDays: 0,
    type: "definedDate",
  },
  {
    label: "Yesterday",
    value: "Yesterday",
    pastDays: 1,
    type: "definedDate",
  },
  {
    label: "Last 7 days",
    value: "Last 7 days",
    pastDays: 7,
    type: "definedDate",
  },
  {
    label: "Last 30 days",
    value: "Last 30 days",
    pastDays: 30,
    type: "definedDate",
  },
];

export function isValidISODate(date) {
  return moment(date, "YYYY-MM-DDThh:mm:ss", true).isValid();
}

export function getAgentFirstName(agent) {
  return agent && agent.first_name ? _.startCase(agent.first_name) : "";
}

export function isJSON(str) {
  try {
    return JSON5.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

/**
 * Use this to show toast
 * @param {"success" | "warning" | "error" | "socket-offline" | "offline" | "online"} type The type of toast to be shown
 * @param {string} msg The message to be shown
 * @returns
 */
export function showNotification(type, msg) {
  switch (type) {
    case "success":
      toast.success(
        ({ closeToast }) => (
          <div className="flex-center">
            <img
              style={{ marginRight: "8px" }}
              src="/assets/svg/toast_success.svg"
              alt=""
            />
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          className: "toast-width-m",
        }
      );
      return;

    case "warning":
      toast.warning(
        ({ closeToast }) => (
          <div className="flex-center">
            <img
              style={{ marginRight: "8px" }}
              src="/assets/svg/toast_warning.svg"
              alt=""
            />
            <div className="toast-font" style={{ wordBreak: "unset" }}>
              {msg}
            </div>
          </div>
        ),
        {
          className: "toast-width-m",
        }
      );
      return;

    case "error":
      toast.error(
        ({ closeToast }) => (
          <div className="flex-center">
            <img
              style={{ marginRight: "8px" }}
              src="/assets/svg/warning_error.svg"
              alt=""
            />
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          className: "toast-width-m",
        }
      );
      return;

    case "socket-offline":
    case "offline":
      toast.error(
        () => (
          <div className="flex-center">
            <img
              style={{ marginRight: "8px" }}
              src="/assets/svg/toast_error.svg"
              alt=""
            />
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          className: "toast-width-m",
        }
      );
      return;

    case "online":
      toast.dismiss();
      toast.success(
        () => (
          <div className="flex-center">
            <img
              style={{ marginRight: "8px" }}
              src="/assets/svg/toast_success.svg"
              alt=""
            />
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          className: "toast-width-m",
        }
      );
      return;
  }
}

export function showCustomNotification(type, component, options, toastId) {
  switch (type) {
    case "warning":
      toast.warning(component, options);
      break;

    case "success":
      if (toastId) {
        toast.dismiss(toastId);
      }
      toast.success(component, options);
      break;

    case "error":
      if (toastId) {
        toast.dismiss(toastId);
      }
      toast.error(component, options);
      break;

    default:
  }
}

export function handleFlatObject(obj) {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) =>
          typeof o[k] === "object" && o[k] ? _flatten(o[k]) : { [k]: o[k] }
        )
      );
    })(obj)
  );
}

export function validBaseURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function checkIfValidUrl(text) {
  return new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,10})(:[0-9]+)?(/.*)?"
  ).test(text);
}

export function checkIfStringHasVariables(text) {
  return text ? text.match(/\{\{[1-9][0-9]*\}\}/g) : [];
}

export function checkIfStringHasSubstring(string, substring) {
  return string ? string.includes(substring) : false;
}

export function removeDuplicateFromArray(arr) {
  let newArr = [];
  arr.forEach((item) => {
    if (
      newArr.filter((itm) => JSON5.stringify(itm) === JSON5.stringify(item))
        .length === 0
    ) {
      newArr.push(item);
    }
  });
  return newArr;
}

export function sortTemplateVariables(unsortedVariables = []) {
  const sortedVariables = [...unsortedVariables];
  return sortedVariables.sort((first, second) => {
    const firstIndex = parseInt(first.replace("{{", "").replace("}}", ""), 10);
    const secondIndex = parseInt(
      second.replace("{{", "").replace("}}", ""),
      10
    );
    return firstIndex - secondIndex;
  });
}

export function validateMediaUrl(media_url, callback, mediaType) {
  let isValidUrl = checkIfValidUrl(media_url);
  if (isValidUrl) {
    callApi(
      `v1/validate/whatsapp/media-url/`,
      "post",
      {
        media_url: media_url,
      },
      true
    ).then((res) => {
      if (res && res.result) {
        if (!res.data.is_valid_media) {
          showNotification("warning", res.message);
        }
        callback(null, res);
      } else {
        showNotification(
          "warning",
          "There is an issue in the Media URL you submitted. Please resubmit it or use another URL."
        );
        callback(null, false);
      }
    });
  } else {
    showNotification(
      "warning",
      "In the {{media_url}} Fallback Value, please check if you have entered a URL containing a media file. In the {{media_url}} Value, either select 'Use Fallback Value' or a trait which contains media URLs"
    );

    callback(null, false);
  }
}

export function getConvertedCSVData(csv_data) {
  let newList = [];
  let headers = csv_data[0].data;
  headers.forEach((item, index) => {
    let items = [];
    for (let i = 1; i < csv_data.length; i++) {
      items.push(csv_data[i].data[index]);
    }
    newList.push({
      header_name: item,
      mapped_variable: item,
      isMappedToCSVName: true,
      items: items,
      index: index,
    });
  });
  return newList;
}

export function getPaginatedOffset(page, size) {
  return (page - 1) * size;
}

export function getPages(count, pagesize) {
  if (count > 0) {
    return Math.ceil(count / pagesize);
  } else {
    return 0;
  }
}

export function getNumberSuffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function makeNameVariableFirstInList(list) {
  list = list.filter((item) => item.keyName !== "name");
  list.unshift({
    keyName: "name",
    keyValue: "name",
    dataType: "str",
  });
  return list;
}

export function getFilenameFromUrl(url) {
  let newUrl = decodeURIComponent(url);
  if (newUrl) {
    let m = newUrl.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return "";
}

export function isValidBaseUrl(url) {
  let match = url.toString().match(/^.+?[^\/:](?=[?\/]|$)/);
  return match[0] === url;
}

export function fetchUserDeviceType() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function handleSearch(qtext, keyNames, list) {
  qtext = qtext.replaceAll("(", "\\(");
  qtext = qtext.replaceAll(")", "\\)");
  const exp = new RegExp(qtext, "i");
  list = list.filter((item) => {
    let isMatch = false;
    keyNames.forEach((key) => {
      if (!!item[key] && !!item[key].match(exp)) {
        isMatch = true;
      }
    });
    return isMatch;
  });

  return list;
}

export function getFormattedUnit(unit) {
  const unitObj = {
    minute: "min",
    minutes: "min",
    hour: "hr",
    hours: "hr",
    day: "day",
    days: "day",
    month: "mon",
    months: "mon",
    year: "yr",
    years: "yr",
  };
  return unitObj[unit];
}

export function isMobile() {
  return "ontouchstart" in document.documentElement;
}

export const getFormattedDate = (item) => {
  if (item) {
    return moment(item).tz("asia/kolkata").format("L");
  } else {
    return moment().tz("asia/kolkata").format("L");
  }
};

export const getFileType = (name, fromHandleSend) => {
  // should work without fromHandleSend Argument

  const imageTypes = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
  const audioTypes = ["wav", "mp3", "m4a", "audio", "amr", "ogg"];
  const videoTypes = ["mp4", "3gpp"];
  const docTypes = ["doc", "docx"];
  const excelTypes = ["xlsx", "xls", "csv"];
  const pdf = ["pdf"];

  let extension;

  //check  for upload
  if (name && typeof name === "object") {
    extension = getUrlExtension(name.name);
  } else if (name) {
    extension = getUrlExtension(name);
  }

  if (name) {
    if (imageTypes.indexOf(extension) > -1) {
      return "Image";
    } else if (audioTypes.indexOf(extension) > -1) {
      return "Audio";
    } else if (videoTypes.indexOf(extension) > -1) {
      return "Video";
    } else if (fromHandleSend) {
      return "Document";
    } else if (docTypes.indexOf(extension) > -1) {
      return "wordDoc";
    } else if (excelTypes.indexOf(extension) > -1) {
      return "excel";
    } else if (pdf.indexOf(extension) > -1) {
      return "Document";
    } else {
      return null;
    }
  } else {
    return "this format not supported";
  }
};

export const getPreview = (mediaUrl, isSelected = false) => {
  const fileType = getFileType(mediaUrl);

  switch (fileType) {
    case "Image":
    case "Video":
      //check for upload at inbox page
      return getHTMLDecodedText(mediaUrl) || URL.createObjectURL(mediaUrl);
    case "Document":
      return "/images/document.svg";
    case "Audio":
      return isSelected
        ? "/images/audiochat_white.svg"
        : "/images/audio_chat.svg";
    case "wordDoc":
      return "/images/doc.svg";
    case "excel":
      return "/images/excel.svg";
  }
};

export function checkIfArrayHasNonEmptyStringElements(array) {
  if (array && array.length > 0) {
    let checkIfBlank = false;
    array.forEach((arrayItem) => {
      if (arrayItem === "" && !checkIfBlank) {
        checkIfBlank = true;
      }
    });
    return !checkIfBlank;
  } else {
    return false;
  }
}

export const getColorAccordingToIndex = (index) => {
  return colors[index % colors.length];
};

export const searchList = (item, searchString) =>
  item.toLowerCase().includes(searchString.toLowerCase());

export const getDaysOption = () => {
  return [
    {
      label: "Monday",
      value: "Monday",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
    },
    {
      label: "Wednesday",
      value: "Wednesday",
    },
    {
      label: "Thursday",
      value: "Thursday",
    },
    {
      label: "Friday",
      value: "Friday",
    },
    {
      label: "Saturday",
      value: "Saturday",
    },
    {
      label: "Sunday",
      value: "Sunday",
    },
  ];
};

export const isEmoji = (str) => {
  if (str) {
    var ranges = [
      "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
    ];
    return str.match(ranges.join("|"));
  }

  return null;
};

export const clearLocalStorage = () => {
  const preserveKeys = [
    "hideOutOfOfficeAnnouncement",
    "hideXDaysAgoAnnouncement",
    "hidePromotionalTemplates",
    "botFlowsVisited",
  ];
  const currentValues = {};
  preserveKeys.forEach(
    (key) => (currentValues[key] = window.localStorage.getItem(key))
  );
  window.localStorage.clear();
  preserveKeys.forEach((key) => {
    if (currentValues[key] && currentValues[key] !== "null") {
      window.localStorage.setItem(key, currentValues[key]);
    }
  });
};

export const minTwoDigits = (n) => {
  return (n < 10 ? "0" : "") + n;
};

export const getSupportedMediaValidation = (fileSize, fileType) => {
  switch (fileType) {
    case "Image":
      return fileSize > mediaSupportedSize.Image;

    case "Audio":
      return fileSize > mediaSupportedSize.Audio;

    case "Video":
      return fileSize > mediaSupportedSize.Video;

    case "Document":
      return fileSize > mediaSupportedSize.Document;

    case "wordDoc":
      return fileSize > mediaSupportedSize.Document;

    case "excel":
      return fileSize > mediaSupportedSize.Document;

    case "Application":
      return fileSize > mediaSupportedSize.Application;

    case "Sticker":
      return fileSize > mediaSupportedSize.Sticker;
  }
};

export const validationForMediaFile = (file) => {
  let isErrorVisible = false;
  const fileSize = parseFloat(file?.size);
  const fileType = getFileType(file);
  if (fileSize && fileType && getSupportedMediaValidation(fileSize, fileType)) {
    isErrorVisible = true;
    showNotification(
      "warning",
      `Media file size exceeds the limit allowed. Please ensure the ${fileType.toLowerCase()} size is less than ${parseInt(
        mediaSupportedSize[fileType] / (1024 * 1024)
      )} MB`
    );

    return isErrorVisible;
  }
  return isErrorVisible;
};

export const detectMobile = () => {
  return !!window && window.innerWidth <= 768;
};

export const validateWebsiteURL = (string) => {
  let res =
    string &&
    string.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/gi
    );
  return res !== null;
};

export const maskNumber = (number, shouldMask = true) => {
  if (!shouldMask || !number) {
    return number;
  }

  const reducer = (accumulator, item, index, array) => {
    if (index < array.length - 3 && index > array.length - 11) {
      accumulator[index] = "X";
    } else {
      accumulator[index] = item;
    }

    return accumulator;
  };

  return number.toString().split("").reduce(reducer, []).join("");
};

export function updateLastSync(org, campaignId) {
  // TODO: Find a better way to store Last sync data
  if (org[0].organization_id?.["campaigns_last_synced_at_utc"]) {
    org[0].organization_id.campaigns_last_synced_at_utc[campaignId] = moment
      .utc()
      .format();
  } else {
    org[0].organization_id["campaigns_last_synced_at_utc"] = {};
    org[0].organization_id.campaigns_last_synced_at_utc[campaignId] = moment
      .utc()
      .format();
  }
}

export const getCustomerNameInitials = (customer_id) => {
  if (customer_id && customer_id.contact_name) {
    return startCase(customer_id.contact_name[0]);
  }

  return "#";
};

// For example, GMT, UTC, PST, MST, CST, EST, etc...
export const timeZoneAbbreviation = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  // In Chrome browser, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari browser, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

  if (tz.includes(" ")) {
    return tz
      .split(" ")
      .map(([first]) => first)
      .join("");
  }
  return tz;
};

export const getTimeZone = () => {
  return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getUrlParams = (variable) => {
  if (variable && typeof variable === "string") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams.get(variable);
  }

  return null;
};

export const validationSignupFieldsForCharCount = (data) => {
  let isValid = true;
  let message = "";
  const errors = {};
  if (data.user.first_name.length > 128) {
    isValid = false;
    errors.first_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.last_name.length > 128) {
    isValid = false;
    errors.last_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.email.length > 256) {
    isValid = false;
    errors.email = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.company_name.length > 128) {
    isValid = false;
    errors.company_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.company_website.length > 128) {
    isValid = false;
    errors.company_website = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.company_location.length > 256) {
    isValid = false;
    errors.company_location = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (data.user.designation.length > 128) {
    isValid = false;
    errors.designation = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  return {
    isValid,
    message,
    errors,
  };
};

// Change ISO time to their local timezone and then convert to IST timezone
// ISO time format -> 2000-01-01 10:00:00
export const updateOtherTimezoneToISTTimezone = (val) => {
  const formats = [moment.ISO_8601, "YYYY-MM-DD  :)  HH*mm*ss"];
  if (val && moment(val, formats, true).isValid()) {
    return new Date(new Date(val).getTime() + 19800000)
      .toISOString()
      .slice(0, -5);
  }
};

// Change IST ISO time to their local timezone
// ISO time format -> 2000-01-01T10:00:00
export const updateISTTimezoneToLocalTimezone = (val) => {
  const updatedValue = `${val.replace(/T/g, " ")}.000Z`;
  const formats = [moment.ISO_8601, "YYYY-MM-DD  :)  HH*mm*ss"];
  if (val && moment(updatedValue, formats, true).isValid()) {
    return new Date(new Date(updatedValue).getTime() - 19800000).toTimeString();
  }
};

export const getRolesOption = () => {
  return [
    {
      label: "Owner",
      value: "Owner",
    },
    {
      label: "Agent",
      value: "Agent",
    },
  ];
};

export const getMessagesOption = () => {
  return [
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Audio",
      value: "audio",
    },
    {
      label: "Document",
      value: "document",
    },
  ];
};

export const getMediaTypeOption = () => {
  return [
    {
      label: "None",
      value: "none",
    },
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Document",
      value: "document",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "Carousel",
      value: "carousel",
    },
  ];
};
export const getCarouselMediaType = () => {
  return [
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
  ];
};

export const getCategoryOption = () => {
  return [
    {
      label: "MARKETING",
      value: "MARKETING",
    },
    {
      label: "UTILITY",
      value: "UTILITY",
    },
    {
      label: "AUTHENTICATION",
      value: "AUTHENTICATION",
    },
  ];
};

export const getTemplateTagOption = () => {
  return [
    { icon: "ecommerce", label: "Ecommerce", value: "ecommerce" },
    {
      icon: "education",
      label: "Education",
      value: "education",
    },
    { icon: "banking", label: "Banking", value: "banking" },
    { icon: "webinar", label: "Webinar", value: "webinar" },
    { icon: "health", label: "Healthcare", value: "healthcare" },
    { icon: "automobile", label: "Automobile", value: "automobile" },
    { icon: "state", label: "Real state", value: "real_state" },
    { icon: "services", label: "Services", value: "services" },
    { icon: "nonProfit", label: "Non profit", value: "non_profit" },
  ];
};

export const getConditionOption = () => {
  return [
    {
      value: "and",
      label: "and",
    },
    {
      value: "or",
      label: "or",
    },
  ];
};

export const getOperatorOption = () => {
  return [
    {
      value: "is",
      label: "is",
    },
    {
      value: "is not",
      label: "is not",
    },
    {
      value: "contain",
      label: "contain",
    },
    {
      value: "contain not",
      label: "contain not",
    },
  ];
};
export const getCountryOption = () => [
  {
    label: "India",
    value: "India",
  },
  {
    label: "Afghanistan",
    value: "Afghanistan",
  },
  {
    label: "Albania",
    value: "Albania",
  },
  {
    label: "Algeria",
    value: "Algeria",
  },
  {
    label: "American Samoa",
    value: "American Samoa",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Anguilla",
    value: "Anguilla",
  },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenia",
    value: "Armenia",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Austria",
    value: "Austria",
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    label: "Bahrain",
    value: "Bahrain",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Belarus",
    value: "Belarus",
  },
  {
    label: "Belgium",
    value: "Belgium",
  },
  {
    label: "Belize",
    value: "Belize",
  },
  {
    label: "Benin",
    value: "Benin",
  },
  {
    label: "Bermuda",
    value: "Bermuda",
  },
  {
    label: "Bhutan",
    value: "Bhutan",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    label: "Botswana",
    value: "Botswana",
  },
  {
    label: "Brazil",
    value: "Brazil",
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  {
    label: "British Virgin Islands",
    value: "British Virgin Islands",
  },
  {
    label: "Brunei",
    value: "Brunei",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Cambodia",
    value: "Cambodia",
  },
  {
    label: "Cameroon",
    value: "Cameroon",
  },
  {
    label: "Canada",
    value: "Canada",
  },
  {
    label: "Cape Verde",
    value: "Cape Verde",
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    label: "Central African Republic",
    value: "Central African Republic",
  },
  {
    label: "Chad",
    value: "Chad",
  },
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "Christmas Island",
    value: "Christmas Island",
  },
  {
    label: "Cocos Islands",
    value: "Cocos Islands",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Comoros",
    value: "Comoros",
  },
  {
    label: "Cook Islands",
    value: "Cook Islands",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    label: "Croatia",
    value: "Croatia",
  },
  {
    label: "Curacao",
    value: "Curacao",
  },
  {
    label: "Cyprus",
    value: "Cyprus",
  },
  {
    label: "Czech Republic",
    value: "Czech Republic",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  {
    label: "Denmark",
    value: "Denmark",
  },
  {
    label: "Djibouti",
    value: "Djibouti",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    label: "East Timor",
    value: "East Timor",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    label: "Egypt",
    value: "Egypt",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Estonia",
    value: "Estonia",
  },
  {
    label: "Ethiopia",
    value: "Ethiopia",
  },
  {
    label: "Falkland Islands",
    value: "Falkland Islands",
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    label: "Fiji",
    value: "Fiji",
  },
  {
    label: "Finland",
    value: "Finland",
  },
  {
    label: "France",
    value: "France",
  },
  {
    label: "French Polynesia",
    value: "French Polynesia",
  },
  {
    label: "Gabon",
    value: "Gabon",
  },
  {
    label: "Gambia",
    value: "Gambia",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Germany",
    value: "Germany",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Greece",
    value: "Greece",
  },
  {
    label: "Greenland",
    value: "Greenland",
  },
  {
    label: "Grenada",
    value: "Grenada",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haiti",
    value: "Haiti",
  },
  {
    label: "Honduras",
    value: "Honduras",
  },
  {
    label: "Hong Kong",
    value: "Hong Kong",
  },
  {
    label: "Hungary",
    value: "Hungary",
  },
  {
    label: "Iceland",
    value: "Iceland",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    label: "Iraq",
    value: "Iraq",
  },
  {
    label: "Ireland",
    value: "Ireland",
  },
  {
    label: "Isle of Man",
    value: "Isle of Man",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italy",
    value: "Italy",
  },
  {
    label: "Ivory Coast",
    value: "Ivory Coast",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Japan",
    value: "Japan",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordan",
    value: "Jordan",
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    label: "Kenya",
    value: "Kenya",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    label: "Laos",
    value: "Laos",
  },
  {
    label: "Latvia",
    value: "Latvia",
  },
  {
    label: "Lebanon",
    value: "Lebanon",
  },
  {
    label: "Lesotho",
    value: "Lesotho",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libya",
    value: "Libya",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Lithuania",
    value: "Lithuania",
  },
  {
    label: "Luxembourg",
    value: "Luxembourg",
  },
  {
    label: "Macau",
    value: "Macau",
  },
  {
    label: "Macedonia",
    value: "Macedonia",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
  },
  {
    label: "Maldives",
    value: "Maldives",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    label: "Mauritius",
    value: "Mauritius",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Mexico",
    value: "Mexico",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
  },
  {
    label: "Moldova",
    value: "Moldova",
  },
  {
    label: "Monaco",
    value: "Monaco",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Morocco",
    value: "Morocco",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    label: "Myanmar",
    value: "Myanmar",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Netherlands",
    value: "Netherlands",
  },
  {
    label: "Netherlands Antilles",
    value: "Netherlands Antilles",
  },
  {
    label: "New Caledonia",
    value: "New Caledonia",
  },
  {
    label: "New Zealand",
    value: "New Zealand",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
  },
  {
    label: "Norway",
    value: "Norway",
  },
  {
    label: "Oman",
    value: "Oman",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestine",
    value: "Palestine",
  },
  {
    label: "Panama",
    value: "Panama",
  },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Peru",
    value: "Peru",
  },
  {
    label: "Philippines",
    value: "Philippines",
  },
  {
    label: "Pitcairn",
    value: "Pitcairn",
  },
  {
    label: "Poland",
    value: "Poland",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Republic of the Congo",
    value: "Republic of the Congo",
  },
  {
    label: "Reunion",
    value: "Reunion",
  },
  {
    label: "Romania",
    value: "Romania",
  },
  {
    label: "Russia",
    value: "Russia",
  },
  {
    label: "Rwanda",
    value: "Rwanda",
  },
  {
    label: "Saint Barthelemy",
    value: "Saint Barthelemy",
  },
  {
    label: "Saint Helena",
    value: "Saint Helena",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    label: "Saint Martin",
    value: "Saint Martin",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "San Marino",
    value: "San Marino",
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbia",
    value: "Serbia",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    label: "Singapore",
    value: "Singapore",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
  },
  {
    label: "Slovakia",
    value: "Slovakia",
  },
  {
    label: "Slovenia",
    value: "Slovenia",
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "South Africa",
    value: "South Africa",
  },
  {
    label: "South Korea",
    value: "South Korea",
  },
  {
    label: "South Sudan",
    value: "South Sudan",
  },
  {
    label: "Spain",
    value: "Spain",
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    label: "Sudan",
    value: "Sudan",
  },
  {
    label: "Suriname",
    value: "Suriname",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
  },
  {
    label: "Swaziland",
    value: "Swaziland",
  },
  {
    label: "Sweden",
    value: "Sweden",
  },
  {
    label: "Switzerland",
    value: "Switzerland",
  },
  {
    label: "Taiwan",
    value: "Taiwan",
  },
  {
    label: "Tajikistan",
    value: "Tajikistan",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    label: "Thailand",
    value: "Thailand",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
  },
  {
    label: "Tunisia",
    value: "Tunisia",
  },
  {
    label: "Turkey",
    value: "Turkey",
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "U.S. Virgin Islands",
    value: "U.S. Virgin Islands",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Ukraine",
    value: "Ukraine",
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    label: "United Kingdom",
    value: "United Kingdom",
  },
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Vatican",
    value: "Vatican",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    label: "Wallis and Futuna",
    value: "Wallis and Futuna",
  },
  {
    label: "Western Sahara",
    value: "Western Sahara",
  },
  {
    label: "Yemen",
    value: "Yemen",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe",
  },
];

export const getLanguageOption = () => {
  return [
    {
      value: "af",
      label: "Afrikaans",
    },
    {
      value: "sq",
      label: "Albanian",
    },
    {
      value: "ar",
      label: "Arabic",
    },
    {
      value: "az",
      label: "Azerbaijani",
    },
    {
      value: "bn",
      label: "Bengali",
    },
    {
      value: "bg",
      label: "Bulgarian",
    },
    {
      value: "ca",
      label: "Catalan",
    },
    {
      value: "zh_CN",
      label: "Chinese (CHN)",
    },
    {
      value: "zh_HK",
      label: "Chinese (HKG)",
    },
    {
      value: "zh_TW",
      label: "Chinese (TAI)",
    },
    {
      value: "hr",
      label: "Croatian",
    },
    {
      value: "cs",
      label: "Czech",
    },
    {
      value: "da",
      label: "Danish",
    },
    {
      value: "nl",
      label: "Dutch",
    },
    {
      value: "en",
      label: "English",
    },
    {
      value: "en_GB",
      label: "English (UK)",
    },
    {
      value: "en_US",
      label: "English (US)",
    },
    {
      value: "et",
      label: "Estonian",
    },
    {
      value: "fil",
      label: "Filipino",
    },
    {
      value: "fi",
      label: "Finnish",
    },
    {
      value: "fr",
      label: "French",
    },
    {
      value: "de",
      label: "German",
    },
    {
      value: "el",
      label: "Greek",
    },
    {
      value: "gu",
      label: "Gujarati",
    },
    {
      value: "ha",
      label: "Hausa",
    },
    {
      value: "he",
      label: "Hebrew",
    },
    {
      value: "hi",
      label: "Hindi",
    },
    {
      value: "hu",
      label: "Hungarian",
    },
    {
      value: "id",
      label: "Indonesian",
    },
    {
      value: "ga",
      label: "Irish",
    },
    {
      value: "it",
      label: "Italian",
    },
    {
      value: "ja",
      label: "Japanese",
    },
    {
      value: "kn",
      label: "Kannada",
    },
    {
      value: "kk",
      label: "Kazakh",
    },
    {
      value: "ko",
      label: "Korean",
    },
    {
      value: "lo",
      label: "Lao",
    },
    {
      value: "lv",
      label: "Latvian",
    },
    {
      value: "lt",
      label: "Lithuanian",
    },
    {
      value: "mk",
      label: "Macedonian",
    },
    {
      value: "ms",
      label: "Malay",
    },
    {
      value: "ml",
      label: "Malayalam",
    },
    {
      value: "mr",
      label: "Marathi",
    },
    {
      value: "nb",
      label: "Norwegian",
    },
    {
      value: "fa",
      label: "Persian",
    },
    {
      value: "pl",
      label: "Polish",
    },
    {
      value: "pt_BR",
      label: "Portuguese (BR)",
    },
    {
      value: "pt_PT",
      label: "Portuguese (POR)",
    },
    {
      value: "pa",
      label: "Punjabi",
    },
    {
      value: "ro",
      label: "Romanian",
    },
    {
      value: "ru",
      label: "Russian",
    },
    {
      value: "sr",
      label: "Serbian",
    },
    {
      value: "sk",
      label: "Slovak",
    },
    {
      value: "sl",
      label: "Slovenian",
    },
    {
      value: "es",
      label: "Spanish",
    },
    {
      value: "es_AR",
      label: "Spanish (ARG)",
    },
    {
      value: "es_ES",
      label: "Spanish (SPA)",
    },
    {
      value: "es_MX",
      label: "Spanish (MEX)",
    },
    {
      value: "sw",
      label: "Swahili",
    },
    {
      value: "sv",
      label: "Swedish",
    },
    {
      value: "ta",
      label: "Tamil",
    },
    {
      value: "te",
      label: "Telugu",
    },
    {
      value: "th",
      label: "Thai",
    },
    {
      value: "tr",
      label: "Turkish",
    },
    {
      value: "uk",
      label: "Ukrainian",
    },
    {
      value: "ur",
      label: "Urdu",
    },
    {
      value: "uz",
      label: "Uzbek",
    },
    {
      value: "vi",
      label: "Vietnamese",
    },
    {
      value: "zu",
      label: "Zulu",
    },
  ];
};

export const getStateOption = () => {
  return [
    {
      label: "Andhra Pradesh",
      value: "Andhra Pradesh",
    },
    {
      label: "Arunachal Pradesh",
      value: "Arunachal Pradesh",
    },
    {
      label: "Assam",
      value: "Assam",
    },
    {
      label: "Bihar",
      value: "Bihar",
    },
    {
      label: "Chhattisgarh",
      value: "Chhattisgarh",
    },
    {
      label: "Goa",
      value: "Goa",
    },
    {
      label: "Gujarat",
      value: "Gujarat",
    },
    {
      label: "Haryana",
      value: "Haryana",
    },
    {
      label: "Himachal Pradesh",
      value: "Himachal Pradesh",
    },
    {
      label: "Jammu and Kashmir",
      value: "Jammu and Kashmir",
    },
    {
      label: "Jharkhand",
      value: "Jharkhand",
    },
    {
      label: "Karnataka",
      value: "Karnataka",
    },
    {
      label: "Kerala",
      value: "Kerala",
    },
    {
      label: "Madhya Pradesh",
      value: "Madhya Pradesh",
    },
    {
      label: "Maharashtra",
      value: "Maharashtra",
    },
    {
      label: "Manipur",
      value: "Manipur",
    },
    {
      label: "Meghalaya",
      value: "Meghalaya",
    },
    {
      label: "Mizoram",
      value: "Mizoram",
    },
    {
      label: "Nagaland",
      value: "Nagaland",
    },
    {
      label: "Odisha",
      value: "Odisha",
    },
    {
      label: "Punjab",
      value: "Punjab",
    },
    {
      label: "Rajasthan",
      value: "Rajasthan",
    },
    {
      label: "Sikkim",
      value: "Sikkim",
    },
    {
      label: "Tamil Nadu",
      value: "Tamil Nadu",
    },
    {
      label: "Telangana",
      value: "Telangana",
    },
    {
      label: "Tripura",
      value: "Tripura",
    },
    {
      label: "Uttarakhand",
      value: "Uttarakhand",
    },
    {
      label: "Uttar Pradesh",
      value: "Uttar Pradesh",
    },
    {
      label: "West Bengal",
      value: "West Bengal",
    },
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    {
      label: "Chandigarh",
      value: "Chandigarh",
    },
    {
      label: "Dadra and Nagar Haveli",
      value: "Dadra and Nagar Haveli",
    },
    {
      label: "Daman and Diu",
      value: "Daman and Diu",
    },
    {
      label: "Delhi",
      value: "Delhi",
    },
    {
      label: "Lakshadweep",
      value: "Lakshadweep",
    },
    {
      label: "Puducherry",
      value: "Puducherry",
    },
  ];
};
export const getTimeZoneOption = () => {
  return [
    ,
    { label: "Asia/Calcutta (+05:30)", value: "asia/calcutta (+05:30)" },
    { label: "US/Arizona (-07:00)", value: "us/arizona (-07:00)" },
    { label: "Asia/Kabul (+04:30)", value: "asia/kabul (+04:30)" },
    { label: "Asia/Yerevan (+04:00)", value: "asia/yerevan (+04:00)" },
    { label: "Asia/Kabul (+04:30)", value: "asia/kabul (+04:30)" },
    { label: "Asia/Karachi (+05:00)", value: "asia/karachi (+05:00)" },
  ];
};
