import React from "react";
import { StyledFlexContainer, StyledCheckBox } from "./OgcFilterCommonStyles";
import Label from "konnekt/Label";

const CheckBoxWithLabel = ({ label, checked, onChange, ...props }) => {
  return (
    <StyledFlexContainer flexWrap="nowrap" alignItems="flex-start" {...props}>
      <StyledCheckBox
        type="checkbox"
        id="noEventsWithinfewTime"
        checked={checked}
        onChange={onChange}
      />
      <Label for="noEventsWithinfewTime" fontWeight={600} fontSize="16px">
        {label}
      </Label>
    </StyledFlexContainer>
  );
};

export default CheckBoxWithLabel;
