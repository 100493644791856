import _ from "lodash";
import { showNotification } from "util/utility";
import { callApiV2 } from "../../util/apiCaller";
import {getPageSize, getPageNum} from './PlansSelectors';

export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_STATUS = 'FETCH_PLANS_STATUS';
export const CREATE_NEW_PLAN = 'CREATE_NEW_PLAN'; 
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const CREATE_NEW_PLAN_STATUS = 'CREATE_NEW_PLAN_STATUS'; 
export const UPDATE_PLAN_STATUS = 'UPDATE_PLAN_STATUS'; 
export const UPDATE_PLAN_PAGINATION_DATA = 'UPDATE_PLAN_PAGINATION_DATA';
export const FETCH_ALL_PLANS = 'FETCH_ALL_PLANS';

export const fetchPlans = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_PLANS_STATUS,
        status: 'pending'   
    });
    try{
        const state = getState();
        const pageSize = getPageSize(state);
        const pageNum = getPageNum(state);
        const response = await callApiV2({
            method: 'get',
            endpoint: `elevate/billing/v1/billing/plans?pageSize=${pageSize}&pageNum=${pageNum}`, 
        })
        if(response.status === 'Success'){
            dispatch({
                type: FETCH_PLANS,
                plans: response?.data?.plans?.list ?? [],
                count: response?.data?.plans?.count,
                status: 'success' 
            })
        }else {
            dispatch({
                type: FETCH_PLANS_STATUS,
                status: 'error'
            })
        }
    }catch(err){
        console.log('Error in fetching plans', err);
        dispatch({
            type: FETCH_PLANS_STATUS,
            status: 'error'
        })
    }
};

export const fetchAllPlans = () => async (dispatch) => {
    const response = await callApiV2({
        method: 'get',
        endpoint: `elevate/billing/v1/billing/plans?pageSize=all&pageNum=1`, 
    })
    if(response.status === 'Success'){
        dispatch({
            type: FETCH_ALL_PLANS,
            plans: response?.data?.plans?.list ?? [], 
        })
    }
};

export const createNewPlan = (plan) => async (dispatch) => {
    dispatch({
        type: CREATE_NEW_PLAN_STATUS,
        status: 'pending',
    });
    try{
        const response = await callApiV2({
            method: 'post',
            endpoint: 'elevate/billing/v1/billing/plans/create',
            body: plan
        });
        if(response.status === 'Success'){
            dispatch(fetchPlans());
            dispatch({
                type: CREATE_NEW_PLAN,
                data: response.data.plan
            })
            showNotification('success', 'Plan created successfully!');
        }else {
            dispatch({
                type: CREATE_NEW_PLAN_STATUS,
                status: 'error'
            })
            showNotification('error', 'Something went wrong. PLease try again.');
        }
    }catch(err){
        console.log('Error in creating plan', err);
        dispatch({
            type: CREATE_NEW_PLAN_STATUS,
            status: 'error'
        })
        showNotification('error', 'Something went wrong. PLease try again.');
    }
}

export const updatePlan = (planId, plan) => async (dispatch) => {
    dispatch({
        type: UPDATE_PLAN_STATUS,
        status: 'pending',
    });
    try{
        const response = await callApiV2({
            method: 'put',
            endpoint: `elevate/billing/v1/billing/plans/${planId}/update`,
            body: plan
        });
        if(response.status === 'Success'){
            dispatch(fetchPlans());
            dispatch({
                type: UPDATE_PLAN,
                data: response.data.plan
            })
            showNotification('success', 'Plan updated successfully!');
        }else {
            dispatch({
                type: UPDATE_PLAN_STATUS,
                status: 'error'
            })
            showNotification('error', 'Something went wrong. PLease try again.');
        }
    }catch(err){
        console.log('Error in creating plan', err);
        dispatch({
            type: UPDATE_PLAN_STATUS,
            status: 'error'
        });
        showNotification('error', 'Something went wrong. PLease try again.');
    }
}

export const deletPlan = (planId) => async (dispatch) => {
    try{
        const response = await callApiV2({
            method: 'delete',
            endpoint: `elevate/billing/v1/billing/plans/${planId}`,
        });
        if(response.status === 'Success'){
            dispatch(fetchPlans());
            showNotification('success', 'Plan deleted successfully!');
        }else {
            showNotification('error', 'Something went wrong. PLease try again.');
        }
    }catch(err){
        console.log('Error in creating plan', err);
        showNotification('error', 'Something went wrong. PLease try again.');
    }
};

export const updatePaginationData = (pageNum, pageSize) => dispatch => {
    dispatch({
        type: UPDATE_PLAN_PAGINATION_DATA,
        pageNum,
        pageSize
    });
};
