export const isIosSafari = () => {
  if ("navigator" in window) {
    return !!(
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/)
    );
  }

  return false;
};
