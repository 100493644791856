import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Button from "konnekt/Buttons";
import ProjectsCard from "modules/App/pages/CreateNewProjectDashboard/components/ProjectsCard";
import {
  fetchProjectList,
  addProjectDetails,
  setProjectId,
} from "modules/App/AppActions";
import { APP_PROJECT_ID } from "../../../../constants/enums/appEnums";
import { showNotification } from "../../../../util/utility";

const DashboardBanner = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 10rem 5.5rem 4rem 5.5rem;
  @media only screen and (max-width: 767px) {
    padding: 100px 15px 10px 15px;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-top: 20px;
  }
`;
const CreateProjectBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ImageBackground = styled(Box)`
  background-image: url("/images/login-dashboard-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 8px;

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #212121;
    margin-top: 2rem;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #212121;
  }

  input {
    background: #ffffff;
    border-radius: 4px;
  }

  button {
    margin-top: 1rem;
    background: none;
  }
  @media only screen and (max-width: 425px) {
    p {
      font-size: 14px;
    }
  }
`;
const StyledCreateProject = styled(Box)`
  display: flex;
  justify-content: space-around;

  div {
    position: relative;
    right: 8rem;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 20px;
    img {
      display: none;
    }
    h4 {
      font-size: 1.6rem;
    }

    div {
      position: relative;
      right: 0rem;
    }
  }
  @media only screen and (max-width: 425px) {
    padding: 20px;
  }
`;
const StyledSuccessProject = styled(Box)`
  padding-bottom: 40px;

  div {
    text-align: -webkit-center;
  }
`;
const CreateNewProjectDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreatedProject, setIsCreatedProject] = useState(true);
  const [errors, setErrors] = useState({});
  const [details, setDetails] = useState("");

  const ProjectsData = useSelector((state) => state.app?.projectList);
  const activeProjectId = useSelector((state) => state.app?.activeProjectId);
  const business_id = useSelector((state) => state.app?.user?.business_id);

  const login_via =
    typeof window === "undefined" ? "" : localStorage.getItem("login_via");

  useEffect(() => {
    if (!!activeProjectId) {
      window.localStorage.setItem(APP_PROJECT_ID, activeProjectId);
    }
    //dispatch(fetchProjectList());
  }, [dispatch]);

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (details === "") {
      isValid = false;
      error["name"] = "please enter your project name";
    }
    return {
      isValid,
      error,
    };
  };

  const handleCreate = async () => {
    let validate = handleValidation();
    if (validate.isValid) {
      if (!!business_id) {
        const res = await dispatch(addProjectDetails(details));
        if (res.status === "Success") {
          setIsCreatedProject(!isCreatedProject);
        }
      } else {
        showNotification("warning", "Invalid Business id");
      }
    } else {
      setErrors(validate.error);
    }
  };

  const handleProject = () => {
    setIsCreatedProject(!isCreatedProject);
    // todo altamas set selected project into redux
    window.localStorage.setItem(APP_PROJECT_ID, ProjectsData?.[0]?._id);
    dispatch(setProjectId(ProjectsData?.[0]));
    if (login_via !== "admin") {
      history.push("/dashboard");
    } else {
      history.push("/admin/manage");
    }
  };

  const handleBack = () => {
    setIsCreatedProject(!isCreatedProject);
    setDetails("");
  };

  return (
    <>
      <DashboardBanner>
        <ImageBackground>
          {isCreatedProject ? (
            <StyledCreateProject>
              <img src="/images/login-dashboard-img.png" alt="" />
              <CreateProjectBox>
                <h4>Create New Project</h4>
                <p>Avail free credits on your next project</p>
                <input
                  className="contact-detail"
                  value={details}
                  placeholder="Enter your project name"
                  onChange={(e) => setDetails(e.target.value)}
                />
                {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                <Button
                  maxWidth="2.2rem"
                  type="outline"
                  onClick={() => handleCreate()}
                >
                  Create
                </Button>
              </CreateProjectBox>
            </StyledCreateProject>
          ) : (
            <StyledSuccessProject>
              <Icon
                iconName="backArrow"
                color="black"
                padding="15px"
                onClick={() => handleBack()}
              ></Icon>
              <Box>
                <h4>Project Created Successfully!</h4>
                <p>A default Assistant has been assigned to your project.</p>
                <Box>
                  <Button type="outline" onClick={() => handleProject()}>
                    Go to Project
                  </Button>
                </Box>
              </Box>
            </StyledSuccessProject>
          )}
        </ImageBackground>
        <h3>Recent Projects</h3>
        <ProjectsCard login_via={login_via} />
      </DashboardBanner>
    </>
  );
};

export default CreateNewProjectDashboard;
