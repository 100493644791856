import styled from "styled-components";

export const ModalBodyTextInput = styled.div`
  padding-top: 6%;
  padding-bottom: 2%;
`;
export const ModalFooterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: right;
  padding-top: 20px;
  padding-bottom: 20px;
`;
