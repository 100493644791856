import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import Label from "konnekt/Label";
import Button from "konnekt/Buttons";
import Box from "konnekt/Box";
import moment from "moment";
import { APP_PROJECT_ID } from "../../../../../constants/enums/appEnums";
import { setProjectId } from "../../../AppActions";
import { getAllPlans } from "modules/Plans/PlansSelectors";

const CreateProjectCards = styled(Box)`
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  button p {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: auto;
    overflow: auto;
    height: auto;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: auto auto auto;
  }
`;
const Cards = styled(Box)`
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 15px;

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
  }

  p {
    margin-bottom: 10px;
  }

  span {
    margin-left: 10px;
  }

  @media (min-resolution: 130dpi) and (max-resolution: 150dpi) {
    h6 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;
const ProjectsCard = ({ login_via }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ProjectsData = useSelector((state) => state.app?.projectList);
  const plans = useSelector(getAllPlans);
  const handleButtonClick = (data) => {
    window.localStorage.setItem(APP_PROJECT_ID, data._id);
    dispatch(setProjectId(data));
    if (login_via !== "admin") {
      history.push("/dashboard");
    } else {
      history.push("/admin/manage");
    }
  };

  const getPlanName = (planid) => {
    return plans.find((item) => item._id === planid)?.name || "-";
  };
  if (ProjectsData) {
    return (
      <CreateProjectCards>
        {ProjectsData?.map((item, index) => (
          <Cards key={index}>
            <h6>{item?.name}</h6>
            <Label color="#212121">
              Status:<span>{item?.whatsapp_api_status ? item?.whatsapp_api_status : "-"}</span>
            </Label>
            <Label color="#212121">
              Plan:<span>Standard</span>
            </Label>
            <Label color="#423F3F" fontSize="14px">
              Created at {moment(item?.created_at).format("DD MMMM, YYYY")}
            </Label>
            <Button type="outlineColor" iconRight={"rightArrow"} width="100%" fontSize="16px" fontWeight="500" onClick={() => handleButtonClick(item)}>
              View
            </Button>
          </Cards>
        ))}
      </CreateProjectCards>
    );
  }

  return null;
};

export default ProjectsCard;
