import styled from "styled-components";
import Box from "konnekt/Box";
export const LiveChatProfilesBody = styled(Box)`
  width: 100%;
  border: 1px solid #5e3ec5;
  border-radius: 1rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
`;
export const LiveChatProfilesHading = styled(Box)`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  color: #000000;
`;
export const LiveChatProfilesText = styled(Box)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: ${(props) => props.color};
`;

export const DatePickerInput = styled.input`
  padding-left: 0px;
  padding-right: 0px;
  width: fit-content;
`;

export const LiveChatProfileItem = styled(Box)`
  display: ${(props) => (props.iconManage ? "block" : "none")};
`;
