import styled from "styled-components";
import Box from "konnekt/Box";
import TextInput from "konnekt/TextInput";

export const Container = styled(Box)`
  svg {
    fill: currentColor;
  }
`;
export const FlexCenter = styled(Box)`
  display: flex;
  align-items: center;
`;
export const FlexCenterGap = styled(FlexCenter)`
  gap: 2rem;
  h5 {
    font-size: 1.6rem;
    color: var(--blue);
  }
`;

export const SearchSyncAndTotalBox = styled(FlexCenter)`
  gap: 2rem;
  h5 {
    font-size: 1.6rem;
    color: var(--blue);
  }
  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentBetween = styled(FlexCenter)`
  justify-content: space-between;
  row-gap: 1rem;
`;

export const PaginationCard = styled(ContentBetween)`
  padding: 1rem 2rem;
  @media only screen and (max-width: 540px) {
    display: flex;
    justify-content: center;
    flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  }
`;

export const DeleteMessageText = styled("p")`
  font-size: 1.4rem;
  margin: 1rem 0;
`;
export const SubTitle = styled(DeleteMessageText)`
  margin: 0 0 1rem;
`;

export const ManageTableCard = styled(Box)`
  padding: 1.6rem;
`;

export const OptionalText = styled("p")`
  font-size: 1.2rem;
  color: var(--grey-l);
  margin: 0;
  font-weight: normal;
`;

export const CommonManageContainer = styled("div")`
  padding: 2.4rem 1.8rem;
`;

export const HeaderTitleContainer = styled(FlexCenterGap)`
  margin-bottom: 2rem;
  gap: 1rem;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  h2 {
    font-size: 2rem;
    margin: 0;
    font-weight: 600;
  }
`;

export const MessageInputContainer = styled.div`
  background-color: var(--white);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: var(--card-shadow);
  @media only screen and (max-width: 768px) {
    overflow: auto;
    height: 100%;
    padding-bottom: 5rem;
    padding: 0.5rem 2rem 2rem;
  }
`;
export const ToolTipText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-weight: normal;
`;

export const CommonTextInput = styled(TextInput)`
  border: 0.01rem solid rgba(0, 0, 0, 0.08);
  height: 4.4rem;
  width: 100%;
`;
export const JustifyCenter = styled(FlexCenter)`
  justify-content: center;
`;
export const LoginTitle = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center;
`;
export const PreviewBgImg = styled.div`
  background-image: url(/images/campaign-mobile.svg);
  background-size: 100% 100%;
  width: 16rem;
  margin: auto;
  height: 32rem;
  display: flex;
  padding: 4.8rem 1.4rem;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 14rem;
    height: 27rem;
  }
`;
export const PreviewContainer = styled(Box)`
  width: 100%;
  max-height: 22.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-height: 24rem;
  }
  h6 {
    font-weight: 400;
    word-wrap: break-word;
  }
`;
export const MobileTemplate = styled(Box)`
  background: #dcf7c5;
  border-radius: 0.4rem;
  padding: 0.5rem;
  position: relative;
`;
export const CarouselContainer = styled(Box)`
  border-left: 0.1rem solid var(--grey-l);
  padding: 2rem;
  position: relative;
  width: 30%;
  h3 {
    background: var(--bg-light-2);
    padding: 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: normal;
  }
`;
export const CarouselActionFooter = styled(Box)`
  border: 0.1rem solid #27835152;
  padding: 0.8rem;
  a {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
  }
`;
export const MediaContainer = styled(Box)`
  background: var(--bg-light-2);
  padding: 0.8rem;
  border-radius: 0.4rem;
  img {
    border-radius: 0.4rem;
    width: 100%;
    height: 14rem;
    object-fit: cover;
  }
  a {
    display: inline-block;
    width: 100%;
  }
`;
export const MobileText = styled(Box)`
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #212121;
  margin: 0.5rem 0 0.6rem;
  word-break: break-word;
`;
export const Time = styled(Box)`
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 0.8rem;
  text-align: right;
  letter-spacing: 0.05rem;
  color: rgba(0, 0, 0, 0.5);
`;
export const TimeTickBody = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;
export const ModalContainer = styled(Box)`
  padding: 2rem 0 0;
`;
export const FormContainer = styled(Box)`
  margin-bottom: 1.4rem;
  select {
    border: 0.01rem solid rgba(0, 0, 0, 0.08);
    height: 4.4rem;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: 1.4rem;
    width: 100%;
  }
`;
export const FooterText = styled.div`
  font-size: 1rem;
  margin: 0;
  line-height: 1.2;
`;
export const QuickReplyCard = styled.div`
  box-shadow: var(--card-shadow);
  background: var(--white);
  margin: 1rem 0.4rem 0.5rem 0.4rem;
  border-radius: 0.4rem;
  padding: 0.4rem;
`;
export const PreviewBox = styled(Box)`
  padding: 1.2rem;
  border-radius: 1rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 70%;
  margin: 2rem auto 0;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 1.2rem;
    margin: 1rem auto 0;
  }
`;
export const UnOrderList = styled.ul`
  margin-top: 2rem;
  list-style-type: disc;
  padding-inline-start: 2rem;
  li {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
`;

export const SelectedNumber = styled(Box)`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const EcommerceIcon = styled(Box)`
  img {
    height: 10rem;
    display: block;
    margin: auto;
  }
`;

export const Heading = styled(Box)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
  letter-spacing: 0.1px;
  color: #212121;
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const CommonBoxStyle = styled(Box)`
  padding: 2rem;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.03);
  border-radius: 1rem;
`;

export const CommonCard = styled(CommonBoxStyle)`
  gap: 1.9rem;
  @media only screen and (max-width: 768px) {
  }
`;
export const CardSubHeading = styled.h3`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #212121;
`;
export const CardParagraph = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: #757575;
`;
