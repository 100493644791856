/* eslint-disable */

import "./css/app/app.css";
import "./css/SideBar/Sidebar.css";
import "./css/Widget/CustomizeWidget.css";
import "./css/Widget/ColorPicker.css";
import "./css/Widget/ChatWidget.css";
import "./css/Widget/InstallWidget.css";
import "./css/BottomBar/BottomBar.css";
import "./css/Billing/Credits.css";
import "./css/inbox/components.css";
import "./css/inbox/inbox.css";
import "./css/inbox/inbox-2.css";
import "./css/Customer/Customer.css";
import "./css/Template/Template.css";
import "./css/Settings/Setting.css";
import "./css/VirtualList.css";
import "./css/Payments/Payments.css";
import "./css/Notification/Notification.css";
import "./css/Stripe.css";
import "modules/Settings/pages/SettingsSignupBusiness/SignUpBusiness.css";
import "modules/Signup/pages/SignUpWhatsapp/SignUpWhatsapp.css";
import "react-toastify/dist/ReactToastify.css";

import {
  ATTEMPT_TO_LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SET_AUTHENTICATION,
  VERIFY_SUCCESS,
  fetchProjectList,
  setProjectId,
} from "modules/App/AppActions";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { fetchUserDeviceType, showNotification } from "util/utility";
import jwt_decode from "jwt-decode";
import BasicLoader from "./components/Loaders/BasicLoader";
import Loader from "components/Loaders/OnlyLoader";
import { PAGE_LOAD_ATTEMPT } from "constants/enums/appEnums";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import WithFavicon from "HOC/WithFavicon";
import { callApiV2 } from "./util/apiCaller";
import componentLoader from "./components/componentLoader";
import { compose } from "redux";
import { connect } from "react-redux";
import logger from "./util/logger";
import publicApiCaller from "./util/publicApiCaller";
import theme from "./theme";
import withPolyfill from "HOC/withPolyfill";
import MainRoutes from "routes/mainRoutes";

const ImageViewer = React.lazy(() =>
  componentLoader(() => import("modules/ImageViewerModule"), PAGE_LOAD_ATTEMPT)
);

const MediaPreview = React.lazy(() =>
  componentLoader(() => import("modules/MediaPreviewModule"), PAGE_LOAD_ATTEMPT)
);

const Manage = React.lazy(() =>
  componentLoader(() => import("modules/Manage/ManagePage"), PAGE_LOAD_ATTEMPT)
);

const sideBar = [
  {
    url: "/manage/user",
    iconPropsFn: (isActive) => ({
      iconName: "userAttr",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "User Attributes",
  },
  {
    url: "/manage/template",
    iconPropsFn: (isActive) => ({
      iconName: "template",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Template Message",
  },
  {
    url: "/manage/premade-reply",
    iconPropsFn: (isActive) => ({
      iconName: "canned",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Premade Reply",
  },
  {
    url: "/manage/team-setting",
    iconPropsFn: (isActive) => ({
      iconName: "team",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
      fill: "red",
    }),
    label: "Team Setting",
  },
  {
    url: "/manage/tags",
    iconPropsFn: (isActive) => ({
      iconName: "tags",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Tags",
  },
  {
    url: "/manage/analytics",
    iconPropsFn: (isActive) => ({
      iconName: "analytics",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Analytics",
  },
  {
    url: "/manage/developer-setting",
    iconPropsFn: (isActive) => ({
      iconName: "apiKey",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "API Key",
  },
  {
    url: "/manage/billing-details",
    iconPropsFn: (isActive) => ({
      iconName: "billing",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Billing",
  },
  {
    url: "/manage/notification-preferences",
    iconPropsFn: (isActive) => ({
      iconName: "notification",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Notification Preferances",
  },
  {
    url: "/manage/chat-setting",
    iconPropsFn: (isActive) => ({
      iconName: "liveChat",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Live Chat Setting",
  },
  {
    url: "/manage/opt-in",
    iconPropsFn: (isActive) => ({
      iconName: "multiCloseChat",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Opt-In Manage",
  },
  {
    url: "/manage/signup-business",
    iconPropsFn: (isActive) => ({
      iconName: "manageProfile",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Manage Whatsapp Profile",
  },
];

const adminSideBar = [
  {
    url: "/manage/admin",
    iconPropsFn: (isActive) => ({
      iconName: "template",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Project",
  },
  {
    url: "/manage/team-setting",
    iconPropsFn: (isActive) => ({
      iconName: "team",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
      fill: "red",
    }),
    label: "Agents",
  },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTakeMeToInteraktTriggered: false,
      isVerifyingToken: false,
      isTokenFound: false,
      isAuthenticated: false,
      isFetchingOrganizationDetails: false,
      isOrganizationOnboarded: true,
      user: {},
      selectedAccountSegment: "",
      organizations: [],
      organizationId: "",
      isRegistered: false,
      fbLoginEnabled: false,
      fbLoginStatus: "",
      isUserDeviceMobile: false,
      headerData: [
        {
          link: "/dashboard",
          icon: "dashboard",
          name: "Dashboard",
          isExpand: false,
        },
        {
          link: "/inbox",
          icon: "live",
          name: "Live Chat",
          isExpand: false,
        },
        {
          link: "/history",
          icon: "history",
          name: "History",
          isExpand: false,
        },
        {
          link: "/contacts/list",
          icon: "contact",
          name: "Contact",
          isExpand: false,
        },
        {
          link: "/campaigns",
          icon: "campaigns",
          name: "Campaigns",
          isExpand: false,
        },
        {
          link: "/manage/user",
          icon: "manage",
          name: "Manage",
          isExpand: true,
        },
      ],
      manageData: [
        {
          link: "/manage/user",
          icon: "userAttr",
          name: "User Attributes",
        },
        {
          link: "/manage/template",
          icon: "template",
          name: "Template Message",
        },
        {
          link: "/manage/premade-reply",
          icon: "canned",
          name: "Premade Reply",
        },
        {
          link: "/manage/team-setting",
          icon: "team",
          name: "Team Setting",
        },
        {
          link: "/manage/tags",
          icon: "tags",
          name: "Tags",
        },
        {
          link: "/manage/analytics",
          icon: "analytics",
          name: "Analytics",
        },
        {
          link: "/manage/developer-setting",
          icon: "apiKey",
          name: "API Key",
        },
        {
          link: "/manage/billing-details",
          icon: "billing",
          name: "Billing",
        },
        {
          link: "/manage/notification-preferences",
          icon: "notification",
          name: "Notification Preferances",
        },
        {
          link: "/manage/chat-setting",
          icon: "liveChat",
          name: "Live Chat Setting",
        },
        {
          link: "/manage/opt-in",
          icon: "multiCloseChat",
          name: "Opt-in Manage",
        },
        {
          link: "/manage/signup-business",
          icon: "manageProfile",
          name: "Manage Whatsapp Profile",
        },
      ],
      activeSideBar: sideBar,
      adminPanel: false,
    };
  }

  componentDidMount() {
    this.checkToken();
    let isUserDeviceMobile = fetchUserDeviceType();
    this.setState({
      isUserDeviceMobile: isUserDeviceMobile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.isOrganizationOnboarded &&
      this.state.isOrganizationOnboarded
    ) {
      if (!!window && window.kiwi && window.kiwi.hide) {
        window.kiwi.hide();
      }
    }
  }

  // todo - remove this logic from App.js and move it to action
  checkToken = () => {
    // if order pages no need to check token
    if (!window.location.pathname.includes("/order/")) {
      logger.log("Check Token API Called");
      const queryParams = new URLSearchParams(window.location.search);
      const externalToken = queryParams.get("token");
      const projectId = queryParams.get("projectId");
      const token = externalToken ?? localStorage.getItem("token");
      if (externalToken) {
        localStorage.setItem("token", externalToken);
      }
      if (projectId) {
        localStorage.setItem("projectId", projectId);
        this.props.dispatch(
          setProjectId({
            _id: projectId,
          })
        );
      }
      if (!token) {
        this.setState({
          isVerifyingToken: false,
          isAuthenticated: false,
          isTokenFound: false,
        });
      } else {
        const tempUser = jwt_decode(token);
        if (!localStorage.getItem("login_via")) {
          localStorage.setItem("login_via", "agent");
        }
        if (tempUser?.role === "Agent") {
          const list = this.state.headerData.filter(
            (item) =>
              item?.name !== "Contact" &&
              item?.name !== "Campaigns" &&
              item?.name !== "Manage"
          );
          this.setState({
            headerData: list,
          });
        }
        if (tempUser?.role === "Admin") {
          const list = this.state.headerData.map((item) => {
            if (item.name === "Manage") {
              item.link = "admin/manage";
              item.icon = "manage";
              item.name = "Manage";
            }
            return item;
          });
          const updatedList = list.filter(
            (item) =>
              item?.name !== "Dashboard" &&
              item?.name !== "Contact" &&
              item?.name !== "Campaigns" &&
              item?.name !== "Live Chat" &&
              item?.name !== "History"
          );
          this.setState({
            headerData: updatedList,
          });
        }
        if (tempUser?.role === "SuperAdmin") {
          this.setState({
            activeSideBar: adminSideBar,
            adminPanel: true,
          });
          const list = this.state.headerData.map((item) => {
            if (item.name === "Manage") {
              item.link = "/manage/admin";
              item.icon = "manage";
              item.name = "Manage";
            }
            return item;
          });
          const updatedList = list.filter(
            (item) =>
              item?.name !== "Dashboard" &&
              item?.name !== "Contact" &&
              item?.name !== "Campaigns" &&
              item?.name !== "LiveChat" &&
              item?.name !== "History"
          );
          this.setState({
            headerData: updatedList,
          });
        }
        this.setState({
          isVerifyingToken: true,
          isTokenFound: true,
        });

        const login_via =
          typeof window === "undefined"
            ? ""
            : localStorage.getItem("login_via");

        if (login_via === "agent") {
          callApiV2({
            method: "post",
            endpoint: `elevate/agent/${tempUser?._id}/details`,
          }).then((res) => {
            if (res.status_code !== 200) {
              window.location.reload();
            }
            if (res.status_code === 401) {
              window.location.href = "/login";
            } else if (res?.data?.agent) {
              const user = res?.data?.agent;

              this.setState({
                isVerifyingToken: false,
                isAuthenticated: true,
              });

              this.props.dispatch({
                type: VERIFY_SUCCESS,
                isVerifyingToken: false,
                isAuthenticated: true,
                token: token,
                user,
              });

              this.props.dispatch(fetchProjectList());
            } else {
              this.setState({
                isVerifyingToken: false,
                isAuthenticated: false,
              });
            }
          });
        } else {
          callApiV2({
            method: "post",
            endpoint: `elevate/admin/${tempUser?._id}/details`,
          }).then((res) => {
            if (res.status_code !== 200) {
              window.location.reload();
            }
            if (res.status_code === 401) {
              window.location.href = "admin/login";
            } else if (res?.data?.admin) {
              const user = res?.data?.admin;

              this.setState({
                isVerifyingToken: false,
                isAuthenticated: true,
              });

              this.props.dispatch({
                type: VERIFY_SUCCESS,
                isVerifyingToken: false,
                isAuthenticated: true,
                token: token,
                user,
              });

              this.props.dispatch(fetchProjectList());
            } else {
              this.setState({
                isVerifyingToken: false,
                isAuthenticated: false,
              });
            }
          });
        }
      }
    }
  };

  loginUser = (creds, url) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    publicApiCaller(`auth/agent/login`, "post", creds, true).then((res) => {
      if (res && res.status === "Success") {
        if (res?.data?.token) {
          const { token } = res?.data;
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("login_via", "agent");
          window.localStorage.setItem("is_super_admin", false);
          const tempUser = jwt_decode(token);
          this.props.dispatch({
            type: LOGIN_SUCCESS,
            isLoggingIn: false,
            isAuthenticated: true,
            token: res?.data?.token,
            user: tempUser,
          });
          this.setState({
            isAuthenticated: true,
            user: tempUser,
          });
          showNotification("success", "Welcome to AutomatEazy");
        }
        this.checkToken();
      } else if (res && res.status === "Error") {
        showNotification("error", res?.data?.message);
        this.props.dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
        this.setState({
          isAuthenticated: false,
        });
      } else {
        showNotification("error", "Failed to Login! Invalid credentials.");
        this.props.dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
        this.setState({
          isAuthenticated: false,
        });
      }
    });
  };

  loginAdmin = (creds, url) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    publicApiCaller(`auth/admin/login`, "post", creds, true).then((res) => {
      if (res && res.status === "Success") {
        if (res?.data?.token) {
          const { token } = res?.data;
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("login_via", "admin");
          window.localStorage.setItem("is_super_admin", false);
          const tempUser = jwt_decode(token);
          this.props.dispatch({
            type: LOGIN_SUCCESS,
            isLoggingIn: false,
            isAuthenticated: true,
            token: res?.data?.token,
            user: tempUser,
          });
          this.setState({
            isAuthenticated: true,
            user: tempUser,
          });
          showNotification("success", "Welcome to AutomatEazy");
        }
        this.checkToken();
      } else {
        showNotification("error", "Failed to Login! Invalid credentials.");
        this.props.dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
        this.setState({
          isAuthenticated: false,
        });
      }
    });
  };

  loginSuperAdmin = (creds, url) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    publicApiCaller(`auth/super-admin/login`, "post", creds, true).then(
      (res) => {
        if (res && res.status === "Success") {
          if (res?.data?.token) {
            const { token } = res?.data;
            window.localStorage.setItem("token", token);
            window.localStorage.setItem("login_via", "admin");
            window.localStorage.setItem("is_super_admin", true);
            const tempUser = jwt_decode(token);
            this.props.dispatch({
              type: LOGIN_SUCCESS,
              isLoggingIn: false,
              isAuthenticated: true,
              token: res?.data?.token,
              user: tempUser,
            });
            this.setState({
              isAuthenticated: true,
              user: tempUser,
            });
            showNotification("success", "Welcome to AutomatEazy");
          }
          this.checkToken();
          window.location = "/super-admin/business-dashboard";
        } else {
          showNotification("error", res.data.message);
          this.props.dispatch({
            type: LOGIN_FAILED,
            isAuthenticated: false,
            isLoggingIn: false,
          });
          this.setState({
            isAuthenticated: false,
          });
        }
      }
    );
  };

  UpdateAuthenticatedState = (val) => {
    this.setState({
      isAuthenticated: val,
    });
    this.props.dispatch({
      type: SET_AUTHENTICATION,
      isRegistering: false,
      isRegistered: true,
    });
  };

  checkIfToShowLoader = () => {
    if (this.state.isTokenFound && this.state.isVerifyingToken) {
      return true;
    }
    //if (
    //  this.state.isTokenFound &&
    //  this.state.organizations.length !== 0
    //) {
    //  return true;
    //}
    else {
      return false;
    }
  };

  getRedirectPath = () => {
    const loginVia = localStorage.getItem("login_via");
    const isSuperAdmin =
      typeof window === "undefined"
        ? ""
        : localStorage.getItem("is_super_admin");
    if (loginVia === "admin") {
      if (isSuperAdmin) {
        return "/super-admin/business-dashboard";
      } else {
        return "/admin/project-dashboard";
      }
    } else {
      return "/project-dashboard";
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="App">
          <ThemeProvider theme={theme}>
            <Suspense fallback={<Loader />}>
              <Router>
                <ImageViewer />
                <MediaPreview />
                <div
                  className={
                    this.checkIfToShowLoader()
                      ? "kiwi-fullscreen-loader"
                      : "hide"
                  }
                >
                  <BasicLoader />
                </div>
                {/* Todo - Split Router file into 2 parts to separate COD */}
                <MainRoutes
                  isAuthenticated={this.state.isAuthenticated}
                  isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                  isTakeMeToInteraktTriggered={
                    this.state.isTakeMeToInteraktTriggered
                  }
                  updateOnboardingStatusForOrg={
                    this.updateOnboardingStatusForOrg
                  }
                  checkIfToShowLoader={this.checkIfToShowLoader}
                  loginUser={this.loginUser}
                  loginSuperAdmin={this.loginSuperAdmin}
                  loginAdmin={this.loginAdmin}
                  checkToken={this.checkToken}
                  UpdateAuthenticatedState={this.UpdateAuthenticatedState}
                  selectedAccountSegment={this.state.selectedAccountSegment}
                  isSandboxMode={this.isSandboxMode}
                  headerData={this.state.headerData}
                  manageData={this.state.manageData}
                  activeSideBar={this.state.activeSideBar}
                  adminPanel={this.state.adminPanel}
                  organizations={this.state.organizations}
                  setFaviconCount={this.props.setFaviconCount}
                  user={this.state.user}
                  getRedirectPath={this.getRedirectPath}
                />
              </Router>
            </Suspense>
          </ThemeProvider>
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withPolyfill, WithFavicon, connect(null))(App);
