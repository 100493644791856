export const UPDATE_ACTIVE_SMART_CARD = "UPDATE_ACTIVE_SMART_CARD";
export const UPDATE_PERSONAL_DETAILS_DATA = "UPDATE_PERSONAL_DETAILS_DATA";
export const SHOW_SEARCH_SECTION = "SHOW_SEARCH_SECTION";
export const CLOSE_SEARCH_SECTION = "CLOSE_SEARCH_SECTION";
export const SET_SEARCH_ACTIVE_CONVERSATION_ID =
  "SET_SEARCH_ACTIVE_CONVERSATION_ID";
export const IS_FINDING_SEARCH_MESSAGE = "IS_FINDING_SEARCH_MESSAGE";
export const CHANGE_SANDBOX_MODAL_VISIBILITY =
  "CHANGE_SANDBOX_MODAL_VISIBILITY";
export const CHANGE_SPAM_MODAL_VISIBILITY = "CHANGE_SPAM_MODAL_VISIBILITY";
export const SET_MULTI_SELECT_CHATS = "SET_MULTI_SELECT_CHATS";
export const RESET_MULTI_SELECT_STATE = "RESET_MULTI_SELECT_STATE";
export const ENABLE_MULTI_SELECT_STATE = "ENABLE_MULTI_SELECT_STATE";
