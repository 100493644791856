import React from "react";
import styled from "styled-components";
import { space, color, layout, position, flexbox, grid } from "styled-system";

const Body = styled.div`
  padding: 12px 28px 0;
  height: 100%;

  ${space}
  ${color}
  ${layout}
  ${position}
  ${flexbox}
  ${grid}
`;

const BottomDropdownBody = ({ children, ...rest }) => {
  return <Body {...rest}>{children}</Body>;
};

export default BottomDropdownBody;
