import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { border } from "styled-system";

import BottomDropdown, {
  BottomDropdownHeader,
  BottomDropdownBody,
} from "konnekt/BottomDropdown";
import {
  EditButton,
  StyledListBottomDropdown,
} from "components/Smartcards/smartCardStyles";
import Modal, { ModalHeader, ModalFooter } from "konnekt/Modal";
import Label from "konnekt/Label";
import Checkbox from "konnekt/Checkbox";
import Searchbar from "konnekt/Searchbar/Searchbar";
import Box from "konnekt/Box";
import useMediaQuery from "customHooks/useMediaQuery";

import { hexToRGBA } from "util/colorUtility";
import Button from "konnekt/Buttons";

const StyledItemBottomDropdown = styled.div`
  border-bottom: 1px solid ${hexToRGBA("#172024", ".08")};
  padding: 18px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${border}
`;

const TagForm = ({
  customerTagsListId,
  allTags,
  handleUpdateActiveSmartCard,
  onSaveButtonClick,
  createNewTag,
}) => {
  const isDesktop = useMediaQuery("desktop", "up");

  const [searchValue, setSearchValue] = useState("");

  const [localCustomerTagsListId, setLocalCustomerTagsListId] = useState(
    customerTagsListId || []
  );

  useEffect(() => {
    if (customerTagsListId) {
      setLocalCustomerTagsListId(customerTagsListId);
    }
  }, [customerTagsListId]);

  const handleCheckBoxClick = (value, _id) => {
    if (value === true) {
      setLocalCustomerTagsListId([...localCustomerTagsListId, _id]);
    } else {
      const localList = [...localCustomerTagsListId];
      const index = localCustomerTagsListId.indexOf(_id);

      if (index > -1) {
        localList.splice(index, 1);
        setLocalCustomerTagsListId([...localList]);
      }
    }
  };

  const renderItem = (item) => {
    return (
      <StyledItemBottomDropdown key={item?._id}>
        <Label
          maxWidth="80%"
          fontSize="14px"
          wordBreak="break-word"
          color="#172024"
        >
          {item?.name}
        </Label>
        <Checkbox
          checked={localCustomerTagsListId?.includes(item?._id)}
          iconSize="tiny"
          onChange={(value) => handleCheckBoxClick(value, item?._id)}
        />
      </StyledItemBottomDropdown>
    );
  };

  const renderDropdownTagsList = () => {
    if (Object.values(allTags).length === 0) {
      return (
        <Label
          my="48px"
          fontSize="12px"
          textAlign="center"
          color="rgba(0,0,0, .3)"
        >
          There are no tags available.
          <br /> Create a new tag to add.
        </Label>
      );
    }

    if (searchValue.length > 0) {
      return Object.values(allTags)
        ?.filter((item) =>
          item.name
            .toLowerCase()
            .trim()
            .includes(searchValue.trim().toLowerCase())
        )
        .map((item) => {
          return renderItem(item);
        });
    }

    return Object.values(allTags)
      .sort((a, b) => a?.name.localeCompare(b?.name))
      .map((item) => {
        return renderItem(item);
      });
  };

  const handleSaveBtnClick = () => {
    onSaveButtonClick(localCustomerTagsListId);
    handleUpdateActiveSmartCard(null);
  };

  const handleCreateNewTag = (value) => {
    createNewTag({ name: searchValue.trim() });
    setSearchValue("");
  };

  const renderAddNewButton = () => {
    if (
      searchValue &&
      Object.values(allTags)?.filter(
        (item) =>
          item.name.trim().toLowerCase() === searchValue.trim().toLowerCase()
      ).length === 0
    ) {
      return (
        <StyledItemBottomDropdown
          style={{ cursor: "pointer" }}
          onClick={handleCreateNewTag}
          border="none"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleCreateNewTag}
          >
            <Label fontSize="12px" fontColor="inherit">
              Click here to create new Tag :
            </Label>
            <Label ml="4px" fontSize="12px" color="primary">
              {searchValue}
            </Label>
          </Box>
        </StyledItemBottomDropdown>
      );
    }
  };

  const renderFormBody = () => {
    return (
      <Box>
        <Searchbar
          value={searchValue}
          setValue={(e) => setSearchValue(e.target.value)}
          placeholder="Search tags or create tags"
          fontSize="12px"
          borderBottom="1px solid rgba(0,0,0,.08)"
          py="12px"
          pl="18px"
          pr={{ _: "18px", mobileL: "10px" }}
          textCount={{
            show: true,
            maxCharCount: 50,
          }}
          allowedKeys="all"
        />

        <StyledListBottomDropdown
          overflow="auto"
          maxHeight={{ _: "calc(100% - 150px)", mobileL: "calc(70vh - 200px)" }}
          p="0 18px"
        >
          {renderDropdownTagsList()}
          {/*{renderAddNewButton()}*/}
        </StyledListBottomDropdown>
      </Box>
    );
  };

  if (isDesktop) {
    return (
      <Modal
        maxWidth="375px"
        maxHeight="70vh"
        padding="0 8px"
        onHide={() => handleUpdateActiveSmartCard(null)}
        isOpen
        position="relative"
      >
        <ModalHeader
          onHide={() => handleUpdateActiveSmartCard(null)}
          variant="white"
        >
          Edit Tags
        </ModalHeader>
        {renderFormBody()}

        <ModalFooter>
          <Button onClick={handleSaveBtnClick} type="primary">
            Save Tags
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <BottomDropdown
      handleOutsideAreaClick={() => handleUpdateActiveSmartCard(null)}
    >
      <BottomDropdownHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pl="26px"
        pr="26px"
      >
        <EditButton onClick={() => handleUpdateActiveSmartCard(null)}>
          Cancel
        </EditButton>
        <Label
          fontWeight="500"
          color="#172024"
          fontSize="18px"
          textAlign="center"
        >
          Edit Tags
        </Label>
        <EditButton fontWeight="500" onClick={handleSaveBtnClick}>
          Save
        </EditButton>
      </BottomDropdownHeader>
      <BottomDropdownBody pt="0" px="0">
        {renderFormBody()}
      </BottomDropdownBody>
    </BottomDropdown>
  );
};

export default TagForm;
