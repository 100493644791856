import * as actions from './BillingActionTypes';
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "util/utility";
import callApi, { callApiV2 } from "util/apiCaller";

export const createRazorpayOrder = (projectId, body) => async (dispatch) => {
    dispatch({
        type: actions.CREATE_RAZORPAY_ORDER_STATUS,
        status: 'pending'
    });
    try{
        const response = await callApiV2({
            method: `post`,
            endpoint: `elevate/billing/v1/project/${projectId}/order/new`,
            body
        });
        if(response?.status === 'Success'){
            dispatch({
                type: actions.CREATE_RAZORPAY_ORDER,
                data: response.data?.data,
                status: 'success'
            });
        }else{
            dispatch({
                type: actions.CREATE_RAZORPAY_ORDER_STATUS,
                status: 'error'
            });
        }
    }catch(error){
        dispatch({
            type: actions.CREATE_RAZORPAY_ORDER_STATUS,
            status: 'error'
        });
    }
}

export const clearRazorpayOrder = () => dispatch => {
    dispatch({
        type: actions.CLEAR_RAZORPAY_ORDER
    });
}

export const fetchPendingInvoices = (projectId) => async (dispatch) => {
    try{
        const response = await callApiV2({
            method: 'post',
            endpoint: `elevate/billing/v1/project/${projectId}/invoices/pending-check`,
        });
        if(response?.status ==='Success'){
            dispatch({
                type: actions.FETCH_PENDING_INVOICES,
                payload: {
                    pendingInvoices: response?.data?.is_invoice_pending
                }
            });
        }else {
            showNotification('warning', 'Something went wrong!')
        }

    }catch{
        showNotification('warning', 'Something went wrong!')
    }
};

export const createNewSubscription = (projectId, body) => async(dispatch) => {
    try{
        dispatch({
            type: actions.CREATE_NEW_SUBSCRIPTION_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            method: 'post',
            endpoint: `elevate/billing/v1/project/${projectId}/billing/new-subscriptions`,
            body,
        });
        if(response?.status === 'Success'){
            dispatch({
                type: actions.CREATE_NEW_SUBSCRIPTION,
                status: 'success',
                data: response.data?.subscriptions
            });
        }else{
            dispatch({
                type: actions.CREATE_NEW_SUBSCRIPTION_STATUS,
                status: 'error'
            });
        }
    }catch(error){
        dispatch({
            type: actions.CREATE_NEW_SUBSCRIPTION_STATUS,
            status: 'error'
        });
    }
};

export const fetchPaymentMethods = (projectId) => async(dispatch) => {
    try{
        dispatch({
            type: actions.FETCH_PAYMENT_METHOD_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            endpoint: `elevate/billing/v1/project/${projectId}/payments/paymentmethods/list`,
            method: 'post',
            body: {
                project_id: projectId
            }
        });
        if(response.status === 'Success'){
            dispatch({
                type: actions.FETCH_PAYMENT_METHODS,
                status: 'success',
                data: response.data?.payment_methods?.list || []
            });
        }else {
            dispatch({
                type: actions.FETCH_PAYMENT_METHOD_STATUS,
                status: 'error'
            });    
        }
    }catch(error){
        dispatch({
            type: actions.FETCH_PAYMENT_METHOD_STATUS,
            status: 'error'
        });
    }
};

export const confirmSubscriptionPayment = (projectId, subscriptionId, body) => async(dispatch) => {
    try{
        dispatch({
            type: actions.CONFIRM_SUBSCRIPTION_PAYMENT_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            endpoint: `elevate/billing/v1/project/${projectId}/billing/subscription/${subscriptionId}/confirm-subscription`,
            method: 'post',
            body,
        });
        if(response.status === 'Success'){
            dispatch({
                type: actions.CONFIRM_SUBSCRIPTION_PAYMENT,
                status: 'success',
                data: response.data?.subscriptions || {}
            });
            fetchInvoices(projectId, {pageSize: 10});
            const paymentStatus = body?.payment_status === 'paid'; 
            showNotification(paymentStatus?'success': 'error', paymentStatus?'Payment is successfull!':'Something went wrong in payment.')
        }else {
            dispatch({
                type: actions.CONFIRM_SUBSCRIPTION_PAYMENT,
                status: 'error'
            });    
            showNotification('error', 'Something went wrong in payment.');
        }
    }catch(error){
        dispatch({
            type: actions.CONFIRM_SUBSCRIPTION_PAYMENT,
            status: 'error'
        });
        showNotification('error', 'Something went wrong in payment.');
    }
};

export const fetchInvoices = (projectId, body) => async(dispatch) => {
    try{
        dispatch({
            type: actions.FETCH_INVOICES_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            method: 'post',
            endpoint: `elevate/billing/v1/project/${projectId}/payments/invoices/list`,
            body,
        });
        if(response?.status === 'Success'){
            dispatch({
                type: actions.FETCH_INVOICES,
                status: 'success',
                data: response.data?.invoices?.list || []
            });
        }else{
            dispatch({
                type: actions.FETCH_INVOICES_STATUS,
                status: 'error'
            });
        }
    }catch(error){
        dispatch({
            type: actions.FETCH_INVOICES_STATUS,
            status: 'error'
        });
    }
};

export const addTopupToSubscription = (projectId, body) => async(dispatch) => {
    try{
        dispatch({
            type: actions.BUY_CONVERSATION_CREDIT_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            method: 'post',
            endpoint: `elevate/billing/v1/project/${projectId}/billing/subscription/new-top-up`,
            body,
        });
        if(response?.status === 'Success'){
            dispatch({
                type: actions.BUY_CONVERSATION_CREDIT,
                status: 'success',
                data: response.data?.subscriptions || []
            });
        }else{
            dispatch({
                type: actions.BUY_CONVERSATION_CREDIT_STATUS,
                status: 'error'
            });
        }
    }catch(error){
        dispatch({
            type: actions.BUY_CONVERSATION_CREDIT_STATUS,
            status: 'error'
        });
    }
};

export const confirmTopupToSubscription = (projectId, subscriptionId, body, cb) => async(dispatch) => {
    try{
        dispatch({
            type: actions.CONFIRM_CONVERSION_CREDIT_STATUS,
            status: 'pending'
        });
        const response = await callApiV2({
            method: 'post',
            endpoint: `elevate/billing/v1/project/${projectId}/billing/subscription/${subscriptionId}/confirm-top-up`,
            body,
        });
        if(response?.status === 'Success'){
            dispatch({
                type: actions.CONFIRM_CONVERSION_CREDIT,
                status: 'success',
                data: response.data?.subscriptions || {}
            });
            if(body.payment_status === 'paid'){
                showNotification('success', 'Purchase WhatsApp Conversation Credits successful!')
                cb();
            }
        }else{
            dispatch({
                type: actions.CONFIRM_CONVERSION_CREDIT_STATUS,
                status: 'error'
            });
            showNotification('error', 'Something went wrong!')
        }
    }catch(error){
        dispatch({
            type: actions.CONFIRM_CONVERSION_CREDIT_STATUS,
            status: 'error'
        });
        showNotification('error', 'Something went wrong!')
    }
};

export const clearConversationCredit = () => dispatch => {
    dispatch({
        type: actions.CLEAR_CONVERSATION_CREDIT
    })
}