import React from "react";
import async from "async";
import JSON5 from "json5";
import { v4 as uuidv4 } from "uuid";

import Icon from "konnekt/Icon";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import { showNotification, showCustomNotification } from "util/utility";
import { addToast } from "../App/ToastActions";
import callApi, { callApiV2, callBulkUpload } from "../../util/apiCaller";
import { updateCustomerDetailsInChats } from "../Inbox/ThreadActions";
import {
  clearNonCreatedTags,
  CREATE_ORGANIZATION_TAG,
  CREATE_ORGANIZATION_TAG_STATUS,
  saveUserJourneyPreferences,
  toggleBulkUploadSelectedSegment,
} from "../App/AppActions";
import { ANALYTICS_BODY_DETAILS } from "../Manage/ManagePageActionTypes";
import callChatApi from "../../util/chatApiCaller";
import { DEFAULT_PAGINATION_INFO } from "../../constants/const";

export const TOGGLE_BULK_UPLOAD_SUCCESS_STATUS =
  "TOGGLE_BULK_UPLOAD_SUCCESS_STATUS";
export const FETCH_CUSTOMER_LIST_STATUS = "FETCH_CUSTOMER_LIST_STATUS";
export const UPDATE_CUSTOMER_TOTAL_COUNT = "UPDATE_CUSTOMER_TOTAL_COUNT";
export const FETCH_CUSTOMER_LIST = "FETCH_CUSTOMER_LIST";
export const UPDATE_CUSTOMER_DATALIST = "UPDATE_CUSTOMER_DATALIST";
export const UPDATE_ACTIVE_CUSTOMER = "UPDATE_ACTIVE_CUSTOMER";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_STATUS = "CREATE_CUSTOMER_STATUS";
export const FETCH_CUSTOMER_STATUS = "FETCH_CUSTOMER_STATUS";
export const UPDATING_CUSTOMER_DETAIL = "UPDATING_CUSTOMER_DETAIL";
export const UPDATE_CUSTOMER_DETAIL = "UPDATE_CUSTOMER_DETAIL";
export const UPDATE_CUSTOMER_TRAIT_START = "UPDATE_CUSTOMER_TRAIT_START";
export const UPDATE_CUSTOMER_TRAIT_SUCCESS = "UPDATE_CUSTOMER_TRAIT_SUCCESS";
export const UPDATE_CUSTOMER_TRAIT_FAILURE = "UPDATE_CUSTOMER_TRAIT_FAILURE";

export const TOGGLE_CUSTOMER_NOTE = "TOGGLE_CUSTOMER_NOTE";
export const UPDATING_CUSTOMER_TAGS = "UPDATING_CUSTOMER_TAGS";
export const UPDATE_CUSTOMER_TAGS = "UPDATE_CUSTOMER_TAGS";

export const FETCH_NOTE_LIST_STATUS = "FETCH_NOTE_LIST_STATUS";
export const FETCH_NOTE_LIST = "FETCH_NOTE_LIST";
export const FETCH_NOTE = "FETCH_NOTE";
export const CREATE_NOTE = "CREATE_NOTE";
export const CREATE_NOTE_STATUS = "CREATE_NOTE_STATUS";
export const FETCH_NOTE_STATUS = "FETCH_NOTE_STATUS";
export const DELETE_NOTE_STATUS = "DELETE_NOTE_STATUS";
export const DELETE_NOTE = "DELETE_NOTE";
export const UPDATE_NOTE_STATUS = "UPDATE_NOTE_STATUS";
export const UPDATE_NOTE_DETAIL = "UPDATE_NOTE_DETAIL";

export const FETCH_CHAT_LIST_STATUS = "FETCH_CHAT_LIST_STATUS";
export const FETCH_CHAT_LIST = "FETCH_CHAT_LIST";
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE_NUM = "SET_PAGE_NUM";
export const FETCH_CUSTOMER_ATTRIBUTES = "FETCH_CUSTOMER_ATTRIBUTES";
export const FETCH_EVENT_ATTRIBUTES = "FETCH_EVENT_ATTRIBUTES";
export const FETCH_CUSTOMER_EVENT_STATUS = "FETCH_CUSTOMER_EVENT_STATUS";
export const FETCH_CUSTOMER_EVENTS = "FETCH_CUSTOMER_EVENTS";
export const FETCH_CUSTOMER_ATTRIBUTES_STATUS =
  "FETCH_CUSTOMER_ATTRIBUTES_STATUS";
export const FETCH_EVENT_ATTRIBUTES_STATUS = "FETCH_EVENT_ATTRIBUTES_STATUS";
export const BULK_UPLOAD = "BULK_UPLOAD";

export const UPLOADING_BULK_DATA = "UPLOADING_BULK_DATA";
export const UPLOADED_BULK_DATA = "UPLOADED_BULK_DATA";
export const ERROR_WHILE_UPLOADING_DATA = "ERROR_WHILE_UPLOADING_DATA";
export const BULK_TAG_CUSTOMER_STATUS = "BULK_TAG_CUSTOMER_STATUS";
export const BULK_TAG_CUSTOMER = "BULK_TAG_CUSTOMER";
export const SAVE_USER_COLUMN_PREFERENCES = "SAVE_USER_COLUMN_PREFERENCES";
export const SAVE_USER_COLUMN_PREFERENCES_STATUS =
  "SAVE_USER_COLUMN_PREFERENCES_STATUS";
export const FETCH_ORDER_LIST = "FETCH_ORDER_LIST";
export const FETCH_ORDER_LIST_STATUS = "FETCH_ORDER_LIST_STATUS";
export const UPDATE_APPLIED_FILTER = "UPDATE_APPLIED_FILTER";
export const TOGGLE_SINGLE_ADD_CUSTOMER_MODAL =
  "TOGGLE_SINGLE_ADD_CUSTOMER_MODAL";
export const TOGGLE_BULK_ADD_CUSTOMER_MODAL = "TOGGLE_BULK_ADD_CUSTOMER_MODAL";
export const TOGGLE_BULK_TAG_CUSTOMER_MODAL = "TOGGLE_BULK_TAG_CUSTOMER_MODAL";
export const TOGGLE_BULK_TAG_CONFIRMATION_MODAL =
  "TOGGLE_BULK_TAG_CONFIRMATION_MODAL";

export const ADD_USERS_TO_SELECTION = "ADD_USERS_TO_SELECTION";
export const REMOVE_USERS_FROM_SELECTION = "REMOVE_USERS_FROM_SELECTION";
export const CLEAR_ALL_USERS_FROM_SELECTION = "CLEAR_ALL_USERS_FROM_SELECTION";
export const ADD_ALL_USERS_IN_SELECTION = "ADD_ALL_USERS_IN_SELECTION";
export const ADD_BULK_USERS_TO_SELECTION = "ADD_BULK_USERS_TO_SELECTION";
export const UPDATE_CSV_FILE_NAME = "UPDATE_CSV_FILE_NAME";
export const UPDATE_CSV_FILE_URL = "UPDATE_CSV_FILE_URL";

export const SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE =
  "SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE";

export const CUSTOMER_BULK_UPLOAD = "CUSTOMER_BULK_UPLOAD";
export const CUSTOMER_BULK_UPLOAD_SUCCESS = "CUSTOMER_BULK_UPLOAD_SUCCESS";
export const CUSTOMER_BULK_UPLOAD_FAILURE = "CUSTOMER_BULK_UPLOAD_FAILURE";

export const CUSTOMER_BULK_DELETE = "CUSTOMER_BULK_DELETE";
export const CUSTOMER_BULK_DELETE_SUCCESS = "CUSTOMER_BULK_DELETE_SUCCESS";
export const CUSTOMER_BULK_DELETE_FAILURE = "CUSTOMER_BULK_DELETE_FAILURE";

export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const UPDATE_ACTIVE_CUSTOMER_DETAIL = "UPDATE_ACTIVE_CUSTOMER_DETAIL";
export const UPDATING_ACTIVE_CUSTOMER_DETAIL =
  "UPDATING_ACTIVE_CUSTOMER_DETAIL";
export const FETCH_CUSTOMERS_ALL_DATA_REQUEST =
  "FETCH_CUSTOMERS_ALL_DATA_REQUEST";
export const FETCH_CUSTOMERS_ALL_DATA_SUCCESS =
  "FETCH_CUSTOMERS_ALL_DATA_SUCCESS";
export const FETCH_CUSTOMERS_ALL_DATA_FAILURE =
  "FETCH_CUSTOMERS_ALL_DATA_FAILURE";

export function toggleSingleCustomerAddModal(val) {
  return {
    type: TOGGLE_SINGLE_ADD_CUSTOMER_MODAL,
    isOpenAddContactForm: !!val,
  };
}

export function addBulkUserToSelection(users) {
  return {
    type: ADD_BULK_USERS_TO_SELECTION,
    users,
  };
}

export function addUserToSelection(userId) {
  return {
    type: ADD_USERS_TO_SELECTION,
    userId,
  };
}

export function removeUserFromSelection(userId) {
  return {
    type: REMOVE_USERS_FROM_SELECTION,
    userId,
  };
}

export function removeAllUsersFromSelection() {
  return {
    type: CLEAR_ALL_USERS_FROM_SELECTION,
  };
}

export function selectAllUsersList() {
  return {
    type: ADD_ALL_USERS_IN_SELECTION,
  };
}

export function toggleBulkCustomerAddModal(val) {
  return {
    type: TOGGLE_BULK_ADD_CUSTOMER_MODAL,
    isOpenBulkUploadModal: !!val,
  };
}

export function toggleBulkCustomerTagModal(val) {
  return {
    type: TOGGLE_BULK_TAG_CUSTOMER_MODAL,
    isOpenAddTagForm: !!val,
  };
}

export function toggleBulkConfirmtaionTagModal(val) {
  return {
    type: TOGGLE_BULK_TAG_CONFIRMATION_MODAL,
    isOpenAddTagConfirmationForm: !!val,
  };
}

export function updateAppliedFilter(filter) {
  return {
    type: UPDATE_APPLIED_FILTER,
    updatedAppliedFilters: filter,
  };
}

export function bulkUpload(data, filename) {
  return {
    type: BULK_UPLOAD,
    csv_data: data,
    csv_filename: filename,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    pageSize,
  };
}

export function setPageNum(pageNum) {
  return {
    type: SET_PAGE_NUM,
    pageNum,
  };
}

export function updateActiveCustomer(customer) {
  return {
    type: UPDATE_ACTIVE_CUSTOMER,
    activeCustomer: customer,
  };
}

export function updateTotalOrgUserCount(
  projectId,
  pageNum = 1,
  pageSize,
  filters = {}
) {
  return (dispatch) =>
    callApi(`elevate/v1/project/${projectId}/customers-list`, "post", {
      filters: {
        ...filters,
        isDeleted: 0,
      },
      pageNum,
      pageSize,
    }).then((res) => {
      if (res.data) {
        dispatch({
          type: UPDATE_CUSTOMER_TOTAL_COUNT,
          totalOrgUserCount: res?.data?.customersCount,
        });
      }
    });
}

export function bulkDeleteUsers(projectId, body, filter) {
  return (dispatch, getState) => {
    let project_id = projectId;
    dispatch({
      type: CUSTOMER_BULK_DELETE,
    });
    if (projectId === undefined) {
      project_id =
        typeof window === "undefined"
          ? ""
          : window.localStorage.getItem("projectId");
    }
    return callApi(
      `elevate/v1/project/${project_id}/customer/bulk-delete`,
      "post",
      body
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Selected User Deleted.");
        dispatch({
          type: CUSTOMER_BULK_DELETE_SUCCESS,
        });
        dispatch(
          fetchCustomerFilteredAndPaginationList(
            project_id,
            DEFAULT_PAGINATION_INFO.pageNum,
            DEFAULT_PAGINATION_INFO.pageSize,
            { ...filter }
          )
        );
      } else {
        showNotification("error", "Something went wrong..");
        dispatch({
          type: CUSTOMER_BULK_DELETE_FAILURE,
        });
      }
    });
  };
}

export function fetchCustomerFilteredAndPaginationList(
  projectId,
  pageNum = 1,
  pageSize = 500,
  filters = {},
  isLoadingInitialList = false,
  resolve = () => {}
) {
  return (dispatch, getState) => {
    const uuid = uuidv4();
    let project_id = projectId;
    dispatch({
      type: FETCH_CUSTOMER_LIST_STATUS,
      isFetching: true,
      isFetchingCustomerList: true,
      pageNum,
      isLoadingInitialList,
      uuid,
    });
    if (projectId === undefined) {
      project_id =
        typeof window === "undefined"
          ? ""
          : window.localStorage.getItem("projectId");
    }
    return callApi(`elevate/v1/project/${project_id}/customers-list`, "post", {
      filters: {
        ...filters,
        isDeleted: 0,
      },
      pageNum,
      pageSize,
    }).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CUSTOMER_LIST,
          customerList: res.data.customers_list,
          pageNum,
          pageSize,
          totalCount: res.data.customers_count,
          isFilterApplied: !!filters && filters.length > 0,
          isFetchingCustomerList: false,
          isLoadingInitialList: false,
          isCustomerListLoaded: true,
          uuid,
          isFetching: false,
        });
      } else {
        showNotification("error", "Failed to fetch Users");
        dispatch({
          type: FETCH_CUSTOMER_LIST_STATUS,
          isFetching: false,
          isFetchingCustomerList: false,
          isLoadingInitialList: false,
          uuid,
        });
      }
      resolve();
    });
  };
}

export async function fetchCustomerFilteredList(projectId, filters = {}) {
  try {
    const response = await callApi(
      `elevate/v1/project/${projectId}/customers-list`,
      "post",
      {
        filters: {
          ...filters,
          isDeleted: 0,
        },
        pageNum: 1,
        pageSize: "all",
      }
    );
    return response?.data?.customers_list || [];
  } catch (err) {
    return [];
  }
}

export function getCustomerById(projectId, customerId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_STATUS,
      isFetchingCustomer: true,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer/${customerId}`,
      "get"
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CUSTOMER,
          activeCustomer: res.data.customerDetails,
          isFetchingCustomer: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_STATUS,
          isFetchingCustomer: false,
        });
      }
    });
  };
}

export function createNewCustomer(
  activeProjectId,
  customer,
  pageNum,
  pageSize,
  filters,
  isLoadingInitialList,
  resolve,
  user_journey = {},
  user_has_relevant_access = false
) {
  return (dispatch) => {
    dispatch({
      type: CREATE_CUSTOMER_STATUS,
      isCreatingCustomer: true,
    });
    customer = { ...customer };
    customer.project_id = activeProjectId;
    return callApi(
      `elevate/customers/new`,
      "post",
      {
        customers: customer,
      },
      true
    ).then((res) => {
      if (res && res?.data?.customers) {
        if (filters && filters.length === 0) {
          dispatch({
            type: CREATE_CUSTOMER,
            isCreatingCustomer: false,
            customer: res.data?.customers,
          });
          showNotification("success", "Contact created" + " successfully");
          dispatch(
            fetchCustomerFilteredAndPaginationList(
              activeProjectId,
              (pageNum = 1),
              pageSize,
              (filters = []),
              isLoadingInitialList,
              (resolve = () => {})
            )
          );
        } else {
          dispatch({
            type: CREATE_CUSTOMER_STATUS,
            isCreatingCustomer: false,
          });
        }
      } else {
        showNotification("error", "Contact Already Exists ");
        dispatch({
          type: CREATE_CUSTOMER_STATUS,
          isCreatingCustomer: false,
        });
      }
    });
  };
}

export function updateUserDetails(name, email, opted) {
  return (dispatch, getState) => {
    const projectId = getState().app?.activeProjectId;
    const customerId = window.location.pathname.includes("/contacts/")
      ? getState().customer.activeCustomerAllUsersPage
      : getState().thread?.activeConversation?.customer_id?._id;

    dispatch({
      type: UPDATE_CUSTOMER_TRAIT_START,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer/${customerId}/update/`,
      "post",
      {
        customer: {
          contact_name: name,
          contact_email: email,
          is_wa_opt_in: opted,
        },
      }
    ).then((res) => {
      if (res && res.status === "Success") {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_SUCCESS,
          payload: { customerId, data: res?.data?.customerDetails },
        });
        showNotification("success", "User card details updated successfully");
        dispatch(
          fetchCustomerFilteredAndPaginationList(
            projectId,
            DEFAULT_PAGINATION_INFO.pageNum,
            DEFAULT_PAGINATION_INFO.pageSize,
            {}
          )
        );
      } else {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_FAILURE,
        });
        showNotification("error", res.message);
      }
    });
  };
}

export function updateUserTraits(data) {
  return (dispatch, getState) => {
    const projectId = getState().app?.activeProjectId;
    const customerId = window.location.pathname.includes("/contacts/")
      ? getState().customer.activeCustomerAllUsersPage
      : getState().thread?.activeConversation?.customer_id?._id;

    dispatch({
      type: UPDATE_CUSTOMER_TRAIT_START,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer/${customerId}/update/`,
      "post",
      {
        customer: {
          traits: data,
        },
      }
    ).then((res) => {
      if (res && res.status === "Success") {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_SUCCESS,
          payload: { customerId, data: res?.data?.customerDetails },
        });
        showNotification("success", "User Attribute Updated Successfully!");
      } else {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_FAILURE,
        });
        showNotification("error", res.message);
      }
    });
  };
}

export const cancelCustomerDetails = () => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_TRAIT_START,
  });
  setTimeout(() => {
    dispatch({
      type: UPDATE_CUSTOMER_TRAIT_FAILURE,
    });
  }, 2000);
};

export const updateCustomerDetails =
  (projectId, customerId, update) => (dispatch) => {
    dispatch({
      type: UPDATING_CUSTOMER_DETAIL,
      isUpdatingCustomerDetail: true,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer/${customerId}/update/`,
      "post",
      update
    ).then((res) => {
      if (res && res.data) {
        showNotification("success", "Personal details updated successfully");
        dispatch({
          type: UPDATE_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
          activeCustomer: { ...update },
        });
        dispatch(updateCustomerDetailsInChats(customerId, update));
      } else {
        dispatch({
          type: UPDATING_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
        });
        showNotification("warning", res.message);
      }
    });
  };

export const updateActiveCustomerDetails =
  (projectId, customerId, update) => (dispatch) => {
    dispatch({
      type: UPDATING_ACTIVE_CUSTOMER_DETAIL,
      isUpdatingCustomerDetail: true,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer/${customerId}/update/`,
      "post",
      update
    ).then((res) => {
      if (res && res.data) {
        let successMsg = "Personal details updated successfully";
        showNotification("success", successMsg);
        dispatch({
          type: UPDATE_ACTIVE_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
          customerId,
          activeCustomer: { ...update.customer },
        });
        dispatch(updateCustomerDetailsInChats(customerId, update));
      } else {
        dispatch({
          type: UPDATING_ACTIVE_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
        });
        console.log(res.message);
        showNotification("warning", res.message);
      }
      return res;
    });
  };

export const updateCanCustomerCanSendMessage =
  (customerId, update) => (dispatch) => {
    dispatch({
      type: UPDATING_ACTIVE_CUSTOMER_DETAIL,
      isUpdatingCustomerDetail: true,
    });
    return callChatApi(
      `v1/customer/${customerId}/update-can-send-message`,
      "post",
      update
    ).then((res) => {
      if (res && res.data) {
        const isCustomerBlocking = update.customer.is_user_message_blocked;
        const successMsg = `Selected customer has been ${
          isCustomerBlocking ? "blocked" : "unblocked"
        }`;
        showNotification("success", successMsg);
        dispatch({
          type: UPDATE_ACTIVE_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
          customerId,
          activeCustomer: { ...update.customer },
        });
        dispatch(updateCustomerDetailsInChats(customerId, update));
      } else {
        dispatch({
          type: UPDATING__ACTIVE_CUSTOMER_DETAIL,
          isUpdatingCustomerDetail: false,
        });
        showNotification("warning", res.message);
      }
    });
  };

export function updateCustomerTags(projectId, customerId, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_CUSTOMER_TAGS,
      isUpdatingCustomerTags: true,
    });
    return callApi(
      //`elevate/v1/project/${projectId}/customer/${customerId}/update/`,
      `elevate/customers/${customerId}/tag`,
      "post",
      {
        customer: update,
      }
    ).then((res) => {
      if (res && res.status === "Success") {
        dispatch({
          type: UPDATE_CUSTOMER_TAGS,
          isUpdatingCustomerTags: false,
          activeCustomer: update,
        });
        if (update.action === "remove") {
          showNotification("success", "Tag Removed successfully");
          update.action = "";
        } else {
          showNotification("success", "Tag Added successfully");
        }
      } else {
        dispatch({
          type: UPDATING_CUSTOMER_TAGS,
          isUpdatingCustomerTags: false,
        });
        showNotification("warning", res.message);
      }
    });
  };
}

export function getAllNotes(organizationId, customerId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTE_LIST_STATUS,
      isFetchingNoteList: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/?page=1&page_size=2000`,
      "get"
    ).then((res) => {
      if (res.results && res.results.data) {
        dispatch({
          type: FETCH_NOTE_LIST,
          customerId,
          noteList: res.results.data,
          isFetchingNoteList: false,
        });
      } else {
        dispatch({
          type: FETCH_NOTE_LIST_STATUS,
          isFetchingNoteList: false,
        });
      }
    });
  };
}

export function getNoteById(organizationId, customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTE_STATUS,
      isFetchingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "get"
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_NOTE,
          activeNote: res.data,
          isFetchingNote: false,
        });
      } else {
        dispatch({
          type: FETCH_NOTE_STATUS,
          isFetchingNote: false,
        });
      }
    });
  };
}

export function deleteNoteById(organizationId, customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_NOTE_STATUS,
      isDeletingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "DELETE"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: DELETE_NOTE,
          noteId,
          isDeletingNote: false,
          customerId,
        });

        showNotification("success", "Note deleted successfully");
      } else {
        dispatch({
          type: DELETE_NOTE_STATUS,
          isDeletingNote: false,
        });

        showNotification("error", res.message);
      }
    });
  };
}

export function createNewNote(organizationId, customerId, note) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_NOTE_STATUS,
      isCreatingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/`,
      "post",
      note
    ).then((res) => {
      const userId = getState()?.app?.user?.id;

      if (res && res.id) {
        note.id = res.id;
        dispatch(addToast(res.message, "success", 3));
        dispatch({
          type: CREATE_NOTE,
          customerId,
          isCreatingNote: false,
          note,
          userId,
        });
        showNotification("success", "Note added successfully");
      } else {
        dispatch({
          type: CREATE_NOTE_STATUS,
          isCreatingNote: false,
        });

        showNotification("error", res?.message);
      }
    });
  };
}

export function updateNoteDetails(organizationId, customerId, noteId, note) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_NOTE_STATUS,
      isUpdatingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "put",
      note
    ).then((res) => {
      const userId = getState()?.app?.user?.id;

      if (res && res.result) {
        dispatch(addToast(res.message, "success", 3));
        dispatch({
          type: UPDATE_NOTE_DETAIL,
          customerId,
          isUpdatingNote: false,
          activeNote: note,
          noteId,
          userId,
        });
        showNotification("success", "Notes updated successfully");
      } else {
        dispatch({
          type: UPDATE_NOTE_STATUS,
          isUpdatingNote: false,
        });

        showNotification("error", res?.message);
      }
    });
  };
}

export function fetchCustomerActiveChats(projectId, userId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHAT_LIST_STATUS,
      isFetchingChatList: true,
    });
    return callChatApi(
      `api/v1/analytics/project/${projectId}/user/${userId}/conversations`,
      "post"
    ).then((res) => {
      if (res.status === "Success") {
        dispatch({
          type: FETCH_CHAT_LIST,
          userId,
          chatList: res.data?.conversations?.[0],
          isFetchingChatList: false,
        });
      } else {
        dispatch({
          type: FETCH_CHAT_LIST_STATUS,
          isFetchingChatList: false,
        });
      }
    });
  };
}

export function fetchCustomerFilterAttributes(projectId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_ATTRIBUTES_STATUS,
      isFetchingCustomerAttributes: true,
    });
    return callApi(
      `elevate/v1/project/${projectId}/customer-attributes/`,
      "get"
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CUSTOMER_ATTRIBUTES,
          customer_attributes: res.data.customer_attributes
            ? res.data.customer_attributes
            : [],
          isFetchingCustomerAttributes: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_ATTRIBUTES_STATUS,
          isFetchingCustomerAttributes: false,
        });
      }
    });
  };
}

export function fetchEventFilterAttributes(projectId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EVENT_ATTRIBUTES_STATUS,
      isFetchingEventAttributes: true,
    });
    return callApi(
      `elevate/v1/project/${projectId}/event-attributes/`,
      "post"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_EVENT_ATTRIBUTES,
          event_attributes:
            res.data && res.data.length > 0 && res.data[0].organization_id
              ? res.data[0].attributes
              : {},
          event_name:
            res.data && res.data.length > 0 && res.data[0]
              ? res.data[0].event_name
              : "",
          eventAttributeList: res.data,
          isFetchingEventAttributes: false,
        });
      } else {
        dispatch({
          type: FETCH_EVENT_ATTRIBUTES_STATUS,
          isFetchingEventAttributes: true,
        });
      }
    });
  };
}

export function getCustomerEventsByCustomerId(
  organizationId,
  customerId,
  pageNum,
  pageSize = 1000
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_EVENT_STATUS,
      isFetchingCustomerEvents: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/events/?offset=${
        (pageNum - 1) * pageSize
      }&limit=${pageSize}`,
      "get"
    ).then((res) => {
      if (res.results) {
        dispatch({
          type: FETCH_CUSTOMER_EVENTS,
          pageNum,
          customerId,
          customerEvents: res.results.data,
          totalCustomerEventCount: res.count,
          isFetchingCustomerEvents: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_EVENT_STATUS,
          isFetchingCustomerEvents: false,
        });
      }
    });
  };
}

export function toggleCustomerNoteActive(customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_CUSTOMER_NOTE,
      customerId,
      noteId,
    });
  };
}

export function toggleBulkUploadSuccess(isBulUploadSuccessModalVisible) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_BULK_UPLOAD_SUCCESS_STATUS,
      isBulUploadSuccessModalVisible,
    });
  };
}

export function updateBulkCustomersUpload(projectId, FormData) {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_BULK_UPLOAD,
      isBulkUploading: true,
    });
    return callBulkUpload(
      `elevate/v1/project/${projectId}/customer/bulk-upload`,
      "POST",
      FormData
    ).then((res) => {
      if (res.status === "Success") {
        dispatch({
          type: CUSTOMER_BULK_UPLOAD_SUCCESS,
          isBulkUploading: false,
        });

        setTimeout(() => {
          dispatch(
            fetchCustomerFilteredAndPaginationList(
              projectId,
              DEFAULT_PAGINATION_INFO.pageNum,
              DEFAULT_PAGINATION_INFO.pageSize,
              {}
            )
          );
        }, 3000);
        showNotification("success", "Bulk upload successfully");
      } else {
        dispatch({
          type: CUSTOMER_BULK_UPLOAD_FAILURE,
          isBulkUploading: false,
        });
        showNotification("error", "Something went wrong...");
      }
    });
  };
}

export function updateBulkCustomersByCSV(
  organizationId,
  data,
  selectedTags,
  tagsNotCreated,
  history
) {
  return (dispatch) => {
    dispatch({
      type: UPLOADING_BULK_DATA,
      isUploadingBulkCSVData: true,
    });
    let isUploadSuccess = true;
    async.series(
      [
        (cb) => {
          if (tagsNotCreated && tagsNotCreated.length > 0) {
            async.each(
              tagsNotCreated,
              (tag, callback) => {
                callApi(
                  `v1/organizations/${organizationId}/tags/`,
                  "POST",
                  tag,
                  true
                ).then((res) => {
                  if (res && res.result) {
                    if (selectedTags) {
                      selectedTags.push(res.id);
                    }
                    const newTag = { ...tag, id: res.id };
                    dispatch({
                      type: CREATE_ORGANIZATION_TAG,
                      tag: newTag,
                      isCreatingOrganizationTag: false,
                    });
                    return callback();
                  }
                  dispatch({
                    type: CREATE_ORGANIZATION_TAG_STATUS,
                    isCreatingOrganizationTag: false,
                  });
                  return callback();
                });
              },
              (err) => cb()
            );
          } else {
            return cb();
          }
        },
        (cb) => {
          if (selectedTags && selectedTags.length > 0) {
            data = data.map((item) => {
              item.tags = selectedTags;
              return item;
            });
          }
          return callApi(
            `v1/organizations/${organizationId}/customers/bulk-upload/`,
            "post",
            {
              customers: data,
            },
            true
          ).then((res) => {
            if (res.result) {
              dispatch({
                type: UPLOADED_BULK_DATA,
                isUploadingBulkCSVData: false,
                isBulUploadSuccessModalVisible: true,
              });
              history.push(`/contacts/list`);
              dispatch(fetchCustomerFilterAttributes(organizationId));
              dispatch(toggleBulkUploadSelectedSegment("firstScreen"));
              return cb();
            }
            isUploadSuccess = false;
            if (res.message) {
              try {
                const data = JSON5.parse(res.message);
                if (data.key === "limit_exceed_error") {
                  showNotification("error", data.error);
                  dispatch({
                    type: ERROR_WHILE_UPLOADING_DATA,
                    isUploadingBulkCSVData: false,
                  });
                  return cb();
                }
                if (data.key === "validation_error") {
                  dispatch(toggleBulkUploadSelectedSegment("errorScreen"));
                  dispatch({
                    type: ERROR_WHILE_UPLOADING_DATA,
                    isUploadingBulkCSVData: false,
                    bulkUploadErrors: data.error,
                  });
                  return cb();
                }
              } catch {
                showNotification("error", res.message);
                dispatch({
                  type: ERROR_WHILE_UPLOADING_DATA,
                  isUploadingBulkCSVData: false,
                });
                return cb();
              }
            } else {
              return cb();
            }
          });
        },
      ],
      (err) => {
        if (isUploadSuccess && tagsNotCreated && tagsNotCreated.length > 0) {
          dispatch(clearNonCreatedTags());
        }
      }
    );
  };
}

export function updateBulkCustomersByCSVv2(
  organizationId,
  requestBody,
  tagsNotCreated,
  history
) {
  return (dispatch) => {
    const payload = { ...requestBody };
    dispatch({
      type: UPLOADING_BULK_DATA,
      isUploadingBulkCSVData: true,
    });
    let isUploadSuccess = true;
    async.series(
      [
        (cb) => {
          if (tagsNotCreated && tagsNotCreated.length > 0) {
            async.each(
              tagsNotCreated,
              (tag, callback) => {
                callApi(
                  `v1/organizations/${organizationId}/tags/`,
                  "POST",
                  tag,
                  true
                ).then((res) => {
                  if (res && res.result) {
                    if (payload) {
                      payload.tags[res.id] = tag.name;
                    }
                    const newTag = { ...tag, id: res.id };
                    dispatch({
                      type: CREATE_ORGANIZATION_TAG,
                      tag: newTag,
                      isCreatingOrganizationTag: false,
                    });
                    return callback();
                  }
                  dispatch({
                    type: CREATE_ORGANIZATION_TAG_STATUS,
                    isCreatingOrganizationTag: false,
                  });
                  return callback();
                });
              },
              (err) => cb()
            );
          } else {
            return cb();
          }
        },
        (cb) =>
          callApi(
            `v1/organizations/${organizationId}/customers/bulk-upload/async/`,
            "post",
            payload,
            true
          ).then((res) => {
            if (res.result) {
              showCustomNotification(
                "warning",
                <Box display="flex">
                  <Icon
                    iconName="upload"
                    iconSize="small"
                    color="white"
                    mr="8px"
                  />
                  <Label
                    fontFamily="Roboto"
                    fontSize="16px"
                    lineHeight="20px"
                    color="white"
                  >
                    Bulk Upload is in progress. We will notify you here and also
                    by email, once the upload is complete. The upload is
                    expected to happen @ 2000 contacts per minute.
                  </Label>
                </Box>,
                {
                  position: "top-center",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  toastId: "csvUploading",
                  className: "toast-width-lg",
                }
              );
              history.push(`/contacts/list`);
              dispatch(fetchCustomerFilterAttributes(organizationId));
            } else {
              showCustomNotification(
                "error",
                <Box display="flex">
                  <Icon
                    iconName="upload"
                    iconSize="small"
                    color="white"
                    mr="8px"
                  />
                  <Label
                    fontFamily="Roboto"
                    font-weight="500"
                    font-size="14px"
                    line-height="16px"
                    color="white"
                  >
                    {res?.message}
                  </Label>
                </Box>,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  className: "toast-width-s",
                }
              );
              isUploadSuccess = false;
            }
            return cb();
          }),
      ],
      (err) => {
        if (isUploadSuccess && tagsNotCreated && tagsNotCreated.length > 0) {
          dispatch(clearNonCreatedTags());
        }
      }
    );
  };
}

export const updateCSVFileName = (filename) => ({
  type: UPDATE_CSV_FILE_NAME,
  filename,
});

export const updateCSVFileURL = (url) => ({
  type: UPDATE_CSV_FILE_URL,
  url,
});

export function customerBulkTag(organizationId, data = {}) {
  return (dispatch) => {
    dispatch({
      type: BULK_TAG_CUSTOMER_STATUS,
      isBulkTaggingCustomer: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/bulk-tag/`,
      "PUT",
      data
    ).then((res) => {
      dispatch({
        type: BULK_TAG_CUSTOMER_STATUS,
        isBulkTaggingCustomer: false,
      });
      if (res.result) {
        showNotification("success", "tags added successfully");
      } else {
        showNotification("error", "failed to add tags");
      }
    });
  };
}

export function saveUserColumnPreferences(
  organizationId,
  all_users_columns_preferences
) {
  return (dispatch) => {
    dispatch({
      type: SAVE_USER_COLUMN_PREFERENCES_STATUS,
      isSavingUserColumnPreferences: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "PUT",
      {
        all_users_columns_preferences: JSON5.stringify(
          all_users_columns_preferences
        ),
      }
    ).then((res) => {
      if (res.result) {
        showNotification("success", "Preferences updated successfully");
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES,
          isSavingUserColumnPreferences: false,
          selectedCustomerAttributes: all_users_columns_preferences,
        });
      } else {
        showNotification("error", "Failed to update");
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES_STATUS,
          isSavingUserColumnPreferences: false,
        });
      }
    });
  };
}

export function fetchUserColumnPreferences(organizationId) {
  return (dispatch) =>
    callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "get"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES,
          isSavingUserColumnPreferences: false,
          selectedCustomerAttributes:
            res.data && !!res.data.all_users_columns_preferences
              ? JSON5.parse(res.data.all_users_columns_preferences)
              : null,
        });
      }
    });
}

export function fetchOrderList(organizationId, customerId, pageNum) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ORDER_LIST_STATUS,
      isFetchingOrderList: true,
    });
    return callApi(
      `v1/orgs/${organizationId}/shopify/${customerId}/orders/`,
      "POST"
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_ORDER_LIST,
          pageNum,
          customerId,
          orderList: res.data,
          isFetchingOrderList: false,
        });
      } else {
        dispatch({
          type: FETCH_ORDER_LIST_STATUS,
          isFetchingOrderList: false,
        });
      }
    });
  };
}

export const setActiveCustomerAllUsersPage = (id) => ({
  type: SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE,
  payload: id,
});

export const clearAllFilterDetails = () => ({
  type: CLEAR_FILTER_DATA,
});

export const filterDetailsUpdate = (keyName, keyValue) => ({
  type: SET_FILTER_DATA,
  keyName,
  keyValue,
});

export const updateConversationPreference =
  (filters, modalFilters) => async (dispatch, getState) => {
    const activeProjectId = getState()?.app?.activeProjectId;
    const response = await callApiV2({
      method: "post",
      endpoint: `elevate/v1/project/${activeProjectId}/bulk-opt-incoming-update`,
      body: {
        filters: {
          ...filters,
        },
      },
    });
    if (response.data) {
      showNotification(
        "success",
        "Conversation Preferences Updated Successfully"
      );
      dispatch(
        fetchCustomerFilteredAndPaginationList(
          activeProjectId,
          DEFAULT_PAGINATION_INFO.pageNum,
          DEFAULT_PAGINATION_INFO.pageSize,
          { ...modalFilters }
        )
      );
    } else {
      showNotification("error", "Something went wrong");
    }
  };

export const bulkTagUpdated =
  (filters, modalFilters) => async (dispatch, getState) => {
    const activeProjectId = getState()?.app?.activeProjectId;
    const response = await callApiV2({
      method: "post",
      endpoint: `elevate/v1/project/${activeProjectId}/bulk-tag-update`,
      body: {
        filters: {
          ...filters,
        },
      },
    });
    if (response.data) {
      if (filters.updateType === "add") {
        showNotification("success", "Bulk Tag Added Successfully");
      } else if (filters.updateType === "remove") {
        showNotification("success", "Bulk Tag Removed Successfully");
      }
      // dispatch(
      //   fetchCustomerFilteredAndPaginationList(
      //     activeProjectId,
      //     DEFAULT_PAGINATION_INFO.pageNum,
      //     DEFAULT_PAGINATION_INFO.pageSize,
      //     { ...modalFilters }
      //   )
      // );
    } else {
      showNotification("error", "Something went wrong");
    }
  };

export const closeBulkTagUpdate =
  (activeProjectId, modalFilters) => async (dispatch) => {
    dispatch(
      fetchCustomerFilteredAndPaginationList(
        activeProjectId,
        DEFAULT_PAGINATION_INFO.pageNum,
        DEFAULT_PAGINATION_INFO.pageSize,
        { ...modalFilters }
      )
    );
  };

export function fetchCustomerFilteredAndPaginationListAllData(
  projectId,
  pageNum = 1,
  pageSize,
  filters = {},
  isLoadingInitialList = false,
  resolve = () => {}
) {
  return async (dispatch, getState) => {
    const uuid = uuidv4();
    let project_id = projectId;
    dispatch({
      type: FETCH_CUSTOMERS_ALL_DATA_REQUEST,
      isFetchingCustomerList: true,
      pageNum,
      isLoadingInitialList,
      uuid,
    });
    if (projectId === undefined) {
      project_id =
        typeof window === "undefined"
          ? ""
          : window.localStorage.getItem("projectId");
    }
    try {
      const res = await callApi(
        `elevate/v1/project/${project_id}/customers-list`,
        "post",
        {
          filters: {
            ...filters,
            isDeleted: 0,
          },
          pageNum,
          pageSize,
        }
      );
      if (res.data) {
        dispatch({
          type: FETCH_CUSTOMERS_ALL_DATA_SUCCESS,
          payload: res?.data?.customers_list,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_CUSTOMERS_ALL_DATA_FAILURE,
        payload: error.message,
      });
    }
    resolve();
  };
}
