import React, { useState } from "react";
import styled from "styled-components";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import { useSelector } from "react-redux";
import { getConversationEvents } from "../../../../../ThreadReducer";
import { getFormattedTime } from "../../../../../../../util/timeUtility";
import { getAgentName } from "../../../../../../../util/chatUtil";
import { getAllAgentsData } from "../../../../../ConversationReducer";
import {
  LiveChatCustomerBody,
  LiveChatCustomerHading,
  LiveChatCustomerItem,
  LiveChatCustomerCircle,
  LiveChatCustomerDashed,
} from "./LiveChatCustomer.styles";

const LiveChatCustomer = ({ heading }) => {
  const [iconManage, setIconManage] = useState();
  const iconChangeHandle = () => {
    setIconManage(!iconManage);
  };

  const agents = useSelector((state) => getAllAgentsData(state));
  const eventList = useSelector((state) => getConversationEvents(state));

  const getEventText = (item) => {
    let eventText = item?.event_type ?? "";
    if (eventText === "conversation_type") {
      let FullMessage = item?.message_text;
      return FullMessage;
    }
    if (eventText === "chat_transfer") {
      const fromAgentName = getAgentName(item?.last_agent_id, agents);
      const activeAgnetName = getAgentName(item?.active_agent_id, agents);
      return `Chat transferred from ${fromAgentName} to ${activeAgnetName}`;
    }
    if (eventText === "whatsapp_opted") {
      const agentName = getAgentName(item?.active_agent_id, agents);
      const messageText = item?.message_text.replace("you", agentName);
      return `${messageText}.`;
    }
    if (eventText === "blocked" || eventText === "unblocked") {
      return `Chat ${eventText} by ${getAgentName(
        item?.active_agent_id,
        agents
      )}`;
    } else if (item?.eventType === "Send") {
      return `${item?.eventName} send to User`;
    }

    const subText = item?.event_type
      ? item?.event_type === "activation"
        ? "User requesting intervention"
        : `Chat ${
            item?.event_type === "assignment" ? "intervened" : item?.event_type
          } by ${getAgentName(item?.active_agent_id, agents)}`
      : item?.eventType === "created"
      ? `${item?.eventName} ${item?.eventType}`
      : `${item?.eventName} ${item?.eventType} by ${getAgentName(
          item?.active_agent_id,
          agents
        )}`;
    return `${subText}`;
  };

  return (
    <LiveChatCustomerBody>
      <Box display="flex" justifyContent="space-between">
        <LiveChatCustomerHading>{heading}</LiveChatCustomerHading>
        {!iconManage ? (
          <Icon iconName="plus" color="#5E3EC5" onClick={iconChangeHandle} />
        ) : (
          <Icon iconName="minus" color="#5E3EC5" onClick={iconChangeHandle} />
        )}
      </Box>
      <LiveChatCustomerItem iconManage={iconManage}>
        <Box pb={eventList?.events?.length === 0 ? 0 : "50px"} pt="25px">
          {eventList?.events?.map((item, index) => (
            <Box display="flex" height="68px" key={index}>
              <Box>
                <LiveChatCustomerCircle
                  background={index === 0 ? "#3a11c5" : ""}
                />
                {index === eventList?.events.length - 1 ? (
                  <Box mr="38px" />
                ) : (
                  <LiveChatCustomerDashed></LiveChatCustomerDashed>
                )}
              </Box>
              <Box>
                <Box fontSize="11px" mb="8px">
                  {getEventText(item)}
                </Box>
                <Box fontSize="10px" color="#6E6E6E">
                  {`${getFormattedTime(item?.created_at, "DD MMM, hh:mm a")}`}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </LiveChatCustomerItem>
    </LiveChatCustomerBody>
  );
};

export default LiveChatCustomer;
