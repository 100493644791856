import styled from "styled-components";
import Box from "konnekt/Box";

export const LiveChatCustomerBody = styled(Box)`
  width: 100%;
  border: 1px solid #5e3ec5;
  border-radius: 1rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
`;
export const LiveChatCustomerHading = styled(Box)`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  color: #000000;
`;
export const LiveChatCustomerItem = styled(Box)`
  display: ${(props) => (props.iconManage ? "block" : "none")};
`;

export const LiveChatCustomerCircle = styled(Box)`
  width: 1.2rem;
  height: 1.2rem;
  border: 1.5px solid #5e3ec5;
  border-radius: 0.6rem;
`;
export const LiveChatCustomerDashed = styled(Box)`
  width: 5.5rem;
  height: 0px;
  border: 1px dashed #5e3ec5;
  transform: rotate(90deg);
  margin-top: 2.7rem;
  margin-left: -2.1rem;
`;
