import styled from "styled-components";
import Box from "konnekt/Box";
import TextInput from "konnekt/TextInput";
import DropDownControlled from "konnekt/Dropdown";

export const CommonContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 10rem 5.5rem 4rem 5.5rem;
  @media only screen and (max-width: 767px) {
    padding: 10rem 1.5rem 1rem 1.5rem;
  }
  h3 {
    font-weight: 600;
    font-size: 2.4rem;
    margin: 0;
  }
`;
export const CommonBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const BusinessHeader = styled(CommonBox)`
  justify-content: space-between;
`;
// project Card Styled
export const CreateProjectCards = styled(Box)`
  height: 200px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px 20px;

  button p {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: auto;
    overflow: scroll;
    height: 450px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: auto auto auto;
  }
`;
export const Cards = styled(Box)`
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 15px;

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
  }

  p {
    margin-bottom: 10px;
  }

  span {
    margin-left: 10px;
  }

  @media (min-resolution: 130dpi) and (max-resolution: 150dpi) {
    h6 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

// message styled

export const MessageContainer = styled("div")`
  padding: 10rem 5.5rem 4rem;
  h3 {
    font-weight: 600;
    font-size: 2.4rem;
    margin: 0;
  }
  h4 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 3rem;
    color: var(--blue);
  }
`;
export const FormContainer = styled("form")`
  margin: 3rem 0;
  sup {
    color: #ff5a00;
  }
`;

export const BoxInput = styled("div")`
  margin-bottom: 1.5rem;
`;
export const FormCard = styled("div")`
  padding: 2rem;
  background: var(--white);
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  @media (max-width: 767px) {
  }
`;
export const FormGridContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TextInputStyle = styled(TextInput)`
  background: #f2f2f2;
  width: 100%;
  border: 0;
  height: 4.4rem;
  border-radius: 0.4rem;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
`;
export const DropDownControlledStyle = styled(DropDownControlled)`
  background: #f2f2f2;
  width: 100%;
  height: 4.4rem;
  border-radius: 0.4rem;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
`;
