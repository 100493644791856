import styled from "styled-components";
import Box from "konnekt/Box";

export const StyledColEight = styled(Box)`
  width: 100%;
  margin-top: 8rem;
  padding-right: 1.5rem;
  @media (max-width: 768px) {
    display: block;
    margin-top: 0;
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    background: var(--white);
    padding: 1.5rem;
    border-radius: 1rem;
    border: 0.1rem solid #ececec;
    box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
  }
  li {
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 0.9rem;
    color: var(--black);
  }
  @media (max-width: 768px) {
    padding: 8.7rem 1rem 1rem 0.8rem;
    ul {
      display: block;
      box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
      border-radius: 1rem;
    }
    li {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0 1.4rem 0;
    }
    p {
      font-size: 1.4rem;
    }
  }
`;

export const ImgContainer = styled(Box)`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background: var(--bg-light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 50%;
    height: 5.5rem;
    width: 5.5rem;
    object-fit: fill;
  }
`;

export const StyledDashboard = styled(Box)`
  width: 100%;
  background: var(--dash-bg);
  display: flex;
  overflow-x: hidden;
  padding: 0 2rem 2rem;

  @media (max-width: 768px) {
    display: block;
    padding: 0;
    overflow-y: auto;
    height: 100%;
  }
`;

export const StyledColFour = styled(Box)`
  width: 45rem;
  padding-top: 6.4rem;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const CommonBoxStyle = styled.div`
  background: var(--white);
  border-radius: 1rem;
  padding: 2rem;
  border: 0.1rem solid #ececec;
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
  margin: 2rem 0 1rem 0;
`;
export const CurrentPlanBody = styled(CommonBoxStyle)``;
export const CustomizeBody = styled(CommonBoxStyle)`
  display: flex;
  justify-content: space-between;
`;

export const BusinessAccountBody = styled.div`
  justify-content: space-between;
  padding: 1.4rem 0 0 0;
  border: 1px solid #ececec;
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
  border-radius: 1rem;
  margin-top: 1.5rem;
`;
export const StyledUserData = styled(CommonBoxStyle)``;
export const StyledEducation = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
`;
export const StyledNumber = styled(Box)`
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
`;
export const DashboardBody = styled(CommonBoxStyle)`
  margin-top: 1.5rem;
`;
export const DashboardPlatformBodyItem = styled.div`
  max-height: ${(props) => (props.platformTutorial ? "200rem" : "0")};
  transition: max-height 0.5s ease-out;
  overflow: hidden;
`;
export const DashboardPlatformItem = styled.div`
  display: flex;
  margin-top: 1rem;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: start;
  }
`;
export const DashboardPlatformTitles = styled.div`
  display: flex;
  margin-left: 2rem;
  justify-content: center;
  @media only screen and (max-width: 425px) {
    align-items: start;
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;
export const CurrentPlanItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CurrentPlanItemLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const CurrentPlanMonthlyBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.7rem 1rem 0.7rem;
  align-items: center;
`;

export const HideProfileHading = styled.h1`
  font-size: 1.3rem;
  line-height: 2rem;
  text-transform: capitalize;
`;
export const HideProfileParagraph = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--black-l);
`;

export const BusinessAccountHading = styled.h1`
  font-weight: 500;
  font-size: 1.6rem;
`;
export const BoardRight = styled.div`
  border-right: 0.1rem solid var(--bg-light-3);
  height: 7.4rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const HeaderWhatsAppBody = styled.div`
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
  margin-top: 0.3rem;
  border-radius: 1rem;

  span {
    font-weight: 600;
    font-size: 2.4rem;
  }

  hr {
    display: none;
  }

  @media (max-width: 768px) {
    hr {
      display: block;
    }
  }
`;

export const HeaderBold = styled.h1`
  font-weight: 600;
  font-size: 1.6rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--black);
`;
export const ProfileHading = styled(Box)`
  font-style: italic;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--green);
`;

export const TittleWithOutBold = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--black-l);
`;
export const LabelMonthly = styled.h2`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
`;
export const Label1982 = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: ${(props) => props.color};
`;
export const AccordionBoldTitle = styled.p`
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.1rem;
  letter-spacing: 0.01rem;
  text-decoration-line: underline;
`;
export const AccordionItemTitle = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.1rem;
  letter-spacing: 0.01rem;
  color: var(--black-l);
`;
export const TextAreaInput = styled.textarea`
  width: 100%;
  height: 13.6rem;
  margin: 1.1rem 0 0 0.2rem;
  border-color: #eceee6;
  padding: 1rem;
  border-radius: 0.4rem;

  &::placeholder {
    color: #b3b3b3;
  }
`;
export const ChangePlanBox = styled(Box)`
  width: 100%;
  border: 1px solid gray;
  border-radius: 1rem;
  padding: 0.7rem 1.5rem;
  margin-top: ${(props) => props.mt};
`;

export const BasicAmountBox = styled(Box)`
  align-items: center;
  justify-content: center;
`;

export const ChangePlanTitle = styled(Box)`
  font-size: ${(props) => props.fs};
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: ${(props) => props.color};
  margin-bottom: ${(props) => props.mb};
`;

export const BtnTittle = styled(Box)`
  font-size: 1.2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--black);
`;
export const BtnTittleLittle = styled(Box)`
  font-size: 0.8rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--black);
`;
export const ModalBody = styled(Box)`
  height: 100%;
`;
export const BillingOrShippingBody = styled(Box)`
  padding: 1.2rem 2.4rem;
  width: 100%;
  background: var(--white);
  border: 1px solid #f3f3f3;
  box-shadow: 0 0.4rem 2.4rem rgba(0, 0, 0, 0.03);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BillShipText = styled(Box)`
  font-size: 1.2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--black);
`;

export const AccordionBoldHeading = styled(Box)`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01rem;
  color: var(--black);
  margin-left: 1rem;
`;

export const ProfileDetailsHading = styled(Box)`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  color: #212121;
  @media only screen and (max-width: 832px) {
    font-size: 1.4rem;
  }
`;

export const PlatformTutorial = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ProfileDetails = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ProfileDetailsInput = styled(Box)`
  width: 49%;
  @media only screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const VerticalDescription = styled.div`
  margin-bottom: 3rem;
  @media only screen and (max-width: 425px) {
    margin-bottom: 0;
  }
`;

export const TitleName = styled.div`
  margin-top: 2.5rem;
  @media only screen and (max-width: 425px) {
    margin-top: 1rem;
  }
`;
