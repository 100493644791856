export const UPDATE_INITIAL_ALL_AGENT_DATA = "UPDATE_INITIAL_ALL_AGENT_DATA";
export const FETCH_ALL_AGENT_DETAILS = "FETCH_ALL_AGENT_DETAILS";
export const FETCH_ALL_AGENT_DETAILS_SUCCESS =
  "FETCH_ALL_AGENT_DETAILS_SUCCESS";
export const AGENT_DETAILS_FAILURE = "FETCH_ALL_AGENT_DETAILS_FAILURE";
export const FETCH_AGENT_DETAILS = "FETCH_AGENT_DETAILS";
export const UPDATE_AGENT_DETAILS = "UPDATE_AGENT_DETAILS";
export const INITIAL_AGENT_DETAILS = "INITIAL_AGENT_DETAILS";
export const ADD_AGENT_DETAILS = "ADD_AGENT_DETAILS";
export const ADD_AGENT_DETAILS_SUCCESS = "ADD_AGENT_DETAILS_SUCCESS";
export const UPDATE_AGENT_DATA = "UPDATE_AGENT_DATA";
export const UPDATE_AGENT_DATA_SUCCESS = "UPDATE_AGENT_DATA_SUCCESS";
export const DELETE_AGENT_DETAILS = "DELETE_AGENT_DETAILS";
export const DELETE_AGENT_DETAILS_SUCCESS = "DELETE_AGENT_DETAILS_SUCCESS";

//Tags
export const UPDATE_INITIAL_TAGS_DATA = "UPDATE_INITIAL_TAGS_DATA";
export const FETCH_ALL_TAGS_DETAILS = "FETCH_ALL_TAGS_DETAILS";
export const FETCH_ALL_TAGS_DETAILS_SUCCESS = "FETCH_ALL_TAGS_DETAILS_SUCCESS";
export const TAG_DETAILS_FAILURE = "TAG_DETAILS_FAILURE";
export const FETCH_TAG_DETAILS = "FETCH_TAG_DETAILS";
export const UPDATE_TAG_DETAILS = "UPDATE_TAG_DETAILS";
export const INITIAL_TAG_DETAILS = "INITIAL_TAG_DETAILS";
export const ADD_TAG_DETAILS = "ADD_TAG_DETAILS";
export const ADD_TAG_DETAILS_SUCCESS = "ADD_TAG_DETAILS_SUCCESS";
export const UPDATE_TAG_DATA = "UPDATE_TAG_DATA";
export const UPDATE_TAG_DATA_SUCCESS = "UPDATE_TAG_DATA_SUCCESS";
export const DELETE_TAG_DETAILS = "DELETE_TAG_DETAILS";
export const DELETE_TAG_DETAILS_SUCCESS = "DELETE_TAG_DETAILS_SUCCESS";

//Canned Message
export const UPDATE_INITIAL_CANNED_DATA = "UPDATE_INITIAL_CANNED_DATA";
export const FETCH_ALL_CANNED_MESSAGE_DETAILS =
  "FETCH_ALL_CANNED_MESSAGE_DETAILS";
export const FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS =
  "FETCH_ALL_CANNED_MESSAGE_DETAILS_SUCCESS";
export const CANNED_MESSAGE_DETAILS_FAILURE = "CANNED_MESSAGE_DETAILS_FAILURE";
export const FETCH_CANNED_MESSAGE_DETAILS = "FETCH_CANNED_MESSAGE_DETAILS";
export const UPDATE_CANNED_MESSAGE_DETAILS = "UPDATE_CANNED_MESSAGE_DETAILS";
export const INITIAL_CANNED_MESSAGE_DETAILS = "INITIAL_CANNED_MESSAGE_DETAILS";
export const ADD_CANNED_MESSAGE_DETAILS = "ADD_CANNED_MESSAGE_DETAILS";
export const ADD_CANNED_MESSAGE_DETAILS_SUCCESS =
  "ADD_CANNED_MESSAGE_DETAILS_SUCCESS";
export const UPDATE_CANNED_MESSAGE_DATA = "UPDATE_CANNED_MESSAGE_DATA";
export const UPDATE_CANNED_MESSAGE_DATA_SUCCESS =
  "UPDATE_CANNED_MESSAGE_DATA_SUCCESS";
export const DELETE_CANNED_MESSAGE_DETAILS = "DELETE_CANNED_MESSAGE_DETAILS";
export const DELETE_CANNED_MESSAGE_DETAILS_SUCCESS =
  "DELETE_CANNED_MESSAGE_DETAILS_SUCCESS";

//Template
export const UPDATE_INITIAL_TEMPLATE_DATA = "UPDATE_INITIAL_TEMPLATE_DATA";
export const FETCH_ALL_TEMPLATE_MESSAGE_DETAILS =
  "FETCH_ALL_TEMPLATE_MESSAGE_DETAILS";
export const FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS =
  "FETCH_ALL_TEMPLATE_MESSAGE_DETAILS_SUCCESS";
export const TEMPLATE_MESSAGE_DETAILS_FAILURE =
  "TEMPLATE_MESSAGE_DETAILS_FAILURE";
export const FETCH_TEMPLATE_MESSAGE_DETAILS = "FETCH_TEMPLATE_MESSAGE_DETAILS";
export const UPDATE_TEMPLATE_MESSAGE_DETAILS =
  "UPDATE_TEMPLATE_MESSAGE_DETAILS";
export const INITIAL_TEMPLATE_MESSAGE_DETAILS =
  "INITIAL_TEMPLATE_MESSAGE_DETAILS";
export const ADD_TEMPLATE_MESSAGE_DETAILS = "ADD_TEMPLATE_MESSAGE_DETAILS";
export const ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS =
  "ADD_TEMPLATE_MESSAGE_DETAILS_SUCCESS";
export const ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE =
  "ADD_TEMPLATE_MESSAGE_DETAILS_FAILURE";
export const UPDATE_TEMPLATE_MESSAGE_DATA = "UPDATE_TEMPLATE_MESSAGE_DATA";
export const UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS =
  "UPDATE_TEMPLATE_MESSAGE_DATA_SUCCESS";
export const DELETE_TEMPLATE_MESSAGE_DETAILS =
  "DELETE_TEMPLATE_MESSAGE_DETAILS";
export const DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS =
  "DELETE_TEMPLATE_MESSAGE_DETAILS_SUCCESS";
export const UPDATE_BUTTON_CLICKED = "UPDATE_BUTTON_CLICKED";
export const SYNC_TEMPLATE_MESSAGE = "SYNC_TEMPLATE_MESSAGE";
export const SYNC_TEMPLATE_MESSAGE_SUCCESS = "SYNC_TEMPLATE_MESSAGE_SUCCESS";
export const SYNC_TEMPLATE_MESSAGE_FAILURE = "SYNC_TEMPLATE_MESSAGE_FAILURE";

//user-attribute
export const UPDATE_INITIAL_USER_ATTRIBUTE_DATA =
  "UPDATE_INITIAL_USER_ATTRIBUTE_DATA";
export const FETCH_ALL_USER_ATTRIBUTE_DETAILS =
  "FETCH_ALL_USER_ATTRIBUTE_DETAILS";
export const FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS =
  "FETCH_ALL_USER_ATTRIBUTE_DETAILS_SUCCESS";
export const USER_ATTRIBUTE_DETAILS_FAILURE = "USER_ATTRIBUTE_DETAILS_FAILURE";
export const FETCH_USER_ATTRIBUTE_DETAILS = "FETCH_USER_ATTRIBUTE_DETAILS";
export const UPDATE_USER_ATTRIBUTE_DETAILS = "UPDATE_USER_ATTRIBUTE_DETAILS";
export const INITIAL_USER_ATTRIBUTE_DETAILS = "INITIAL_USER_ATTRIBUTE_DETAILS";
export const ADD_USER_ATTRIBUTE_DETAILS = "ADD_USER_ATTRIBUTE_DETAILS";
export const ADD_USER_ATTRIBUTE_DETAILS_SUCCESS =
  "ADD_USER_ATTRIBUTE_DETAILS_SUCCESS";
export const UPDATE_USER_ATTRIBUTE_DATA = "UPDATE_USER_ATTRIBUTE_DATA";
export const UPDATE_USER_ATTRIBUTE_DATA_SUCCESS =
  "UPDATE_USER_ATTRIBUTE_DATA_SUCCESS";
export const DELETE_USER_ATTRIBUTE_DETAILS = "DELETE_USER_ATTRIBUTE_DETAILS";
export const DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS =
  "DELETE_USER_ATTRIBUTE_DETAILS_SUCCESS";

// update agent profile
export const UPDATE_AGENT_PROFILE_DETAIL = "UPDATE_AGENT_PROFILE_DETAIL";
export const UPDATE_AGENT_PROFILE_SUCCESS = "UPDATE_AGENT_PROFILE_SUCCESS";
export const UPDATE_AGENT_PROFILE_FAILURE = "UPDATE_AGENT_PROFILE_FAILURE";
// fetch agent  data
export const FETCH_UPDATE_AGENT_DETAIL = "FETCH_UPDATE_AGENT_DETAIL";
export const FETCH_UPDATE_AGENT_SUCCESS = "FETCH_UPDATE_AGENT_SUCCESS";
export const FETCH_UPDATE_AGENT_FAILED = "FETCH_UPDATE_AGENT_FAILED";

//Billing
export const BILLING_DATE_RANGE_STATUS = "BILLING_DATE_RANGE_STATUS";
export const BILLING_DATE_RANGE = "BILLING_DATE_RANGE_STATUS";
export const BILLING_CREDIT_AMOUNT_STATUS = "BILLING_CREDIT_AMOUNT_STATUS";
export const BILLING_CREDIT_AMOUNT = "BILLING_CREDIT_AMOUNT_STATUS";
export const BILLING_PAYMANT_PLANS_LIST_STATUS =
  "BILLING_PAYMANT_PLANS_LIST_STATUS";
export const BILLING_PAYMANT_PLANS_LIST = "BILLING_PAYMANT_PLANS_LIST";
export const BILLING_USER_COUNT_STATS = "BILLING_USER_COUNT_STATS";
export const BILLING_USER_COUNT_STATUS = "BILLING_USER_COUNT_STATUS";
export const BILLING_NOTIFICATION_COUNT_STATUS =
  "BILLING_NOTIFICATION_COUNT_STATUS";
export const BILLING_NOTIFICATION_COUNT = "BILLING_NOTIFICATION_COUNT";
export const FETCH_NEW_BUSINESS = "FETCH_NEW_BUSINESS";
export const FETCH_NEW_BUSINESS_STATUS = "FETCH_NEW_BUSINESS_STATUS";

//Analytics
export const FETCH_ANALYTICS_OVERVIEW_STATS = "FETCH_ANALYTICS_OVERVIEW_STATS";
export const FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS =
  "FETCH_ANALYTICS_OVERVIEW_STATS_SUCCESS";
export const FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE =
  "FETCH_ANALYTICS_OVERVIEW_STATS_FAILURE";
export const FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS =
  "FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS";
export const FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS =
  "FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_SUCCESS";
export const FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE =
  "FETCH_ANALYTICS_AGENT_PERFORMANCE_STATS_FAILURE";
export const ANALYTICS_BODY_DETAILS = "ANALYTICS_BODY_DETAILS";
export const ANALYTICS_AGENT_PERFORMANCE_DETAILS =
  "ANALYTICS_AGENT_PERFORMANCE_DETAILS";

// Billings Int

export const LIST_BILLING_PAYMENT_METHOD = "LIST_BILLING_PAYMENT_METHOD";
export const LIST_BILLING_PAYMENT_METHOD_SUCCESS =
  "LIST_BILLING_PAYMENT_METHOD_SUCCESS";
export const LIST_BILLING_PAYMENT_METHOD_FAILURE =
  "LIST_BILLING_PAYMENT_METHOD_FAILURE";
export const ADD_BILLING_PAYMENT_METHOD = "ADD_BILLING_PAYMENT_METHOD";
export const ADD_BILLING_PAYMENT_METHOD_SUCCESS =
  "ADD_BILLING_PAYMENT_METHOD_SUCCESS";
export const ADD_BILLING_PAYMENT_METHOD_FAILURE =
  "ADD_BILLING_PAYMENT_METHOD_FAILURE";
export const LIST_BILLING_ADDRESS_STATUS = "LIST_BILLING_ADDRESS_STATUS";
export const LIST_BILLING_ADDRESS_SUCCESS = "LIST_BILLING_ADDRESS_SUCCESS";
export const LIST_BILLING_ADDRESS_FAILURE = "LIST_BILLING_ADDRESS_FAILURE";
export const ADD_NEW_BILLING_ADDRESS_STATUS = "ADD_NEW_BILLING_ADDRESS_STATUS";
export const ADD_NEW_BILLING_ADDRESS_SUCCESS =
  "ADD_NEW_BILLING_ADDRESS_SUCCESS";
export const ADD_NEW_BILLING_ADDRESS_FAILURE =
  "ADD_NEW_BILLING_ADDRESS_FAILURE";
export const BILLING_ADDRESS_DETAILS_UPDATE = "BILLING_ADDRESS_DETAILS_UPDATE";
export const NEW_BILLING_ADDRESS_UPDATE_SUCCESS =
  "NEW_BILLING_ADDRESS_UPDATE_SUCCESS";
export const NEW_BILLING_ADDRESS_UPDATE_STATUS =
  "NEW_BILLING_ADDRESS_UPDATE_STATUS";
export const NEW_BILLING_ADDRESS_UPDATE_FAILURE =
  "NEW_BILLING_ADDRESS_UPDATE_FAILURE";
export const BILLING_PLAN_LIST_STATUS = "BILLING_PLAN_LIST_STATUS";
export const BILLING_PLAN_LIST_SUCCESS = "BILLING_PLAN_LIST_SUCCESS";
export const BILLING_PLAN_LIST_FAILURE = "BILLING_PLAN_LIST_FAILURE";
export const ADD_NEW_BILLING_SUBSCRIPTION = "ADD_NEW_BILLING_SUBSCRIPTION";
export const ADD_NEW_BILLING_SUBSCRIPTION_SUCCESS =
  "ADD_NEW_BILLING_SUBSCRIPTION_SUCCESS";
export const ADD_NEW_BILLING_SUBSCRIPTION_FAILURE =
  "ADD_NEW_BILLING_SUBSCRIPTION_FAILURE";
export const ACTIVE_SUBSCRIPTION_LIST_DETAILS =
  "ACTIVE_SUBSCRIPTION_LIST_DETAILS";
export const ACTIVE_SUBSCRIPTION_LIST_SUCCESS =
  "ACTIVE_SUBSCRIPTION_LIST_SUCCESS";
export const FETCH_BILLING_STATS = "FETCH_BILLING_STATS";
export const FETCH_BILLING_STATS_STATUS = "FETCH_BILLING_STATS_STATUS";

// Push Device
export const DETAILS_PUSH_DEVICE_STATUS = "DETAILS_PUSH_DEVICE_STATUS";
export const LIST_PUSH_DEVICE_SUCCESS = "LIST_PUSH_DEVICE_SUCCESS";
export const ADD_PUSH_DEVICE_SUCCESS = "ADD_PUSH_DEVICE_SUCCESS";
export const UPDATE_PUSH_DEVICE_SUCCESS = "UPDATE_PUSH_DEVICE_SUCCESS";
export const DELETE_PUSH_DEVICE_SUCCESS = "DELETE_PUSH_DEVICE_SUCCESS";
export const UPDATE_PUSH_DEVICE_SOUND = "UPDATE_PUSH_DEVICE_SOUND";

// Business
export const UPDATE_BUSINESS_DETAILS = "UPDATE_BUSINESS_DETAILS";
export const UPDATE_BUSINESS_ADMIN_DETAILS = "UPDATE_BUSINESS_ADMIN_DETAILS";
export const UPDATE_BUSINESS_DETAILS_EMPTY = "UPDATE_BUSINESS_DETAILS_EMPTY";
export const UPDATE_BUSINESS_DATA = "UPDATE_BUSINESS_DATA";
export const FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS =
  "FETCH_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS";
export const FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS =
  "FETCHING_WORKING_HOURS_FROM_LIVE_CHAT_SETTINGS";
export const UPDATE_WORKING_HOURS = "UPDATE_WORKING_HOURS";
export const UPDTAE_LIVE_CHAT_SETTINGS_CONFIG =
  "UPDTAE_LIVE_CHAT_SETTINGS_CONFIG";
export const UPDTAING_LIVE_CHAT_SETTINGS_CONFIG =
  "UPDTAING_LIVE_CHAT_SETTINGS_CONFIG";
export const FETCHING_LIVE_CHAT_SETTINGS = "FETCHING_LIVE_CHAT_SETTINGS";
export const UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY =
  "UPDATE_LIVE_CHAT_SETTINGS_AUTO_REPLY";
export const FETCH_ALL_TAG_CATEGORIES = "FETCH_ALL_TAG_CATEGORIES";
export const ADD_NEW_TAG_CATEGORY = "ADD_NEW_TAG_CATEGORY";
export const FETCH_PREDIFINED_TEMPLATES = "FETCH_PREDIFINED_TEMPLATES";
export const UPDATE_TEMPLATE_MESSAGE = "UPDATE_TEMPLATE_MESSAGE";

// campaign optOut
export const UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY =
  "UPDATE_IS_OPTED_OUT_AUTOMATE_REPLY";
export const OPT_OUT_KEYWORDS = "OPT_OUT_KEYWORDS";
export const UPDATING_OPT_OUT_CONFIG_DATA = "UPDATING_OPT_OUT_CONFIG_DATA";
export const UPDATE_OPT_OUT_CONFIG_DATA = "UPDATE_OPT_OUT_CONFIG_DATA";
export const UPDATE_OPT_IN_CONFIG_DATA = "UPDATE_OPT_IN_CONFIG_DATA";
export const OPT_IN_KEYWORDS = "OPT_IN_KEYWORDS";
//quality rating
export const GET_QUALITY_RATING = "GET_QUALITY_RATING";
export const FETCH_CONVERSATION_USAGE = "FETCH_CONVERSATION_USAGE";
export const FETCH_CONVERSATION_CREDIT_USAGE =
  "FETCH_CONVERSATION_CREDIT_USAGE";
