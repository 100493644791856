import React from "react";

import { StyledItem } from "components/Smartcards/smartCardStyles";
import styled from "styled-components";
import Box from "../../../../../konnekt/Box";

export const LiveChatProfilesText = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: space-between;
  color: ${props => props.color};
`;

const ViewItem = ({ item }) => {
  const renderValue = () => {
    if (typeof item.value === "string" || typeof item.value === "number") {
      return item.value;
    }

    if (typeof item.value === "object") {
      return Object.values(item.value).join(", ");
    }

    return "";
  };

  return (
    <StyledItem key={item.keyName}>
      <LiveChatProfilesText color="#757575">{item?.keyName}</LiveChatProfilesText>
      <Box fontSize="12px" color="#212121">{renderValue()}</Box>
    </StyledItem>
  );
};

export default ViewItem;
