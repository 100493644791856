import React from "react";
import styled from "styled-components";
import { space, color, layout, position, border } from "styled-system";

const Container = styled.div`
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;

  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};

  ${space}
  ${color}
  ${layout}
  ${position}
  ${border}
`;

const SmartCardHeader = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default SmartCardHeader;
