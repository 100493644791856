import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Modal, { ModalHeader } from "konnekt/Modal";
import TextInput from "konnekt/TextInput";
import Button from "konnekt/Buttons";
import Label from "konnekt/Label";
import { addTagDetails } from "modules/Manage/ManagePageAction";
import {
  FormContainer,
  ModalContainer,
} from "CommonStyle/CommonStyles";

const ModalFooterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: right;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default function CreateAndAddTag({ onHide, updateTag, customerTags }) {
  const dispatch = useDispatch();
  const [tagName, setTagName] = React.useState({ value: "", error: "" });
  const addTagToCustomer = (newTag) => {
    updateTag({ tags: [...customerTags, newTag?._id] });
    onHide();
  };
  const handleOnSave = () => {
    if (tagName.value === "") {
      setTagName({
        ...tagName,
        error: "Please enter valid tag name.",
      });
      return;
    }
    dispatch(
      addTagDetails(
        {
          name: tagName.value,
          category: "",
          customer_journey: "",
          primary_message: "",
        },
        addTagToCustomer
      )
    );
  };

  return (
    <Modal isOpen onHide={onHide} pl="24" pr="24">
      <ModalHeader variant="white" onHide={onHide} padding="0">
        Create & add tag
      </ModalHeader>
      <ModalContainer>
        <FormContainer>
          <Label variant="text4" mb="12px">
            Tag Name
          </Label>
          <TextInput
            type="text"
            onChange={(value) => setTagName({ value, error: "" })}
            value={tagName.value}
            width="100%"
            bodyTextLimit={24}
            placeholder="Tag's Name"
            border="1px solid rgba(0,0,0,.08)"
            height="44px"
          />
          {tagName.error && <p style={{ color: "red" }}>{tagName.error}</p>}
        </FormContainer>
      </ModalContainer>
      <ModalFooterItem>
        <Button type="outline" text={"Cancel"} onClick={onHide} />
        <Button
          type="primary"
          text="Create & Add"
          marginLeft="10px"
          onClick={handleOnSave}
        />
      </ModalFooterItem>
    </Modal>
  );
}
