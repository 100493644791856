import styled from "styled-components";
import { space, layout, grid, flexbox } from "styled-system";
import Label from "konnekt/Label";
import CustomDatePicker from "components/CustomDatePicker";
import Checkbox from "konnekt/Checkbox";

export const StyledBoxContainer = styled("div")`
  border: 1px solid #989898;
  border-radius: 6px;
  margin: 20px 0px;
  ${space}
`;
export const StyledFlexContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 23px;
  grid-gap: 15px;
  ${space}
  ${grid}
  ${flexbox}
`;
export const StyledCheckBox = styled(Checkbox)`
  width: 18px;
  height: 18px;
  margin-top: 4px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 5px;
  }
`;
export const StyledInput = styled("input")`
  border: 1px solid black;
  outline: none;
  height: 35px;
  border-radius: 0.25rem;
  border: ${({ disabled }) =>
    disabled ? "1px solid #989898" : "1px solid black"};
  color: ${({ disabled }) => (disabled ? "#989898" : "#000000CC")};
  caret-color: ${({ isEditable, disabled }) => {
    if (isEditable && !disabled) {
      return "#000000CC";
    }
    if (isEditable && disabled) {
      return "#989898";
    }
    return "transparent";
  }};
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${layout}
`;
export const StyledAddLabel = styled(Label)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  width: fit-content;
`;
export const StyledCustomDatePicker = styled(CustomDatePicker)`
  max-width: 125px;
  height: 35px;
  padding: 6px;
  margin: 0;
  border: 1px solid #222222;
`;
