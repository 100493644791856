import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import KiwiSvgImage from "../KiwiSvgImage";
import _ from "lodash";
import { showNotification } from "../../util/utility";
import keyCode from "constants/keyboard-codes.json";

class AddTagForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      tagList: [],
      isShowDropdown: false,
      searchText: "",
      keyUpDownIndex: -1,
      isCommaExist: false,
    };
    this.searchTag = React.createRef();
  }

  componentDidMount() {
    if (this.props.show) {
      this.searchTag.focus();
      this._isMounted = true;
    }
    if (!_.isEmpty(this.props.oldTags)) {
      this.setState({
        selectedTags: this.props.oldTags,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerTags &&
      _.isArray(this.props.customerTags) &&
      !_.isEqual(prevProps.customerTags, this.props.customerTags)
    ) {
      this.setState(
        {
          selectedTags: [],
        },
        () => {
          this.getTagOptions(this.state.searchText);
        },
      );
    }
    if (!_.isEqual(prevProps.tagList, this.props.tagList)) {
      this.getTagOptions(this.state.searchText);
    }
    if (
      prevProps.isUpdatingCustomerTags &&
      !this.props.isUpdatingCustomerTags
    ) {
      this.props.onHide();
    }
    if (
      prevProps.isUpdatingCustomerTags &&
      !this.props.isUpdatingCustomerTags
    ) {
      // showNotification('succ')
    }
    if (!prevProps.show && this.props.show) {
      this.searchTag.focus();
      this._isMounted = true;
    }
    if (prevProps.show && !this.props.show) {
      this._isMounted = false;
      window.onkeydown = null;
    }
    if (
      (_.isEmpty(prevProps.oldTags) && !_.isEmpty(this.props.oldTags)) ||
      !_.isEqual(prevProps.customerId, this.props.customerId) ||
      (!prevProps.show && this.props.show)
    ) {
      this.setState({
        selectedTags: this.props.oldTags,
      });
    }
  }

  getFilteredTagList = (tagList, selectedTags) => {
    selectedTags.forEach((id) => {
      tagList = tagList.filter((tag) => tag.id !== id);
    });
    return tagList;
  };

  handleSelectTag = (tag) => {
    let selectedTags = [...this.state.selectedTags];
    selectedTags.push(tag.id);
    let tagList = [...this.state.tagList];
    tagList = tagList.filter((item) => item.id !== tag.id);
    this.setState(
      {
        selectedTags: selectedTags,
        tagList: tagList,
        // isShowDropdown: false,
        searchText: "",
      },
      () => {
        this.getTagOptions();
        // this.handleAddCustomerTag(tag.id);
      },
    );
  };

  handleRemoveTag = (tag) => {
    let selectedTags = [...this.state.selectedTags];
    selectedTags = selectedTags.filter((id) => id !== tag.id);
    let tagList = [...this.state.tagList];
    tagList.push(tag);
    this.setState({
      selectedTags: selectedTags,
      tagList: tagList,
    });
  };

  getTagOptions = (input) => {
    let list = this.props.tagList ? this.props.tagList : [];
    let isCommaExist = input ? (input.match(/\,/g) || []).length > 0 : false;

    if (!!!input) {
      this.setState({
        tagList: this.getFilteredTagList(list, this.state.selectedTags),
        searchText: "",
        isShowDropdown: true,
        isCommaExist: false,
      });
    } else if (isCommaExist) {
      this.setState({
        tagList: [],
        searchText: input,
        isShowDropdown: false,
        isCommaExist: isCommaExist,
      });
    } else {
      let rgx = new RegExp(input, "i");
      let tagList = [...list];
      tagList = this.getFilteredTagList(tagList, this.state.selectedTags);
      tagList = tagList.filter((item) => !!item.name.match(rgx));
      this.setState({
        tagList: tagList,
        searchText: input,
        isShowDropdown: true,
        isCommaExist: false,
        keyUpDownIndex: tagList.length === 0 ? 0 : this.state.keyUpDownIndex,
      });
      // return Promise.resolve({options: tagList});
    }
  };

  handleToggleDropdown = (e, state) => {
    e.preventDefault();
    e.stopPropagation();
    let tagList = this.getFilteredTagList(
      [...this.props.tagList],
      [...this.state.selectedTags],
    );
    this.setState({
      isShowDropdown: !!state,
      tagList: tagList ? tagList : [],
    });
  };

  handleAddCustomerTag = (tagId) => {
    this.props.handleUpdateCustomerTag(tagId);
  };

  handleUpdateTags = () => {
    let updatedCustomer = { tags: [...this.state.selectedTags] };
    this.props.updateCustomer(updatedCustomer);
  };

  handleCreateNewTag = () => {
    let tag = {};
    tag.name = this.state.searchText;
    this.props.createNewTag(tag);
  };

  shouldCreateNewTag = () => {
    return (
      this.props.tagList &&
      this.state.isShowDropdown &&
      this.state.searchText &&
      this.props.tagList.filter(
        (item) =>
          item.name.toLowerCase() === this.state.searchText.toLowerCase(),
      ).length === 0
    );
  };

  tagAllUsers = () => {
    if (this.state.selectedTags && this.state.selectedTags.length > 0) {
      this.props.handleAddNewtagsUser([...this.state.selectedTags]);
    } else {
      showNotification("warning", "Please select a tag");
    }
  };

  handlePressEnter = () => {
    if (
      this.state.keyUpDownIndex === this.state.tagList.length &&
      this.state.searchText !== "" &&
      this.shouldCreateNewTag()
    ) {
      this.handleCreateNewTag();
    } else if (
      this.state.keyUpDownIndex > -1 &&
      this.state.tagList.length > 0 &&
      this.state.isShowDropdown
    ) {
      this.state.tagList.forEach((item, index) => {
        if (this.state.keyUpDownIndex === index) {
          this.handleSelectTag(item);
        }
      });
    } else if (this.state.searchText === "") {
      this.tagAllUsers();
    }
  };

  handleKeyPress = (e) => {
    if (this.props.isUpdatingCustomerTags) {
      e.preventDefault();
    } else if (e.charCode === keyCode.ENTER) {
      this.handlePressEnter();
    } else if (e.charCode === keyCode.UP_ARROW) {
      this.setState({
        keyUpDownIndex:
          this.state.keyUpDownIndex > 0
            ? this.state.keyUpDownIndex - 1
            : this.state.tagList.length,
      });
    } else if (e.charCode === keyCode.DOWN_ARROW) {
      this.setState({
        keyUpDownIndex:
          this.state.keyUpDownIndex > this.state.tagList.length - 1
            ? 0
            : this.state.keyUpDownIndex + 1,
      });
    }
  };

  getButtonName = () => {
    if (this.props.userCount) {
      if (this.props.userCount > 1) {
        return `Tag ${this.props.userCount} Users`;
      } else {
        return "Tag 1 User";
      }
    } else {
      return "Tag User";
    }
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          border: "none",
          margin: "auto",
          padding: "0 15px 0 15px",
          borderRadius: "6px",
          boxShadow: "4px 4px 4px rgba(166, 166, 166, 0.25)",
        }}
      >
        <Modal.Header style={{ backgroundColor: "#278351", color: "#fff" }}>
          <Modal.Title className="full-width">
            <div className="d-flex justify-content-between align-items-center full-width">
              <span>
                <span>
                  <svg
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.938 0H8.93801C8.52601 0 7.94901 0.239 7.65801 0.53L0.219014 7.969C0.0787466 8.10993 0 8.30067 0 8.4995C0 8.69833 0.0787466 8.88907 0.219014 9.03L6.65801 15.469C6.79894 15.6093 6.98968 15.688 7.18851 15.688C7.38735 15.688 7.57809 15.6093 7.71901 15.469L15.158 8.03C15.45 7.738 15.688 7.162 15.688 6.75V0.75C15.6872 0.551331 15.608 0.361024 15.4675 0.220543C15.327 0.0800612 15.1367 0.000789946 14.938 0V0ZM11.188 6C10.991 5.99993 10.7959 5.96106 10.6138 5.88559C10.4318 5.81012 10.2664 5.69954 10.1271 5.56016C9.98786 5.42078 9.87739 5.25533 9.80204 5.07325C9.72669 4.89118 9.68795 4.69605 9.68801 4.499C9.68808 4.30195 9.72696 4.10685 9.80242 3.92482C9.87789 3.7428 9.98847 3.57742 10.1279 3.43813C10.2672 3.29884 10.4327 3.18837 10.6148 3.11303C10.7968 3.03768 10.992 2.99893 11.189 2.999C11.587 2.99913 11.9686 3.15735 12.2499 3.43884C12.5312 3.72033 12.6891 4.10204 12.689 4.5C12.6889 4.89796 12.5307 5.27956 12.2492 5.56087C11.9677 5.84217 11.586 6.00013 11.188 6Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
                <span style={{ fontSize: "18px", marginLeft: "10px" }}>
                  Tags
                </span>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => this.props.onHide()}
              >
                <KiwiSvgImage targetClass="" iconName="close.svg" />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 0 }}>
          <div style={{ minHeight: "250px" }}>
            {/*<label htmlFor="">Search Tag here</label>*/}
            <div style={{ position: "relative" }}>
              <div
                style={{
                  padding: "3px",
                  borderBottom: "1px solid #C4C4C4",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <span style={{ paddingLeft: "10px", display: "flex" }}>
                  <KiwiSvgImage
                    targetClass=""
                    style={{ width: "20px" }}
                    iconName="search.svg"
                  />
                </span>
                {this.state.selectedTags.map((id) => (
                  <span className="multi-select-input-tag">
                    <span>
                      {this.props.allTags &&
                      this.props.allTags[id] &&
                      this.props.allTags[id].name
                        ? this.props.allTags[id].name
                        : ""}
                    </span>
                    <span
                      onClick={() =>
                        this.handleRemoveTag(this.props.allTags[id])
                      }
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        marginLeft: "3px",
                        width: "18px",
                        height: "18px",
                        backgroundColor: "#A5A5A5",
                        borderRadius: "50%",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        style={{
                          height: "14px",
                          width: "14px",
                        }}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.705 1.70501L10.295 0.295013L6.00001 4.59001L1.70501 0.295013L0.295013 1.70501L4.59001 6.00001L0.295013 10.295L1.70501 11.705L6.00001 7.41001L10.295 11.705L11.705 10.295L7.41001 6.00001L11.705 1.70501Z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                  </span>
                ))}
                <div style={{ position: "relative", flex: 1 }}>
                  <input
                    ref={(ref) => (this.searchTag = ref)}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      height: "40px",
                      outline: "none",
                      minWidth: "200px",
                    }}
                    placeholder="Search tag or create a new tag"
                    disabled={this.props.isCreatingOrganizationTag}
                    value={this.state.searchText}
                    onFocus={(e) => this.handleToggleDropdown(e, true)}
                    onChange={(e) => this.getTagOptions(e.target.value)}
                    type="text"
                    onKeyPress={(e) => this.handleKeyPress(e)}
                  />
                </div>
              </div>
            </div>
            {((this.state.isShowDropdown &&
              this.state.tagList &&
              this.state.tagList.length > 0) ||
              this.shouldCreateNewTag()) &&
            !this.state.isCommaExist ? (
              <div
                className="hide-scrollbar"
                style={{
                  backgroundColor: "#fff",
                  zIndex: 100,
                  color: "#777777",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {this.state.tagList.map((item, index) => (
                  <div
                    className={`search-tag-list-item ${
                      this.state.keyUpDownIndex === index
                        ? "search-tag-list-item-hover"
                        : ""
                    }`}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      paddingLeft: "45px",
                    }}
                    onClick={() => this.handleSelectTag(item)}
                  >
                    {item.name}
                  </div>
                ))}
                {this.shouldCreateNewTag() ? (
                  <div
                    className={`search-tag-list-item ${
                      this.state.keyUpDownIndex === this.state.tagList.length
                        ? "search-tag-list-item-hover"
                        : ""
                    }`}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      paddingLeft: "45px",
                    }}
                    onClick={() => this.handleCreateNewTag()}
                  >
                    {`Create new Tag: ${this.state.searchText}${
                      this.props.isCreatingOrganizationTag
                        ? "        creating..."
                        : ""
                    }`}
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                {!this.state.isCommaExist &&
                this.state.isShowDropdown &&
                this.state.searchText !== "" ? (
                  <div className="text-center alert-danger">
                    Tag Already Exist
                  </div>
                ) : this.state.isCommaExist ? (
                  <div className="text-center alert-danger">
                    Comma(,) is not allowed in Tags
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            disabled={this.props.isUpdatingCustomerTags}
            type="button"
            className="btn btn-green"
            onClick={() => this.tagAllUsers()}
          >
            {this.props.isUpdatingCustomerTags
              ? "...Adding"
              : `${this.getButtonName()}`}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddTagForm;
