// update agent profile
export const UPDATE_AGENT_PROFILE_DETAIL = "UPDATE_AGENT_PROFILE_DETAIL";
export const UPDATE_AGENT_PROFILE_SUCCESS = "UPDATE_AGENT_PROFILE_SUCCESS";
export const UPDATE_AGENT_PROFILE_FAILURE = "UPDATE_AGENT_PROFILE_FAILURE";
// fetch agent  data
export const FETCH_UPDATE_AGENT_DETAIL = "FETCH_UPDATE_AGENT_DETAIL";
export const FETCH_UPDATE_AGENT_SUCCESS = "FETCH_UPDATE_AGENT_SUCCESS";
export const FETCH_UPDATE_AGENT_FAILED = "FETCH_UPDATE_AGENT_FAILED";

export const UPDATE_AGENT_PASSWORD_SUCCESS = "UPDATE_AGENT_PASSWORD_SUCCESS";
export const UPDATE_AGENT_PASSWORD = "UPDATE_AGENT_PASSWORD";
export const UPDATE_AGENT_PASSWORD_FAILED = "UPDATE_AGENT_PASSWORD_FAILED";
