import React from "react";
import styled from "styled-components";
import { space, color, layout, position } from "styled-system";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #f2f2f2;
  cursor: pointer;

  & > p {
    margin: 0;
    font-size: 14px;
    line-height: 1;
  }

  ${space}
  ${color}
  ${layout}
  ${position}
`;

const SmartCardFooter = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default SmartCardFooter;
