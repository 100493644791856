import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import Icon from "konnekt/Icon";

import useMediaQuery from "customHooks/useMediaQuery";

const Container = styled.div`
  background-color: #adb2bf;
  padding: 8px 14px;
  margin-right: 8px;
  margin-bottom: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 16px;

  & > p {
    font-family: Roboto, sans-serif;
    line-height: 1.2;
    color: white;
    margin: 0;
    word-break: break-word;

    @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Tag = ({ children, id, onCloseClick, ...rest }) => {
  const isDesktop = useMediaQuery("desktop", "up");

  return (
    <Container {...rest}>
      <Icon
        transform="rotate(-135deg)"
        width="15px"
        color="white"
        iconName="tag"
        mr="8px"
      />

      <p data-tip data-for={`tag${id}`}>
        {children}
      </p>

      <ReactTooltip
        className="tooltip special-tooltip"
        multiline
        id={`tag${id}`}
        place="bottom"
        effect="solid"
      >
        {children}
      </ReactTooltip>

      <Icon
        onClick={() => onCloseClick({ id, action: "remove" })}
        width="8px"
        height="8px"
        color="white"
        iconName="close"
        ml="8px"
      />
    </Container>
  );
};

export default Tag;
