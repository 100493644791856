import React, { useState, useEffect } from "react";
import {v4 as uuidv4} from 'uuid';
import { useSelector, useDispatch } from "react-redux";
import ViewItem, { LiveChatProfilesText } from "./ViewItem";
import { StyledList } from "components/Smartcards/smartCardStyles";
import { StyledItem } from "components/Smartcards/smartCardStyles";
import {
  EMAIL,
  ID,
  NAME,
  PHONE_NUMBER,
  TAGS,
  WHATSAPP_OPTED_IN,
} from "constants/userAttributesContants";
import { getActiveConversation } from "../../../../../modules/Inbox/ThreadReducer";
import { getFormattedTime } from "../../../../../util/timeUtility";
import Box from "../../../../../konnekt/Box";
import Buttons from "../../../../../konnekt/Buttons";
import TextInput from "../../../../../konnekt/TextInput";
import {
  updateUserDetails,
  updateActiveCustomerDetails,
} from "../../../../../modules/Customer/CustomerActions";
import Checkbox from "../../../../../konnekt/Checkbox";
import Label from "../../../../../konnekt/Label";
import { useLocation } from "react-router-dom";
import useActiveChat from "../../../../../customHooks/useChatCustomer";
import { getAgentName } from "../../../../../util/chatUtil";
import Modal, { ModalHeader } from "konnekt/Modal";
import {
  DeleteMessageText,
  FlexCenterGap,
  UnOrderList,
} from "CommonStyle/CommonStyles";
import Button from "../../../../../konnekt/Buttons";

const ViewList = ({
  userDetailList,
  activeCustomer,
  activeCustomerNumber,
  edit,
  setEdit,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [name, updateName] = useState("");
  const [email, updateEmail] = useState("");
  const [opted, updateOpted] = useState(null);
  const [optedModal, setOptedModal] = useState(false);
  let activeConversation = null;

  if (location.pathname.includes("/contacts/")) {
    activeConversation = useSelector((state) => useActiveChat(state));
  } else {
    activeConversation = useSelector((state) => getActiveConversation(state));
  }

  const SelectedCustomerId = useSelector(
    (state) => state.customer?.activeCustomerAllUsersPage
  );

  const { activeSmartCard } = useSelector(
    (state) => state.inboxState.smartCard
  );
  // const agents = useSelector(getAllAgents);
  const agents = useSelector((state) => state.manage.teamSetting?.agents);
  useEffect(() => {
    updateName("");
    updateEmail("");
    if (activeCustomer?.contact_name) {
      updateName(activeCustomer?.contact_name);
    }
    if (activeCustomer?.contact_email) {
      updateEmail(activeCustomer?.contact_email);
    }
    if (activeCustomer?.is_wa_opt_in) {
      updateOpted(activeCustomer?.is_wa_opt_in);
    }
  }, [activeCustomer, SelectedCustomerId]);

  const updateTraits = (keyName, keyValue) => {
    if (keyName === "name") {
      updateName(keyValue);
    }
    if (keyName === "email") {
      updateEmail(keyValue);
    }
    if (keyName === "opted") {
      updateOpted(keyValue);
    }
  };

  const handleSave = () => {
    setEdit(false);
    dispatch(updateUserDetails(name, email, opted));
  };

  const handleCancel = () => {
    setEdit(false);
    updateName(activeCustomer?.contact_name);
    updateEmail(activeCustomer?.contact_email);
  };

  const handleWhatsappOpted = () => {
    const { project_id, _id, is_wa_opt_in } = activeCustomer;
    dispatch(
      updateActiveCustomerDetails(project_id, _id, {
        customer: {
          is_wa_opt_in: !is_wa_opt_in,
          wa_message_id: uuidv4()
        },
      })
    );
    setOptedModal(false);
  };

  const handleCloseModal = () => setOptedModal(false);

  const renderStaticFields = () => {
    if (edit) {
      return (
        <Box mt="-12px">
          <Box fontSize="12px">
            <Box
              pb="13px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box fontWeight="600" color="#757575">
                Name{" "}
              </Box>
              <Box>
                <TextInput
                  type="text"
                  onChange={(e) => updateTraits("name", e)}
                  value={name}
                  fontSize="11px"
                  placeholder="Enter name"
                  width="175px"
                  border="1px solid rgba(0,0,0,.08)"
                  height="36x"
                />
              </Box>
            </Box>
            <Box
              pb="8px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box fontWeight="600" color="#757575">
                Email{" "}
              </Box>
              <Box>
                <TextInput
                  type="text"
                  onChange={(e) => updateTraits("email", e)}
                  value={email}
                  placeholder="Enter email"
                  width="175px"
                  border="1px solid rgba(0,0,0,.08)"
                  fontSize="11px"
                  height="36x"
                />
              </Box>
            </Box>
            <Box
              pb="8px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box fontWeight="600" color="#757575">
                WhatsApp Opted
              </Box>
              <Box display="flex" alignItems="center" mt="5px">
                <Checkbox
                  type="radio"
                  iconSize="tiny"
                  checked={opted === true}
                  onChange={() => updateTraits("opted", true)}
                />
                <Label
                  fontSize="12px"
                  mr="14px"
                  ml="6px"
                  color="darkGrey.60"
                  text="Yes"
                />
                <Checkbox
                  type="radio"
                  iconSize="tiny"
                  className="mr-left"
                  checked={opted === false}
                  onChange={() => updateTraits("opted", false)}
                />
                <Label fontSize="12px" ml="6px" color="darkGrey.60" text="No" />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            my="8px"
            mt="30px"
          >
            <Buttons
              minHeight="25px"
              minWidth="40px"
              text="Save"
              fontSize="10px"
              onClick={() => handleSave()}
            />
            <Buttons
              minHeight="25px"
              minWidth="40px"
              ml="25px"
              cursor="pointer"
              fontSize="10px"
              onClick={() => handleCancel()}
            >
              Cancel
            </Buttons>
          </Box>
        </Box>
      );
    }
    return (
      <>
        <ViewItem
          item={{
            keyName: "Status",
            value: activeConversation?.conversation_status || "-",
          }}
        />
        {activeConversation?.conversation_status === "intervened" && (
          <ViewItem
            item={{
              keyName: "Intervened by",
              value: getAgentName(activeConversation?.active_agent_id, agents),
            }}
          />
        )}
        <ViewItem
          item={{
            keyName: "Last Active",
            value:
              getFormattedTime(
                activeConversation?.last_seen_timestamp,
                "DD MMM, hh:mm a"
              ) || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Template Message",
            value: activeConversation?.template_message_count || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Session Message",
            value: activeConversation?.session_message_count || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Source",
            value: activeCustomer?.customer_source || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Lead State",
            value: activeConversation?.conversation_status || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "First Message",
            value: activeCustomer?.first_message || "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Contact",
            value: activeCustomer?.contact_phone
              ? `${activeCustomer.country_code}${activeCustomer.contact_phone}`
              : `${activeConversation?.customer_id?.country_code}${activeConversation?.customer_id?.contact_phone}` ||
                "-",
          }}
        />
        <ViewItem
          item={{
            keyName: "Email",
            value: email
              ? email
              : activeConversation?.customer_id?.contact_email || "-",
          }}
        />
        <StyledItem>
          <LiveChatProfilesText color="#757575">
            WhatsApp Opted
          </LiveChatProfilesText>
          <Box>
            <Checkbox
              type="switch"
              checked={Boolean(activeCustomer?.is_wa_opt_in)}
              onChange={() => setOptedModal(!optedModal)}
            />
          </Box>
        </StyledItem>
      </>
    );
  };

  const renderList = () => {
    if (userDetailList && activeSmartCard.name) {
      const traitsToNotRender = [
        ID,
        TAGS,
        WHATSAPP_OPTED_IN,
        NAME,
        PHONE_NUMBER,
        EMAIL,
      ];

      return userDetailList
        .filter(
          (item) => !traitsToNotRender.includes(item.keyName) && item.value
        )
        .map((item) => <ViewItem key={item.keyName} item={item} />);
    }

    return null;
  };

  return (
    <>
      <StyledList
        borderTopLeftRadius={!!activeSmartCard.name ? "8px" : "0"}
        borderTopRightRadius={!!activeSmartCard.name ? "8px" : "0"}
        p={!!activeSmartCard.name ? "21px 18px 12px" : "0"}
        m={{ _: activeSmartCard.name ? "0 18px 18px 18px" : "0", desktop: 0 }}
      >
        {renderStaticFields()}
        {renderList()}
      </StyledList>
      {/* Opted in modal */}
      <Modal isOpen={optedModal} onHide={handleCloseModal} pl="24" pr="24">
        <ModalHeader
          variant="white"
          onHide={handleCloseModal}
          padding="0"
          border-bottom="none"
          border="none"
        >
          {activeCustomer?.is_wa_opt_in ? "Opt-Out" : "Opt-In"}
          {""} to {activeCustomer?.contact_name}
        </ModalHeader>
        <UnOrderList>
          <li>
            {activeCustomer?.is_wa_opt_in
              ? `Choosing this option will signify the user's preference to unsubscribe from future campaigns/notifications.`
              : `By selecting this option, the user indicates their willingness to receive future campaigns/notifications.`}
          </li>
          <li>
            This choice will help us better categorize recipients, reducing the
            risk of spam and maintaining high-quality communication.
          </li>
          <li>You can change this choice at any time, as it is reversible.</li>
        </UnOrderList>

        <FlexCenterGap justifyContent="end" pb="2rem">
          <Button type="outline" text="No" onClick={() => handleCloseModal()} />
          <Button
            type="primary"
            text="Yes"
            onClick={() => handleWhatsappOpted()}
          />
        </FlexCenterGap>
      </Modal>
    </>
  );
};

export default ViewList;
