import React, { useEffect, useRef, useState } from "react";
import {
  border,
  color,
  flexbox,
  layout,
  maxHeight,
  position,
  space,
  system,
  typography,
} from "styled-system";

import Icon from "konnekt/Icon";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${space}
  ${border}
  ${typography}
  ${layout}
  ${color}
  ${flexbox}
  ${position}
`;

const StyledInput = styled.input`
  font-weight: 400;
  outline: none;
  padding: 0;
  height: inherit;
  font-size: inherit;
  background-color: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.3);
    font-size: 1.3rem;
    padding-left: 0.5em;
  }

  ${system({
    wordBreak: {
      property: "word-break",
    },
  })}
  ${system({
    resize: {
      property: "resize",
    },
  })}
  ${space}
  ${border}
  ${typography}
  ${layout}
  ${color}
  ${flexbox}
  ${maxHeight}
`;

const StyledCount = styled.div`
  display: flex;
  align-items: center;
  font-size: inherit;
  color: rgba(0, 0, 0, 0.3);

  & > p {
    margin: 0;
    padding: 0;
  }

  ${flexbox}
`;

const Searchbar = ({
  value,
  placeholder,
  setValue,
  textCount,
  inputProps,
  resetTextAreaHeight,
  allowedKeys = /[^0-9A-Za-z _-]/g,
  searchIcon,
  searchIconPositionLeft,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [textAreaHeight, setTextAreaHeight] = useState("auto");

  useEffect(() => {
    if (inputProps?.as === "textarea") {
      setTextAreaHeight(inputRef?.current.scrollHeight);
    }
  }, [inputRef?.current]);

  useEffect(() => {
    if (resetTextAreaHeight) setTextAreaHeight("18px");
  }, [resetTextAreaHeight]);

  const isKeyAllowed = (str) => {
    if (allowedKeys === "all") {
      return true;
    }
    return !new RegExp(allowedKeys).test(str);
  };

  const handleChange = (e) => {
    if (textCount && textCount.show && textCount.maxCharCount) {
      if (
        e.target.value.length <= textCount.maxCharCount &&
        isKeyAllowed(e.target.value)
      ) {
        setValue(e);
        // handle textarea height
        if (
          inputProps?.as === "textarea" &&
          textAreaHeight !== inputRef?.current.scrollHeight
        ) {
          setTextAreaHeight(inputRef?.current.scrollHeight + 20);
        }
      }
    } else {
      if (isKeyAllowed(e.target.value)) setValue(e);
    }
  };

  return (
    <Container {...rest}>
      {searchIcon &&
        (searchIconPositionLeft ? (
          <Icon
            iconSize="tiny"
            iconName="search"
            color="darkGrey.80"
            mr="10px"
            ml="10px"
            position="absolute"
            left="0px"
          />
        ) : (
          <Icon
            iconSize="tiny"
            iconName="search"
            color="darkGrey.80"
            mr="10px"
            position="absolute"
            right="0px"
          />
        ))}
      <StyledInput
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        height={textAreaHeight}
        ml={searchIconPositionLeft && "10px"}
        {...inputProps}
      />
      <StyledCount
        alignSelf={inputProps?.as === "textarea" ? "start" : "center"}
      >
        <p>{textCount?.show && value?.length}</p>
        {textCount?.show && textCount?.maxCharCount && (
          <p>/{textCount.maxCharCount}</p>
        )}
      </StyledCount>
    </Container>
  );
};

Searchbar.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  textCount: PropTypes.shape({
    show: PropTypes.bool,
    maxCharCount: PropTypes.number,
  }),
  allowedKeys: PropTypes.string,
  inputProps: PropTypes.object,
  resetTextAreaHeight: PropTypes.bool,
};

export default Searchbar;
