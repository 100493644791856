import React, { useState } from "react";
import styled from "styled-components";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Button from "konnekt/Buttons";
import { EDIT, TAGS } from "../../../../../../../constants/smartCardsContants";
import TagForm from "../../../../../../../components/Smartcards/TagsCard/TagForm";
import { useSelector, useDispatch } from "react-redux";
import { updateActiveSmartCard } from "../../../../../inboxStateActions";
import Label from "../../../../../../../konnekt/Label";
import Tag from "../../../../../../../components/Smartcards/TagsCard/Tag";
import {
  LiveChatProfilesBody,
  LiveChatProfilesHading,
  LiveChatProfileItem,
} from "./TagCard.styles";
import CreateAndAddTag from "./CreateAndAddTag";

const TagsCard = ({ updateTag, createNewTag, heading, activeCustomer }) => {
  const dispatch = useDispatch();
  const [iconManage, setIconManage] = useState();
  const [isCreateAndAddTag, setCreateAndAddTag] = useState(false);

  const allTags = useSelector((state) => state.manage?.tags?.tagList);

  const { activeSmartCard } = useSelector(
    (state) => state.inboxState.smartCard
  );

  const iconChangeHandle = () => {
    setIconManage(!iconManage);
  };

  const handleUpdateActiveSmartCard = (value) => {
    dispatch(updateActiveSmartCard(value));
  };

  const handleEditClick = () => {
    handleUpdateActiveSmartCard({ mode: EDIT, name: TAGS });
  };

  const handleSaveButtonClick = (tagsList) => {
    updateTag({ tags: tagsList });
  };

  const handleDeleteTag = ({ id, action }) => {
    const newTags = [...activeCustomer?.tags].filter((tagId) => tagId !== id);
    updateTag({ tags: newTags, action });
  };

  const getTagName = (tagId) => {
    return allTags
      ?.filter((tag) => tag?._id === tagId)
      .map((item) => {
        if (item.name) {
          return (
            <Tag key={tagId} id={tagId} onCloseClick={handleDeleteTag}>
              {item?.name}
            </Tag>
          );
        }
        return null;
      });
  };

  const renderTagsList = () => {
    if (activeCustomer?.tags === null || activeCustomer?.tags?.length === 0) {
      return (
        <Label pb="21px" fontSize="12px" color="rgba(0,0,0, .3)">
          No tags available
        </Label>
      );
    }

    return activeCustomer?.tags?.map((tagId) => {
      return <>{getTagName(tagId)}</>;
    });
  };

  return (
    <LiveChatProfilesBody>
      <Box display="flex" justifyContent="space-between">
        <LiveChatProfilesHading>{heading}</LiveChatProfilesHading>
        {!iconManage ? (
          <Icon iconName="plus" color="#5E3EC5" onClick={iconChangeHandle} />
        ) : (
          <Icon iconName="minus" color="#5E3EC5" onClick={iconChangeHandle} />
        )}
      </Box>
      <LiveChatProfileItem iconManage={iconManage}>
        <Box display="flex" mt="7px" justifyContent="space-between">
          <Button
            fontSize="13px"
            fontWeight="500"
            height="28px"
            mt="10px"
            onClick={handleEditClick}
          >
            Add Tag
          </Button>
          <Button
            fontSize="13px"
            fontWeight="500"
            height="28px"
            mt="10px"
            onClick={() => setCreateAndAddTag(true)}
          >
            Create & Add Tag
          </Button>
        </Box>
        <Box fontSize="12px" mt="20px">
          {renderTagsList()}
        </Box>
      </LiveChatProfileItem>
      {activeSmartCard.mode === EDIT && activeSmartCard.name === TAGS && (
        <TagForm
          handleUpdateActiveSmartCard={(value) =>
            handleUpdateActiveSmartCard(value)
          }
          allTags={allTags}
          customerTagsListId={activeCustomer?.tags}
          onSaveButtonClick={handleSaveButtonClick}
          createNewTag={createNewTag}
          handleDeleteTag={handleDeleteTag}
        />
      )}
      {isCreateAndAddTag && (
        <CreateAndAddTag
          onHide={() => setCreateAndAddTag(false)}
          updateTag={updateTag}
          customerTags={activeCustomer?.tags}
        />
      )}
    </LiveChatProfilesBody>
  );
};

export default TagsCard;
