import {
  getConversationMessage,
  getCustomerNameOrNumber,
  getFileType,
  reverseSortArrayWithTimeStamp,
  sanitizeFromNumber,
  sortArrayWithTimeStampNewest,
} from "../../util/chatUtil";
import { sendBrowserNotification } from "../../util/notification";
import _ from "lodash";
import moment from "moment";
import {
  FETCH_ALL_INBOX_STATS,
  FETCH_NEW_CONVERSATIONS,
  FETCHING_NEW_CONVERSATIONS,
  UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION,
  UPDATE_SINGLE_CONVERSATION,
  UPDATE_LAST_MESSAGE_IN_CONVERSATION,
  UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION,
  CLOSE_CHAT_IN_NEW_CONVERSATION,
  UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION,
  LOAD_NEW_CHAT,
  UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION,
  UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION,
  REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
  TOGGLE_FOR_NEW_CHANGE,
  UPDATE_IS_UPDATING_NEW_CONVERSATION,
  UPDATE_FETCH_ALL_INBOX_STATS_STATUS,
  SET_NEW_MESSAGE_IN_NEW_CONVERSATION,
  REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION,
  shouldShowNewChatInView,
  CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION,
  UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION,
  SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION,
  SET_ACTIVE_TOOLTIP,
  UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL,
  TOGGLE_CONVERSATION_LABEL_DROPDOWN,
  FETCH_CUSTOM_FILTERS,
  FETCH_CUSTOMER_SEARCH_RESULT_START,
  FETCH_CUSTOMER_SEARCH_RESULT_SUCCESS,
  FETCH_CUSTOMER_SEARCH_RESULT_FAILURE,
  REMOVE_CHAT_FROM_CONVERSATION,
  UPDATE_CHAT_INTERVENED_FOR_NEW_CONVERSATION,
  INTERVENE_CHAT_IN_NEW_CONVERSATION,
  UPDATE_CHAT_REQUESTING_INTERVENTION_FOR_NEW_CONVERSATION,
  FETCH_ALL_AGENTS,
  REMOVE_ALL_AGENTS,
  UPDATE_AGENT_VALUE,
  CLEAR_CONVERSATIONS,
  UPDATE_REQUESTING_COUNT,
} from "./ConversationActions";
import { CLEAR_CONVERSATION_LABEL } from "modules/Inbox/ThreadActions";
import { CLOSE_SEARCH_SECTION } from "modules/Inbox/inboxStateTypes";

const removedChatBaseOnId = (chats, chatId) =>
  chats.filter((chat) => chat.id !== chatId);

const initialState = {
  isFetchingConversations: true,
  chats: [],
  chatPageNum: 1,
  chatPageSize: 25,
  chatTotalCount: 0,
  selectedView: "1",
  orgActiveChatCount: 0,
  orgClosedChatCount: 0,
  orgUnassignedChatCount: 0,
  orgActiveAgentChatCount: 0,
  orgClosedAgentChatCount: 0,
  phoneThreads: {},
  isUpdatingConversationList: false,
  isUpdatingInboxStats: false,
  agentSpecificValue: "",
  // Create seperate file for data
  views: {
    1: {
      id: "1",
      name: "All",
      agentId: null,
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
    2: {
      id: "2",
      name: "You",
      agentId: "", // on profile load need to set this value
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
    3: {
      id: "3",
      name: "Unassigned",
      agentId: null,
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
  },
  toggleForNewChange: false,
  loadNewChat: false,
  filteredChatAPIUUID: "",
  isFetchingNewMessageConversation: false,
  sendNewMessageConversation: {},
  afterSendNewMessageConversation: {},
  isSendingNewMessage: false,
  isSendingNewCustomerMessage: false,
  activeTooltip: null,
  conversationLabelDropDownVisiblity: false,
  customFilters: {},
  searchResult: {
    list: [],
    resultCount: 0,
    resultTotalCount: 0,
    isFetchingSearchResult: false,
    searchCompleted: false,
    searchedText: "",
  },
  multiSelectedChats: [],
  agents: [],
};

const ConversationReducer = (state = initialState, action) => {
  let phoneThreads = {};
  let chats = [];
  let fromNumber = "";
  let newChats = [];
  let chatTotalCount = 0;
  let selectedChat = {};
  let messageType;
  let sendNewMessageConversation = {};
  let afterNewMessageConversation = {};
  let isSendingNewCustomerMessage = false;
  let loadNewChat = false;

  switch (action.type) {
    case UPDATE_FETCH_ALL_INBOX_STATS_STATUS:
      return {
        ...state,
        isUpdatingInboxStats: action.value,
      };

    case FETCH_ALL_INBOX_STATS:
      return {
        ...state,
        orgActiveChatCount: action.orgActiveChatCount,
        orgClosedChatCount: action.orgClosedChatCount,
        orgUnassignedChatCount: action.orgUnassignedChatCount,
        orgActiveAgentChatCount: action.orgActiveAgentChatCount,
        orgClosedAgentChatCount: action.orgClosedAgentChatCount,
        chatTotalCount: action.chatTotalCount,
        isUpdatingInboxStats: false,
      };
    case UPDATE_REQUESTING_COUNT:
      return {
        ...state,
        orgUnassignedChatCount: (state.orgUnassignedChatCount += 1),
      };
    case UPDATE_AGENT_VALUE:
      return {
        ...state,
        agentSpecificValue: action.value,
      };

    case UPDATE_SINGLE_CONVERSATION:
      phoneThreads = state.phoneThreads;
      loadNewChat = state.loadNewChat;
      afterNewMessageConversation = state.afterSendNewMessageConversation;
      chatTotalCount = state.chatTotalCount;
      chats = state.chats;
      if (action.chat) {
        let phoneNumber = sanitizeFromNumber(
          action?.chat?.conversation?.customer_id?.wa_phone_number
        );
        if (!phoneThreads[phoneNumber]) {
          let newChat = action.chat.conversation;
          newChat.phone_number = phoneNumber;
          newChat.timestamp = new Date(newChat.updated_at).getTime();
          chats.unshift(newChat);
          chatTotalCount++;
          phoneThreads[phoneNumber] = 1;
          loadNewChat = !loadNewChat && chats.length - 1 === 0;
        }
        // if (
        //   action.isFromChatReassignment &&
        //   !_.isEmpty(afterNewMessageConversation)
        // ) {
        //   chats = chats.map((chat) => {
        //     if (chat.id === action.chat.id) {
        //       chat.assigned_to_user_id = action.chat.assigned_to_user_id;
        //       afterNewMessageConversation = {};
        //     }
        //     return chat;
        //   });
        // }
      }
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        afterSendNewMessageConversation: afterNewMessageConversation,
        toggleForNewChange: !state.toggleForNewChange,
        isSendingNewCustomerMessage: false,
        loadNewChat: chats.length === 1,
      };

    case FETCHING_NEW_CONVERSATIONS:
      return {
        ...state,
        isFetchingConversations: action.isFetchingConversations,
        chatPageNum: action.chatPageNum,
        chats: action.chatPageNum === 1 ? [] : state.chats,
        loadNewChat: false,
      };

    case FETCH_NEW_CONVERSATIONS:
      if (action.chatPageNum == 1) {
        phoneThreads = {};
      } else {
        phoneThreads = state.phoneThreads;
      }
      action.chats.forEach((chat) => {
        let phoneNumber = sanitizeFromNumber(chat.customer_id?.wa_phone_number);
        if (!phoneThreads[phoneNumber]) {
          phoneThreads[phoneNumber] = 1;
        }
      });
      chats = action.chats.map((item) => {
        if (item) {
          let phone_number =
            item && item.customer_id && item.customer_id.wa_phone_number
              ? item.customer_id.wa_phone_number.toString()
              : "";
          item.phone_number = phone_number;
          item.timestamp = new Date(item.updated_at).getTime();
        }
        return item;
      });
      newChats = chats;
      if (action.chatPageNum != 1) {
        newChats = [...state.chats, ...chats];
      }
      let loadChatCondition = action.chatPageNum === 1 && newChats.length > 0;
      return {
        ...state,
        isFetchingConversations: action.isFetchingConversations,
        isActiveChatLoaded: action.isActiveChatLoaded,
        chats: newChats,
        loadNewChat: loadChatCondition,
        phoneThreads: phoneThreads,
        chatTotalCount: action.chatTotalCount,
        chatPageNum: action.chatPageNum,
        chatPageSize: action.chatPageSize,
        totalConversationsCount: action.chatTotalCount,
        // orgActiveChatCount: !action.isClosedFilter
        //   ? action.chatTotalCount
        //   : state.orgActiveChatCount,
        // orgUnassignedChatCount: !action.isClosedFilter
        //   ? action.chatTotalCount
        //   : state.orgUnassignedChatCount,
        // orgActiveAgentChatCount: !action.isClosedFilter
        //   ? action.chatTotalCount
        //   : state.orgActiveAgentChatCount,
      };

    case UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION:
      chats = state.chats;
      messageType = action.event.source;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.event.to)
      ) {
        fromNumber = sanitizeFromNumber(action.event.from);
      } else {
        fromNumber = sanitizeFromNumber(action.event.to);
      }
      newChats = chats.map((item) => {
        if (sanitizeFromNumber(item.phone_number) === fromNumber) {
          if (
            item.last_message_id &&
            _.toString(item.last_message_id.wc_id) ===
              _.toString(action.event.wc_id)
          ) {
            item.last_message_id.wa_message_id = action.event.wa_message_id;
            item.last_message_id.message_status = "Sent";
          }
          item.recieved_at_utc = new moment().toISOString();
          item.timestamp = new Date().getTime();
        }
        return item;
      });
      return {
        ...state,
        chats: newChats,
      };

    case UPDATE_LAST_MESSAGE_IN_CONVERSATION:
      chats = state.chats;
      let chatMessage = action.event;
      let isChatsModified = false;
      let message = "";
      let isCustomerMessage = chatMessage?.source === "outbox";
      let showNotification = isCustomerMessage && action.isWindowBlurred;
      sendNewMessageConversation = state.sendNewMessageConversation;
      if (chatMessage?.wa_media_id || chatMessage?.media_url) {
        message = _.startCase(
          getFileType(chatMessage.media_url, chatMessage.media_id)
        );
      } else {
        message = _.truncate(getConversationMessage(chatMessage));
      }
      let senderName = chatMessage.customer_name;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(chatMessage.from)
      ) {
        fromNumber = sanitizeFromNumber(chatMessage.to);
      } else {
        fromNumber = sanitizeFromNumber(chatMessage.from);
      }
      if (chats) {
        chats = chats.map((item) => {
          if (sanitizeFromNumber(item.phone_number) === fromNumber) {
            if (!item.assigned_to_user_id) {
              senderName = `New Customer ${chatMessage.customer_name} Says`;
            } else if (
              item.assigned_to_user_id &&
              item.assigned_to_user_id !== action.currentUserId
            ) {
              showNotification = false;
            }
            isChatsModified = true;
            item.last_message_id = chatMessage;
            if (isCustomerMessage)
              item.last_customer_message_at_utc = new moment().toISOString();
            item.recieved_at_utc = new moment().toISOString();
            item.timestamp = new Date().getTime();
          }
          return item;
        });
      }

      if (
        !_.isEmpty(sendNewMessageConversation) &&
        sanitizeFromNumber(sendNewMessageConversation.phone_number) ===
          fromNumber
      ) {
        sendNewMessageConversation.last_message_id = chatMessage;
        if (isCustomerMessage)
          sendNewMessageConversation.last_customer_message_at_utc =
            new moment().toISOString();
        sendNewMessageConversation.recieved_at_utc = new moment().toISOString();
        sendNewMessageConversation.timestamp = new Date().getTime();
      }

      if (showNotification) sendBrowserNotification(senderName, message);

      if (isChatsModified) {
        chats.forEach(function (item, i) {
          if (item._id === chatMessage?.conversation_id) {
            chats.splice(i, 1);
            chats.unshift(item);
          }
        });
      }

      return {
        ...state,
        chats: chats,
        sendNewMessageConversation: sendNewMessageConversation,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION: {
      chats = state.chats;
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      fromNumber = sanitizeFromNumber(action.event.from);
      const filteredChats = chats.filter((chat) => {
        if (
          //fromNumber &&
          chat._id &&
          chat._id === action.event?.conversation_id
        ) {
          delete phoneThreads[chat._id];
          chatTotalCount--;
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: filteredChats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case UPDATE_CHAT_REQUESTING_INTERVENTION_FOR_NEW_CONVERSATION:
    case UPDATE_CHAT_INTERVENED_FOR_NEW_CONVERSATION: {
      chats = state.chats;
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      fromNumber = sanitizeFromNumber(action.event.from);
      const filteredChats = chats.filter((chat) => {
        if (
          //fromNumber &&
          chat._id &&
          chat._id === action.event?.conversation_id
        ) {
          delete phoneThreads[chat._id];
          chatTotalCount--;
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: filteredChats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION:
      chats = state.chats;
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      fromNumber = sanitizeFromNumber(action.event.from);
      chats = chats.filter((chat) => {
        if (
          chat.phone_number &&
          fromNumber &&
          chat.phone_number === fromNumber
        ) {
          delete phoneThreads[chat.phone_number];
          chatTotalCount--;
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case INTERVENE_CHAT_IN_NEW_CONVERSATION:
    case CLOSE_CHAT_IN_NEW_CONVERSATION:
      let chatId = action.chatId;
      chats = state.chats;
      phoneThreads = state.phoneThreads;
      chatTotalCount = state.chatTotalCount;
      newChats = chats.filter((item) => {
        if (_.isEqual(_.toString(item.id), _.toString(chatId))) {
          delete phoneThreads[item.phone_number];
          chatTotalCount--;
          return false;
        }
        return true;
      });

      return {
        ...state,
        chats: newChats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: false,
      };

    case LOAD_NEW_CHAT:
      return {
        ...state,
        loadNewChat: action.loadNewChat,
      };

    case UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION:
      chats = state.chats;
      let customerName = "";
      newChats = chats.map((item) => {
        if (item.id === action.event.chat_id) {
          item.assigned_to_user_id = action.event.agent_assigned_user_id;
          if (action.showNotification && item.customer_id) {
            customerName = getCustomerNameOrNumber(item.customer_id);
          }
        }
        return item;
      });
      if (action.showNotification && customerName.length > 0) {
        sendBrowserNotification(customerName, "Chat Assigned To You");
      }
      return {
        ...state,
        chats: newChats,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION:
      chats = state.chats;
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      if (
        action.event &&
        action.event.chat_id &&
        action.event.agent_replaced_user_id
      ) {
        chats = chats.filter((item) => {
          if (
            item.id == action.event.chat_id &&
            item.assigned_to_user_id !== action.event.agent_replaced_user_id
          ) {
            chatTotalCount--;
            delete phoneThreads[item.phone_number];
            return false;
          } else {
            return true;
          }
        });
      }
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: false,
      };

    case UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL:
      chats = state.chats;
      if (action.event && action.event.chat_id) {
        newChats = chats.filter((item) => {
          if (item.id == action.event.chat_id) {
            item.conversation_label_id = action.event.new_conversation_label_id;
          }
          return item;
        });
      }
      return {
        ...state,
        chats: newChats,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case CLEAR_CONVERSATION_LABEL:
      const updatedChats = state.chats.filter((chat) => {
        if (chat.id === action.activeConversationId) {
          chat.conversation_label_id = null;
        }
        return chat;
      });
      return {
        ...state,
        chats: updatedChats,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION:
      chats = state.chats;
      phoneThreads = state.phoneThreads;
      chatTotalCount = state.chatTotalCount;
      fromNumber = sanitizeFromNumber(action.event.from);
      chats = chats.filter((chat) => {
        if (
          chat.phone_number &&
          fromNumber &&
          fromNumber === chat.phone_number
        ) {
          chatTotalCount--;
          delete phoneThreads[fromNumber];
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: action.value,
      };
    case TOGGLE_FOR_NEW_CHANGE:
      return {
        ...state,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case UPDATE_IS_UPDATING_NEW_CONVERSATION:
      return {
        ...state,
        isUpdatingConversationList: action.value,
      };

    case SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION:
      return {
        ...state,
        sendNewMessageConversation: action.chat,
        isFetchingNewMessageConversation: true,
      };

    case SET_NEW_MESSAGE_IN_NEW_CONVERSATION:
      chats = state.chats;
      phoneThreads = state.phoneThreads;
      selectedChat = action.chat;
      chatTotalCount = state.chatTotalCount;
      if (action.isChatExisting) {
        chats = chats.filter((chat) => {
          if (chat.id === action.chat.id) {
            selectedChat = chat;
            chatTotalCount--;
            return false;
          } else {
            return true;
          }
        });
      } else {
        fromNumber = sanitizeFromNumber(
          action.chat.customer_id.channel_phone_number
        );
        if (!phoneThreads[fromNumber]) {
          phoneThreads[fromNumber] = 1;
        }
      }
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        isSendingNewCustomerMessage: action.isNewCustomerMessage,
        sendNewMessageConversation: selectedChat,
        afterSendNewMessageConversation: selectedChat,
        isSendingNewMessage: true,
        isFetchingNewMessageConversation: false,
      };

    case REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION:
      chats = state.chats;
      phoneThreads = state.phoneThreads;
      afterNewMessageConversation = state.afterSendNewMessageConversation;
      isSendingNewCustomerMessage = state.isSendingNewCustomerMessage;
      let newMessageChat = state.sendNewMessageConversation;
      chatTotalCount = state.chatTotalCount;
      const { activeUserId, selectedFilter, selectedChatType, selectedSort } =
        action.filters;
      const showInCurrentView = shouldShowNewChatInView(
        newMessageChat,
        activeUserId,
        selectedFilter,
        selectedChatType
      );
      if (showInCurrentView) {
        let isChatExisting = chats.find(
          (chat) => chat.id === newMessageChat.id
        );
        if (!isChatExisting) {
          chats = chats.concat(newMessageChat);
          chatTotalCount++;
        }
        if (selectedSort == "Newest") {
          chats = sortArrayWithTimeStampNewest(chats);
        } else {
          chats = reverseSortArrayWithTimeStamp(chats);
        }
      } else {
        fromNumber = sanitizeFromNumber(
          newMessageChat.customer_id.channel_phone_number
        );
        if (phoneThreads[fromNumber]) {
          delete phoneThreads[fromNumber];
        }
      }
      if (action.isCancelled) {
        afterNewMessageConversation = {};
        isSendingNewCustomerMessage = false;
      }
      return {
        ...state,
        chats: chats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        sendNewMessageConversation: {},
        isSendingNewMessage: false,
        afterSendNewMessageConversation: afterNewMessageConversation,
        isSendingNewCustomerMessage: isSendingNewCustomerMessage,
      };

    case UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION:
      selectedChat = state.sendNewMessageConversation;
      selectedChat[action.key] = action.value;
      return {
        ...state,
        sendNewMessageConversation: selectedChat,
      };

    case CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION:
      return {
        ...state,
        sendNewMessageConversation: {},
        afterSendNewMessageConversation: {},
        isSendingNewMessage: false,
        isSendingNewCustomerMessage: false,
        isFetchingNewMessageConversation: false,
      };

    case SET_ACTIVE_TOOLTIP:
      return {
        ...state,
        activeTooltip: action.payload,
      };
    case TOGGLE_CONVERSATION_LABEL_DROPDOWN:
      return {
        ...state,
        // if payload then change to payload else toggle
        conversationLabelDropDownVisiblity:
          action.payload !== null
            ? action.payload
            : !state.conversationLabelDropDownVisiblity,
      };
    case FETCH_CUSTOM_FILTERS:
      return {
        ...state,
        customFilters: action.payload,
      };

    case FETCH_CUSTOMER_SEARCH_RESULT_START:
      return {
        ...state,
        searchResult: { ...state.searchResult, isFetchingSearchResult: true },
      };

    case FETCH_CUSTOMER_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          isFetchingSearchResult: false,
          list: action.payload.result,
          resultCount: action.payload.result_count,
          resultTotalCount: action.payload.total_count,
          searchCompleted: true,
          searchedText: action.payload.query,
        },
      };

    case FETCH_CUSTOMER_SEARCH_RESULT_FAILURE:
      return {
        ...state,
        searchResult: { ...state.searchResult, isFetchingSearchResult: false },
      };

    case CLOSE_SEARCH_SECTION:
      return {
        ...state,
        searchResult: { ...initialState.searchResult },
        toggleForNewChange: !state.toggleForNewChange,
      };

    case REMOVE_CHAT_FROM_CONVERSATION:
      return {
        ...state,
        chats: removedChatBaseOnId(state.chats, action.payload.chat_id),
        chatTotalCount: state.chatTotalCount - 1,
        toggleForNewChange: !state.toggleForNewChange,
      };
    case CLEAR_CONVERSATIONS:
      return {
        ...state,
        chats: [],
        chatPageNum: 1,
        chatPageSize: 25,
        chatTotalCount: 0,
      };
    case FETCH_ALL_AGENTS:
      return {
        ...state,
        agents: [...action.payload],
      };
    case REMOVE_ALL_AGENTS:
      return {
        ...state,
        agents: [],
      };

    default:
      return { ...state };
  }
};

export default ConversationReducer;
export const getIsFetchingConversations = (state) =>
  state.conversation.isFetchingConversations;
export const getOrgActiveChatCount = (state) =>
  state.conversation.orgActiveChatCount;
export const getOrgClosedChatCount = (state) =>
  state.conversation.orgClosedChatCount;
export const getOrgUnassignedChatCount = (state) =>
  state.conversation.orgUnassignedChatCount;
export const getOrgActiveAgentChatCount = (state) =>
  state.conversation.orgActiveAgentChatCount;
export const getOrgClosedAgentChatCount = (state) =>
  state.conversation.orgClosedAgentChatCount;

export const getNewChatFetchingState = (state) =>
  state.conversation.isFetchingConversations;
export const getNewChats = (state) => state.conversation.chats;
export const getNewChatPageNum = (state) => state.conversation.chatPageNum;
export const getNewChatPageSize = (state) => state.conversation.chatPageSize;
export const getNewChatPageTotalCount = (state) =>
  state.conversation.chatTotalCount;
export const getPhoneThreads = (state) => state.conversation.phoneThreads;
export const getNewToggleState = (state) =>
  state.conversation.toggleForNewChange;
export const getNewChatLoadState = (state) => state.conversation.loadNewChat;
export const getIsUpdatingNewConversation = (state) =>
  state.conversation.isUpdatingConversationList;
export const getIsUpdatingInboxStats = (state) =>
  state.conversation.isUpdatingInboxStats;
export const getIsFetchingNewMessageConversation = (state) =>
  state.conversation.isFetchingNewMessageConversation;
export const getNewMessageConversation = (state) =>
  state.conversation.sendNewMessageConversation;
export const getAfterNewMessageConversation = (state) =>
  state.conversation.afterSendNewMessageConversation;

export const getIsSendingNewMessage = (state) =>
  state.conversation.isSendingNewMessage;
export const getIsSendingNewCustomerMessage = (state) =>
  state.conversation.isSendingNewCustomerMessage;
export const getCustomFilters = (state) => state.conversation.customFilters;
export const getAllAgentsData = (state) => state.conversation.agents;
export const getConversationSelectedAgent = (state) =>
  state.conversation.agentSpecificValue;
