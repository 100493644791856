import { useSelector } from "react-redux";

const useActiveCustomerId = () => {
  const { activeCustomerAllUsersPage } = useSelector((state) => state.customer);
  const customer = useSelector(
    (state) => state?.thread?.activeConversation?.customer_id?._id
  );

  if (window.location.pathname.includes("/contacts/")) {
    return activeCustomerAllUsersPage;
  }

  return customer?.customerId??customer;

};

export default useActiveCustomerId;
