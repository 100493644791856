export const getCannedMessages = (state) => state.manage.cannedMessages?.cannedMessagesList;
export const getTagList = (state) => state.manage.tags?.tagList;
export const getAllTags = (state) => state.manage.tags?.allTags;
export const getAttributeList = (state) => state.manage.userAttributes?.activeAttributesList;
export const getAgentList = (state) => state.manage.teamSetting?.agents;
export const getTemplateMessagesList = (state) => state.manage.templateMessages?.templateMessagesList;
export const getApprovedTemplateList = (state) =>
  state.manage.templateMessages?.templateMessagesList &&
  state.manage.templateMessages?.templateMessagesList.length > 0
    ? state.manage.templateMessages?.templateMessagesList.filter(
      (item) => item.approval_status?.toUpperCase() === "APPROVED",
    )
    : [];