import styled from "styled-components";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";

export const ImageBackground = styled(Box)`
  background-image: url("https://automateeazy.s3.us-east-1.amazonaws.com/images/LgBg.svg");
  height: 100vh;
  background-size: 100%;
  width: 100%;
  text-align: center;
  padding-top: 15rem;

  img {
    width: 300px;
    margin-bottom: 25px;
  }

  h4 {
    line-height: 38px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 30px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
    img {
      width: 100%;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 20px;
    }
  }
`;
export const LoginContent = styled(Box)`
  padding: 150px 35px 0px 35px;

  a {
    text-align: center;
    display: block;
    font-weight: 600;
    color: #5e3ec5;
  }

  img {
    position: absolute;
    right: 12px;
    top: 14px;
    width: 21px;
  }

  input {
    box-sizing: border-box;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 50px 0px 15px;
  }

  button {
    background: #5e3ec5;
    border-radius: 8px;
    color: #fff;
    padding: 13px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 40px 20px;
    h2 {
      font-size: 25px;
    }
  }
`;
export const IconImage = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

export const ErrorImage = styled(Icon)`
  margin-right: 7px;
`;

export const SecretKeyInputIcon = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
