import React, { useEffect, useState } from "react";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon/Icon";
import Searchbar from "konnekt/Searchbar";
import Table from "konnekt/Table";
import styled from "styled-components";
import AdminTableData from "constants/newConstants/AdminData.json";
import MobileAdminTableData from "constants/newConstants/MobileAdminData.json";
import { getFormattedTime } from "util/timeUtility";
import useMediaQuery from "customHooks/useMediaQuery";
import { useSelector } from "react-redux";

const TeamContent = styled(Box)`
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 16px 0px 0px 30px;

  h6 {
    padding: 20px 20px 0px;
  }

  @media only screen and (max-width: 767px) {
    margin: 30px 12px 0px 12px;
  }
`;

const StyledPara = styled("p")`
  margin-top: 0;
  margin-bottom: 0;
`;
const SearchBox = styled(Box)`
  padding: 10px 20px 20px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const SearchBoxMobile = styled(Box)`
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
    padding: 12px;
  }
`;

const ProjectTable = ({ actionHandler, formKeys, tableKeys, plans }) => {
  let projectList = useSelector((state) => state.app?.projectList);
  const projectListCopy = useSelector((state) => state.app?.projectListCopy);

  const isMobile = useMediaQuery("tablet", "down");
  const [value, setValue] = useState("");
  let isActive = "";
  const renderItem = (item) => {
    let rowData = [];
    for (let key of tableKeys) {
      if (key === "created_at") {
        item.created_at = rowData?.created_at
          ? getFormattedTime(item.created_at, "MMMM DD, YYYY")
          : "-";
      }
      if (key === "plan_name") {
        const projectItem = projectListCopy.find((it) => it._id === item._id);
        item.plan_name =
          plans?.find((itm) => itm._id === projectItem[key])?.name || "";
      }
      if (key === "is_active") {
        if (item.is_active) {
          rowData.push("active");
          isActive = "active";
          continue;
        }
        rowData.push("Inactive");
        isActive = "Inactive";
        continue;
      }
      rowData.push(item[key]);
    }

    if (isMobile) {
      return [
        item?.name,
        item?.status,
        item?.website,
        isActive,
        item?.phone_number,
        <>
          <StyledPara>
            <Icon
              style={{ marginRight: ".8rem" }}
              iconName="editVector"
              onClick={() => actionHandler(true, false, item)}
              noWrapper
            />
            <Icon
              onClick={() => actionHandler(false, true, item)}
              iconName="deleteVector"
              color="error.600"
              noWrapper
            />
          </StyledPara>
        </>,
      ];
    }
    return [
      ...rowData,
      <>
        <StyledPara>
          <Icon
            style={{ marginRight: ".8rem" }}
            iconName="editVector"
            onClick={() => actionHandler(true, false, item)}
            noWrapper
          />
          <Icon
            onClick={() => actionHandler(false, true, item)}
            iconName="deleteVector"
            color="error.600"
            noWrapper
          />
        </StyledPara>
      </>,
    ];
  };

  const renderList = () => {
    if (value.length > 0) {
      return (
        projectList?.length > 0 &&
        projectList
          .filter((item) =>
            item?.name?.toLowerCase().trim().includes(value?.toLowerCase())
          )
          .map((item) => {
            return renderItem(item);
          })
      );
    }

    return (
      projectList?.length > 0 &&
      projectList
        .filter((item) => !item.is_deleted)
        .map((item) => {
          return renderItem(item);
        })
    );
  };

  return (
    <Box width="100%">
      <TeamContent>
        <Box>
          <SearchBox>
            <Box className="d-lg-flex justify-content-between">
              <Searchbar
                value={value}
                setValue={(e) => setValue(e.target.value)}
                placeholder="Search by name"
                fontSize="1.2rem"
                searchIcon
                backgroundColor="var(--bg-light-1)"
                borderRadius=".4rem"
                width="36rem"
                height="4rem"
                paddingLeft="1rem"
                position="relative"
              />
            </Box>
          </SearchBox>
          <SearchBoxMobile>
            <Box>
              <Searchbar
                value={value}
                setValue={(e) => setValue(e.target.value)}
                placeholder="Search by name"
                fontSize="12px"
                border="1px solid rgba(0,0,0,.08)"
                searchIcon
                backgroundColor="#F5F6FA"
                borderRadius="4px"
                width="100%"
                height="44px"
                position="relative"
                paddingLeft="20px"
              />
            </Box>
          </SearchBoxMobile>
          <Box>
            <Table
              gridTemplateColumnsHeader={
                isMobile
                  ? "15% 15% 25% 15% 18% 12%"
                  : "10% 10% 10% 10% 10% 10% 10% 10% 10% 10%"
              }
              gridTemplateColumnsBody={
                isMobile
                  ? "15% 15% 25% 15% 18% 12%"
                  : "10% 10% 10% 10% 10% 10% 10% 10% 10% 10%"
              }
              columns={isMobile ? MobileAdminTableData : AdminTableData}
              data={renderList()}
              tableProps={{
                theadProps: {
                  position: "unset",
                },
              }}
            />
          </Box>
        </Box>
      </TeamContent>
    </Box>
  );
};

export default ProjectTable;
