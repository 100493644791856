import React, { useState, useEffect } from "react";
import logo from "konnekt/Icon/logo.svg"
import {v4 as uuidv4} from 'uuid';
import Box from "konnekt/Box";
import TextInput from "konnekt/TextInput";
import Button from "konnekt/Buttons";
import { useSelector, useDispatch } from "react-redux";
import { getProjectId } from "modules/App/AppSelectors";
import { showNotification } from "util/utility";
import {addTopupToSubscription, confirmTopupToSubscription, clearConversationCredit, clearRazorpayOrder} from '../BillingActions';
import { getPaymentMethod, getAddTopupSubscription} from '../BillingSelectors';

const BuyMoreModal = ({ billingDetails, paymentConfirmationCallback }) => {
  const dispatch = useDispatch();
  const [buyMoreValue, setBuyMoreValue] = useState(null);
  const activeSubscription = useSelector(
    (state) => state.manage?.activeSubscriptionList[0]
  );
  const activeUser = useSelector(state => state?.app?.user);
  const addTopupSubscription = useSelector(getAddTopupSubscription);
  const activeProjectId = useSelector((state) => getProjectId(state));
  const paymentMethod = useSelector(getPaymentMethod);
  const billingAddress = useSelector(state => state?.manage?.billingAddressList?.addressList?.[0] || {});
  
  const handlePaymentConfirmation = (status) => {
    const body = {
      payment_status: status,
      invoice_id: addTopupSubscription.data?._id,
      plan_id: activeSubscription.plan_id?._id
    };
    dispatch(confirmTopupToSubscription(activeProjectId, activeSubscription?._id, body, paymentConfirmationCallback));
  };

  const openRazorpayWindow = (order_id) => {
    const options = {
        key: process.env.RAZORPAY_API_KEY || 'rzp_test_HKcCUmEfsNPCdE', // Enter the Key ID generated from the Dashboard
        amount: buyMoreValue?.toString(),
        currency: 'INR',
        name: activeUser?.contact_name,
        description: `Payment for WCC purchase`,
        image: { logo },
        order_id,
        handler: async function (response) {
            const data = {
                orderCreationId: order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
            handlePaymentConfirmation('paid');
            // onClose();
        },
        prefill: {
            name: activeUser?.contact_name,
            email: activeUser?.contact_email,
            contact: activeUser?.phone_number,
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      handlePaymentConfirmation('unpaid');
      showNotification('error', 'Payment is not completed!');
    });
  };

  const initiateBuyMorePayment = () => {
    if (buyMoreValue > 3000) {
      showNotification("error", "A maximum value of 3000 can be entered!");
      return;
    }
    dispatch(addTopupToSubscription(activeProjectId, {
      subscription: {
        billing_address_id: billingAddress?._id,
        payment_method_id: paymentMethod?._id,
        subscription_id: activeSubscription?._id,
        credit_amount: buyMoreValue,
        order_num: uuidv4(),
        plan_id: activeSubscription?.plan_id?._id
      }
    }))
  };

  useEffect(()=> {
    if(addTopupSubscription.status === 'success'){
      openRazorpayWindow(addTopupSubscription.data?.order_id);
    }
    return () => {
      dispatch(clearRazorpayOrder());
      dispatch(clearConversationCredit());
    }
  }, [addTopupSubscription]);

  return (
    <Box>
      <p style={{ fontWeight: 600, paddingTop: "1rem", fontSize: "1.5rem" }}>
        Enter WCC Amount
      </p>
      <TextInput
        type="text"
        onChange={(e) => setBuyMoreValue(e)}
        value={buyMoreValue}
        width="98%"
        bodyTextLimit={24}
        placeholder="Maximum purchase of ₹3000 is allowed"
        border="1px solid rgba(0,0,0,.08)"
        height="42px"
      />
      <br></br>
      <Button
        type="primary"
        margin="auto"
        mt="10"
        onClick={() => initiateBuyMorePayment()}
      >
        Continue
      </Button>
    </Box>
  );
};

export default React.memo(BuyMoreModal);
