import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "util/utility";
import callApi, { callApiV2 } from "../../util/apiCaller";
import callChatApi from "../../util/chatApiCaller";
import { fetchTrackerReadByChatIds } from "./TrackerActions";
import {
  EMPTY_MESSAGE_THREAD,
  selectActiveChat,
  selectActiveChatId,
  updateChatThreadsFromNewConversations,
  INTERVENE_CHAT,
} from "./ThreadActions";
import { getConversationMessage, getFileType } from "../../util/chatUtil";
import { sendBrowserNotification } from "../../util/notification";
import { sendUserPilotUserTraits } from "../App/AppActions";
import { getCustomerById } from "modules/Customer/CustomerActions";

// api actions
export const FETCH_ALL_INBOX_STATS = "FETCH_ALL_INBOX_STATS";
export const UPDATE_FETCH_ALL_INBOX_STATS_STATUS =
  "UPDATE_FETCH_ALL_INBOX_STATS_STATUS";
export const FETCHING_NEW_CONVERSATIONS = "FETCHING_NEW_CONVERSATIONS";
export const FETCH_NEW_CONVERSATIONS = "FETCH_NEW_CONVERSATIONS";
export const TOGGLE_FOR_NEW_CHANGE = "TOGGLE_FOR_NEW_CHANGE";
export const UPDATE_SINGLE_CONVERSATION = "UPDATE_SINGLE_CONVERSATION";
export const UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION =
  "UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION";
export const UPDATE_LAST_MESSAGE_IN_CONVERSATION =
  "UPDATE_LAST_MESSAGE_IN_CONVERSATION";
export const UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION";
export const UPDATE_CHAT_INTERVENED_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_INTERVENED_FOR_NEW_CONVERSATION";
export const UPDATE_CHAT_REQUESTING_INTERVENTION_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_REQUESTING_INTERVENTION_FOR_NEW_CONVERSATION";
export const UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION";
export const CLOSE_CHAT_IN_NEW_CONVERSATION = "CLOSE_CHAT_IN_NEW_CONVERSATION";
export const INTERVENE_CHAT_IN_NEW_CONVERSATION =
  "INTERVENE_CHAT_IN_NEW_CONVERSATION";
export const UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION =
  "UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION";
export const UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION =
  "UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION";
export const REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION =
  "REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION";
export const UPDATE_IS_UPDATING_NEW_CONVERSATION =
  "UPDATE_IS_UPDATING_NEW_CONVERSATION";
export const SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const SET_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "SET_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const SET_ACTIVE_TOOLTIP = "SET_ACTIVE_TOOLTIP";

export const LOAD_NEW_CHAT = "LOAD_NEW_CHAT";
export const UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL =
  "UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL";
export const TOGGLE_CONVERSATION_LABEL_DROPDOWN =
  "TOGGLE_CONVERSATION_LABEL_DROPDOWN";
export const FETCH_CUSTOM_FILTERS = "FETCH_CUSTOM_FILTERS";

export const FETCH_CUSTOMER_SEARCH_RESULT_START =
  "FETCH_CUSTOMER_SEARCH_RESULT_START";

export const FETCH_CUSTOMER_SEARCH_RESULT_SUCCESS =
  "FETCH_CUSTOMER_SEARCH_RESULT_SUCCESS";

export const FETCH_CUSTOMER_SEARCH_RESULT_FAILURE =
  "FETCH_CUSTOMER_SEARCH_RESULT_FAILURE";

export const REMOVE_CHAT_FROM_CONVERSATION = "REMOVE_CHAT_FROM_CONVERSATION";

export const FETCH_ALL_AGENTS = "FETCH_ALL_AGENTS";
export const REMOVE_ALL_AGENTS = "REMOVE_ALL_AGENTS";
export const TRANSFER_CHAT = "TRANSFER_CHAT";
export const UPDATE_AGENT_VALUE = "UPDATE_AGENT_VALUE";
export const MARK_FAVORITE = "MARK_FAVORITE";
export const ADD_NOTES = "ADD_NOTES";
export const CLEAR_CONVERSATIONS = "CLEAR_CONVERSATIONS";
export const UPDATE_REQUESTING_COUNT = "UPDATE_REQUESTING_COUNT";

function getPaginatedOffset(page, size) {
  return (page - 1) * size;
}

function getPages(count, pagesize) {
  if (count > 0) {
    return Math.ceil(count / pagesize);
  }
  return 0;
}
export const updateRequestingCount = (key) => (dispatch) => {
  dispatch({
    type: UPDATE_REQUESTING_COUNT,
    value: key,
  });
};
// v1/project/:project_id/stats
// todo raghav to clear it with saif for basic stats
export function updateInboxStats(projectId, filters) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FETCH_ALL_INBOX_STATS_STATUS,
      value: true,
    });
    projectId &&
      callChatApi(`v1/project/${projectId}/stats`, "post", {
        filters,
      }).then((res) => {
        if (res && res.data) {
          const chatTotalCount =
            filters.status === "initiated"
              ? res.data.initiated_chat_count
              : filters.status === "intervened"
              ? res.data.intervened_chat_count
              : res.data.resolved_chat_count;
          dispatch({
            type: FETCH_ALL_INBOX_STATS,
            orgActiveChatCount: res.data.intervened_chat_count,
            orgClosedChatCount: res.data.resolved_chat_count,
            orgUnassignedChatCount: res.data.initiated_chat_count,
            orgActiveAgentChatCount: res.data.agent_active_chat_count,
            orgClosedAgentChatCount: res.data.agent_closed_chat_count,
            chatTotalCount,
            isUpdatingInboxStats: false,
          });
        } else {
          dispatch({
            type: UPDATE_FETCH_ALL_INBOX_STATS_STATUS,
            value: false,
          });
        }
      });
  };
}
export const updateAgentValue = (keyValue) => (dispatch) => {
  dispatch({
    type: UPDATE_AGENT_VALUE,
    value: keyValue,
  });
};
export function transferChat(projectId, activeAgentId, conversionId) {
  const uuid = uuidv4();
  return (dispatch) => {
    dispatch({
      type: TRANSFER_CHAT,
      value: true,
    });
    const filters = {
      active_agent_id: activeAgentId,
      conversations_id: conversionId,
      wa_message_id: uuid,
      project_id: projectId,
    };
    projectId &&
      callChatApi(`v1/project/conversations/updateAgent`, "post", filters)
        .then((res) => {
          if (res && res.data) {
            showNotification("success", "Chat Transferred Successfully");
          }
          // dispatch(getConversations(projectId));
          //dispatch(updateInboxStats(projectId));
        })
        .catch((err) => {
          showNotification("error", "Something went wrong");
          console.error(err);
        });
  };
}

export function getConversationParamUrl(filters) {
  let params = ``;
  if (filters.selectedChatType) {
    if (filters.selectedChatType === "Spam") {
      params = `?type=spam`;
    } else if (filters.selectedChatType === "Open") {
      params = `?type=active`;
    } else {
      params = `?type=closed`;
    }
  } else {
    params = `?type=active`;
  }
  if (filters.pageSize) {
    params += `&limit=${filters.pageSize}`;
  }
  if (!_.isNull(filters.assigned)) {
    params += `&assigned=${_.toString(filters.assigned)}`;
    if (filters.agentId) {
      params += `&agentId=${filters.agentId}`;
    }
  }
  if (filters.isAssignedConvLabel) {
    params += `&conversationLabelId=${filters.conversationLabelId}`;
  }
  if (filters.pageNum && filters.pageSize) {
    params += `&offset=${getPaginatedOffset(
      filters.pageNum,
      filters.pageSize
    )}`;
  }
  if (filters.sortBy) {
    params += `&sortBy=${filters.sortBy}`;
  }

  return `v1/organizations/${filters.organizationId}/chats/${params}`;
}

export function getConversations(projectId, filters, resolve) {
  const userProjectId = projectId ?? localStorage.getItem("projectId");
  const uuid = uuidv4();
  return (dispatch) => {
    if (filters.assigned === false && filters.selectedChatType === "Closed") {
      dispatch({
        type: FETCH_NEW_CONVERSATIONS,
        isFetchingConversations: false,
        isActiveChatLoaded: true,
        agentId: filters.agentId,
        chats: [],
        chatPageNum: filters.pageNum,
        chatPageSize: filters.pageSize,
        chatTotalCount: 0,
        sortBy: filters.sortBy,
        isClosedFilter: filters.selectedChatType === "Closed",
      });
    } else {
      dispatch({
        type: FETCHING_NEW_CONVERSATIONS,
        isFetchingConversations: true,
        chatPageNum: filters.pageNum,
        uuid,
      });
      if (filters.pageNum === 1) {
        dispatch({
          type: EMPTY_MESSAGE_THREAD,
        });
        dispatch(clearNewMessageConversation());
        dispatch(selectActiveChat({}));
        dispatch(selectActiveChatId(""));
      }
      let endPoint;
      if (!!filters?.customer_name) {
        endPoint = `v1/project/${userProjectId}/conversations`;
      } else {
        endPoint = `v1/project/${userProjectId}/conversations`;
      }
      // todo based on status, agent_id, const url = getConversationParamUrl(filters);
      const updatedFiletrs = {
        status: filters?.status,
        qtext: filters?.customer_name,
        agent_specific_chat: filters?.activeAgent,
        ...filters,
      };
      return callChatApi(endPoint, "post", {
        // active_agent_id
        filters: {
          ...updatedFiletrs,
        },
      }).then((res) => {
        if (res.data) {
          const chats = res.data.conversationList;
          const chat_ids = chats.map((item) => item._id);
          if (chat_ids && chat_ids.length > 0) {
            dispatch(fetchTrackerReadByChatIds(chat_ids));
            dispatch(updateChatThreadsFromNewConversations(chats));
          }
          dispatch({
            type: FETCH_NEW_CONVERSATIONS,
            isFetchingConversations: false,
            isActiveChatLoaded: true,
            agentId: filters.agentId,
            chats: res.data.conversationList ? res.data.conversationList : [],
            chatPageNum: filters.pageNum,
            chatPageSize: filters.pageSize,
            chatTotalCount: res.data.conversationCount,
            sortBy: filters.sortBy,
            isClosedFilter: filters.selectedChatType === "Closed",
          });
          const statsFilters = { ...updatedFiletrs };
          delete statsFilters?.pageNum;
          delete statsFilters?.pageSize;
          // if (window.location.pathname !== "/history") {
          //   dispatch(updateInboxStats(userProjectId, statsFilters));
          // }
        } else {
          dispatch({
            type: FETCHING_NEW_CONVERSATIONS,
            isFetchingConversations: false,
          });
        }
        if (resolve) {
          resolve();
        }
      });
    }
  };
}

export function fetchConversationsInBackground(projectId, filters, resolve) {
  const userProjectId = projectId ?? localStorage.getItem("projectId");
  const uuid = uuidv4();
  return (dispatch) => {
    if (filters.assigned === false && filters.selectedChatType === "Closed") {
      dispatch({
        type: FETCH_NEW_CONVERSATIONS,
        isFetchingConversations: false,
        isActiveChatLoaded: true,
        agentId: filters.agentId,
        chats: [],
        chatPageNum: filters.pageNum,
        chatPageSize: filters.pageSize,
        chatTotalCount: 0,
        sortBy: filters.sortBy,
        isClosedFilter: filters.selectedChatType === "Closed",
      });
    } else {
      const endPoint = `v1/project/${userProjectId}/conversations`;
      // todo based on status, agent_id, const url = getConversationParamUrl(filters);
      const updatedFiletrs = {
        status: filters?.status,
        qtext: filters?.customer_name,
        agent_specific_chat: filters?.activeAgent,
        ...filters,
      };
      return callChatApi(endPoint, "post", {
        // active_agent_id
        filters: {
          ...updatedFiletrs,
        },
      }).then((res) => {
        if (res.data) {
          const chats = res.data.conversationList;
          const chat_ids = chats.map((item) => item._id);
          if (chat_ids && chat_ids.length > 0) {
            dispatch(fetchTrackerReadByChatIds(chat_ids));
            dispatch(updateChatThreadsFromNewConversations(chats));
          }
          dispatch({
            type: FETCH_NEW_CONVERSATIONS,
            isFetchingConversations: false,
            isActiveChatLoaded: true,
            agentId: filters.agentId,
            chats: res.data.conversationList ? res.data.conversationList : [],
            chatPageNum: filters.pageNum,
            chatPageSize: filters.pageSize,
            chatTotalCount: res.data.conversationCount,
            sortBy: filters.sortBy,
            isClosedFilter: filters.selectedChatType === "Closed",
          });
          const statsFilters = { ...updatedFiletrs };
          delete statsFilters?.pageNum;
          delete statsFilters?.pageSize;
          dispatch(updateInboxStats(userProjectId, statsFilters));
        } else {
          dispatch({
            type: FETCHING_NEW_CONVERSATIONS,
            isFetchingConversations: false,
          });
        }
        if (resolve) {
          resolve();
        }
      });
    }
  };
}

export function markFavorite(projectId, customerId, status, filters) {
  return (dispatch) => {
    dispatch({
      type: MARK_FAVORITE,
      value: true,
    });
    const body = {
      status: status,
      _id: customerId,
    };
    projectId &&
      callApiV2({
        method: "post",
        endpoint: `elevate/v1/project/${projectId}/mark-favourite`,
        body,
      })
        .then((res) => {
          if (res && res.data) {
            showNotification("success", "Chat Updated Successfully");
          }
          dispatch(getConversations(projectId, filters, null));
        })
        .catch((err) => {
          showNotification("error", "Something went wrong");
          console.error(err);
        });
  };
}
export function addNotes(projectId, data) {
  return (dispatch) => {
    dispatch({
      type: ADD_NOTES,
      value: true,
    });
    console.log(data);
    const body = {
      ...data,
    };
    projectId &&
      callApiV2({
        method: "post",
        endpoint: `elevate/v1/project/${projectId}/add-notes`,
        body,
      })
        .then((res) => {
          if (res && res.data) {
            showNotification("success", "Notes Added Successfully");
            dispatch(getCustomerById(projectId, data?._id));
          }
        })
        .catch((err) => {
          showNotification("error", "Something went wrong");
          console.error(err);
        });
  };
}
export function getMissingChat(
  filters,
  chatMessage,
  isFromChatReassignment = false
) {
  return (dispatch) =>
    callChatApi(
      `v1/project/${filters.activeProjectId}/conversation/${filters.conversation_id}`,
      "get",
      undefined,
      true
    ).then((res) => {
      if (res && res.data) {
        const chat_ids = [];
        const trackerFilters = {};
        trackerFilters.chat_ids = chat_ids;
        trackerFilters.activeProjectId = filters.activeProjectId;
        trackerFilters.agentId = filters.currentUserId;
        if (res.data && filters.currentUserId) {
          const chats = [];
          chats.push(res.data?.conversation);
          chat_ids.push(res.data?.conversation?._id);
          if (chat_ids && chat_ids.length > 0) {
            dispatch(fetchTrackerReadByChatIds(chat_ids));
            dispatch(updateChatThreadsFromNewConversations(chats));
          }
          dispatch({
            type: UPDATE_SINGLE_CONVERSATION,
            chat: res.data,
            isFromChatReassignment,
          });
        }
        if (
          chatMessage.type == "Message" &&
          chatMessage.chat_message_type == "CustomerMessage"
        ) {
          handleBrowserNotification(
            res.data?.conversation,
            filters.currentUserId,
            chatMessage,
            filters.isWindowBlurred
          );
        }
      }
    });
}

export function shouldShowNewChatInView(
  chat,
  activeUserId,
  selectedFilter,
  selectedChatType,
  customFilters
) {
  const chatAssignedToUserId = chat.assigned_to_user_id;
  const isChatOpen = !chat.is_closed;
  const isChatSpam = chat.is_spam;

  const isChatFromSelectedAssignFilter = () =>
    selectedFilter === "All" ||
    (!chatAssignedToUserId && selectedFilter === "Unassigned") ||
    (chatAssignedToUserId &&
      chatAssignedToUserId === activeUserId &&
      selectedFilter === "You");

  const isChatFromCustomFilter = () =>
    chat.conversation_label_id ===
      customFilters?.selectedConvLabelData?.convLabelData?.id &&
    chat.assigned_to_user_id === (customFilters?.agentData?.id || activeUserId);

  if (selectedChatType === "Spam") {
    if (customFilters && Object.keys(customFilters).length !== 0) {
      return isChatFromSelectedAssignFilter() && isChatFromCustomFilter();
    }

    return isChatSpam;
  }

  // all filters are applied

  if (customFilters && Object.keys(customFilters).length !== 0 && !isChatSpam) {
    return (
      (selectedChatType === "Open") === isChatOpen &&
      isChatFromSelectedAssignFilter() &&
      isChatFromCustomFilter()
    );
  }

  if (
    !chatAssignedToUserId &&
    (selectedFilter == "All" || selectedFilter == "Unassigned") &&
    !isChatSpam
  ) {
    return (
      (isChatOpen && selectedChatType === "Open") ||
      (!isChatOpen && selectedChatType === "Closed")
    );
  }

  if (
    ((chatAssignedToUserId &&
      chatAssignedToUserId === activeUserId &&
      selectedFilter === "You") ||
      (chatAssignedToUserId && selectedFilter === "All")) &&
    !isChatSpam
  ) {
    return (
      (isChatOpen && selectedChatType === "Open") ||
      (!isChatOpen && selectedChatType === "Closed")
    );
  }

  return false;
}

export function handleBrowserNotification(
  chat,
  activeUserId,
  chatMessage,
  isWindowBlurred
) {
  let message = "";
  let showNotification = isWindowBlurred;
  if (chatMessage.media_id || chatMessage.media_url) {
    message = _.startCase(
      getFileType(chatMessage.media_url, chatMessage.media_id)
    );
  } else {
    message = _.truncate(getConversationMessage(chatMessage));
  }
  let senderName = chatMessage.customer_name;
  if (!chat.assigned_to_user_id) {
    senderName = `New Customer ${chatMessage.customer_name} Says`;
  } else if (
    chat.assigned_to_user_id &&
    chat.assigned_to_user_id != activeUserId
  ) {
    showNotification = false;
  }

  if (showNotification && senderName && !chat?.is_spam) {
    sendBrowserNotification(senderName, message);
  }
}

export function updateLastMessageInNewChat(
  phone_number,
  event,
  currentUserId,
  selectedSort,
  isWindowBlurred
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAST_MESSAGE_IN_CONVERSATION,
      phone_number,
      event,
      currentUserId,
      selectedSort,
      isWindowBlurred,
    });
  };
}

export function updateChatSentEventInNewChat(
  phone_number,
  event,
  currentUserId
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION,
      phone_number,
      event,
      currentUserId,
    });
  };
}

export function updateChatOpenedInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function updateChatClosedInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function updateChatIntervenedInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_INTERVENED_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function updateChatRequestingInterventionInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_REQUESTING_INTERVENTION_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function setLoadNewChat(val) {
  return (dispatch) => {
    dispatch({
      type: LOAD_NEW_CHAT,
      loadNewChat: !!val,
    });
  };
}

export function updateAgentChangeInNewConversation(event, showNotification) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION,
      event,
      showNotification,
    });
  };
}

export function updateCustomerConversationLabel(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL,
      event,
    });
  };
}

export function updateAgentReplacedInNewConversation(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION,
      event,
    });
  };
}

export function removeAssignedChatInNewConversation(event) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
      event,
    });
  };
}

export function toggleForConversationList() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FOR_NEW_CHANGE,
    });
  };
}

export function setFetchingNewMessageInNewConversation(chat) {
  return (dispatch) => {
    dispatch({
      type: SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION,
      chat,
    });
  };
}

export function setNewMessageInNewConversation(
  chat,
  isChatExisting,
  isNewCustomerMessage = false
) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_MESSAGE_IN_NEW_CONVERSATION,
      chat,
      isChatExisting,
      isNewCustomerMessage,
    });
  };
}

export function updateNewMessageInConversation(key, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION,
      key,
      value,
    });
  };
}

export function removeNewMessageInNewConversation(filters, isCancelled) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION,
      filters,
      isCancelled,
    });
  };
}

export function clearNewMessageConversation() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION,
    });
  };
}

export function setActiveTooltip(id) {
  return (dispatch) =>
    dispatch({
      type: SET_ACTIVE_TOOLTIP,
      payload: id,
    });
}

export const toggleConversationlabelDropdown = (flag) => (dispatch) =>
  dispatch({
    type: TOGGLE_CONVERSATION_LABEL_DROPDOWN,
    payload: flag,
  });

export function clearCustomFilters() {
  return (dispatch) =>
    dispatch({
      type: FETCH_CUSTOM_FILTERS,
      payload: {},
    });
}

export function updateCustomFilters(updatedData) {
  return (dispatch) =>
    dispatch({
      type: FETCH_CUSTOM_FILTERS,
      payload: updatedData,
    });
}

export const fetchSearchResult = (query) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_CUSTOMER_SEARCH_RESULT_START,
  });

  try {
    const organizationId = getState().app?.organizations[0]?.organization_id.id;

    const response = await callApiV2({
      method: "get",
      endpoint: `v1/organizations/${organizationId}/chats/search/?q=${query}&message_search=true`,
    });

    if (response.result) {
      dispatch({
        type: FETCH_CUSTOMER_SEARCH_RESULT_SUCCESS,
        payload: { ...response.data, query },
      });

      // Todo - To be removed once store re-structure is done

      const elem = document.querySelector(".searchList > div");

      if (window.screen.width > 768 && elem) {
        elem.click();
      }
    } else {
      dispatch({
        type: FETCH_CUSTOMER_SEARCH_RESULT_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    showNotification("error", error?.message);
    dispatch({ type: FETCH_CUSTOMER_SEARCH_RESULT_FAILURE });
  }
};

export const removeChatFromConversation = (data) => ({
  type: REMOVE_CHAT_FROM_CONVERSATION,
  payload: data,
});

export const fetchAllAgents = (activeBusinessId, activeProjectId) => {
  return (dispatch) => {
    callApiV2({
      method: "post",
      endpoint: `team-settings/business/${activeBusinessId}/all-agent`,
      body: {
        filters: {
          project_id: activeProjectId,
        },
        pageNum: 1,
        pageSize: "all",
      },
    })
      .then((res) => {
        dispatch({
          type: FETCH_ALL_AGENTS,
          payload: [...res.data.agentList],
        });
      })
      .catch((err) => {
        console.error("Error occcurred while fetching all agents", err);
      });
  };
};

export const clearAllAgentsData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALL_AGENTS,
    });
  };
};

export const clearAllConversations = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CONVERSATIONS,
    });
  };
};
