import {
  UPDATE_AGENT_PROFILE_DETAIL,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_PROFILE_FAILURE,
  FETCH_UPDATE_AGENT_DETAIL,
  FETCH_UPDATE_AGENT_SUCCESS,
  FETCH_UPDATE_AGENT_FAILED,
  UPDATE_AGENT_PASSWORD_FAILED,
  UPDATE_AGENT_PASSWORD,
  UPDATE_AGENT_PASSWORD_SUCCESS,
} from "./ProfileActionType";
import { callApiV2 } from "util/apiCaller";
import { showNotification } from "../../util/utility";

export const updateAgentProfile = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AGENT_PROFILE_DETAIL,
      isUpdateProfile: true,
    });
    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/agent/${id}/update`,
      body: {
        agent: body,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Updated Profile details");
      dispatch({
        type: UPDATE_AGENT_PROFILE_SUCCESS,
        isUpdateProfile: false,
        agent: res?.data?.agent,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AGENT_PROFILE_FAILURE,
      isUpdateProfile: false,
    });
  }
};

export const fetchUpdateAgentDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_UPDATE_AGENT_DETAIL,
      isFetchUpdate: true,
    });

    let res = await callApiV2({
      method: "post",
      endpoint: `elevate/agent/${id}/details`,
    });

    if (res?.status === "Success") {
      dispatch({
        type: FETCH_UPDATE_AGENT_SUCCESS,
        activeAgent: res?.data,
        isFetchUpdate: false,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_UPDATE_AGENT_FAILED,
      isFetchUpdate: false,
    });
  }
};

export const agentPasswordUpdate =
  (newPass, oldPass, email) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_AGENT_PASSWORD,
        isFetchUpdate: true,
      });
      const Body = {
        newPassword: newPass,
        oldPassword: oldPass,
        contact_email: email,
      };
      let res = await callApiV2({
        method: "post",
        endpoint: `auth/admin/change-password`,
        body: Body,
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Password Change successfully.");
        dispatch({
          type: UPDATE_AGENT_PASSWORD_SUCCESS,
          passwordChangeResult: res?.data?.passwordChangeResult,
          isFetchUpdate: false,
        });
      }
      if (res?.status === "Error") {
        showNotification("error", res.data.message);
        dispatch({
          type: UPDATE_AGENT_PASSWORD_FAILED,
          isFetchUpdate: false,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_AGENT_PASSWORD_FAILED,
        isFetchUpdate: false,
      });
    }
  };

// export function updatingResetPassword(cred) {
//   return dispatch => {
//     dispatch({
//       type: UPDATING_RESET_PASSWORD,
//       isUpdatingResetPassword: true,
//     });
//     const body = {
//       reset_key: cred.reset_key,
//       password: cred.new_password,
//       email: cred.email,
//     };
//     return publicApiCaller(`v1/user/password/reset`, "post", body).then(res => {
//       if (res && res.result) {
//         dispatch(toggleResetPasswordSegment("confirmationScreen"));
//         dispatch({
//           type: UPDATING_RESET_PASSWORD,
//           isUpdatingResetPassword: false,
//         });
//       } else {
//         dispatch({
//           type: UPDATING_RESET_PASSWORD,
//           isUpdatingResetPassword: false,
//         });
//       }
//     });
//   };
// }
