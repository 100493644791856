import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import BottomDropdown, {
  BottomDropdownBody,
  BottomDropdownHeader,
} from "konnekt/BottomDropdown";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import SmartCard, {
  SmartCardBody,
  SmartCardFooter,
  SmartCardHeader,
} from "konnekt/SmartCard";

import { getCustomerNameInitials } from "util/utility";

import { updateActiveSmartCard } from "modules/Inbox/inboxStateActions";
import { EDIT, PERSONAL_DETAILS, VIEW } from "constants/smartCardsContants";

import useMediaQuery from "customHooks/useMediaQuery";
import useActiveCustomerNumber from "customHooks/useActiveCustomerNumber";

import { EditButton, InitialIcon } from "components/Smartcards/smartCardStyles";
import PersonalDetailsList from "./Components/PersonalDetailsList";
import { FlexCenterGap } from "CommonStyle/CommonStyles";
import Button from "konnekt/Buttons";
import Modal, { ModalHeader } from "konnekt/Modal";
import SendLeadModal from "modules/Inbox/pages/InboxPage/components/UserDetailsSidebar/components/SendLeadModal";

const PersonalDetailsCard = ({
  activeCustomer,
  customerAttributes,
  handleSaveBtnClick,
  customerList,
  ...rest
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery("desktop", "up");
  // console.log("customerList", activeCustomer);
  const [edit, setEdit] = useState(false);
  const [sendLeadModal, setSendLeadModal] = useState(false);
  const business_id = useSelector((state) => state.app?.user?.business_id);
  const BUSINESS_ID = process.env.REACT_APP_BUSINESS_ID;
  const handleCloseModal = () => setSendLeadModal(false);
  //const activeCustomerNumber = useActiveCustomerNumber();
  const activeCustomerNumber = `${activeCustomer?.country_code || ""}${
    activeCustomer?.phone_number || ""
  }`;

  const { activeSmartCard } = useSelector(
    (state) => state.inboxState.smartCard
  );

  const handleEditClick = () => {
    setEdit(true);
    //dispatch(updateActiveSmartCard({ name: PERSONAL_DETAILS, mode: EDIT }));
  };

  const handleShowMoreClick = () => {
    dispatch(updateActiveSmartCard({ name: PERSONAL_DETAILS, mode: VIEW }));
  };

  const handleBackBtnClick = () => {
    if (activeSmartCard.lastMode) {
      dispatch(
        updateActiveSmartCard({
          name: PERSONAL_DETAILS,
          mode: activeSmartCard.lastMode,
        })
      );
    } else {
      dispatch(updateActiveSmartCard(null));
    }
  };

  if (
    activeSmartCard.name !== null &&
    activeSmartCard.name !== PERSONAL_DETAILS
  ) {
    return null;
  }
  // console.log(activeCustomer);

  return (
    <>
      <SmartCard
        mb={!activeSmartCard.name && "12px"}
        p={activeSmartCard.name && { _: "0", mobileL: 0 }}
        activeSmartCard={activeSmartCard.name}
        {...rest}
      >
        {activeSmartCard.name === null && !edit && (
          <SmartCardHeader>
            <FlexCenterGap>
              <InitialIcon>
                {getCustomerNameInitials(activeCustomer)}
              </InitialIcon>
              <FlexCenterGap>
                <Box textAlign="center">
                  <Label
                    text={activeCustomer?.contact_name || activeCustomerNumber}
                    fontSize="1.4rem"
                    fontWeight="500"
                    wordBreak="break-word"
                  />
                  <span>{activeCustomer?.contact_phone}</span>
                </Box>
                {BUSINESS_ID === business_id && (
                  <Button
                    type="outline"
                    iconName={"leadGeneration"}
                    iconSize={"small"}
                    minWidth="auto"
                    title="Click to Lead Generate"
                    onClick={() => setSendLeadModal(true)}
                  />
                )}
              </FlexCenterGap>
            </FlexCenterGap>
            {customerList && (
              <EditButton
                onClick={handleEditClick}
                className="haptik-primary-text"
              >
                Edit
              </EditButton>
            )}
          </SmartCardHeader>
        )}
        <SmartCardBody
          bg={activeSmartCard.name && "#f5f5f5"}
          p={activeSmartCard.name && 0}
        >
          <PersonalDetailsList
            customerAttributes={customerAttributes}
            handleSaveBtnClick={handleSaveBtnClick}
            type="normal"
            edit={edit}
            setEdit={setEdit}
          />
        </SmartCardBody>
      </SmartCard>
      {/* send lead modal */}
      <Modal
        isOpen={sendLeadModal}
        onHide={handleCloseModal}
        pl="24"
        pr="24"
        maxHeight="90%"
        overflowY="auto"
        style={{ Maxwidth: "900px", minHeight: "70vh" }}
      >
        <ModalHeader
          variant="white"
          padding="0"
          className="border-0"
          onHide={handleCloseModal}
        >
          Send Lead
        </ModalHeader>
        <SendLeadModal
          activeCustomer={activeCustomer}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default PersonalDetailsCard;
