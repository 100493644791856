import React from "react";

export default function KiwiSvgImage(props) {
  return (
    <img
      className={props.targetClass ? props.targetClass : ""}
      style={props.style ? props.style : {}}
      src={`/assets/svg/${props.iconName}`}
      alt={props.alt ? props.alt : ""}
    />
  );
}
