import styled from "styled-components";
import Box from "konnekt/Box";

export const ProfileBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 12.9rem 12.9rem 12.9rem;
  @media only screen and (max-width: 768px) {
    overflow-y: scroll;
    padding: 0 2rem 13rem 2.1rem;
  }
`;
export const ProfileContainer = styled(Box)`
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.03);
  border-radius: 0.8rem;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  @media only screen and (max-width: 1242px) {
  }
`;
export const HadingLabel = styled(Box)`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  color: #212121;
  margin-bottom: 1rem;
  padding-left: 2rem;
`;
export const ImageBody = styled(Box)`
  width: 100%;
  position: relative;
  padding: 1.5rem;
`;
export const ProfileDetails = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;

  @media only screen and (max-width: 376px) {
    padding-left: 0%;
  }

  @media only screen and (max-width: 832px) {
    font-size: 1.4rem;
    display: block;
  }
`;
export const ProfileDetailsHading = styled(Box)`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1rem;
  align-items: center;
  letter-spacing: 0.1px;
  color: #212121;
  @media only screen and (max-width: 832px) {
    font-size: 1.4rem;
  }
`;
export const ProfileDetailsMessage = styled(Box)`
  font-weight: 400;
  padding-top: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  align-items: center;
  letter-spacing: 0.08625px;
  color: #919191;
  @media only screen and (max-width: 832px) {
    font-size: 1rem;
  }
`;
export const ProfileBorder = styled(Box)`
  border: 1px solid #f3f3f3;
  color: #f3f3f3;
  margin-top: 1.5rem;
`;
export const ProfileInput = styled(Box)`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;
export const ProfileInputBody = styled(Box)`
  padding: 3rem 0;
  @media only screen and (max-width: 768px) {
  }
`;
export const ProfileBtn = styled(Box)`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 832px) {
    margin-top: 1.8rem;
  }
`;
export const ProfileInputItem = styled(Box)`
  width: 100%;
  margin-right: 1rem;
  position: relative;
  border-radius: 0.4rem;
  @media only screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
`;
export const ProfilesInput = styled.input`
  width: 90%;
  height: 3.9rem;
  box-sizing: border-box;
  background: #ffffff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0.4rem;
  &::placeholder {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const ProfileInputIcon = styled.div`
  background: #f5f6fa;
  top: 0;
  right: 0;
  position: absolute;
  height: 4rem;
  width: 10%;
  @media only screen and (max-width: 767px) {
    left: 90%;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 555px) {
    left: 90%;
  }
`;
export const ProfileBox = styled(Box)`
  width: 7rem;
  height: 7rem;
  background: #ffdde7;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalFooterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: right;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;
