import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import componentLoader from "../components/componentLoader";
import ProtectRoute from "./protectRoute";
import SettingHeader from "../modules/SettingHeader/SettingHeader";
import { PAGE_LOAD_ATTEMPT } from "../constants/enums/appEnums";
import CreateNewBusinessDashboard from "modules/App/pages/CreateNewBusinessDashboard/CreateNewBusinessDashboard";
import SuperAdminLogin from "modules/App/pages/LoginPage/SuperAdminLogin";
import SuperAdminProjectPage from "modules/App/pages/SuperAdminProjectList/SuperAdminProjectPage";
const Manage = React.lazy(() =>
  componentLoader(
    () => import("../modules/Manage/ManagePageSuperAdmin"),
    PAGE_LOAD_ATTEMPT
  )
);

const NoPageFound = React.lazy(() =>
  componentLoader(
    () => import("modules/NoPageFound/NoFoundPage"),
    PAGE_LOAD_ATTEMPT
  )
);

const Plans = React.lazy(() =>
  componentLoader(() => import("modules/Plans"), PAGE_LOAD_ATTEMPT)
);

const data = {
  headerData: [
    {
      link: "/super-admin/manage/template",
      icon: "manage",
      name: "Manage",
      isExpand: true,
    },
  ],
  manageData: [
    {
      link: "/super-admin/manage/template",
      icon: "template",
      name: "Template Message",
    },
    {
      link: "/super-admin/manage/team-setting",
      icon: "team",
      name: "Team Setting",
    },
  ],
};

const adminSideBar = [
  {
    url: "/super-admin/manage/template",
    iconPropsFn: (isActive) => ({
      iconName: "template",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
    }),
    label: "Template Message",
  },
  {
    url: "/super-admin/manage/team-setting",
    iconPropsFn: (isActive) => ({
      iconName: "team",
      color: isActive ? "primary" : "darkGrey.60",
      iconSize: "xs",
      fill: "red",
    }),
    label: "Team Setting",
  },
];

const SuperAdminRoutes = ({ isAuthenticated, loginUser }) => {
  const { path } = useRouteMatch();

  return (
    <>
      {isAuthenticated && (
        <SettingHeader
          HeaderData={data.headerData}
          manageData={data.manageData}
        />
      )}
      <Switch>
        <Route
          path={`${path}/login`}
          exact
          component={(props) => (
            <SuperAdminLogin {...props} loginUser={loginUser} />
          )}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/business-dashboard`}
          component={(props) => <CreateNewBusinessDashboard {...props} />}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/project/:id`}
          component={(props) => <SuperAdminProjectPage {...props} />}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/manage`}
          component={(props) => (
            <Manage {...props} values={adminSideBar} adminPanel />
          )}
        />
        <ProtectRoute
          isAuthenticated={isAuthenticated}
          path={`${path}/plans`}
          component={(props) => <Plans {...props} />}
        />
        <Route path="*">
          <NoPageFound />
        </Route>
      </Switch>
    </>
  );
};

export default SuperAdminRoutes;
