import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "konnekt/Box";
import UserDetailsSidebar from "modules/Inbox/pages/InboxPage/components/UserDetailsSidebar";
import DetailsViewHeader from "modules/Inbox/pages/InboxPage/components/CustomerDetails/DetailsViewHeader";
import useOrganizationId from "customHooks/useOrganizationId";
import {
  fetchCustomerActiveChats,
  fetchOrderList,
  getAllNotes,
  getCustomerById,
  getCustomerEventsByCustomerId,
  setActiveCustomerAllUsersPage,
} from "modules/Customer/CustomerActions";
import useActiveCustomer from "customHooks/useActiveCustomer";
import { showNotification } from "util/utility";
import { getActiveProjectId } from "../../../App/AppSelectors";
import { getCampaignDetails, getConversationEvent } from "../../../Inbox/ThreadActions";

const CustomerDashboardPage = (props) => {
  const dispatch = useDispatch();

  const { channel_phone_number: channelPhoneNumber } = useActiveCustomer();

  const activeProjectId = useSelector(state => getActiveProjectId(state));

  const { activeSmartCard } = useSelector(
    (state) => state.inboxState.smartCard,
  );

  const organizationId = useOrganizationId();

  useEffect(() => {
    const customerId = props.match.params.id;

    if (activeProjectId && customerId) {

      dispatch(fetchCustomerActiveChats(activeProjectId, customerId),);

      dispatch(getCustomerById(activeProjectId, customerId),);

      dispatch(getCampaignDetails(activeProjectId, customerId,),);

      dispatch(getConversationEvent(activeProjectId, customerId),);

      dispatch(setActiveCustomerAllUsersPage(customerId));

    }
  }, [dispatch]);

  const handleRedirectbtnClick = () => {
    if (channelPhoneNumber && channelPhoneNumber.length > 0) {
      const url = `${window.location.origin}/inbox?channelPhoneNumber=${channelPhoneNumber}`;
      window.open(url, "_blank");
    } else {
      showNotification(
        "warning",
        "Message cannot be sent as user is not on WhatsApp",
      );
    }
  };

  return (
    <Box>
      {!activeSmartCard.name && (
        <DetailsViewHeader
          headerText="User details"
          handleGoBack={() => props.history.push("/contacts/list")}
          handleRedirectbtnClick={handleRedirectbtnClick}
        />
      )}
      <div style={{ marginTop: "70px" }}>
        <Box
          width="100%"
          bg="#f5f5f5"
          overflow="auto"
          borderLeft="1px solid #e9e9e9"
          noScrollBar
          height="calc(100vh - 50px)"
        >
          <UserDetailsSidebar/>
        </Box>
      </div>
    </Box>
  );
};

export default CustomerDashboardPage;
