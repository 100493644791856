export const FETCH_CAMPAIGN_STATS_DETAILS = "FETCH_CAMPAIGN_STATS_DETAILS";
export const FETCH_CAMPAIGN_STATS_DETAIL_SUCCESS =
  "FETCH_CAMPAIGN_STATS_SUCCESS";
export const FETCH_CAMPAIGN_STATS_FAILURE = "FETCH_CAMPAIGN_STATS_FAILURE";
export const FETCH_CAMPAIGN_LIST_DETAILS = "FETCH_CAMPAIGN_LIST_DETAILS";
export const FETCH_CAMPAIGN_LIST_DETAIL_SUCCESS = "FETCH_CAMPAIGN_LIST_SUCCESS";
export const FETCH_CAMPAIGN_LIST_FAILURE = "FETCH_CAMPAIGN_LIST_FAILURE";
export const FETCH_CAMPAIGN_LIST_EXCEL = "FETCH_CAMPAIGN_LIST_EXCEL";
export const FETCH_CAMPAIGN_LIST_EXCEL_SUCCESS =
  "FETCH_CAMPAIGN_LIST_EXCEL_SUCCESS";
export const FETCH_CAMPAIGN_LIST_EXCEL_FAILURE =
  "FETCH_CAMPAIGN_LIST_EXCEL_FAILURE";
export const NEW_CAMPAIGN_DETAILS = "NEW_CAMPAIGN_DETAILS";
export const NEW_CAMPAIGN_DETAILS_SUCCESS = "NEW_CAMPAIGN_DETAILS_SUCCESS";
export const NEW_CAMPAIGN_FAILED = "NEW_CAMPAIGN_FAILED";
export const CAMPAIGN_TEMPLATE_ID = "CAMPAIGN_TEMPLATE_ID";
export const CAMPAIGN_BODY_DETAILS = "CAMPAIGN_BODY_DETAILS";
export const CAMPAIGN_TEMPLATE_MESSAGE_ID = "CAMPAIGN_TEMPLATE_MESSAGE_ID";
export const CLEAR_NEW_CAMPAIGN_TEMPLATE = "CLEAR_NEW_CAMPAIGN_TEMPLATE";
export const UPDATE_CAMPAIGN_DETAILS = "UPDATE_CAMPAIGN_DETAILS";
export const FETCH_CAMPAIGN_ID_STATS_DETAILS =
  "FETCH_CAMPAIGN_ID_STATS_DETAILS";
export const FETCH_CAMPAIGN_ID_STATS_DETAIL_SUCCESS =
  "FETCH_CAMPAIGN_ID_STATS_SUCCESS";
export const FETCH_CAMPAIGN_ID_STATS_FAILURE =
  "FETCH_CAMPAIGN_ID_STATS_FAILURE";
export const FETCH_CAMPAIGN_ID_STATS_USER_DETAILS =
  "FETCH_CAMPAIGN_ID_STATS_USER_DETAILS";
export const FETCH_CAMPAIGN_ID_STATS_USER_DETAIL_SUCCESS =
  "FETCH_CAMPAIGN_ID_STATS_USER_SUCCESS";
export const FETCH_CAMPAIGN_ID_STATS_USER_FAILURE =
  "FETCH_CAMPAIGN_ID_STATS_USER_FAILURE";
export const UPDATE_INITIAL_DATA = "UPDATE_INITIAL_DATA";
export const FETCH_CAMPAIGN_CUSTOMER_COUNT = "FETCH_CAMPAIGN_CUSTOMER_COUNT";
export const UPDATE_SORT_BY = "UPDATE_SORT_BY";
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";
